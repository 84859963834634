import React, { Component } from 'react';
import Translate from '../Helpers/Translate';
import Fetcher from '../Helpers/Fetcher';
import { showToast } from '../Helpers/Toast';

class UploadAvatar extends Component {
    constructor(props) {
      super(props);
      this.state = {
        goBack:true,
        avatar:'',
      }
    }
    componentDidMount(){
        console.log(this.props);
        this.displayAvatar();
    }

    /*componentDidUpdate(previousProps, previousState){
      if (previousProps.unSavedData !== this.props.unSavedData) {

        this.displayAvatar();
      }
    }*/
    displayAvatar(force_image = ''){
        let $path = '';
        let $avatar_path = '';
        if(force_image !== '') $avatar_path = force_image;
        else $avatar_path = this.props.user_meta;
        console.log($avatar_path.bk_profile_picture_url);
        
        if($avatar_path.bk_profile_picture_url !== undefined) $path = process.env.REACT_APP_SITE_URL+'/coffrefort/avatar/'+$avatar_path.bk_profile_picture_url;		
        else if($avatar_path.ig_profile_picture_url !== undefined && $avatar_path.ig_profile_picture_url !== '') $path = $avatar_path.ig_profile_picture_url;
        else if($avatar_path.picture_facebook !== undefined) $path = $avatar_path.picture_facebook;
        else $path = process.env.REACT_APP_SITE_URL+'/assets/images/default_avatar.png';
        this.setState({
            avatar:$path
        })
    }
    changeAvatar = (evt) => {
        let value =  evt.target.value;          

        const files = evt.target.files[0];
        const that=this;
        const fr=new FileReader();
        
        fr.readAsDataURL(files);
        fr.addEventListener("load", function () {
            value = this.result;
            const avatar = document.getElementById('avatar');
            avatar.classList.add('loading');

            new Fetcher().callApi({
                api_path:'/helpers/upload_avatar/',
                api_data:{
                    "avatar_file": value
                },
                method:'POST',
            }).then((r) => {
                if(r.status === 'success') {
                    that.displayAvatar({bk_profile_picture_url:r.results})
                    showToast('app_well_saved');
                }
                else showToast('app_an_error_occured');
                
                avatar.classList.remove('loading');
            });      
                    
            /*(error) => {
                showToast(error.toString())
                avatar.classList.remove('loading');
            }*/
               
        });
        
    }
    render() {
      return (
        <div id="avatar" className="upload_avatar" style={{'backgroundImage':"url("+this.state.avatar+")"}}>
            <div className="add_file as_link">
                <label htmlFor="avatar_file"><Translate s="app_change" /></label>
                <input 
                    type="file"  
                    accept="image/png, image/jpeg"
                    className="file_input_id" 
                    id="avatar_file"
                    name="avatar_file" 
                    onChange={(e) => this.changeAvatar(e)} />
            </div>
        </div>
      )
    }
  }
export default UploadAvatar;