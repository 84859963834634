import React from 'react';
import BackButton from "./Components/BackButton";
import Translate from './Helpers/Translate';

class Help extends React.Component {  
    
    constructor(props) {
        super(props);
        
        this.state = {
        };   
    }
    componentDidMount() {
        var acc = document.getElementsByClassName("btn_accordion");
        var i;

        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function() {
                this.classList.toggle("active");
                var panel = this.nextElementSibling;
                if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
                } else {
                panel.style.maxHeight = panel.scrollHeight + "px";
                } 
            });
        }
    }
    render() {  
        return (
            
            <div id="bk_help" className="container  bg-white min-vh-100">

            
                <div className="row header header_icon">
                    <div className="col px-0">
                        <BackButton />
                        <div className="icon"></div>
                    </div>
                </div>

                    
                <div className="row content mt-5 mb-5">
                    <div className="col">
                        <h1 className="text-center text-andeslight mt-2 py-2">FAQ</h1>
                            
                        <iframe height="250" src="https://www.youtube.com/embed/ckbiLmK6YSg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        <i><Translate s="app_faq_tuto" /></i>

                        <div className="accordion my-4">
                            <button className="btn_accordion"><Translate s="app_faq_program" /></button>
                            <div className="panel_accordion">
                                <iframe height="250" src="https://www.youtube.com/embed/_hZsGeOBuH4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>

                            <button className="btn_accordion"><Translate s="app_faq_invoice" /></button>
                            <div className="panel_accordion">
                            <p><Translate s="app_faq_invoice_desc" /></p>
                            </div>

                            <button className="btn_accordion"><Translate s="app_faq_profiles" /></button>
                            <div className="panel_accordion">
                                <p><Translate s="app_faq_profiles_desc" /></p>
                            </div>

                            <button className="btn_accordion"><Translate s="app_faq_campaigns" /></button>
                            <div className="panel_accordion">
                                <p><Translate s="app_faq_campaigns_desc" /></p>
                            </div>

                            <button className="btn_accordion"><Translate s="app_faq_otherquestions" /></button>
                            <div className="panel_accordion">
                                <p><Translate s="app_faq_otherquestions_desc" /> <a href="mailto:partenariat@bilbokid.com">partenariat@bilbokid.com</a></p>
                            </div>
                        </div>
                    


                    </div>
                </div>
                
            </div>
        )
    }  
}

export default Help;