import React from 'react';
import {Link} from "react-router-dom";
import Fetcher from './Helpers/Fetcher';
import Translate from './Helpers/Translate';
import {openTab,swipeTab,unmountHammer} from './Components/Tabs';
import BackButton from "./Components/BackButton";
import { validateField, validateFormPartnership, errorClass } from './Helpers/Formvalidation';
import { showToast } from './Helpers/Toast';
import { Dialog } from '@capacitor/dialog';
import {renderButton,addLoadingButton,removeLoadingButton} from "./Forms/DealSaveButton";
import {AiOutlineQuestionCircle} from 'react-icons/ai';
import Loading from './Components/Loading';

import Moment from 'react-moment';
import 'moment/locale/fr';

const post_buttons = {
    ig:['igp','igr','igs'],
    fb:['fb'],
    bg:['bg'],
    yt:['yt'],
    sc:['sc'],
    tw:['tw'],
    bkc:['bkc'],
    tk:['tk'],
}
const post_titles = {
    igp: 'Post',
    igs: 'Story',
    igr: 'Reel',
    fb: 'Post',
    bg: 'Article',
    yt: 'Vidéo',
    sc: 'Snap',
    tw: 'Tweet',
    bkc: 'Contenu',
    tk: 'TikTok',
}

const post_fields = {
    igp:{
        post_type:{
            name:'igp',
        },
        post_src:{
            name:'',
            accept:"image/*,video/*",
            required:true,
            required_api:false,
            title: 'Ajouter un fichier (image/vidéo)',
            help:'',
        },
        post_url:{
            name:'',
            required:true,
            required_api:true,
            label:'program_fiche_facebook_url',
            error:'Merci de renseigner une URL valide commençant par https://www.instagram.com/p/',
            help:'aide_instagram',
        },
        post_like:{
            name:'',
            required:true,
            required_api:false,
            label:'program_fiche_facebook_likes',
            help:'',
        },
        post_comment:{
            name:'',
            required:true,
            required_api:false,
            label:'program_fiche_facebook_comments',
            help:'',
        },
        post_reach:{
            name:'',
            required:true,
            required_api:false,
            label:'program_fiche_instagram_comptesatteints',
            help:'aide_instagram_portee',
        },
        post_saved:{
            name:'',
            required:true,
            required_api:false,
            label:'program_fiche_instagram_saved',
            help:'',
        },
    },
    igr:{
        post_type:{
            name:'igr',
        },
        post_src:{
            name:'',
            accept:"image/*,video/*",
            required:true,
            required_api:true,
            title: 'Ajouter un fichier (image/vidéo)',
            help:'',
        },
        post_url:{
            name:'',
            required:true,
            required_api:true,
            label:'program_fiche_facebook_url',
            error:'Merci de renseigner une URL valide commençant par https://www.instagram.com/reel/',
            help:'aide_instagram',
        },
        post_like:{
            name:'',
            required:true,
            required_api:true,
            label:'program_fiche_facebook_likes',
            help:'',
        },
        post_comment:{
            name:'',
            required:true,
            required_api:true,
            label:'program_fiche_facebook_comments',
            help:'',
        },
        post_reach:{
            name:'',
            required:true,
            required_api:true,
            label:'program_fiche_instagram_comptesatteints',
            help:'aide_instagram_portee_reel',
        },
        post_view:{
            name:'',
            required:true,
            required_api:true,
            label:'program_fiche_instagram_vues',
            help:'aide_instagram_reel',
        },
        post_saved:{
            name:'',
            required:true,
            required_api:false,
            label:'program_fiche_instagram_saved',
            help:'',
        },
    },
    igs:{
        post_type:{
            name:'igs'
        },
        post_src:{
            name:'',
            accept:"image/*,video/*",
            required:true,
            required_api:true,
            title: 'Ajouter votre story',
            help:'',
        },
        /*post_url:{
            name:'',
            required:true,
            required_api:false,
            label:'program_fiche_story_url',
            error :'Merci de renseigner une URL valide'
        },*/
        post_view:{
            name:'',
            required:true,
            required_api:true,
            label:'program_fiche_instagram_impressions',
            help:'aide_instagram_story',
        },
        post_reach:{
            name:'',
            required:true,
            required_api:true,
            label:'program_fiche_instagram_comptesatteints',
            help:'aide_instagram_portee_story',
        },
        post_click:{
            name:'',
            required:false,
            required_api:false,
            label:'program_fiche_instagram_clics',
            help:'aide_program_fiche_instagram_clics',
        },
        post_like:{
            name:'',
            required:false,
            required_api:false,
            label:'program_fiche_instagram_story_like',
            help:'aide_program_fiche_instagram_story_like',
        },
        post_click_bis:{
            name:'',
            required:false,
            required_api:false,
            label:'program_fiche_instagram_clics_stickers',
            help:'aide_program_fiche_instagram_clics_stickers',
        },

    },
    fb:{
        post_type:{
            name:'fb',
            required:false,
            required_api:false,
        },
        post_src:{
            name:'',
            accept:"image/*,video/*",
            required:true,
            required_api:false,
            title: 'Ajouter un fichier (image/vidéo)',
            help:'',
        },
        post_url:{
            name:'',
            required:true,
            required_api:true,
            label:'program_fiche_facebook_url',
            error :'Merci de renseigner une URL valide',
            help:'',
        },
        post_like:{
            name:'',
            required:true,
            required_api:false,
            label:'program_fiche_facebook_likes',
            help:'',
        },
        post_comment:{
            name:'',
            required:true,
            required_api:false,
            label:'program_fiche_facebook_comments',
            help:'',
        },
        post_reach:{
            name:'',
            required:true,
            required_api:false,
            label:'program_fiche_instagram_comptesatteints',
            help:'aide_instagram_portee',
        },
    },
    bg:{
        post_type:{
            name:'bg'
        },
        post_url:{
            name:'',
            required:true,
            required_api:true,
            label:'program_fiche_article_url',
            error :'Merci de renseigner une URL valide',
            help:'',
        },
        post_view:{
            name:'',
            required:true,
            required_api:true,
            label:'program_fiche_article_pageview',
            help:'',
        },
    },
    yt:{
        post_type:{
            name:'yt',
            required:false,
            required_api:false,
        },
        post_url:{
            name:'',
            required:true,
            required_api:true,
            label:'program_fiche_youtube_url',
            error :'Merci de renseigner une URL valide',
            help:'',
        },
        post_view:{
            name:'',
            required:true,
            required_api:true,
            label:'program_fiche_youtube_views',
            help:'',
        },
        post_comment:{
            name:'',
            required:true,
            required_api:true,
            label:'program_fiche_facebook_comments',
            help:'',
        },
    },
    tw:{
        post_type:{
            name:'tw',
            required:false,
            required_api:false,
        },
        post_url:{
            name:'',
            required:true,
            required_api:true,
            label:'program_fiche_facebook_url',
            error :'Merci de renseigner une URL valide',
            help:'',
        },
        post_like:{
            name:'',
            required:true,
            required_api:true,
            label:'program_fiche_twitter_rt',
            help:'',
        },
        post_comment:{
            name:'',
            required:true,
            required_api:true,
            label:'program_fiche_twitter_bookmarks',
            help:'',
        },
    },
    tk:{
        post_type:{
            name:'tk',
        },
        post_src:{
            name:'',
            accept:"image/*,video/*",
            required:true,
            required_api:true,
            title: 'Ajouter un fichier (image/vidéo)',
            help:'',
        },
        post_url:{
            name:'',
            required:true,
            required_api:true,
            label:'program_fiche_facebook_url',
            error:'Merci de renseigner une URL valide',
            help:'',
        },
        post_like:{
            name:'',
            required:true,
            required_api:true,
            label:'program_fiche_facebook_likes',
            help:'',
        },
        post_comment:{
            name:'',
            required:true,
            required_api:true,
            label:'program_fiche_facebook_comments',
            help:'',
        },
        post_view:{
            name:'',
            required:true,
            required_api:true,
            label:'program_fiche_instagram_vues',
            help:'',
        },
        post_saved:{
            name:'',
            required:true,
            required_api:true,
            label:'program_fiche_instagram_saved',
            help:'',
        },
    },
    sc:{
        post_type:{
            name:'sc',
            required:false,
            required_api:false,
        },
        post_src:{
            name:'',
            accept:"image/*,video/*",
            required:true,
            required_api:true,
            title: 'Ajouter un fichier (image/vidéo)',
            help:'',
        },
        post_view:{
            name:'',
            required:true,
            required_api:true,
            label:'program_fiche_youtube_views',
            help:'',
        },
    },
    bkc:{
        post_type:{
            name:'bkc'
        },
        post_src:{
            name:'',
            accept:"image/*,video/*,application/msword,application/pdf,application/zip",
            required:false,
            required_api:false,
            title: 'Ajouter votre fichier',
            help:'',
        },
        post_url:{
            name:'',
            required:false,
            required_api:false,
            label:'program_fiche_content_wetransfer',
            error :'Merci de renseigner une URL valide',
            help:'program_fiche_content_wetransfer_help',
        },
    },    
}

class DealPartnership extends React.Component {  
    
    constructor(props) {
        super(props);
        
        this.entireForm = React.createRef();
        this.submitBtn = React.createRef();
        
        this.state = {
            isLoaded:true,
            readOnly:this.props.readOnly,
            user_meta: this.props.user_meta,
            user_posts: (this.props.user_posts?this.props.user_posts:{}),
            campaign: this.props.campaign_data,
            campaign_social_network : ((this.props.campaign_data.campaign_social_network !== null)?this.props.campaign_data.campaign_social_network.split(","):['bkc']),
            formErrors:[],
            formInit:[],
            unSavedData:false,
            progressBars:{},
            loadingStates:{},
            noPreviewStates:{},
            errorStates:{},
            killswitch:false,
        };   
    }
    componentDidMount(){
        //Init tabs
        swipeTab(this.state.isLoaded,'.bk_deal_partnership');
    }
    social_api_flag(iv){
        if(this.state.user_meta.fb_api === '1' && iv !== 'bkc' && iv !== 'igr' && iv !== 'igs' && iv !== 'bg' && iv !== 'yt'  && iv !== 'tw' && iv !== 'sc' && iv !== 'tk') return true;
        else return false;
    }
    componentWillUnmount(){
        unmountHammer('.bk_deal_partnership');
    } 
    refreshUserPosts(){ 
        new Fetcher().callApi({
            api_path:'/campaigns/get/'+this.state.campaign.uid_campaign,
            api_data:{},
            method:'GET',
        }).then((r) => {
            if(r.status === 'success'){
                this.setState({
                    isLoaded: true, 
                    user_posts:r.results.user_posts,         
                })   
            }
            else {
                showToast('app_an_error_occured');
            }
        });
    }
    createFormItem = (social_type) => {
        let cur_up = this.state.user_posts;
        let cur_u_errors = this.state.formErrors;
        let cur_u_init = this.state.formInit;
        let post = {};
        let post_error = []; 
        let post_init = []; 
        for (const [key, value] of Object.entries(post_fields[social_type])) {
            post[key] = value.name;
            if(value.required && !this.social_api_flag(key)) post_error[key] = 'error';
            else if(value.required_api  && this.social_api_flag(key)) post_error[key] = 'error';
            else post_error[key] = 'success';
            post_init[key] = true;

        }            
        post['reduced'] = false;
        
        if(cur_u_errors[social_type] === undefined)  cur_u_errors[social_type] = [];
        if(cur_u_init[social_type] === undefined)  cur_u_init[social_type] = [];
        if(cur_up[social_type] === undefined) {
            cur_up[social_type] = {};
            cur_up[social_type]['0'] = post;
            cur_u_errors[social_type]['0'] = post_error;
            cur_u_init[social_type]['0'] = post_init;
        }
        else {
            const kk = Object.keys(this.state.user_posts[social_type]).length;
            cur_up[social_type][kk] = post;
            cur_u_errors[social_type][kk] = post_error;
            cur_u_init[social_type][kk] = post_init;
        }
        
        this.setState({
            user_posts:cur_up,
            formErrors:cur_u_errors,
            formInit:cur_u_init,
        })
    } 
    openBlock = (e) =>{
        if(!this.state.readOnly){
            const el = e.currentTarget.parentNode;
            el.classList.remove('reduced');     
            el.classList.add('opened');        
        }
    }
    minimizeBlock(e){ 
        const el = e.target.parentNode.parentNode.parentNode;
        el.classList.remove('opened');     
        el.classList.add('reduced');    
    }
    deleteBlock = async (social_type,block_id) => {
        const { value } = await Dialog.confirm({
            title: 'Confirmation',
            message: `Êtes-vous sûr de vouloir supprimer?`,
        });
        if(value){
            
            //killswitch
            /*this.setState({
                killswitch:true
            });*/

            //Delete block from state
            let user_posts = {...this.state.user_posts};
            const uid_post = user_posts[social_type][block_id]['uid_post'];
            delete user_posts[social_type][block_id];
            this.setState(user_posts);

            //Delete form error from state
            let formErrors = {...this.state.formErrors};
            if(formErrors[social_type] !== undefined && formErrors[social_type][block_id] !== undefined) {
                delete formErrors[social_type][block_id];
                this.setState(formErrors);
            }
            
            //Delete from DB
            if(uid_post !== undefined){

                //Delete from DB
                new Fetcher().callApi({
                    api_path:'/campaigns/delete_post/',
                    api_data:{
                        "uid_campaign" : this.state.campaign.uid_campaign,
                        "uid_post": uid_post
                    },
                    method:'POST',
                }).then((r) => {
                    if(r.status === 'success'){
                        showToast('Supprimer');
                    }
                    else showToast('Erreur lors de la suppression');
                });
            }
        }


    } 
    m(n,d = 1){
        if(n < 1000) return n;
        let x=(''+n).length,p=Math.pow,dd=p(10,d)
        x-=x%3
        return Math.round(n*dd/p(10,x))/dd+" kMGTPE"[x/3]
    }
    renderFormItem(social_type){   
            const upkeys = Object.keys(this.state.user_posts);  //Post type list
            return(
                Object.values(this.state.user_posts).map((iup,kup) => {  
                    if(!post_buttons[social_type].includes(upkeys[kup])) return;
                    const sub_social = upkeys[kup];  //Post type 
                    const post_index = Object.keys(iup); //Post index list
                    return(
                        Object.values(iup).map((iupp,iupp_in) => {                            
                            let server_file = '';
                            let style = {};
                            let video = '';
                            let showBlank = false;
                            let kupp = post_index[iupp_in]; //Post index 
                            if(this.state.user_posts[sub_social][kupp]['post_thumbnail'] !== undefined && this.state.user_posts[sub_social][kupp]['post_thumbnail'] !== null && this.state.user_posts[sub_social][kupp]['post_thumbnail'] !== ''){
                                showBlank = true;
                                server_file = this.state.user_posts[sub_social][kupp]['post_thumbnail'];
                                if(this.state.user_posts[sub_social][kupp]['post_thumbnail'].substring(0, 5) === 'data:') style ={backgroundImage:"url('"+server_file+"')"};
                                else     style ={backgroundImage:"url('"+process.env.REACT_APP_SITE_URL+'/coffrefort/medias'+server_file+"')"};
                            }
                            else if(this.state.user_posts[sub_social][kupp]['post_src'] !== undefined && this.state.user_posts[sub_social][kupp]['post_src'] !== null){
                                server_file = this.state.user_posts[sub_social][kupp]['post_src'];
                                if(server_file !== '' && !server_file.startsWith('data') && (server_file.endsWith('jpg') || server_file.endsWith('jpeg') || server_file.endsWith('png'))) {
                                    showBlank = true;
                                    style ={backgroundImage:"url('"+process.env.REACT_APP_SITE_URL+'/coffrefort/medias'+server_file+"')"};
                                }
                                else if(server_file !== '' && !server_file.startsWith('data') && (server_file.endsWith('mov') || server_file.endsWith('mp4'))) {
                                    showBlank = true;
                                    video = '<source src="'+process.env.REACT_APP_SITE_URL+'/coffrefort/medias'+server_file+'" type="video/mp4">Your browser does not support the video tag.';
                                }
                                else {
                                    showBlank = false;
                                    style ={backgroundColor:"transparent"};
                                }
                            }
                            else {
                                showBlank = false;
                                style ={backgroundColor:"transparent"};
                            }
                            //If empty block from createFormItem func
                            if(this.state.user_posts[sub_social][kupp]['post_src'] === ''){
                                showBlank = true;
                            }

                            return(
                                
                                <div key={sub_social+kupp} id={sub_social+kupp} className={this.state.user_posts[sub_social][kupp].reduced === undefined?'reduced':'opened'}>
                                        
                                    {/* REDUCED BLOCK*/}
                                    <div className="reduced_block align-items-center my-3" onClick={(e) => this.openBlock(e)}>
                                        <div className="col-2 px-0"><span className="avatar rounded" style={style}>
                                        {(video !== '') && <video className="block_video"  preload="metadata" dangerouslySetInnerHTML={{__html: video}} />}
                                        </span></div>
                                        <div className="col-7">
                                            <div>{post_titles[sub_social]} {parseInt(kupp)+1}</div>
                                            <small  className="text-muted"><Moment locale="fr" fromNow>{this.state.user_posts[sub_social][kupp].post_timestamp !== null?this.state.user_posts[sub_social][kupp].post_timestamp:this.state.user_posts[sub_social][kupp].post_added_date_iso}</Moment></small>
                                        </div>
                                        <div className={"col-3 kpis "+(this.state.campaign.campaign_type === '14'?'text-end':'text-start')+" pe-2"}>
                                            
                                            {(() => {
                                                if(post_fields[sub_social]['post_like'] !== undefined && this.state.user_posts[sub_social][kupp].post_like !== ''){
                                                    return (

                                                        <span className="text-muted">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572"></path></svg>
                                                        &nbsp;{this.m(this.state.user_posts[sub_social][kupp].post_like)}
                                                        </span>
                                                    )
                                                }
                                            })()}
                                            
                                            {(() => {
                                                if(post_fields[sub_social]['post_comment'] !== undefined && this.state.user_posts[sub_social][kupp].post_comment !== ''){
                                                    return (
                                                        <span className="text-muted">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1" /><line x1="12" y1="12" x2="12" y2="12.01" /><line x1="8" y1="12" x2="8" y2="12.01" /><line x1="16" y1="12" x2="16" y2="12.01" /></svg>
                                                        &nbsp;{this.m(this.state.user_posts[sub_social][kupp].post_comment)}
                                                        </span>
                                                    )
                                                }
                                            })()}
                                            
                                            {(() => {
                                                if(post_fields[sub_social]['post_reach'] !== undefined && this.state.user_posts[sub_social][kupp].post_reach !== ''){
                                                    return (
                                                        <span className="text-muted">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <path d="M5.931 6.936l1.275 4.249m5.607 5.609l4.251 1.275" />
                                                        <path d="M11.683 12.317l5.759 -5.759" />
                                                        <circle cx="5.5" cy="5.5" r="1.5" />
                                                        <circle cx="18.5" cy="5.5" r="1.5" />
                                                        <circle cx="18.5" cy="18.5" r="1.5" />
                                                        <circle cx="8.5" cy="15.5" r="4.5" />
                                                        </svg>
                                                        &nbsp;{this.m(this.state.user_posts[sub_social][kupp].post_reach)}
                                                        </span>
                                                    )
                                                }
                                            })()}
                                            
                                            {(() => {
                                                if(post_fields[sub_social]['post_view'] !== undefined && this.state.user_posts[sub_social][kupp].post_view !== ''){
                                                    return (
                                                        <span className="text-muted">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="12" cy="12" r="2"></circle><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path></svg>
                                                        &nbsp;{this.m(this.state.user_posts[sub_social][kupp].post_view)}
                                                        </span>
                                                    )
                                                }
                                            })()}
                                            
                                            {(() => {
                                                if(this.state.user_posts[sub_social][kupp].post_extra !== undefined && this.state.campaign.campaign_type === '14'){
                                                    const post_extra  = JSON.parse(this.state.user_posts[sub_social][kupp].post_extra);
                                                    if(post_extra !== null && post_extra.extension !== undefined){
                                                        if(post_extra.extension === 'pdf' || post_extra.extension === 'docx' || post_extra.extension === 'doc' || post_extra.extension === 'zip') {
                                                            return (
                                                                <span className="picto_contenu picto_pdf"></span>
                                                            )
                                                        }
                                                        if(post_extra.extension === 'mp4' || post_extra.extension === 'ogg' || post_extra.extension === 'quicktime') {
                                                            return (
                                                                <span className="picto_contenu picto_video"></span>
                                                            )
                                                        }
                                                        if(post_extra.extension === 'jpg' || post_extra.extension === 'jpeg' || post_extra.extension === 'png') {
                                                            return (
                                                                <span className="picto_contenu picto_photo"></span>
                                                            )
                                                        }
                                                    }
                                                    else {
                                                        return (
                                                            <span className="picto_contenu picto_dl"></span>
                                                        )
                                                    }
                                                }
                                            })()}


                                        </div>
                                    </div>

                                    {/* FORM BLOCK*/}
                                    <div className="row form_block mb-3" id={"form_block_"+sub_social+kupp} >

                                        <div className="block_header">
                                            <h5 className="pb-0">
                                                {post_titles[sub_social]} {parseInt(kupp)+1}
                                            </h5>
                                            {(() => {
                                                if(sub_social === 'igs'){
                                                    return (
                                                        <div className="igs_disclaimer">
                                                            Attention à télécharger votre contenu et non un screen de vos statistiques
                                                        </div>
                                                    )
                                                }
                                            })()}
                                            <button type="button" className="minimize" onClick={(e) => this.minimizeBlock(e)}></button>
                                        </div>
                                        <div className="block_body">

                                        {(() => {
                                            if(this.social_api_flag(sub_social)){
                                                return (
                                                    <div className="alert alert-thumbsup mb-0">
                                                    Votre compte est connecté à Facebook, indiquez l'URL de votre post.  Vos statistiques remonteront automatiquement sous 24h.
                                                    </div>
                                                )
                                            }
                                        })()}
                                        {(() => {
                                            if(iupp['post_src'] !== undefined && post_fields[sub_social]['post_src'] !== undefined && !this.social_api_flag(sub_social)){
                                                
                                                return (
                                                    <div id={"add_file"+sub_social+kupp} className={"add_file mt-2 image_loaded"} style={style}>
                                                        {
                                                        //Progressbar 
                                                        (this.state.progressBars[sub_social] !== undefined && this.state.progressBars[sub_social][kupp] !== undefined) &&
                                                        <div className="progress"><div className="progress-bar" role="progressbar" id={"progress-"+sub_social+kupp} style={{width: this.state.progressBars[sub_social][kupp]+"%"}}></div></div>}
                                                        {/*
                                                        //Loading state
                                                        (this.state.loadingStates[sub_social] !== undefined && this.state.loadingStates[sub_social][kupp] !== undefined) &&
                                                        <div className={"loading_thumb "+this.state.loadingStates[sub_social][kupp]}><Loading heading={<Translate s="lbl_load_preview" />} /></div>*/}
                                                        {
                                                        //Error state
                                                        (this.state.errorStates[sub_social] !== undefined && this.state.errorStates[sub_social][kupp] !== undefined) &&
                                                        <div className={"small_text pt-3 bg-white "+this.state.errorStates[sub_social][kupp]}><span className="bico ico_refus"></span><p className="fs-7"><Translate s="form_error_file_upload" /></p></div>}

                                                        {
                                                        //Loading state
                                                        (this.state.noPreviewStates[sub_social] !== undefined && this.state.noPreviewStates[sub_social][kupp] !== undefined) &&
                                                        <div className={"small_text  pt-3 "+this.state.noPreviewStates[sub_social][kupp]}><Translate s="lbl_no_preview" /></div>}

                                                        {(video !== '') && <video className="block_video"  preload="metadata" dangerouslySetInnerHTML={{__html: video}} />}
                                                        

                                                        <label id={"labeladdfile"+sub_social+kupp} htmlFor={sub_social+kupp+"post_src"} className={this.handleFieldError(sub_social,kupp,'post_src')}>{post_fields[sub_social]['post_src']['title']}</label>
                                                        {!showBlank && <div className="file_name"><Translate s="lbl_no_preview" /> <br /><Translate s="lbl_loaded_file" /> : {this.grabPostExtra(this.state.user_posts[sub_social][kupp].post_extra,'file_name')}</div>}
                                                        <input 
                                                            type="file"
                                                            accept={post_fields[sub_social]['post_src']['accept']}
                                                            data-is_required={post_fields[sub_social]['post_src']['required']}
                                                            className="file_input_id" 
                                                            data-social_type={sub_social} 
                                                            data-post_id={kupp} 
                                                            id={sub_social+kupp+"post_src"} 
                                                            name="post_src" 
                                                            onChange={(e) => this.handleChange(e)} />                                                            
                                                            <div className="invalid-feedback">Merci de fournir un fichier</div>
                                                    </div>       
                                                )
                                            }
                                        })()}                         

                                        {(() => {
                                            if(iupp['post_url'] !== undefined && post_fields[sub_social]['post_url'] !== undefined){
                                                return (
                                                    <div className="row mt-2">
                                                        <div className="col-12 form-group">
                                                            <label htmlFor="post_url" className={'form-label-popovers '+(post_fields[sub_social]['post_url']['required']?'required':'')}><Translate s={post_fields[sub_social]['post_url']['label']} />
                                                            {post_fields[sub_social]['post_url']['help'] !== '' && <span className="popovers"><AiOutlineQuestionCircle /> <span className="popover_text"><Translate s={post_fields[sub_social]['post_url']['help']} /></span></span>}</label>
                                                            <input 
                                                                type="text" 
                                                                data-validation={((sub_social === 'igp')?"url_igp":(sub_social === 'igr')?"url_igr":"url")}
                                                                data-is_required={post_fields[sub_social]['post_url']['required']} 
                                                                className={`form-control ${this.handleFieldError(sub_social,kupp,'post_url')}`} data-social_type={sub_social} data-post_id={kupp} id={sub_social+kupp+"post_url"}name="post_url" value={this.state.user_posts[sub_social][kupp]['post_url']}  onChange={(e) => this.handleChange(e)} placeholder="" />
                                                            <div className="invalid-feedback">{post_fields[sub_social]['post_url']['error']}</div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })()}                    
                                        {(() => {
                                            if(iupp['post_like'] !== undefined && post_fields[sub_social]['post_like'] !== undefined && !this.social_api_flag(sub_social)){
                                                return (
                                                    <div className="row mt-2">
                                                        <div className="col-12 form-group">
                                                            <label htmlFor="post_like" className={'form-label fw-normal '+(post_fields[sub_social]['post_like']['required']?'required':'')}><Translate s={post_fields[sub_social]['post_like']['label']} />
                                                            {post_fields[sub_social]['post_like']['help'] !== '' && <span className="popovers"><AiOutlineQuestionCircle /> <span className="popover_text"><Translate s={post_fields[sub_social]['post_like']['help']} /></span></span>}
                                                            </label>
                                                            <input type="number" data-is_required={post_fields[sub_social]['post_like']['required']} className={`form-control ${this.handleFieldError(sub_social,kupp,'post_like')}`} data-social_type={sub_social} data-post_id={kupp} id={sub_social+kupp+"post_like"} name="post_like" value={this.state.user_posts[sub_social][kupp].post_like}  onChange={(e) => this.handleChange(e)} />
                                                            <div className="invalid-feedback">Merci de renseigner le nombre de likes</div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })()}                
                                        {(() => {
                                            if(iupp['post_comment'] !== undefined && post_fields[sub_social]['post_comment'] !== undefined && !this.social_api_flag(sub_social)){
                                                return (
                                                    <div className="row mt-2">
                                                        <div className="col-12 form-group">
                                                            <label htmlFor="post_comment" className={'form-label fw-normal '+(post_fields[sub_social]['post_comment']['required']?'required':'')}><Translate s={post_fields[sub_social]['post_comment']['label']} />
                                                            {post_fields[sub_social]['post_comment']['help'] !== '' && <span className="popovers"><AiOutlineQuestionCircle /> <span className="popover_text"><Translate s={post_fields[sub_social]['post_comment']['help']} /></span></span>}</label>
                                                            <input type="number" data-is_required={post_fields[sub_social]['post_comment']['required']} className={`form-control ${this.handleFieldError(sub_social,kupp,'post_comment')}`} data-social_type={sub_social} data-post_id={kupp} id={sub_social+kupp+"post_comment"} name="post_comment" value={this.state.user_posts[sub_social][kupp].post_comment}  onChange={(e) => this.handleChange(e)} />
                                                            <div className="invalid-feedback">Merci de renseigner le nombre de commentaires</div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })()}          
                                        {(() => {
                                            if(iupp['post_reach'] !== undefined && post_fields[sub_social]['post_reach'] !== undefined && !this.social_api_flag(sub_social)){
                                                return (
                                                    <div className="row mt-2">
                                                        <div className="col-12 form-group">
                                                            <label htmlFor="post_reach" className={'form-label-popovers '+(post_fields[sub_social]['post_reach']['required']?'required':'')}><Translate s={post_fields[sub_social]['post_reach']['label']} />{post_fields[sub_social]['post_reach']['help'] !== '' && <span className="popovers"><AiOutlineQuestionCircle /> <span className="popover_text"><Translate s={post_fields[sub_social]['post_reach']['help']} /></span></span>}</label>
                                                            <input type="number" data-is_required={post_fields[sub_social]['post_reach']['required']} className={`form-control ${this.handleFieldError(sub_social,kupp,'post_reach')}`} data-social_type={sub_social} data-post_id={kupp} id={sub_social+kupp+"post_reach"} name="post_reach" value={this.state.user_posts[sub_social][kupp].post_reach}  onChange={(e) => this.handleChange(e)} />
                                                            <div className="invalid-feedback">Merci de renseigner le reach</div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })()}  
                                        {(() => {
                                            if(iupp['post_view'] !== undefined && post_fields[sub_social]['post_view'] !== undefined && !this.social_api_flag(sub_social)){
                                                return (
                                                    <div className="row mt-2">
                                                        <div className="col-12 form-group">
                                                            <label htmlFor="post_view" className={'form-label-popovers '+(post_fields[sub_social]['post_view']['required']?'required':'')}>{social_type === 'ig'?<Translate s={post_fields[sub_social]['post_view']['label']} />:<Translate s={post_fields[sub_social]['post_view']['label']} />}
                                                            {post_fields[sub_social]['post_view']['help'] !== '' && <span className="popovers"><AiOutlineQuestionCircle /> <span className="popover_text"><Translate s={post_fields[sub_social]['post_view']['help']} /></span></span>}
                                                            </label>
                                                            <input type="number" data-is_required={post_fields[sub_social]['post_view']['required']} className={`form-control ${this.handleFieldError(sub_social,kupp,'post_view')}`} data-social_type={sub_social} data-post_id={kupp} id={sub_social+kupp+"post_view"} name="post_view" value={this.state.user_posts[sub_social][kupp].post_view}  onChange={(e) => this.handleChange(e)} />
                                                            <div className="invalid-feedback">Merci de renseigner le nombre de vues</div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })()}
                                        {(() => {
                                            if(iupp['post_click'] !== undefined && post_fields[sub_social]['post_click'] !== undefined && !this.social_api_flag(sub_social)){
                                                return (
                                                    <div className="row mt-2">
                                                        <div className="col-12 form-group">
                                                            <label htmlFor="post_click"  className={'form-label-popovers '+(post_fields[sub_social]['post_click']['required']?'required':'')} ><Translate s={post_fields[sub_social]['post_click']['label']} />
                                                            {post_fields[sub_social]['post_click']['help'] !== '' && <span className="popovers"><AiOutlineQuestionCircle /> <span className="popover_text"><Translate s={post_fields[sub_social]['post_click']['help']} /></span></span>}
                                                            </label>
                                                            <input type="number" data-is_required={post_fields[sub_social]['post_click']['required']} className={`form-control ${this.handleFieldError(sub_social,kupp,'post_click')}`} data-social_type={sub_social} data-post_id={kupp} id={sub_social+kupp+"post_click"} name="post_click" value={this.state.user_posts[sub_social][kupp].post_click}  onChange={(e) => this.handleChange(e)} />
                                                            <div className="invalid-feedback">Merci de renseigner le nombre de clicks</div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })()}
                                        {(() => {
                                            if(iupp['post_click_bis'] !== undefined && post_fields[sub_social]['post_click_bis'] !== undefined && !this.social_api_flag(sub_social)){
                                                return (
                                                    <div className="row mt-2">
                                                        <div className="col-12 form-group">
                                                            <label htmlFor="post_click_bis" className={'form-label-popovers '+(post_fields[sub_social]['post_click_bis']['required']?'required':'')}><Translate s={post_fields[sub_social]['post_click_bis']['label']} />
                                                            {post_fields[sub_social]['post_click_bis']['help'] !== '' && <span className="popovers"><AiOutlineQuestionCircle /> <span className="popover_text"><Translate s={post_fields[sub_social]['post_click_bis']['help']} /></span></span>}
                                                            </label>
                                                            <input type="number" data-is_required={post_fields[sub_social]['post_click_bis']['required']} className={`form-control ${this.handleFieldError(sub_social,kupp,'post_click_bis')}`} data-social_type={sub_social} data-post_id={kupp} id={sub_social+kupp+"post_click_bis"} name="post_click_bis" value={this.state.user_posts[sub_social][kupp].post_click_bis}  onChange={(e) => this.handleChange(e)} />
                                                            <div className="invalid-feedback">Merci de renseigner le nombre de clicks</div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })()}
                                        {(() => {
                                            if(iupp['post_saved'] !== undefined && post_fields[sub_social]['post_saved'] !== undefined && !this.social_api_flag(sub_social)){
                                                return (
                                                    <div className="row mt-2">
                                                        <div className="col-12 form-group">
                                                            <label htmlFor="post_saved" className={'form-label fw-normal '+(post_fields[sub_social]['post_saved']['required']?'required':'')}><Translate s={post_fields[sub_social]['post_saved']['label']} />{post_fields[sub_social]['post_saved']['help'] !== '' && <span className="popovers"><AiOutlineQuestionCircle /> <span className="popover_text"><Translate s={post_fields[sub_social]['post_saved']['help']} /></span></span>}</label>
                                                            <input type="number" data-is_required={post_fields[sub_social]['post_saved']['required']} className={`form-control ${this.handleFieldError(sub_social,kupp,'post_saved')}`} data-social_type={sub_social} data-post_id={kupp} id={sub_social+kupp+"post_saved"} name="post_saved" value={this.state.user_posts[sub_social][kupp].post_saved}  onChange={(e) => this.handleChange(e)} />
                                                            <div className="invalid-feedback">Merci de renseigner le reach</div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })()}  
                                    
                                        <button className="btn-text float-end" type="button" onClick={() => this.deleteBlock(sub_social,kupp)}><small>Supprimer</small></button>
                                        
                                    </div>
                                </div>
                            </div>
                            )
                            
                    
                        })
                    )
                
                })
            )        
    }
    grabPostExtra(json,field){
        if(json !== undefined){
            const post_extra  = JSON.parse(json);
            if(post_extra !== null && post_extra[field] !== undefined) return  post_extra[field];
            else return '';
        }
        else return '';
    }
    handleFieldError = (sub_social,post_id,posttype) => {
        if(
            (this.state.formInit[sub_social] !== undefined && this.state.formInit[sub_social][post_id] !== undefined && !this.state.formInit[sub_social][post_id][posttype]) &&
            (this.state.formErrors[sub_social] !== undefined && this.state.formErrors[sub_social][post_id] !== undefined)
        ) return errorClass(this.state.formErrors[sub_social][post_id][posttype])
        else return '';
    }
    handleChange(evt){
        let cur_up = this.state.user_posts;

        const name = evt.target.name;
        const type = evt.target.type;
        const social_type = evt.target.getAttribute("data-social_type");
        const post_id = evt.target.getAttribute("data-post_id");
        let value = type === "checkbox" ? evt.target.checked : evt.target.value;          

        //Logic for  post_src
        if(name === 'post_src'){
            //Hide add file input
            const addfile = document.getElementById("labeladdfile"+social_type+post_id);
            addfile.classList.add('d-none');


            //Show progress bar
            this.setState({
                progressBars:{
                    ...this.state.progressBars,
                    [social_type]:{
                        [post_id]:2, //default to 2%
                    }
                },
                saveBtnEls: addLoadingButton(this.submitBtn) //Disable disable submit
            });
           
            //Parse file
            const files = evt.target.files[0];
            cur_up[social_type][post_id]['file_name'] = files.name;
            this.setPostState(evt,cur_up,social_type,post_id);

            //load thumbnail if video
            const fileExtension  = files.name.split('.').pop();
            if(['mp4','mov','quicktime','mkv','avi'].includes(fileExtension.toLowerCase())) this.getVideoCover(files,social_type,post_id);
            else if(['jpg','jpeg','png'].includes(fileExtension.toLowerCase())) this.getImageCover(files,social_type,post_id);
            else {
                this.setState({
                    noPreviewStates :{
                        ...this.state.noPreviewStates,
                        [social_type]:{
                            [post_id]:'d-block'
                        }
                    }
                });
            }

            //parseFile
            this.parseFile(files,social_type,post_id);
            
        }
        else {
            cur_up[social_type][post_id][name] = value;
            this.setPostState(evt,cur_up,social_type,post_id);
        }
        //End logic post_src

    }
    setPostState = (evt,cur_up,social_type,post_id) =>{
        this.setState({
            user_posts:cur_up
        },() => {
            var err = validateField(evt.target);
            let cur_up_errors = this.state.formErrors;
            let cur_up_inits = this.state.formInit;
            if(cur_up_errors[social_type] === undefined) cur_up_errors[social_type] = [];
            if(cur_up_errors[social_type][post_id] === undefined) cur_up_errors[social_type][post_id] = [];
            if(cur_up_inits[social_type] === undefined) cur_up_inits[social_type] = [];
            if(cur_up_inits[social_type][post_id] === undefined) cur_up_inits[social_type][post_id] = [];
            cur_up_errors[social_type][post_id][evt.target.name] = (err.fieldValid?'success':'error');
            cur_up_inits[social_type][post_id][evt.target.name] = false;
            this.setState({
                unSavedData:true,
                formErrors:cur_up_errors,
                formInit:cur_up_inits
            },() => {
                //Validate form
                if(validateFormPartnership(this.state.formErrors) === 'success'){
                    this.setState({greenLight:''});
                }
                else{
                    this.setState({greenLight:'disable'});
                }
            });
        });
    }
    timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    getImageCover = (file, social_type, post_id) => {
        const self = this; 
        var fr=new FileReader();
        fr.readAsDataURL(file);
        var that = this;
        fr.addEventListener("load", function () {
            let cur_up = self.state.user_posts;
            cur_up[social_type][post_id]['post_thumbnail'] = this.result;
            self.setState({
                user_posts:cur_up
            });
        });
    }
    getVideoCover = (file, social_type, post_id, seekTo = 0.0) => {
            const self = this;
        return new Promise((resolve, reject) => {

            // load the file to a video player
            const videoPlayer = document.createElement('video');
            videoPlayer.setAttribute('src', URL.createObjectURL(file));
            videoPlayer.setAttribute('width', 350);
            videoPlayer.setAttribute('height', 350);
            videoPlayer.load();
            videoPlayer.addEventListener('error', (ex) => {
                console.log("error when loading video file", ex);
                //show no preview available but your file is uploading
                self.setState({
                    noPreviewStates :{
                        ...this.state.noPreviewStates,
                        [social_type]:{
                            [post_id]:'d-block'
                        }
                    }
                });

            });
            // load metadata of the video to get video duration and dimensions
            videoPlayer.addEventListener('loadedmetadata', () => {
                // seek to user defined timestamp (in seconds) if possible
                if (videoPlayer.duration < seekTo) {
                    console.log("video is too short.");
                    return;
                }
                // delay seeking or else 'seeked' event won't fire on Safari
                setTimeout(() => {
                  videoPlayer.currentTime = seekTo;
                }, 200);
                // extract video thumbnail once seeking is complete
                videoPlayer.addEventListener('seeked', () => {
                    // define a canvas to have the same dimension as the video
                    const canvas = document.createElement("canvas");
                    canvas.width = (videoPlayer.videoWidth/2);
                    canvas.height = (videoPlayer.videoHeight/2);
                    // draw the video frame to canvas
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
                    // return the canvas image as a blob
                    ctx.canvas.toBlob(
                        blob => {
                            resolve(blob);
                        },
                        "image/jpeg",
                        0.50 /* quality */
                    );
                    //Load canvas to DOM > post_thumbnail
                    const base64thumb = canvas.toDataURL();

                    let cur_up = self.state.user_posts;
                    cur_up[social_type][post_id]['post_thumbnail'] = base64thumb;
                    self.setState({
                        user_posts:cur_up
                    });

                });
            });
        });
    }
    parseFile = async (file,social_type,post_id) => {
        var fileSize   = file.size;
        //var fileSize = blob.length;
        var fileName   = file.name;
        var fileExtension  = fileName.split('.').pop();
        var chunkSize  = 4500000; //1500000
        var chunkNum     = 0;
        var offset     = 0;
        var self       = this; // we need a reference to the current object
        var chunkReaderBlock = null;
        var chunkTotal = Math.ceil((fileSize/chunkSize));
        
        var readEventHandler = async function(b) {

            chunkNum++;
            offset += b.loaded; //b.length;
            await self.timeout(500); //avoid server 504 gateaway timeout
            const res = await self.sa_send(
                'split',
                b.target.result,
                offset,
                chunkNum,
                fileSize,
                fileName,
                fileExtension,
                chunkTotal,
                social_type,
                post_id
            );
            if(res.status === 'success'){
                //build progress bar
                self.setState({
                    progressBars:{
                        ...self.state.progressBars,
                        [social_type]:{
                            [post_id]:Math.ceil((chunkNum*100)/chunkTotal)
                        }
                    }
                }/*,() =>{
                    if(self.state.progressBars[social_type][post_id] >= 100){
                        //hide progress bar after
                        setTimeout(function(){
                            //console.log(self.state.progressBars[social_type][post_id]);
                            let state = {...self.state.progressBars};
                            delete state[social_type][post_id];
                            self.setState(state);
                        }, 750);
                    }
                }*/);

                if (chunkNum >= chunkTotal) {
                    self.sa_send(
                        'merge',
                        b.target.result,
                        offset,
                        chunkNum,
                        fileSize,
                        fileName,
                        fileExtension,
                        chunkTotal,
                        social_type,
                        post_id
                    ); 
                    return;
                }
                // of to the next chunk
                chunkReaderBlock(offset, chunkSize, file);
            }
                    
        }
        
        chunkReaderBlock = function(_offset, length, _file) {
            /*if(self.state.killswitch) {                
                self.setState({
                    killswitch:false
                });
                removeLoadingButton(self.submitBtn,self.state.saveBtnEls);
                return;
            }*/
            var r = new FileReader();
            var blob = _file.slice(_offset, length + _offset);
            r.onload = readEventHandler;
            r.readAsDataURL(blob);
        }
    
        chunkReaderBlock(offset, chunkSize, file);
    }

    sa_send = async (type,chunk, offset,chunkNum,fileSize,fileName,fileExtension,chunkTotal,social_type,post_id) => {
        let handle = '';
        const self = this;
        if(type === 'merge'){
            //show endless progress bar behaviour
            const progbar = document.getElementById("progress-"+social_type+post_id);
            if(progbar !== null){
                const progbarpar = progbar.parentElement;
                progbar.style.transition = 'none';
                let p = 100;
                let direction;
                handle  = setInterval(function() {
                    if(p <= 1) {
                        direction = '+';
                        progbarpar.classList.remove('justify-content-end');
                    }
                    if(p >= 100) {
                        direction = '-';
                        progbarpar.classList.add('justify-content-end');
                    }
                    direction === '+' ? p++ : p--;
                    progbar.style.width = p+'%';
                }, 0);
            }
        }
        return new Fetcher().callApi({
            api_path:'/campaigns/worker_assets/'+type,
            api_data:{
                "id_campaign" : this.state.campaign.id_campaign,
                "social_type": social_type,
                "post_id": post_id,
                //"files": files,
                chunkNum:chunkNum,
                chunkTotal:chunkTotal,
                offset:offset,
                fileSize:fileSize,
                fileName:fileName,
                fileExtension:fileExtension,
                chunk:chunk,
            },
            method:'POST',
        }).then((r) => {

            //hide error
            this.setState({
                errorStates:{
                    ...this.state.errorStates,
                    [social_type]:{
                        [post_id]:'d-none'
                    }
                }
            });
            
            if(type === 'merge'){
                //Hide endless progress bar behaviour
                clearInterval(handle);
                setTimeout(function(){
                    let state = {...self.state};
                    delete state['progressBars'][social_type][post_id];
                    if(Object.keys(state['noPreviewStates']).length !== 0 && state['noPreviewStates'][social_type] !== undefined) delete state['noPreviewStates'][social_type][post_id]; //Remove no preview state   
                    self.setState(state);
                }, 750);
                
                //Show add file input
                const addfile = document.getElementById("labeladdfile"+social_type+post_id);
                if(addfile!== null) addfile.classList.remove('d-none');

            }

            if(r.status === 'error'){ //&& r.message == 'server_error'){
                
                    //show error
                    this.setState({
                        errorStates:{
                            ...this.state.errorStates,
                            [social_type]:{
                                [post_id]:'d-block'
                            }
                        }
                    });
                    //Flush input so we can reupload file
                    document.getElementById(social_type+post_id+"post_src").value = '';
                    //Show upload file again
                    const addfile = document.getElementById("labeladdfile"+social_type+post_id);
                    if(addfile!== null) addfile.classList.remove('d-none');
                    //Put submit button back
                    removeLoadingButton(this.submitBtn,this.state.saveBtnEls);
            }
            if(r.message == 'final'){
                let cur_up = this.state.user_posts;
                if(r.status === 'success'){                    
                    //Loading back the post_src into the state
                    for (const [social_type, value] of Object.entries(r.results)) {
                        for (const [post_id, v] of Object.entries(value)) {
                            cur_up[social_type][post_id]['post_src'] = v.post_src;
                            cur_up[social_type][post_id]['post_src_extension'] = v.post_src_extension;
                            if(!['jpg', 'jpeg', 'png', 'mp4', 'mov', 'quicktime'].includes(v.post_src_extension)){
                                cur_up[social_type][post_id]['post_extra'] = JSON.stringify({'file_name':fileName});
                            }
                        }
                    }    

                    this.setState({
                        user_posts:cur_up
                    }); 
                    
                    //enable submit
                    removeLoadingButton(this.submitBtn,this.state.saveBtnEls);
                }
                else {
                    //Flush cur_up 
                    cur_up[social_type][post_id]['post_src'] = ''
                    cur_up[social_type][post_id]['post_src_extension'] = '';

                    //show error
                    this.setState({
                        errorStates:{
                            ...this.state.errorStates,
                            [social_type]:{
                                [post_id]:'d-block'
                            }
                        }
                    });

                }
            }
            return r;
        });
    }

    handleSubmit = (e) =>  {
        if(validateFormPartnership(this.state.formErrors) === 'success'){
            //this.setState({isLoaded:false});
            const saveBtnEls = addLoadingButton(e);
            new Fetcher().callApi({
                api_path:'/campaigns/save_partnership/',
                api_data:{
                    "uid_campaign" : this.state.campaign.uid_campaign,
                    "user_posts": this.state.user_posts
                },
                method:'POST',
            }).then((r) => {
                if(r.status === 'success'){
                    showToast('app_well_saved');
                    this.refreshUserPosts(); //refresh post state to avoid dedups
                    this.setState({unSavedData:false});
                    removeLoadingButton(e,saveBtnEls);
                }
                else {
                    showToast('app_an_error_occured');   
                    removeLoadingButton(e,saveBtnEls);
                }
            });             
        }
        else{
            let cur_form_init = this.state.formInit;
            const form_init_keys = Object.keys(this.state.formInit);
            Object.values(this.state.formInit).forEach((fe_el,fe_i) => {
                fe_el.forEach((fee_el,fee_i) => {
                    Object.entries(fee_el).forEach((feee_el) => {
                        cur_form_init[form_init_keys[fe_i]][fee_i][feee_el[0]] = false;
                    });
                });
            });

            this.setState({
                formInit:cur_form_init
            });
            showToast('app_help_formerrors');
        }

    }
    render() {
        let bk_tabs_active, $display = '';
        return (
            <div id="bk_campaign" className="bk_deal_partnership container brand_page fixed_header  bg-white min-vh-100 px-md-0">
                <div className="row header">
                    <div className="col px-0">
                        <BackButton unSavedData={this.state.unSavedData} />
                        <div className="logo_marque" style={{'backgroundImage':"url("+process.env.REACT_APP_SITE_URL+'/coffrefort/brands/'+this.state.campaign.id_marque+'/'+this.state.campaign.logo_marque+")"}}></div>
                        <Link className="top_right_button ico_help" to="/help"></Link>
                    </div>
                </div>
                <div className="row content mt-5 px-md-5">
                    <div className="col-12 text-center mt-3">
                        <h2 className="text-andeslight"><Translate s="app_nav_deal_partnership" /></h2>
                    </div>
                    <form ref={this.entireForm} className="col-12 mt-2">
                        

                        <div className="bk_tabs w3-black row">
                            {this.state.campaign_social_network.map((iv,kv) => {
                                if(kv === 0) bk_tabs_active = 'bk_tabs_active';
                                else bk_tabs_active = '';
                                return (
                                    <button type="button" className={"bk_tabs_item bk_tabs_button tablink col "+bk_tabs_active} onClick={(e) => openTab(e,iv)}><Translate s={'lbl_'+iv} /></button>
                                )
                            })}                            
                        </div>
                        
                        {this.state.campaign_social_network.map((iv,kv) => {
                                if(kv !== 0) $display = 'none';
                                else $display = '';
                                return (
                                    <div id={iv} className="tabcontent row gx-2 px-1"  style={{display:$display}}>

                                        <div id={"form_ctnr_"+iv} className="col-12">            
                                             {this.renderFormItem(iv)}
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                            {(() => {
                                                if(!this.state.readOnly){
                                                    return(
                                                        post_buttons[iv].map((ib,kb) =>{
                                                            return(
                                                                    <div className="col text-center px-0">
                                                                        <button type="button" className="btn btn-primary" onClick={() => this.createFormItem(ib)}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                                <line x1="12" y1="5" x2="12" y2="19" />
                                                                                <line x1="5" y1="12" x2="19" y2="12" />
                                                                            </svg> 
                                                                            {post_titles[ib]}
                                                                        </button>
                                                                    </div>
                                                                )
                                                        })
                                                    )
                                                }
                                            })()}
                                            </div>
                                        
                                        </div>

                                    </div>

                                )
                        })}
                        <div className={"alert alert-warning mt-4 "+(this.state.readOnly?'d-none':'')} role="alert"><Translate s="app_help_postintegration" /> <a href="mailto:partenariat@bilbokid.com">partenariat@bilbokid.com</a>.</div>
                    </form>

                </div>
                

                
                <div className="fixed-bottom bg-white gradient-top">
                    <div ref={this.submitBtn} className={"d-grid p-3 mt-4 btn-bottom-ctnr "+(this.state.readOnly?'d-none':'')}>
                    {renderButton((e) => this.handleSubmit(e))}
                    </div>
                </div>
            </div>
        )
    }  
}

export default DealPartnership;