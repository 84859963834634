import React, { Component } from 'react';
import Translate from '../Helpers/Translate';
import Fetcher from '../Helpers/Fetcher';
import { showToast } from '../Helpers/Toast';

class UploadImage extends Component {
    constructor(props) {
      super(props);
      this.state = {
        goBack:true,
        image:'',
        has_image:false,
      }
    }
    componentDidMount(){
        this.displayThumbnail();
    }
    componentDidUpdate(previousProps, previousState){
      if (previousProps.pic_url !== this.props.pic_url) {
        this.displayThumbnail();
      }
    }
    displayThumbnail(force_image = ''){
        let $path = '';
        let $image_path = '';
        let has_image = false;
        if(force_image !== '') $image_path = force_image;
        else $image_path = this.props.pic_url;
        
        if($image_path !== '') {
          has_image = true;
          $path = process.env.REACT_APP_SITE_URL+'/coffrefort/'+$image_path;		
        }
        else $path = process.env.REACT_APP_SITE_URL+'/assets/images/default_avatar.png';
        this.setState({
          image:$path,
          has_image:has_image
        })
    }
    changeImage = (evt) => {
        let value =  evt.target.value;          
        const files = evt.target.files[0];
        const image_id = evt.target.getAttribute('id');
        const image = document.getElementById(image_id);
        
        const that=this;
        const fr=new FileReader();
        
        fr.readAsDataURL(files);
        fr.addEventListener("load", function () {
            value = this.result;
            image.classList.add('loading');

            new Fetcher().callApi({
                api_path:that.props.api_endpoint,
                api_data:{
                    "image_file": value,
                    "image_folder" : that.props.image_folder,
                    "meta_name" : that.props.db_field
                },
                method:'POST',
            }).then((r) => {
                if(r.status === 'success') {
                    const image_file = r.results
                    that.displayThumbnail(image_file)
                    showToast('app_well_saved');
                    that.props.setFormFields('add',that.props.db_field,image_file);
                    that.props.setTotImages('inc');
                }
                else showToast('app_an_error_occured');
                
                image.classList.remove('loading');
            });      
                    
            /*(error) => {
                showToast(error.toString())
                avatar.classList.remove('loading');
            }*/
               
        });
        
    }
    deleteImage = (meta,image_file) => {
      new Fetcher().callApi({
          api_path:this.props.api_delete_endpoint,
          api_data:{
              "meta_name": meta,
              'pic_url' : this.state.image
          },
          method:'POST',
      }).then((r) => {
          if(r.status === 'success') {
              this.props.setFormFields('delete',this.props.db_field);
              this.props.setTotImages('dec');

              this.setState({
                image:process.env.REACT_APP_SITE_URL+'/assets/images/default_avatar.png',
                has_image:false,
              });

              showToast('app_well_saved');        


          }
          else showToast('app_an_error_occured');
      });    

    }
    render() {
      return (

        <div className="col-auto mb-3">
          <div className={"upload_block rounded "+(this.state.has_image?'has_image':'')} style={{'backgroundImage':"url("+this.state.image+")"}}>
                <div className="delete_image" onClick={() => this.deleteImage(this.props.db_field,this.props.db_field )}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24}
                      viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path d="M0 0h24v24H0z" stroke="none" /><path d="M18 6L6 18" /><path d="M6 6L18 18" /></svg>
                </div>
                <label htmlFor={this.props.db_field}>
                  <svg xmlns="http://www.w3.org/2000/svg"className="icon iconplus" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path d="M0 0h24v24H0z" stroke="none" /><path d="M12 5v14M5 12h14" /></svg>
                  <span className="upload_block_text"><Translate s="app_add" /></span>
                </label>
                <input 
                    type="file"  
                    accept="image/png, image/jpeg"
                    className="file_input_id d-none" 
                    data-is_required={this.props.is_required}
                    id={this.props.db_field}
                    name={this.props.name}
                    data-value={this.props.pic_url}
                    data-validation="file_multiple"
                    data-mincount={this.props.min_images}
                    onChange={(e) => this.changeImage(e)} />
            </div>
        </div>
      )
    }
  }
export default UploadImage;