
import { Device } from '@capacitor/device';
export const placeToAddress = (place = '')  => {
    let address = {};
    place.address_components.forEach(function(c) {
        switch(c.types[0]){
            case 'street_number':
                address.streetNumber = c;
                break;
            case 'route':
                address.streetName = c;
                break;
            case 'neighborhood': case 'locality': case 'postal_town': case 'administrative_area_level_3':
                address.city = c;
                break;
            case 'administrative_area_level_1':
                address.state = c;
                break;
            case 'postal_code':
                address.zip = c;
                break;
            case 'country':
                address.country = c;
                break;
        }
    })
    return address;
}

export const gMapApiKey = async () => {
    /*
REACT_APP_GOOGLE_MAP_API_KEY_ANDROID = AIzaSyDlS0CQ4QyYG3Fx2BERKQwT_GTMr7poZuw
REACT_APP_GOOGLE_MAP_API_KEY_IOS = AIzaSyC93eR5Kf4cjsQx-lkwmJQULFBXdwL7NSs
*/
    const info = await Device.getInfo();
    if(info.platform == 'android') return process.env.REACT_APP_GOOGLE_MAP_API_KEY_ANDROID;
    else if(info.platform == 'ios') return process.env.REACT_APP_GOOGLE_MAP_API_KEY_IOS;
    else  return process.env.REACT_APP_GOOGLE_MAP_API_KEY;
}