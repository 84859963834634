export const isValidDate = (dateString) => {
    // First check for the pattern
    if(!/^\d{4}-\d{1,2}-\d{1,2}$/.test(dateString))
        return false;

    // Parse the date parts to integers
    var parts = dateString.split("-");
    var day = parseInt(parts[2], 10);
    var month = parseInt(parts[1], 10);
    var year = parseInt(parts[0], 10);
    // Check the ranges of month and year
    if(year < 1000 || year > 3000 || month === 0 || month > 12)
        return false;

    var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

    // Adjust for leap years
    if(year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
        monthLength[1] = 29;

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
} 


export const FormErrors = ({formErrors}) =>
<div className='formErrors'>
  {Object.keys(formErrors).map((fieldName, i) => {
    if(formErrors[fieldName].length > 0){
      return (
        <p key={i}>{fieldName} {formErrors[fieldName]}</p>
      )        
    } else {
      return '';
    }
  })}
</div>

export const validateField = (i) => {
    
    let fieldType = i.type;
    const fieldValue = i.value;
    let fieldValid = '';
    let fieldValidationErrors = '';

    const is_required= i.getAttribute("data-is_required");

    const validation= i.getAttribute("data-validation");
    if(validation !== null) fieldType = validation;

    let mincount= i.getAttribute("data-mincount");
    if(mincount === null) mincount = 1;
    
    //if(validation !== null) fieldType = validation;


    const url_pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d@%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

    switch(fieldType) {
        case 'email':
            fieldValid = fieldValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            fieldValidationErrors = fieldValid ? '' : ' is invalid';
        break;
        case 'url':
          fieldValid = !!url_pattern.test(fieldValue);
          fieldValidationErrors = fieldValid ? '': ' required';
        break;
        case 'url_igp':
          fieldValid = !!new RegExp('https?:\/\/(www.)?instagram.com\/(.*)?p\/(.*)\/?','i').test(fieldValue);
          fieldValidationErrors = fieldValid ? '': ' required';
        break;
        case 'url_igr':
          fieldValid = !!new RegExp('https?:\/\/(www.)?instagram.com\/(.*)?reel\/(.*)\/?','i').test(fieldValue) || !!new RegExp('https?:\/\/(www.)?instagram.com\/(.*)?p\/(.*)\/?','i').test(fieldValue);
          fieldValidationErrors = fieldValid ? '': ' required';
        break;
        case 'file':
          fieldValid = fieldValue.length >= 1;
          fieldValidationErrors = fieldValid ? '': ' required';
        break;
        case 'text': case 'date' :
          fieldValid = fieldValue.length >= 1;
          fieldValidationErrors = fieldValid ? '': ' required';
        break;
        case 'textarea':
          //fieldValid = fieldValue.length >= 1;
          fieldValid = fieldValue.trim().length !== 0;
          fieldValidationErrors = fieldValid ? '': ' required';
        break;
        case 'number':
          fieldValid = Number.isInteger(parseInt(fieldValue));
          fieldValidationErrors = fieldValid ? '': ' required';
        break;
        case 'password':
            fieldValid = fieldValue.length >= 8;
            fieldValidationErrors = fieldValid ? '': ' is too short';
        break;
        case 'radio':
            fieldValid = i.checked;
            fieldValidationErrors = fieldValid ? '': ' is mandatory';
        break;
        case 'checkbox':
            fieldValid = i.checked;
            fieldValidationErrors = fieldValid ? '': ' is mandatory';
        break;
        case 'checkbox_multiple':
            const checkboxes = document.querySelectorAll('input[name="'+i.name+'"]:not(:disabled)');
            const checkedOne = Array.prototype.slice.call(checkboxes).some(x => x.checked);
            fieldValid = checkedOne;
            fieldValidationErrors = fieldValid ? '': ' is mandatory';
        break;
        case 'file_multiple':
            const files = document.querySelectorAll('input[name="'+i.name+'"]');
            const files_with_image = Array.from(files).filter(function(item) {
              return item.getAttribute("data-value") !== '';
            })
            fieldValid = files_with_image.length >= mincount;
            fieldValidationErrors = fieldValid ? '': ' is mandatory';
        break;
        case 'autocomplete':
          const av= i.getAttribute("data-autocompleteValidation");
          fieldValid = (av === 'false')?false:true; //get from data-autocompleteValidation
          fieldValidationErrors = fieldValid ? '': ' required';
        break;
        case 'button':
            fieldValid = true;
        break;
        default:
          fieldValid = true;
        break;
    }
    
    //Lenght rules
    const minlength = i.getAttribute("minlength");
    const maxlength = i.getAttribute("maxlength");
    if(minlength !== null) {
      if(fieldValue.length < minlength || fieldValue.length > maxlength ) {
        fieldValid= false;
        fieldValidationErrors = ' is not the required length';
      }
    }
    
    //required behaviour
    if((is_required !== null && is_required === 'false')) fieldValid = true;
    return {formErrors:fieldValidationErrors,fieldValid:fieldValid}
  }
  
export const validateForm = (fields) => {
    //this.setState({formValid: this.state.emailValid && this.state.passwordValid});
    var s = 'success';
    Object.values(fields).forEach((el, i) => {
      if(el === 'error' || el === '') s = 'error'; 
    })
    return s;
}


  
export const validateFormPartnership = (fields) => {
  //this.setState({formValid: this.state.emailValid && this.state.passwordValid});
  var s = 'success';
  Object.values(fields).forEach((el, i) => {
    el.forEach((ell,inn) => {
      Object.values(ell).forEach((elll,innn) => {
        if(elll === 'error' || elll === '') s = 'error';
      })
    })
  })
  return s;
}

export const errorClass = (error) => {
  //eturn(error.length === 0 ? '' : 'has-error');
  if(error === 'success') return '';
  else if(error === 'error') return 'is-invalid';
  else return '';
}

export const initiateFormErrors = (formfields) => {
  let tmp_err = {};
  let evtt = '';
  //Object.values(formfields).forEach((v,k) => {
  for(const v of formfields){
    const evt =  document.querySelectorAll('input[name="'+v+'"]:not(:disabled),textarea[name="'+v+'"]:not(:disabled)'); //document.getElementsByName(v);
    
    if(evt.length > 1){ //if more than 2 items with same name radio or checkboxes
      evtt = evt[0];
      evt.forEach((ie,ke) => {
          if(ie.checked) evtt = evt[ke];
      });
      const err = validateField(evtt);
      tmp_err[evtt.name] =(err.fieldValid?'success':'');
    }
    else if(evt.length > 0){ //if only one item
      evtt = evt[0];
      const err = validateField(evt[0]);
      tmp_err[evtt.name] =(err.fieldValid?'success':'');
    }
    else { //if no item in dom , default to success
      tmp_err[v] ='success';
    }

  }//);
  return tmp_err;
}


export const validateEntireForm = (formfields,formErrors) => {
  let evtt = '';
  let tmp_err = formErrors;
  Object.values(formfields).forEach((v,k) => {
      const evt = document.querySelectorAll('input[name="'+v+'"]:not(:disabled),textarea[name="'+v+'"]:not(:disabled)'); //document.getElementsByName(v);
      if(evt.length > 1){
          evtt = evt[0];
          for(const [ind,el] of evt.entries()){
            if(el.disabled) return; //don't check disabled cehckboxes
            else evtt = evt[ind]; //reinitiate the element to check based on that
            if(el.checked) { evtt = evt[ind]; break; } //or check for a checked checkbox
          } 
          const err = validateField(evtt);
          tmp_err[evtt.name] =(err.fieldValid?'success':'error');
      }
      else if(evt.length > 0){
          const err = validateField(evt[0]);
          tmp_err[evt[0].name] =(err.fieldValid?'success':'error');
      }
      else {
          tmp_err[v] ='success';
      }

  });
  return tmp_err;
}