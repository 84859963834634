import React from 'react';

import { Dialog } from '@capacitor/dialog';
import { showToast } from '../../Helpers/Toast';
import BackButton from "../../Components/BackButton";
import Fetcher from '../../Helpers/Fetcher';
import {PushNotifications } from '@capacitor/push-notifications';
import { FCM } from "@capacitor-community/fcm";
import { Device } from '@capacitor/device';
import Translate from '../../Helpers/Translate';

class AlertsNotifications extends React.Component {  
    
    constructor(props) {
        super(props);
        
        this.state = {
            isLoaded:false,
            error:false,
            optin_email:0,
            optin_push:0,
            push_notifications:'granted'
        };   
    }

    componentDidMount(){       
        this.fetchConfig();
        this.logDeviceInfo();
    }
    fetchConfig(){
        new Fetcher().callApi({
            api_path:'/notifications/get_config',
            api_data:{},
            method:'GET',
        }).then((r) => {
            if(r.status === 'success'){
                this.setState({
                    optin_email:r.results.optin_email,
                    optin_push:r.results.optin_push,
                    init_optin_email:r.results.optin_email,
                    init_optin_push:r.results.optin_push,
                    
                });

            }
            else {
                this.setState({
                    isLoaded: true,
                    error:true
                });  
            }
        });
    } 
    handleChange = async(e) => {     
        if(e.target.id === 'email_mobile'){
            this.setState({
                optin_email:'1',
                optin_push:'1'
            },()=>this.saveChange())
        }
        if(e.target.id === 'uniquement_email'){
            this.setState({
                optin_email:'1',
                optin_push:'0'
            },()=>this.saveChange())
        }   
        if(e.target.id === 'no_noti'){
            const { value } = await Dialog.confirm({
                title: 'Notifications',
                message: `Êtes-vous sûr ? Vous ne pourrez pas être au courant instantanément des nouvelles campagnes.`,
            });
            if(value){    
                this.setState({
                    optin_email:'0',
                    optin_push:'0'
                },()=>this.saveChange())
            }
        }        
    }
    saveChange = () => {
        new Fetcher().callApi({
            api_path:'/notifications/update_config',
            api_data:{"optin_email": this.state.optin_email, "optin_push":this.state.optin_push},
            method:'POST',
        }).then((r) => {
            if(r.status === 'error'){
                showToast('Vous avez des campagnes actives');
                this.setState({
                    optin_email:this.state.init_optin_email,
                    optin_push:this.state.init_optin_push
                });
            }
            else{
                showToast('app_well_saved');
                this.setState({
                    init_optin_email:r.results.optin_email,
                    init_optin_push:r.results.optin_push
                });
            }
        });       
                
        
    }
    logDeviceInfo = async () => {
        const info = await Device.getInfo();
        if(info.platform !== 'web'){
            //Notification check
            PushNotifications.checkPermissions().then((res) => {
                if (res.receive !== 'granted') {
                    this.setState({
                        'push_notifications':'denied'
                    })
                }
                else {
                    this.setState({
                        'push_notifications':'granted'
                    })
                }
            });  
        }
        

    }
    askForPushPermission = () =>{
        
        PushNotifications.requestPermissions().then((res) => {
            if (res.receive === 'denied') {
                this.setState({
                    'push_notifications':'denied'
                })
            }
            else {
                this.setState({
                    'push_notifications':'granted'
                }, () => this.registerNotification())
            }
        });
    }
    registerNotification = () => {
        PushNotifications.register();
        PushNotifications.addListener('registration',
            (push_token) => {
                new Fetcher().callApi({
                    api_path:'/helpers/update_user_meta',
                    api_data:{
                        "metas":{
                            "push_token": push_token.value
                        }
                    },
                    method:'POST',
                }).then((r) => {
	                if(r.status === 'success'){
                        FCM.subscribeTo({ topic: "all_influencers" })
                        .then((rtkn) => console.log(rtkn))
                        .catch((err) => console.log(err));
                        showToast('app_well_saved');
                    }
	                else {showToast('Une erreur est survenue')}
                });
                
            }
        );

        PushNotifications.addListener('registrationError',
            (error) => {
                showToast('Une erreur est survenue')
            }
        );

    }
    render() {  
        return (
            <div id="bk_alertssettings" className="container  bg-white min-vh-100">
                

                <div className="row header header_icon">
                    <div className="col px-0">
                        <BackButton />
                        <div className="icon"></div>
                    </div>
                </div>


                <div className="row mt-5 mb-1">
                    <div className="col">
                        <h1 className="text-center text-andeslight mt-2 py-2 font-14"><Translate  s="app_my_notifications" /></h1>
                        <h2 className="px-3 text-andesmedium font-12"><Translate s="lbl_alerts_h1" /></h2>
                        <p className="mt-2 px-3"><Translate s="lbl_alerts_desc" /></p>
                    </div>
                </div>
                {(() => {
                    if(this.state.push_notifications === 'denied'){
                        return (
                            <div className="alert alert-pending">
                                <Translate s="lbl_alerts_enable" />
                                <button className="btn btn-primary" onClick={() => this.askForPushPermission()}><Translate s="lbl_alerts_btn" /></button>
                            </div>
                        )
                    }

                })()}

                <div className="row pb-7"> 
                    <div className="col px-4">
                        <div className="row mt-2 align-items-center">
                            <div className="col-2 switch_ctnr">
                                <label className="switch v-center">
                                    <input type="radio" value="1" name="notification_config" id="email_mobile" onChange={(e) => this.handleChange(e)} checked={this.state.optin_email === '1' && this.state.optin_push === '1'} />
                                    <span className="slider pink round"></span>
                                </label>
                            </div>
                            <div className="col-10">
                                <label className="switch_txt" htmlFor="email_mobile"><Translate s="lbl_alerts_option1" /></label>
                            </div>
                        </div>
                        <div className="row mt-3 align-items-center">
                            <div className="col-2 switch_ctnr">
                                <label className="switch v-center">
                                    <input type="radio" value="1" name="notification_config" id="uniquement_email" onChange={(e) => this.handleChange(e)} checked={this.state.optin_email === '1' && this.state.optin_push === '0'} />
                                    <span className="slider pink round"></span>
                                </label>
                            </div>
                            <div className="col-10">
                                <label className="switch_txt" htmlFor="uniquement_email"><Translate s="lbl_alerts_option2" /></label>
                            </div>
                        </div>
                        <div className="row mt-3 align-items-center">
                            <div className="col-2 switch_ctnr">
                                <label className="switch v-center">
                                    <input type="radio" value="1" name="notification_config" id="no_noti" onChange={(e) => this.handleChange(e)} checked={this.state.optin_email === '0' && this.state.optin_push === '0'} />
                                    <span className="slider pink round"></span>
                                </label>
                            </div>
                            <div className="col-10">
                                <label className="switch_txt" htmlFor="no_noti"><Translate s="lbl_alerts_option3" /></label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }  
}

export default AlertsNotifications;