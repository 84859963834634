
import Translate from '../Helpers/Translate';

export const renderButton = (a, lbl = 'btn_i_save')  => {
    return (
        <button className="btn btn-secondary" type="button" onClick={a}>  
            <span className="spinner-border spinner-border-sm d-none" role="status" aria-hidden="true"></span> 
            <span className="text-save"><Translate s={lbl} /></span>
            <span className="text-loading d-none">Chargement...</span>
        </button>
    )
}  



export const addLoadingButton = (e)  => {
    let el = '';
    let el_btn = '';
    if(e.currentTarget === undefined) { //trigger action
        el = e.current;    
        el_btn = e.current.children[0];
    }
    else { //actual click on the btn
        el = e.currentTarget
        el_btn = e.target;
    }
    const loader = el.querySelector('.spinner-border');
    const textsave = el.querySelector('.text-save');
    const textloading = el.querySelector('.text-loading');
    loader.classList.remove('d-none');
    textloading.classList.remove('d-none');
    textsave.classList.add('d-none');
    el_btn.disabled = true;
    el_btn.blur();
    return [loader,textloading,textsave];
}  

export const removeLoadingButton = (e,saveBtnEls)  => {
    saveBtnEls[0].classList.add('d-none');
    saveBtnEls[1].classList.add('d-none');
    saveBtnEls[2].classList.remove('d-none');
    let el = '';
    let el_btn = '';
    if(e.currentTarget === undefined) { //trigger action
        el = e.current;    
        el_btn = e.current.children[0];
    }
    else { //actual click on the btn
        el = e.currentTarget
        el_btn = e.target;
    }
    el_btn.disabled = false;
    el_btn.blur();
}  
