import React from 'react';
import {Link} from "react-router-dom";
import Translate from './Helpers/Translate';
import logo from './Assets/img/default_logo.png';
import Fetcher from './Helpers/Fetcher';
import {  setLocalStorageItem } from './Helpers/Storage';

class Lock extends React.Component {  
    componentDidMount(){
        new Fetcher().callApi({
            api_path:'/auth/check_validation',
            api_data:{},
            method:'GET',
        }).then((r) => {
            if(r.status === 'success'){
                const login_time = new Date().getTime();
                r.results.isLogin = true;
                r.results.login_time = login_time;
                setLocalStorageItem("bk_user_info",JSON.stringify(r.results)).then(
                    () => {
                        //window.location.href = '/';
                    }
                );
            }
        });

    }
    render() {  
        return (
            <div id="bk_login" className="bg_blur h-100 container">
                <main className="form-signin">
                    <img className="mb-4" src={logo} alt="" width="90" />
                    <h1><Translate s="profil_info_pending_title" /></h1>
                    <br />
                    <p><span className="purple"><Translate s="profil_info_pending_subtitle" /></span><br /><Translate s="profil_info_pending_desc" /></p>
                    <p className="text-center py-3"><Link to="/login"><Translate s="dropdown_logout" /></Link></p>
                </main>
            </div>
        )
    }  
}

export default Lock;