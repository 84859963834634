
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { App } from '@capacitor/app';

const AppUrlListener = () => {
    let history = useHistory();
    useEffect(() => {
      App.addListener('appUrlOpen', (event) => {
        const slug = event.url.split('.com/app/').pop(); //Old desktop website logic
        const slug_app = event.url.split('.com/').pop(); //new app desktop logic
        //Old desktop website logic
        if (slug) {
            const regex = /[a-z]{2}\/campagnes\/(description|fiche|archive|offres)\/?([a-zA-Z0-9]{6})?/gm;
            const m = regex.exec(slug);
            if(m[1] !== 'undefined' && m[2] !== 'undefined') {
                if(m[1] === 'description') history.push('/campaign/'+m[2]);
                if(m[1] === 'fiche') history.push('/deal/'+m[2]);
                if(m[1] === 'archive') history.push('/deal/'+m[2]);
            }
            if(m[1] !== 'undefined' && m[2] === 'undefined') {
                if(m[1] === 'offres') history.push('/campaigns');
            }
        }
        //new app desktop logic
        else if (slug_app) history.push('/'+slug_app);
        else {}

        // If no match, do nothing - let regular routing
        // logic take over
      });
    }, [history]);
  
    return null;
  };
  
  export default AppUrlListener;