
import { Toast } from '@capacitor/toast';
import {getLang} from './Translation';
const txts = {
  french:{
    app_help_formerrors:'Merci de corriger les erreurs dans le(s) formulaire(s)',
    app_an_error_occured:'Une erreur est survenue',
    app_well_saved:'Bien enregistré',
  },
  english:{
    app_help_formerrors:'Please fix the errors on the form',
    app_an_error_occured:'An error occured',
    app_well_saved:'Well saved',
  },
}

export const showToast = async (m = 'Hello')  => {
  let txt = '';
  const l = await getLang();
  
  if(txts[l][m] !== undefined) txt = txts[l][m];
  else txt = m;

  await Toast.show({
    text: txt,
    duration: 'long'
  });
};
export const showDomError = (m = '')  => {
    
    const error_dom = document.getElementById('error_dom');
    error_dom.classList.remove("d-none");
    error_dom.innerText = m
};

