import React from 'react';
import {Link} from "react-router-dom";

import Translate from '../../Helpers/Translate';
import logo from '../../Assets/img/default_logo.png';
import orange_arrow from '../../Assets/img/orange_arrow.png';

import { HiOutlineMail,HiLockClosed } from 'react-icons/hi';
//import {isValidDate} from './Helpers/Formvalidation';
import { showToast,showDomError } from '../../Helpers/Toast';
import { getLocalStorageItem} from '../../Helpers/Storage';

import { Device } from '@capacitor/device';
import { Browser } from '@capacitor/browser';

import {renderTranslationButtons} from '../../Helpers/Translation';

class Register extends React.Component {  
    
    constructor(props) {
        super(props);
        
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        
        this.state = {
            toastState: false,
            toastMessage: 'Message',
            formFields: {email: '', password: '',password_check:'',optin:'',cgu:''},
            formErrors: {email: '', password: '',password_check:'',optin:'',cgu:''},
            formErrorsMessages: {email: '', password: '',password_check:'',optin:'',cgu:''},
            app_langage:'',
            user_country_code: 'FR',
            pendingCheckEmail: false,

        };   
    }
    componentDidMount(){
        this.getLocalisationLang();
    }
    handleChange(e) {
        
        const id = e.target.id;
        const value =  e.target.type === 'checkbox' ? e.target.checked : e.target.value;  
        console.log(value);

        this.setState({
            formFields:{
                ...this.state.formFields,
                [id]: value,
            },
            formErrors : {
                ...this.state.formErrors,
                [id]:''
            }
        });
    }
    errorClass(error) {
        return(error.length === 0 ? '' : 'is-invalid');
    }
    handleSubmit() {
        //Chech data
        if(this.validateForm()){
            this.register();
            //if(this.state.formAction === 'login') this.login();
            //else this.editChild();
        }        

    }
    validateForm() {   
        let fieldValidationErrors = this.state.formErrors;
        let formErrorsMessages = this.state.formErrorsMessages;
        let formIsValid = true;        
        Object.keys(this.state.formFields).forEach(fieldName => {
            var value = this.state.formFields[fieldName];
            if(fieldName === 'email'){
                let emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                fieldValidationErrors.email = emailValid ? '' : 'error';
                if(!emailValid) {
                    formIsValid = false;
                    formErrorsMessages.email = <Translate s="app_err_email" />;
                }
            }
            if(fieldName === 'password'){
                const pwd_rule = new RegExp('^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$','i');
                //console.log(!pwd_rule.test(value));
                if(!pwd_rule.test(value)) {
                    formIsValid = false;
                    fieldValidationErrors.password = 'error';
                    formErrorsMessages.password = <Translate s="app_err_pwdrule" />
                }
                else {
                    fieldValidationErrors.password = '';
                }
            }
            if(fieldName === 'password_check'){
                const passwordValid = this.state.formFields.password;;
                let passwordCheckValid = value; //(!value.trim().length)?false:true;
                //fieldValidationErrors.password_check = passwordCheckValid ? '': 'error';
                if(passwordCheckValid !== passwordValid) {
                    formIsValid = false;
                    fieldValidationErrors.password_check = 'error';
                    formErrorsMessages.password_check = <Translate s="app_err_pwdcheck" />
                }
                else fieldValidationErrors.password_check = ''
            }
            if(fieldName === 'cgu'){
                console.log(value);
                let cguValid = (!value)?false:true;
                fieldValidationErrors.cgu = cguValid ? '' : 'error';
                if(!cguValid) {
                    formIsValid = false;
                    formErrorsMessages.cgu = <Translate s="app_err_mandatoryfield" />;
                }
            }
          })
          this.setState({
            formErrors: fieldValidationErrors,
            formErrorsMessages:formErrorsMessages,
        });
          return formIsValid;
    }
    logDeviceInfo = async () => {
        const info = await Device.getInfo();
        return info.platform;
    }
    register = () => {
        let url , credentials;     
        url = process.env.REACT_APP_API_URL + '/auth/register';
        credentials = {
            "email": this.state.formFields.email,
            "password": this.state.formFields.password,
            "password_check": this.state.formFields.password_check,
            "optin": this.state.formFields.optin,
            "cgu": this.state.formFields.cgu,
            "user_display_lang": this.state.app_langage
        }
        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            body: JSON.stringify(credentials)
        })
        .then((res) => {
            if(res.status === 200) return res.json();
            else showToast("Error Logging in");            
        })
        .then(
            (result) => {
                if(result.status === 'error') showToast(result.message);
                else if(result.status === 'error_dom') showDomError(result.message);
                else this.setState({ pendingCheckEmail: true});
            },
            (error) => {        
                showToast(error.toString());
            }
        )
    }
    openCapacitorSite = async (url) => {
      await Browser.open({ url: url });
    }
    getLocalisationLang = async () => {
        //This fetch local storage lang to set flag as active   
        const localisation_lang = await getLocalStorageItem('localisation_lang');
        let cc = 'FR'; 
        if(localisation_lang == 'french') cc = 'FR';
        if(localisation_lang == 'english') cc =  'EN';
    
        this.setState({
            app_langage:localisation_lang,
            user_country_code : cc
        })
    }
    render() { 
        return this.state.pendingCheckEmail ? this.renderCheckEmail() : this.renderForm();      

    } 
    renderCheckEmail() {  
        return (
            
            <div id="bk_login" className="bg_blur h-100 container">
                <main className="form-signin">
                        <img className="mb-4" src={logo} alt="" width="90" />
                        <p className="h6 mb-4 fw-normal"><Translate s='auth_register_confirmation' /></p>
                        <Link to={'/login'}><Translate s="auth_login_cta" /></Link>
                </main>
            </div>
        )
    }
    renderForm() {  
        return (
            <div id="bk_login" className="bg_blur h-100 container">
                <main className="form-signin">
                        <img className="mb-4" src={logo} alt="" width="90" />
                        <h1 className="h3 mb-4 fw-normal"><Translate s='app_welcome' /></h1>
                        <div id="error_dom" className="alert alert-danger text-start d-none"></div>
                        <div className="form-floating">
                            <input type="email" className={"form-control "+this.errorClass(this.state.formErrors.email)} id="email" placeholder="name@example.com" value={this.state.formFields.email}  onChange={this.handleChange} />
                            <label htmlFor="email"><HiOutlineMail /> <Translate s="auth_email" /></label>
                            <div className="invalid-feedback text-start mt-1">{this.state.formErrorsMessages.email}</div>
                        </div>
                        <div className="form-floating mt-3">
                            <input type="password" className={"form-control "+this.errorClass(this.state.formErrors.password)} id="password" placeholder="Password" value={this.state.formFields.password}  onChange={this.handleChange} />
                            <label htmlFor="password"><HiLockClosed /> <Translate s="auth_pwd" /></label>
                            <div className="invalid-feedback  text-start">{this.state.formErrorsMessages.password}</div>
                        </div>
                        <div className="form-floating mt-3">
                            <input type="password" className={"form-control "+this.errorClass(this.state.formErrors.password_check)} id="password_check" placeholder="Confirm Password" value={this.state.formFields.password_check}  onChange={this.handleChange} />
                            <label htmlFor="password_check"><HiLockClosed /> <Translate s="auth_pwd_confirm" /></label>
                            <div className="invalid-feedback text-start">{this.state.formErrorsMessages.password_check}</div>
                        </div>

                        <div className="checkbox mb-3 text-start">
                            <label class="form-check-label" for="optin">
                                <input type="checkbox" name="optin" value="1" id="optin" className={"form-check-input "+this.errorClass(this.state.formErrors.optin)} defaultChecked={true} /> <Translate s='auth_register_optin' />
                            </label> 
                        </div>

                        <div className="checkbox mb-3 text-start">
                            <label>
                                <input type="checkbox" name="cgu" id="cgu" value="1" className={"form-check-input "+this.errorClass(this.state.formErrors.cgu)} onChange={this.handleChange} /> <Translate s='auth_register_cgu'   />  <a href={process.env.REACT_APP_SITE_URL+'/coffrefort/cgu/'+this.state.user_country_code+'-cgu-influenceurs-bilbokid.pdf'} target="_blank" class="cta_orange"><Translate s='auth_cgu' /></a>
                                <div className="invalid-feedback text-start pt-3">{this.state.formErrorsMessages.cgu}</div>
                            </label>
                        </div>

                        <button className="mt-2 w-100 btn btn-lg btn-primary" onClick={() => this.handleSubmit()}><Translate s="auth_register_cta" /></button>
                        
                        <p className="mt-4 mb-3">
                            <Link to={'/login'} className="cta_orange cta_bordered_orange text-andesmedium" >
                                <Translate s="auth_cta_alreadyaccount_1" /> 
                                <img src={orange_arrow} alt="" width="25" className="d-inline-block align-top mx-2" />
                                <Translate s="auth_cta_alreadyaccount_2" />
                            </Link>
                        </p>
                        <p className='mt-4 mb-3'>
                        {renderTranslationButtons(this.state.app_langage)}
                        </p>
                </main>
            </div>
        )
    }  
}

export default Register;