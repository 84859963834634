import React from 'react';
import Fetcher from '../../Helpers/Fetcher';
import { initiateFormErrors, validateEntireForm, validateField, validateForm, errorClass } from '../../Helpers/Formvalidation';
import Translate from '../../Helpers/Translate';
import {renderButton,addLoadingButton,removeLoadingButton} from "../../Forms/DealSaveButton";
import { showToast } from '../../Helpers/Toast';
import BackButton from '../../Components/BackButton';
import {genRadiogroupBoxes, genRadioInline} from '../../Forms/GenRadiogroupBoxes';
import {genCheckgroupBoxes} from '../../Forms/GenCheckgroupBoxes';

import {AiOutlinePlus,AiOutlineMinus} from 'react-icons/ai'

class StepTypeInfluenceur extends React.Component {      
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            error:false,
            user:{},
            user_meta:{},

            formFieldsNames:['enceinte','date_accouchement','enfants'],
            formFields:{
                prenom_surnom_enfant:{},
                date_naissance_enfant:{},
                sexe_enfant:{},
                animaux:{},
                autres_animaux:'',
                specialite_medicale_enf:{},
                autres_specialite_medicale_enf:''
            },
            formErrors:{},

            readOnly:false,
            showDetailsEnceinte:false,
            showDetailsEnfants:false,

            animaux:['chien','chat','lapin','hamster'],
            specialite_medicale_enf:['allergie','handicap','precocite'],
            triggers:{
                animaux:false,
                specialite_medicale_enf:false,
            }
        }
    }
    componentDidMount(){
        //Fetch data 
        new Fetcher().callApi({
            api_path:'/profil/init',
            api_data:{},
            method:'POST',
        }).then((r) => {
            if(r.status === 'success'){
                console.log(r.results.user_meta);
                let ffn = this.state.formFieldsNames;
                //prepare child data 
                if(r.results.user_meta.prenom_surnom_enfant !== undefined){
                    r.results.user_meta.prenom_surnom_enfant = JSON.parse(r.results.user_meta.prenom_surnom_enfant);
                    r.results.user_meta.date_naissance_enfant = JSON.parse(r.results.user_meta.date_naissance_enfant);
                    r.results.user_meta.sexe_enfant = JSON.parse(r.results.user_meta.sexe_enfant);
                    r.results.user_meta.prenom_surnom_enfant.forEach((el,i) => {
                        ffn.push('prenom_surnom_enfant['+i+']');
                        ffn.push('date_naissance_enfant['+i+']');
                        ffn.push('sexe_enfant['+i+']');
                    });
                }
                else {
                    r.results.user_meta.prenom_surnom_enfant = [];
                    r.results.user_meta.date_naissance_enfant = [];
                    r.results.user_meta.sexe_enfant = [];
                    r.results.user_meta.nb_enfant = 0;
                    ffn.push('prenom_surnom_enfant[0]');
                    ffn.push('date_naissance_enfant[0]');
                    ffn.push('sexe_enfant[0]');
                }
                
                //parse animals
                r.results.user_meta.animaux = ((r.results.user_meta.animaux !== undefined)?JSON.parse(r.results.user_meta.animaux):[]);
                if(r.results.user_meta.animaux[0] !== undefined && r.results.user_meta.animaux[0] === '') r.results.user_meta.animaux = [];
                let trigger_autres_animaux = this.state.triggers.animaux;
                if(r.results.user_meta.autres_animaux !== ''  && r.results.user_meta.autres_animaux !== undefined) trigger_autres_animaux = true;
                
                //parse specialtes medicals
                r.results.user_meta.specialite_medicale_enf = ((r.results.user_meta.specialite_medicale_enf !== undefined)?JSON.parse(r.results.user_meta.specialite_medicale_enf):[]);
                if(r.results.user_meta.specialite_medicale_enf[0] !== undefined && r.results.user_meta.specialite_medicale_enf[0] === '') r.results.user_meta.specialite_medicale_enf = [];
                //TODO check if initial state array is different from what in the user array
                let trigger_autres_specialite_medicale_enf = this.state.triggers.specialite_medicale_enf;
                if(r.results.user_meta.autres_specialite_medicale_enf !== '' && r.results.user_meta.autres_specialite_medicale_enf !== undefined) trigger_autres_specialite_medicale_enf = true;
                //Legacy check
                if(r.results.user_meta.specialite_medicale_enf !== undefined){
                    const arr1 = r.results.user_meta.specialite_medicale_enf;
                    const arr2 =  this.state.specialite_medicale_enf;
                    let difference = arr1.filter(x => !arr2.includes(x));
                    if(difference.length > 0){
                        trigger_autres_specialite_medicale_enf = true;
                        r.results.user_meta.autres_specialite_medicale_enf = difference[0];
                    }
                }
                

                //Set states
                this.setState({
                    isLoaded: true,
                    user:r.results.user,
                    formFields:r.results.user_meta,
                    formFieldsNames:ffn,
                    triggers:{
                        animaux:trigger_autres_animaux,
                        specialite_medicale_enf:trigger_autres_specialite_medicale_enf
                    }
                  }, () => {
                    this.showDetailsEnceinte();
                    this.showDetailsEnfants();

                    //reinitiate form errors if check or not
                    this.setState({
                        formErrors:initiateFormErrors(this.state.formFieldsNames)
                    })
                    
                  })    
            }
            else {
                this.setState({
                    isLoaded: true,
                    error:true
                });   
            }
        });

    }
    showDetailsEnceinte = () =>{
        let showDetailsEnceinte = false;
        if(this.state.formFields.enceinte === "1"){
            showDetailsEnceinte = true;
        } 
        this.setState({
            showDetailsEnceinte:showDetailsEnceinte
        }, ()  => {
            
            //reinitiate form errors if check or not
            this.setState({
                formErrors:initiateFormErrors(this.state.formFieldsNames)
            })

        });
    }
    showDetailsEnfants = () =>{
        let showDetailsEnfants = false;
        if(this.state.formFields.enfants === "1"){
            showDetailsEnfants = true;
        } 
        this.setState({
            showDetailsEnfants:showDetailsEnfants
        }, ()  => {
            
            //reinitiate form errors if check or not
            this.setState({
                formErrors:initiateFormErrors(this.state.formFieldsNames)
            })

        });
    }
    
    handleChange(evt){
        let e = '';
        if(evt.target !== undefined) e = evt.target;
        else e = evt;

        const name = e.name;
        const type = e.type;
        const value = type === "checkbox" ? e.checked : e.value; 
        //update form fields
        let ff = {};
        ff = this.state.formFields;
        let fe = {};
        fe = this.state.formErrors;
        console.log(name);
        console.log(e.value);
        const el = document.getElementsByName(name);
    

        //Rules
        const regex = /(.*)\[([0-9]*)\]/g;
        const array_field = regex.exec(name);
        if(array_field !== null){
            ff[array_field[1]][array_field[2]] = value;
        }
        else if(el.length > 2){ //checkbox or radio
            if(value) ff[name].push(e.value); //add
            else { // remove
                var index = ff[name].indexOf(e.value);
                if (index !== -1) {
                    ff[name].splice(index, 1);
                }
            }

            /*if(ff[name] !== undefined) ff[name][e.value] = value;
            else { 
                ff[name] = {};
                ff[name][e.value] = value;
            }*/
        }
        else ff[name] = value;
        
        //Errors
        const err = validateField(e);
        fe[name] = (err.fieldValid?'success':'error');

        console.log(ff);
        //push state
        this.setState({
            formFields:ff,
            formErrors:fe
        }, () => {
            this.showDetailsEnceinte();
            this.showDetailsEnfants();
        });


    }
    handleSubmit = (e) =>  {
        if(validateForm(this.state.formErrors) === 'success'){
            const saveBtnEls = addLoadingButton(e);
            new Fetcher().callApi({
                api_path:'/profil/save/',
                api_data:{
                    slug: 'influenceur',
                    formFields: this.state.formFields,
                }, 
                method:'POST',
            }).then((r) => {
                if(r.status === 'success'){
                    showToast('app_well_saved');
                    removeLoadingButton(e,saveBtnEls);
                    //move back to steps 
                    this.props.history.goBack();
                    //this.props.history.push('/register/steps');
                }
                else showToast('app_an_error_occured'); 
            });
        }
        else{
            const tmp_err = validateEntireForm(this.state.formFieldsNames,this.state.formErrors);
            console.log(tmp_err);
            this.setState({
                formErrors : tmp_err
            });
        }

    }
    fiedlTicker = (e,t) => {
        let ff = this.state.formFields;
        let ffn = this.state.formFieldsNames;
        
        let c = parseInt(this.state.formFields.nb_enfant);
        if(t === '-') {
            c--;
            const dec = ff.prenom_surnom_enfant.length-1;
            /*ff.prenom_surnom_enfant.pop();
            ff.date_naissance_enfant.pop();
            ff.sexe_enfant.pop();*/
            ff.prenom_surnom_enfant.splice(dec, 1);
            ff.date_naissance_enfant.splice(dec, 1);
            ff.sexe_enfant.splice(dec, 1);
            
            const atr_pse = ffn.indexOf('prenom_surnom_enfant['+dec+']');
            ffn.splice(atr_pse, 1);
            
            const atr_dne = ffn.indexOf('date_naissance_enfant['+dec+']');
            ffn.splice(atr_dne, 1);
            
            const atr_se = ffn.indexOf('sexe_enfant['+dec+']');
            ffn.splice(atr_se, 1);
        }
        else {
            c++;
            const inc = ff.prenom_surnom_enfant.length;
            ff.prenom_surnom_enfant[inc] = '';
            ff.date_naissance_enfant[inc] = '';
            ff.sexe_enfant[inc] = '';
            ffn.push('prenom_surnom_enfant['+inc+']');
            ffn.push('date_naissance_enfant['+inc+']');
            ffn.push('sexe_enfant['+inc+']');
        }
        
        if(c === 1 && t === '-') e.currentTarget.disabled = true;
        if(c > 1 && t === '+') document.getElementsByClassName('btn-minus')[0].disabled = false;
        if(c > 9 && t === '+') e.currentTarget.disabled = true;
        if(c < 10 && t === '-') document.getElementsByClassName('btn-plus')[0].disabled = false;
        ff.nb_enfant = c;

        //console.log(ff.prenom_surnom_enfant);
        this.setState({
            formFields:ff,
            formFieldsNames:ffn
        },() => {
            //reinitiate form errors if check or not
            this.setState({
                formErrors:initiateFormErrors(this.state.formFieldsNames)
            })
        });

    }
    dateToYmd = (d) => {
        if(d === undefined) return '';
        if(d.includes("-")) return d;
        const dateParts = d.split("/");
        return  dateParts[2]+'-'+dateParts[1]+'-'+dateParts[0];
    }
    showOtherField = (e,t) => {
        let ff = this.state.formFields;
        let triggers = this.state.triggers;
        if(!e.checked)  ff['autres_'+t] = '';
        triggers[t] = !this.state.triggers[t];
        this.setState({
            triggers:triggers,
            formFields:ff
        })
    }
    render() {  
        return (
            <div id="bk_register_steps" className="">
                <div className="container-fluid bg-white">
                    <div className="header row px-3 py-5">
                        <div className="col text-center">
                            <BackButton />
                            <h2 className="header_title"><Translate s="profil_profil_influenceur_title" /></h2>
                        </div>
                    </div>
                </div>
                
                <div className="container pb-9">

                    <div className="row justify-content-center my-4"> 
                        <div className="col-12 col-md-6">
                        {genRadiogroupBoxes({
                            label:'profil_profil_influenceur_enceinte',
                            name:'enceinte',
                            defaultValue:this.state.formFields.enceinte,
                            onChangeAction:(e) => this.handleChange(e),
                            required:true,
                            class:errorClass(this.state.formErrors.enceinte),
                            data:[{    
                                key:'1',
                                value:<Translate s='yes' />
                            },{
                                key:'0',
                                value:<Translate s='no' />
                            }]
                        })} 
                        </div>
                    </div>

                    <div className={'row justify-content-center '+(this.state.showDetailsEnceinte?'':'d-none')}>
 
                        <div className="col-12 col-md-6 mb-3">
                            <label className={"form-label "+(this.state.showDetailsEnceinte?'required':'')}><Translate s="profil_profil_influenceur_date_accouchement" /></label>
                            <input type="date" className={`form-control ${errorClass(this.state.formErrors.date_accouchement)}`} autoComplete="off" data-is_required={this.state.showDetailsEnceinte} name="date_accouchement" value={this.state.formFields.date_accouchement} onChange={(e) => this.handleChange(e)} />

                        </div>

                    </div>                    

                    <div className="row justify-content-center my-4"> 
                        <div className="col-12 col-md-6">
                            {genRadiogroupBoxes({
                                label:'profil_profil_influenceur_enfants',
                                name:'enfants',
                                defaultValue:this.state.formFields.enfants,
                                onChangeAction:(e) => this.handleChange(e),
                                required:true,
                                class:errorClass(this.state.formErrors.enfants),
                                data:[{    
                                    key:'1',
                                    value:<Translate s='yes' />
                                },{
                                    key:'0',
                                    value:<Translate s='no' />
                                }]
                            })} 
                        </div>
                    </div>
                    
                    <div className={'row justify-content-center '+(this.state.showDetailsEnfants?'':'d-none')}>
 
                        <div className="col-12 col-md-6 mb-3">
                            <div className="row">
                                <label className={"col-8 form-label "+(this.state.showDetailsEnfants?'required':'')}><Translate s="profil_profil_influenceur_nb_enfant" /></label>

                                <div className="col input-group field_plus_minus_ticker">
                                    <span className="input-group-btn">
                                        <button 
                                            type="button" 
                                            className="btn btn-default btn-number btn-minus"
                                            onClick={(e) => this.fiedlTicker(e,'-')}>
                                                <AiOutlineMinus />
                                        </button>
                                    </span>
                                    <input 
                                        type="text"
                                        name="nb_enfant" 
                                        className={`form-control input-number ${errorClass(this.state.formErrors.nb_enfant)}`} 
                                        data-is_required={this.state.showDetailsEnfants}
                                        value={this.state.formFields.nb_enfant}
                                        readOnly={true}
                                        onChange={(e) => this.handleChange(e)} />
                                    <span className="input-group-btn">
                                        <button type="button" className="btn btn-default btn-number btn-plus" onClick={(e) => this.fiedlTicker(e,'+')}><AiOutlinePlus /></button>
                                    </span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 mt-2 mb-3">
                                {
                                    
                                    Object.values(this.state.formFields.prenom_surnom_enfant).map((iv,kv) => {
                                        const cc = kv+1;
                                        
                                        return (
                                            <div key={kv} className="row">
                                                <div className="col mt-2">
                                                    <label class="form-label"><Translate s={"profil_profil_influenceur_"+cc} /> <Translate s="enfant" /></label>                
                                                    <fieldset className="form-fieldset">
                                                        <div className="col-12 mb-3">
                                                            <label className={"form-label required "}><Translate s="profil_profil_influenceur_prenom_surnom_enfant" /></label>
                                                            <input 
                                                                type="text" 
                                                                className={"form-control "+errorClass(this.state.formErrors['prenom_surnom_enfant['+kv+']'])}      
                                                                autoComplete="off" 
                                                                data-is_required={true}
                                                                name={"prenom_surnom_enfant["+kv+"]"} 
                                                                value={iv} 
                                                                onChange={(e) => this.handleChange(e)} />
                                                        </div>
                                                        
                                                        <div className="row">
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <label className={"form-label required "}><Translate s="profil_profil_influenceur_date_naissance_enfant" /></label>
                                                                <input type="date" className={'form-control '+errorClass(this.state.formErrors['date_naissance_enfant['+kv+']'])} autoComplete="off" data-is_required={true} name={"date_naissance_enfant["+kv+"]"} value={this.dateToYmd(this.state.formFields.date_naissance_enfant[kv])} onChange={(e) => this.handleChange(e)} />
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                        

                                                                {genRadioInline({
                                                                    label:'profil_profil_influenceur_sexe_enfant',
                                                                    name:'sexe_enfant['+kv+']',
                                                                    defaultValue:this.state.formFields.sexe_enfant[kv],
                                                                    onChangeAction:(e) => this.handleChange(e),
                                                                    required:true,
                                                                    class:errorClass(this.state.formErrors['sexe_enfant['+kv+']']),
                                                                    data:[{    
                                                                        key:'F',
                                                                        value:<Translate s='profil_profil_influenceur_sexe_enfant_girl' />
                                                                    },{
                                                                        key:'G',
                                                                        value:<Translate s='profil_profil_influenceur_sexe_enfant_boy' />
                                                                    }]
                                                                })}
                                                                
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div> 
                                            </div>
                                        )
                                    })
                                }
                                </div>
                            </div>

                        </div>
                

                    </div>

     

                    <div className="row justify-content-center my-4"> 
                        <div className="col-12 col-md-6">
                            
                        {genCheckgroupBoxes({
                            label:'profil_profil_influenceur_animaux',
                            name:'animaux',
                            defaultValue:this.state.formFields.animaux,
                            onChangeAction:(e) => this.handleChange(e),
                            required:false,
                            class:errorClass(this.state.formErrors.animaux),
                            data: this.state.animaux.map(v => {
                                return {
                                        'key':v,
                                        'value':<Translate s={"profil_profil_influenceur_animaux_"+v} />
                                    }
                                })
                            })} 

                            <div className="form-selectgroup form-selectgroup-boxes d-flex flex-column">
                                <label key="animaux_autres" className={"form-selectgroup-item flex-fill "}>
                                    <input 
                                        type="checkbox"
                                        className={"form-selectgroup-input"}
                                        checked={this.state.triggers.animaux} 
                                        onChange={(e) => this.showOtherField(e,'animaux')} 
                                        data-is_required={false} 
                                    />
                                    <div className="form-selectgroup-label d-flex align-items-center p-3">
                                        <div className="me-3">
                                            <span className="form-selectgroup-check"></span>
                                        </div>
                                        <div>
                                            <span className="payment payment-provider-visa payment-xs me-2"></span>
                                            <Translate s="autres" />
                                        </div>
                                    </div>
                                </label>
                            
                            </div>

                            <div className={"ps-4 "+(this.state.triggers.animaux?'':'d-none')}>
                                <label><Translate s="app_lbl_specify" /> : </label>
                                <input type="text" className={`form-control ${errorClass(this.state.formErrors.autres_animaux)}`} autoComplete="off" data-is_required={this.state.triggers.animaux} name="autres_animaux" value={this.state.formFields.autres_animaux} onChange={(e) => this.handleChange(e)} />

                            </div>

                        
                        </div>
                    </div>

                    
     

                    <div className="row justify-content-center my-4"> 
                        <div className="col-12 col-md-6">
                            
                        {genCheckgroupBoxes({
                            label:'profil_profil_influenceur_specialite_medicale_enf',
                            name:'specialite_medicale_enf',
                            defaultValue:this.state.formFields.specialite_medicale_enf,
                            onChangeAction:(e) => this.handleChange(e),
                            required:false,
                            class:errorClass(this.state.formErrors.specialite_medicale_enf),
                            data: this.state.specialite_medicale_enf.map(v => {
                                return {
                                        'key':v,
                                        'value':<Translate s={"profil_profil_influenceur_specialite_medicale_enf_"+v} />
                                    }
                                })
                            })} 

                            <div className="form-selectgroup form-selectgroup-boxes d-flex flex-column">
                                <label key="animaux_autres" className={"form-selectgroup-item flex-fill "}>
                                    <input 
                                        type="checkbox"
                                        className={"form-selectgroup-input"}
                                        checked={this.state.triggers.specialite_medicale_enf} 
                                        onChange={(e) => this.showOtherField(e,'specialite_medicale_enf')} 
                                        data-is_required={false} 
                                    />
                                    <div className="form-selectgroup-label d-flex align-items-center p-3">
                                        <div className="me-3">
                                            <span className="form-selectgroup-check"></span>
                                        </div>
                                        <div>
                                            <span className="payment payment-provider-visa payment-xs me-2"></span>
                                            <Translate s="autres" />
                                        </div>
                                    </div>
                                </label>
                            
                            </div>

                            <div className={"ps-4 "+(this.state.triggers.specialite_medicale_enf?'':'d-none')}>
                                <label><Translate s="app_lbl_specify" /> : </label>
                                <input type="text" className={`form-control ${errorClass(this.state.formErrors.specialite_medicale_enf)}`} autoComplete="off" data-is_required={this.state.triggers.specialite_medicale_enf} name="autres_specialite_medicale_enf" value={this.state.formFields.autres_specialite_medicale_enf} onChange={(e) => this.handleChange(e)} />

                            </div>

                        
                        </div>
                    </div>
                    


                
                        
                    <div className="fixed-bottom bg-white gradient-top">
                        <div className={"btn-bottom-ctnr d-grid p-3 mt-4 "+(this.state.readOnly?'d-none':'')}>
                        {renderButton((e) => this.handleSubmit(e))}
                        </div>
                    </div>

                </div>

            </div>
            
        )
    }  
}

export default StepTypeInfluenceur;