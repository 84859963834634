import React from 'react';
import {Link} from "react-router-dom";
import Translate from '../../Helpers/Translate';
import Fetcher from '../../Helpers/Fetcher';
import Loading from '../../Components/Loading';
import {BsInstagram,BsFacebook} from 'react-icons/bs';

import {infDisplayNickname} from '../../Helpers/Global';

class Partnerships extends React.Component {  
    constructor(props) {
        super(props);
        
        this.state = {
            isLoaded:false,
            error:false,
            campaign:[],
            campaign_users:false,
            campaign_users_all:false,
            cnt_user_campaign_valide:0,
            cnt_brand_parrainage:0,
        };   
    }

    componentDidMount(){
        console.log(this.props.match.params);
        this.fetchCampaignApi();
    }
    fetchCampaignApi = async () => {
        const r = await new Fetcher().callApi({
            api_path:'/brand/campaigns/partnerships/'+this.props.match.params.id_campaign,
            api_data:{},
            method:'POST',
        })
        console.log(r);
        if(r.status === 'success'){
            this.setState({
                hasCampaigns:true,
                isLoaded: true,
                campaign: r.results.campaign,            
                campaign_users_all: r.results.campaign_users_all,            
                campaign_users: r.results.campaign_users,            
                cnt_user_campaign_valide: r.results.cnt_user_campaign_valide,            
                cnt_brand_parrainage: r.results.cnt_brand_parrainage,            
            }, () => {
                //manage height viewport to trigger insertersection observerv
                /*if(document.body.scrollHeight <= window.innerHeight) {
                    this.setState({page:1},() => this.fetchCampaignApi())
                }*/
            }); 
        
        }
        else{
            this.setState({
                isLoaded: true,
                error:true
            });  
        }
        
    }  
    getAvatar(um){
        if(um.bk_profile_picture_url !== undefined && um.bk_profile_picture_url !== null) return  process.env.REACT_APP_SITE_URL+'/coffrefort/avatar/'+um.bk_profile_picture_url;
        else return ''
            /*else if(isset(um['ig_profile_picture_url']) && !is_null(um['ig_profile_picture_url'])) $path = um['ig_profile_picture_url'];
            else if(isset(um['picture_facebook']) && !is_null(um['picture_facebook'])) $path = um['picture_facebook'];
            else $path = base_url().'assets/images/default_avatar.png';*/
    }
    render () {
        //return (!this.state.hasCampaigns)?this.renderNoCampaign():this.renderCampaigns();
        return this.state.isLoaded ? this.renderPartnerships() : (       
            <Loading alwaysShow={false} />
        );   

    }
    renderPartnerships() {  
        return (
            <div id="bk_brandcampaigns" className="bg_blur bg-primary container vh-100 pb-7">
                <div className="d-flex mb-4 px-2 pt-5">
                    <div className="align-self-center pe-4">
                        <h2 className="header_title text-white text-andeslight text-uppercase mb-0">{this.state.campaign.campaign_name}</h2>
                        <p className="sub_title text-andeslight mb-0"><Translate s="lbl_partnerships" /> : {this.state.cnt_user_campaign_valide}/{this.state.campaign.objectif}</p>
                    </div>
                    <div className="align-self-center ms-auto">
                     <button className="btn btn-secondary"><Translate s="cta_my_analyze" /></button>
                    </div>
                </div>

                
                <div className="campaigns_ctnr">
                        <div className="row campaign_thead align-items-center bg-white my-1 py-2 px-1">
                            <div className="col-12 col-md-5"><Translate s="lbl_name" /></div>
                            <div className="col d-none d-md-block"><BsInstagram /></div>
                            <div className="col d-none d-md-block"><BsFacebook /></div>
                            <div className="col d-none d-md-block text-center"><Translate s="lbl_review" /></div>
                            <div className="col d-none d-md-block">Date</div>
                            <div className="col d-none d-md-block text-center"><Translate s="lbl_status" /></div>
                            <div className="col d-none d-md-block">Action</div>
                        </div>
                        {(() => {
                            if(this.state.campaign_users){
                                return (
                                    Object.values(this.state.campaign_users).map((iv,kv) => {
                                        return (
                                                    
                                            <div key={kv} id={iv.id_campaign} className="row campaign_ctnr align-items-center bg-white my-1 py-2 px-1 pointer" onClick={(e) => this.showActions(e,iv)}>
                                                <div className="col-12 col-md-5 1st_col">
                                                    <div className="rounded-circle brand_listing" style={{'backgroundImage':"url("+this.getAvatar(iv.user_metas)+")"}}></div>
                                                    {infDisplayNickname(iv.user_metas,iv.info)}
                                                </div>
                                            </div>
                                                
                                        )
                                    })
                                    
                                )
                            }
                            /*else if(true){
                
                                return (
                                    <tr>
                                        <td colspan="9" class="text-center">
                                            <span class="dvalign"><Translate s='text_no_partnerships_after_filter' /></span>
                                        </td>
                                    </tr>
                                )
                            }*/
                            else if(!this.state.campaign_users_all){
                                return (
                                    <div className="row campaign_ctnr align-items-center bg-white my-1 py-2 px-1 vh-100">
                                        <div className="col-12 text-center">
                                            <span class="bico ico_clepsydra valign"></span>
                                            <Translate s='text_no_partnerships_to_moderate' />
                                        </div>
                                    </div>
                                )
                            }
                            else { 
                                return (
                                    <div className="row campaign_ctnr align-items-center bg-white my-1 py-2 px-1 vh-100">
                                        <div className="col-12 text-center">
                                            <span class="bico ico_clepsydra valign"></span>
                                            <span class="text-uppercase text-pink font-weight-bold dvalign">Patience ! ...</span> 
                                            <span class="dvalign"><Translate s='text_no_partnerships' /></span>
                                        </div>
                                    </div>
                                )
                            }
                        })()}


                      

                </div>
            </div>
        )
    }  
}

export default Partnerships;