import React from 'react';
import {Link} from "react-router-dom";

import Loading from './Components/Loading';
import Fetcher from './Helpers/Fetcher';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle,faDotCircle } from '@fortawesome/free-regular-svg-icons'

class Alerts extends React.Component {  
    
    constructor(props) {
        super(props);
        this.state = {
            isLoaded:false,
            hasError:false,
            notifications:[],
        };   
    }

    componentDidMount(){ 
        this.fetchNotificationApi();   
    }
    fetchNotificationApi(page = 0,filter = ''){
        new Fetcher().callApi({
            api_path:'/notifications/list',
            api_data:{
                "page": page,
                "filter": filter
            },
            method:'POST',
        }).then((r) => {
            if(r.status === 'success'){
                this.setState({
                    isLoaded: true,
                    notifications:r.results
                  }) 
            }
            else {
                this.setState({
                    isLoaded: true,
                    hasError:true
                });  
            }
        });
    } 
    handleClick = (id_notification,iv) => {
        //mark as read 
        if(iv.notification_read === null){
            new Fetcher().callApi({
                api_path:'/notifications/notification_mark_read',
                api_data:{"uid_notification": iv.uid_notification},
                method:'POST',
            }).then((r) => {
                if(r.status === 'success'){
                    
                    //update pills
                    this.props.refreshPills();  

                    this.setState({
                        notifications:{
                            ...this.state.notifications,
                            [id_notification]:{
                                ...this.state.notifications[id_notification],
                                notification_read:'lol'
                            }
                        }
                    });              
                }
                else {
                    this.setState({
                        isLoaded: true,
                        hasError:true
                    }); 
                }
            });


        }
        
        //go to link
        if(iv.notification_action_app !== null){
            this.props.history.push(iv.notification_action_app);
        }    
    }
    render() {  
        return (
            <div id="bk_notifications" className="bg_blur h-100 container bg-white">
                
                <div className="row header header_icon">
                    <div className="col px-0">
                        <div className="icon"></div>
                        <Link className="top_right_button ico_roue" to="/settings/notifications"></Link>
                    </div>
                </div>

                {/*<div className="row py-5 position-relative">
                    <div className="col">
                        <h2 className="header_title text-center text-pink text-andesbold">Alertes</h2>
                        
                    </div>
                </div>*/}
                <div className="row mt-5 pt-4 px-0 pb-8"> 
                    <div className="col notification_nav">
                        {(() => {
                            if(!this.state.isLoaded){
                                return (
                                    <Loading />
                                )
                            }
                            else {
                                let read_state, link_state;
                                if(Object.keys(this.state.notifications).length){
                                    return(
                                        Object.values(this.state.notifications).map((iv,kv) => {
                                            if(iv.notification_read !== null) read_state = 'read_link';
                                            else read_state = 'unread_link';
                                            if(iv.notification_action_app !== null) link_state = 'has_link';
                                            else link_state = 'has_no_link';
                                            
                                            return(
                                                <div className={"notification_item row d-flex flex-wrap align-items-center "+read_state+" "+link_state} key={kv} id={kv} onClick={()=>this.handleClick(kv,iv)}>
                                                <div className="col-2 col-md-1 col-lg-1 text-start">
                                                        <FontAwesomeIcon icon={faCircle} className="circle" />
                                                        <FontAwesomeIcon icon={faDotCircle} className="dotcircle" />
                                                    </div>
                                                    <div className="col-10 col-md-11 col-lg-11 text-start">{iv.notification_content}</div>
                                                </div>
                                            )
                                        
                                        
                                        })
                                    ) 
                                }
                                else {
                                    return (
                                        <p>Aucune alerte</p>
                                    )
                                }
                            }
                        })()}
                    </div>
                </div>
            </div>
        )
    }  
}

export default Alerts;