import React from 'react';
import {Link} from "react-router-dom";
import Fetcher from './Helpers/Fetcher';

import Translate from './Helpers/Translate';
import { initiateFormErrors, validateEntireForm, validateField, validateForm, errorClass } from './Helpers/Formvalidation';
import { showToast } from './Helpers/Toast';
import BackButton from "./Components/BackButton";
import {renderButton,addLoadingButton,removeLoadingButton} from "./Forms/DealSaveButton";

class DealOpinion extends React.Component {  
    
    constructor(props) {
        super(props);
        this.opinionForm = React.createRef();
        this.state = {
            campaign: this.props.campaign_data,
            user_campaign_meta: this.props.user_campaign_meta,
            readOnly:this.props.readOnly,
            formFieldsNames:['mon_avis','mes_photos','retour_experience'],
            //formFields:{},
            formErrors:{
                mon_avis:'',
                mes_photos: '',
                retour_experience:'',
            }
        };   
    }
    componentDidMount(){
        //Initiate form error
        const tmp_err = initiateFormErrors(this.state.formFieldsNames);
        this.setState({formErrors : tmp_err});
        //end init error

        //default to 5 stars
        console.log(this.state.user_campaign_meta.avis_rating);
        if(this.state.user_campaign_meta.avis_rating === undefined){
            this.setState({
                user_campaign_meta:{
                    ...this.state.user_campaign_meta,
                    avis_rating:"5"
                }
            });
        }
    }
    handleChange(evt){
        const name = evt.target.name;
        const type = evt.target.type;
        const value = type === "checkbox" ? evt.target.checked : evt.target.value;   
        console.log(type);
        console.log(value);
        this.setState({
            user_campaign_meta:{
                ...this.state.user_campaign_meta,
                [name]: value,
            }
        }, () => {
            //Errors
            const err = validateField(evt.target);
            this.setState({
                formErrors : {
                    ...this.state.formErrors,
                    [name]:(err.fieldValid?'success':'error')
                }
            });
        });
        
    }
    handleSubmit = (e) =>  {
        if(validateForm(this.state.formErrors) === 'success'){
            const saveBtnEls = addLoadingButton(e);
            
            new Fetcher().callApi({
                api_path:'/campaigns/save_opinion/',
                api_data:{
                    "uid_campaign" : this.state.campaign.uid_campaign,
                    "user_campaign_meta": this.state.user_campaign_meta
                },
                method:'POST',
            }).then((r) => {
                if(r.status === 'success'){
                    showToast('app_well_saved');
                    removeLoadingButton(e,saveBtnEls);
                }
                else showToast('app_an_error_occured');
            });
        }
        else{
            const tmp_err = validateEntireForm(this.state.formFieldsNames,this.state.formErrors);
            this.setState({formErrors : tmp_err});

        }
    }
    render() { 
        return (
            <div id="bk_campaign" className="container brand_page fixed_header  bg-white min-vh-100 px-md-0">
                
                <div className="row header">
                    <div className="col px-0">
                        <BackButton />
                        <div className="logo_marque" style={{'backgroundImage':"url("+process.env.REACT_APP_SITE_URL+'/coffrefort/brands/'+this.state.campaign.id_marque+'/'+this.state.campaign.logo_marque+")"}}></div>
                        <Link className="top_right_button ico_help" to="/help"></Link>
                    </div>
                </div>

                <div className="row content mt-5 px-md-5">
                    <div className="col-12 text-center mt-2">
                        <h2 className="text-andeslight"><Translate s="program_fiche_feedback" /></h2>
                    </div>
                    <form ref={this.opinionForm} className="col-12 mt-2">
                        {(() => {
                            if(this.state.campaign.mon_avis === '1' && !this.state.readOnly){
                                return(
                                    <>
                                        <p className="mb-2"> <Translate s="program_fiche_review_mail" /> <a href={"mailto:partenariat@bilbokid.com?subject=Photos"+this.state.campaign.campaign_name}><Translate s="partnership_bilbokid_com" /></a></p>
                                        <div className="form-selectgroup form-selectgroup-boxes d-flex flex-column">
                                            <label className="form-selectgroup-item flex-fill">
                                                <input type="radio" name="mon_avis" value="1" className="form-selectgroup-input" onChange={(e) => this.handleChange(e)} checked={this.state.user_campaign_meta.mon_avis === "1"} />
                                                <div className="form-selectgroup-label d-flex align-items-center p-3">
                                                    <div className="me-3"><span className="form-selectgroup-check"></span></div>
                                                    <div>Oui</div>
                                                </div>
                                            </label>
                                            <label className="form-selectgroup-item flex-fill">
                                                <input type="radio" name="mon_avis" value="0" className={`form-selectgroup-input ${errorClass(this.state.formErrors.mes_photos)}`} onChange={(e) => this.handleChange(e)} checked={this.state.user_campaign_meta.mon_avis === "0"} />
                                                <div className="form-selectgroup-label d-flex align-items-center p-3">
                                                    <div className="me-3"><span className="form-selectgroup-check"></span></div>
                                                    <div>Non</div>
                                                </div>
                                                <div className="invalid-feedback">Merci de cocher une réponse</div>
                                            </label>
                                        </div>
                                    </>
                                )
        
                            }
                        })()}
                        {(() => {
                            if(this.state.campaign.mes_photos === '1' && !this.state.readOnly){
                                return(
                                    <>
                                        <p className="mb-2"> <Translate s="program_fiche_picture_mail" /> <a href={"mailto:partenariat@bilbokid.com?subject=Photos"+this.state.campaign.campaign_name}><Translate s="partnership_bilbokid_com" /></a></p>
                                        <div className="form-selectgroup form-selectgroup-boxes d-flex flex-column">
                                            <label className="form-selectgroup-item flex-fill">
                                                <input type="radio" name="mes_photos" value="1" className="form-selectgroup-input" onChange={(e) => this.handleChange(e)} checked={this.state.user_campaign_meta.mes_photos === "1"} />
                                                <div className="form-selectgroup-label d-flex align-items-center p-3">
                                                    <div className="me-3"><span className="form-selectgroup-check"></span></div>
                                                    <div>Oui</div>
                                                </div>
                                            </label>
                                            <label className="form-selectgroup-item flex-fill">
                                                <input type="radio" name="mes_photos" value="0" className={`form-selectgroup-input ${errorClass(this.state.formErrors.mes_photos)}`} onChange={(e) => this.handleChange(e)} checked={this.state.user_campaign_meta.mes_photos === "0"} />
                                                <div className="form-selectgroup-label d-flex align-items-center p-3">
                                                    <div className="me-3"><span className="form-selectgroup-check"></span></div>
                                                    <div>Non</div>
                                                </div>
                                                <div className="invalid-feedback">Merci de cocher une réponse</div>
                                            </label>
                                        </div>
                                    </>
                                )
        
                            }
                        })()}

                        {(() => {
                            if(this.state.campaign.campaign_type === '7' && !this.state.readOnly){
                                return(
                                    <div className="my-3">
                                        <p className="mb-2"> <Translate s="program_fiche_feedback_avisconso_star" /></p>
                                        <div className="rating">
                                            <input type="radio" name="avis_rating" value="5" id="5" onChange={(e) => this.handleChange(e)} checked={this.state.user_campaign_meta.avis_rating === '5'?true:false} /><label for="5">☆</label>
                                            <input type="radio" name="avis_rating" value="4" id="4" onChange={(e) => this.handleChange(e)} checked={this.state.user_campaign_meta.avis_rating === '4'?true:false} /><label for="4">☆</label>
                                            <input type="radio" name="avis_rating" value="3" id="3" onChange={(e) => this.handleChange(e)} checked={this.state.user_campaign_meta.avis_rating === '3'?true:false} /><label for="3">☆</label>
                                            <input type="radio" name="avis_rating" value="2" id="2" onChange={(e) => this.handleChange(e)} checked={this.state.user_campaign_meta.avis_rating === '2'?true:false} /><label for="2">☆</label>
                                            <input type="radio" name="avis_rating" value="1" id="1" onChange={(e) => this.handleChange(e)} checked={this.state.user_campaign_meta.avis_rating === '1'?true:false} /><label for="1">☆</label>
                                        </div>
                                    </div>
                                )
        
                            }
                           
                            if(this.state.campaign.campaign_type === '7' && this.state.readOnly){
                                return(
                                    <div className="rating_readonly my-3">
                                        <label className={this.state.user_campaign_meta.avis_rating === '5'?'stared':''}>☆</label>
                                        <label className={this.state.user_campaign_meta.avis_rating === '4'?'stared':''}>☆</label>
                                        <label className={this.state.user_campaign_meta.avis_rating === '3'?'stared':''}>☆</label>
                                        <label className={this.state.user_campaign_meta.avis_rating === '2'?'stared':''}>☆</label>
                                        <label className={this.state.user_campaign_meta.avis_rating === '1'?'stared':''}>☆</label>
                                    </div>
                                )
                            }
                        })()}
                        {(() => {
                            if(!this.state.readOnly){
                                return(
                                    <>
                                    <p className="mb-2"> <Translate s="program_fiche_feedback_add" /></p>
                                    <textarea className={`form-control ${errorClass(this.state.formErrors.retour_experience)}`} data-is_required="false" name="retour_experience" rows="4" value={this.state.user_campaign_meta.retour_experience} onChange={(e) => this.handleChange(e)}></textarea>
                                    </>
                                )
                            }
                            else{
                                return (
                                    <p>{this.state.user_campaign_meta.retour_experience}</p>
                                )
                            }
                        })()}
                    </form>
                   
                </div>
                
                <div className="fixed-bottom bg-white gradient-top">
                    <div className={"btn-bottom-ctnr d-grid p-3 mt-4 "+(this.state.readOnly?'d-none':'')}>
                    {renderButton((e) => this.handleSubmit(e))}
                    </div>
                </div>
            </div>
        )
    }  
}

export default DealOpinion;