import React, { Component } from 'react';
import {IoArrowBack, IoOpenOutline} from 'react-icons/io5';
import { Browser } from '@capacitor/browser';


class DisplayGridImages extends Component {
    constructor(props) {
      super(props);
    }
    displayImage = (e) => {
        //console.log(e);
        e.currentTarget.classList.toggle('fullscreen')
        e.currentTarget.parentNode.classList.toggle('opened_image')
        e.stopPropagation()
    }
    closeImage = (e) => {
        document.getElementsByClassName("fullscreen")[0].click();
    }
    openImage = async  (e,link) => {
        await Browser.open({ url: link });
        e.stopPropagation();
    }

    render() {
        return (
            <>
            {
             Object.values(this.props.images).map((iv,kv) => {
                return (
                    <div className="col-auto mb-3">
                        <div className="action_bar">
                            <button className='me-auto no_outline' onClick={() => this.closeImage()}><IoArrowBack /></button>
                            <button onClick={(e) => this.openImage(e,process.env.REACT_APP_SITE_URL+'/coffrefort/'+iv)}><IoOpenOutline /> Save</button>
                        </div>
                        <div className={"upload_block rounded"} style={{'backgroundImage':"url("+process.env.REACT_APP_SITE_URL+'/coffrefort/'+iv+")"}} onClick={(e) => this.displayImage(e)}>
                        </div>
                    </div>
                )
             })
             } 
             </>
        )
    }
}
export default DisplayGridImages;