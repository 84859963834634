import React from 'react';
import { validateField, validateForm, errorClass } from '../Helpers/Formvalidation';

import { showToast } from '../Helpers/Toast';
import Fetcher from '../Helpers/Fetcher';

class Address extends React.Component {  
    
    constructor(props) {
        super(props);
     
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            user_meta:this.props.data,
            //formFields: {adresse: '', code_postal: '', ville:'', pays:''},
            formErrors: {adresse: 'success', code_postal: 'success', ville:'success', pays:'success'},
            greenLight:''  
        };
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.data !== prevProps.data) {
            this.setState({
                user_meta:this.props.data,
                token:this.props.token,
            });
        }
    }
    showBlockWithData(){
        this.showBlock();
    }
    showBlock(){
        const acm = document.getElementById('bk_edit_block');
        acm.classList.replace("d-none", "d-block");
    }
    closeBlock(){       
        const acm = document.getElementById('bk_edit_block');
        acm.classList.replace("d-block", "d-none");
        
        this.setState({
            user_meta: {},
        });
    }
    
    handleChange(evt) {
        
        const id = evt.target.id;
        const type = evt.target.type;
        const value = type === "checkbox" ? evt.target.checked : evt.target.value;
        this.setState({
            user_meta : {
                ...this.state.user_meta,
                [id]: value
            }
        },() => {
            var err = validateField(evt.target);
            this.setState({
                formErrors:{
                    ...this.state.formErrors,
                    [id]:(err.fieldValid?'success':'error')
                }
            },() => {
                if(validateForm(this.state.formErrors) === 'success'){
                    this.setState({greenLight:''});
                }
                else{
                    this.setState({greenLight:'disable'});
                }
            });
        });
    }
    handleSubmit() {
        //Chech data
        if(validateForm(this.state.formErrors) === 'success'){
            new Fetcher().callApi({
                api_path:'/helpers/update_user_meta/',
                api_data:{
                    "metas": this.state.user_meta
                },
                method:'POST',
            }).then((r) => {
                if(r.status === 'success') {
                    showToast('Votre adresse a été changée avec succès');
                    //call parent func to refresh data
                    this.props.refrechCallback();
                    this.closeBlock();
                }
                else showToast('app_an_error_occured');
            });            
        }        
        
        
    }

    render() {  
        return (
            <div id="bk_edit_block" className="form_block mt-3 d-none">
                <div className="block_header">
                    <h5 className="pb-2">Changer mon adresse</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.closeBlock()}></button>
                </div>
                <div className="block_body">
                    <div className="row">
                        <div className="col-12 form-group">
                            <label htmlFor="adresse">Adresse</label>
                            <input type="text" className={`form-control ${errorClass(this.state.formErrors.adresse)}`} id="adresse" value={this.state.user_meta.adresse}  onChange={this.handleChange} placeholder="" />
                            <div className="invalid-feedback">Merci de renseigner une adresse</div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-4 form-group">
                            <label htmlFor="code_postal">Code postal</label>
                            <input type="text" className={`form-control ${errorClass(this.state.formErrors.code_postal)}`} id="code_postal"  value={this.state.user_meta.code_postal}  onChange={this.handleChange} />
                            <div className="invalid-feedback">Merci de renseigner un code postal</div>
                        </div>
                        <div className="col-4 form-group">
                            <label htmlFor="ville">Ville</label>
                            <input type="text" className={`form-control ${errorClass(this.state.formErrors.ville)}`} id="ville"  value={this.state.user_meta.ville}  onChange={this.handleChange} />
                            <div className="invalid-feedback">Merci de renseigner une ville</div>
                        </div>
                        <div className="col-4 form-group px-1">
                            <label htmlFor="pays">Pays</label>
                            <input type="text" className={`form-control ${errorClass(this.state.formErrors.pays)}`} id="pays"  value={this.state.user_meta.pays}  onChange={this.handleChange} />
                            <div className="invalid-feedback">Merci de renseigner un pays</div>
                        </div>
                    </div>
                </div>
                <div className="block_footer mt-3  d-grid">
                    <button type="button" className={"btn btn-primary "+this.state.greenLight}  onClick={() => this.handleSubmit()}>Modifier</button>
                </div>
            </div>

        )
    }  
}

export default Address;