import Translate from '../Helpers/Translate';
export const genRadiogroupBoxes = (d) => {
    return (
        <>
            {d.label !== ''? <label class={"form-label "+(d.required !== undefined && d.required?'required':'')}><Translate s={d.label} /> :</label> : '' }
            <div className="form-selectgroup form-selectgroup-boxes d-flex flex-column">
                {
                    
                    Object.values(d.data).map((vd,kd) => {
                        let checked = false;
                        if(vd.key === d.defaultValue) checked = true;
                        return (
                            <label key={d.name+vd.key} className={"form-selectgroup-item flex-fill "+(d.class !== undefined?d.class:'')}>
                                <input 
                                    type="radio" 
                                    name={d.name} 
                                    value={vd.key} 
                                    className={"form-selectgroup-input form-control "+(d.class !== undefined?d.class:'')}
                                    defaultChecked={checked} 
                                    checked={checked} 
                                    onChange={(e) => d.onChangeAction(e)} 
                                    data-is_required={(d.required !== undefined?d.required:false)} 
                                />
                                <div className="form-selectgroup-label d-flex align-items-center p-3">
                                    <div className="me-3">
                                        <span className="form-selectgroup-check"></span>
                                    </div>
                                    <div>
                                        <span className="payment payment-provider-visa payment-xs me-2"></span>
                                        {vd.value}
                                    </div>
                                </div>
                            </label>
                        )
                    })
                }
                <div className="invalid-feedback"><Translate s="app_err_mandatoryfield" /></div>
            </div>
        </>
    )
}

export const genRadioInline = (d) => {
    return (
        <>
            {d.label !== ''? <label class={"form-label "+(d.required !== undefined && d.required?'required':'')}><Translate s={d.label} /> :</label> : '' }
            <div className="">
                {
                    
                    Object.values(d.data).map((vd,kd) => {
                        let checked = false;
                        if(vd.key === d.defaultValue) checked = true;
                        return (
                            <label key={d.name+vd.key} className={"form-check form-check-inline "+(d.class !== undefined?d.class:'')}>
                                <input 
                                    type="radio" 
                                    name={d.name} 
                                    value={vd.key} 
                                    className={"form-check-input "+(d.class !== undefined?d.class:'')}
                                    defaultChecked={checked} 
                                    checked={checked} 
                                    onChange={(e) => d.onChangeAction(e)} 
                                    data-is_required={(d.required !== undefined?d.required:false)} 
                                />                                
                                <span class="form-check-label"> {vd.value}</span>

                            </label>
                        )
                    })
                }
                <div className="invalid-feedback"><Translate s="app_err_mandatoryfield" /></div>
            </div>
        </>
    )
}
