import React from 'react';

import BackButton from "./Components/BackButton";

import CampaignApply from './CampaignApply';
import Translate from './Helpers/Translate';

const REGIONS = {'r0' : 'Auvergne-Rhône-Alpes','r1' : 'Bourgogne-Franche-Comté','r2' : 'Bretagne','r3' : 'Centre-Val de Loire','r4' : 'Corse','r5' : 'Grand Est','r6' : 'Hauts-de-France','r7' : 'Île-de-France','r8' : 'Normandie','r9' : 'Nouvelle-Aquitaine','r10' : 'Occitanie','r11' : 'Pays de la Loire','r12' : 'Provence-Alpes-Côte d\'Azur'}
const DEPARTEMENTS = {'1' : 'Ain','2' : 'Aisne','3' : 'Allier','4' : 'Alpes-de-Haute-Provence','5' : 'Hautes-Alpes','6' : 'Alpes-Maritimes','7' : 'Ardèche','8' : 'Ardennes','9' : 'Ariège','10' : 'Aube','11' : 'Aude','12' : 'Aveyron','13' : 'Bouches-du-Rhône','14' : 'Calvados','15' : 'Cantal','16' : 'Charente','17' : 'Charente-Maritime','18' : 'Cher','19' : 'Corrèze','2A' : 'Corse-du-Sud','2B' : 'Haute-Corse','21' : 'Côte-d\'Or','22' : 'Côtes d\'Armor','23' : 'Creuse','24' : 'Dordogne','25' : 'Doubs','26' : 'Drôme','27' : 'Eure','28' : 'Eure-et-Loir','29' : 'Finistère','30' : 'Gars','31' : 'Haute-Garonne','32' : 'Gers','33' : 'Gironde','34' : 'Hérault','35' : 'Ille-et-Vilaine','36' : 'Indre','37' : 'Indre-et-Loire','38' : 'Isère','39' : 'Jura','40' : 'Landes','41' : 'Loir-et-Cher','42' : 'Loire','43' : 'Haute-Loire','44' : 'Loire-Atlantique','45' : 'Loiret','46' : 'Lot','47' : 'Lot-et-Garonne','48' : 'Lozère','49' : 'Maine-et-Loire','50' : 'Manche','51' : 'Marne','52' : 'Haute-Marne','53' : 'Mayenne','54' : 'Meurthe-et-Moselle','55' : 'Meuse','56' : 'Morbihan','57' : 'Moselle','58' : 'Nièvre','59' : 'Nord','60' : 'Oise','61' : 'Orne','62' : 'Pas-de-Calais','63' : 'Puy-de-Dôme','64' : 'Pyrénées-Atlantiques','65' : 'Hautes-Pyrénées','66' : 'Pyrénées-Orientales','67' : 'Bas-Rhin','68' : 'Haut-Rhin','69' : 'Rhône','70' : 'Haute-Saône','71' : 'Saône-et-Loire','72' : 'Sarthe','73' : 'Savoie','74' : 'Haute-Savoie','75' : 'Paris','76' : 'Seine-Maritime','77' : 'Seine-et-Marne','78' : 'Yvelines','79' : 'Deux-Sèvres','80' : 'Somme','81' : 'Tarn','82' : 'Tarn-et-Garonne','83' : 'Var','84' : 'Vaucluse','85' : 'Vandée','86' : 'Vienne','87' : 'Haute-Vienne','88' : 'Vosges','89' : 'Yonne','90' : 'Territoire de Belfort','91' : 'Essonne','92' : 'Hauts-de-Seine','93' : 'Seine-St-Denis','94' : 'Val-de-Marne','95' : 'Val-D\'Oise','971' : 'Guadeloupe','972' : 'Martinique','973' : 'Guyane','974' : 'La Réunion','976' : 'Mayotte',}

class CampaignBrand extends React.Component {  
    
    constructor(props) {
        super(props);
        this.state = {
            campaign: this.props.campaign_data, 
            user_campaign: this.props.user_campaign,
            readOnly:this.props.readOnly,
        };   
    }
    render() { 
        return (
            <div id="bk_campaign" className="container profil_page fixed_header  bg-white  px-md-0 min-vh-100">
                
                <div className="row header">
                    <div className="col px-0">
                        
                        <BackButton />
                        <div className="logo_marque" style={{'backgroundImage':"url("+process.env.REACT_APP_SITE_URL+'/coffrefort/brands/'+this.state.campaign.id_marque+'/'+this.state.campaign.logo_marque+")"}}></div>
                    </div>
                </div>

                <div className="row content mt-5  px-md-5">
                    <div className="col-12 mt-2">
                        <h1 className="text-center text-andeslight font-14"><Translate s="app_campaign_profiles" /></h1>
                        <div className="separator"></div>
                    </div>
                    <div className="col-12 mt-2">
                        {(() => {
                            if((this.state.campaign.campaign_type !== '7' && this.state.campaign.campaign_type !== '13' && this.state.campaign.campaign_type !== '14') && this.state.campaign.abonne_min_insta > 1){
                                return(
                                    <>
                                        <div className="row bg-lightbeige py-3">
                                            <div className="col text-center">
		                                        <span className="text-pink d-block mt-1"><Translate s='label_abonne_min_insta' /></span>
                                                &#x0003E; {this.state.campaign.abonne_min_insta} <Translate s="app_lbl_followers" />
                                            </div>
                                            <div className="col text-center">
		                                        <span className="text-pink d-block mt-1"><Translate s="app_lbl_engagement_min" /></span>
                                                {this.state.campaign.taux_engagement_min}%
                                            </div>
                                        </div>  
                                        <div className="separator"></div>
                                    </>
                                )
                            }
                        })()}
                        

                        
                        <div className="row pt-0 py-2">
                            <div className="col text-center">
                                <span className="label-pink"><Translate s={"campaign_tag_"+this.state.campaign.campaign_tags} /></span> 
                                <br />
                                <span className="label-pink"><Translate s={"lbl_"+this.state.campaign.campaign_social_network} /></span> / <span className="label-pink"><Translate s={"campaign_type_"+this.state.campaign.campaign_type} /></span>

                                <div className="separator"></div>
                                
                                {(() => {
                                    console.log('this.state.campaign.campaign_cat_inf',this.state.campaign.campaign_cat_inf);
                                    if(this.state.campaign.campaign_cat_inf !== null){
                                        const campaign_cat_inf = JSON.parse(this.state.campaign.campaign_cat_inf);
                                        return(
                                            <>
                                                {  
                                                    campaign_cat_inf.map((ici,kci) => {
                                                        return(
                                                            <>
                                                            <span className={"bico ico_"+ici}></span>
                                                            </>
                                                        )
                                                    })
                                                }
                                                <br />
                                            </>
                                          
                                        )
                                    }
                                })()}
                                {(() => {
                                    if(this.state.campaign.campaign_cat_inf !== null){
                                        const campaign_cat_inf = JSON.parse(this.state.campaign.campaign_cat_inf);
                                        const campaign_cat_inf_cnt = Object.keys(campaign_cat_inf).length-1;
                                        let separator = '';
                                        return(
                                            campaign_cat_inf.map((ici,kci) => {
                                                if(kci < campaign_cat_inf_cnt) separator = ', ';
                                                else separator = '';
                                                return(
                                                    <>
                                                        <Translate s={'profil_info_as_'+ici+'_short_s'}/>{separator}
                                                    </>
                                                )
                                            })
                                        )
                                    }
                                })()}
                                {(() => {
                                    if(this.state.campaign.campaign_secteurs_activites !== null){
                                        const campaign_secteurs_activites = JSON.parse(this.state.campaign.campaign_secteurs_activites);
                                        const campaign_secteurs_activites_cnt = Object.keys(campaign_secteurs_activites).length-1;
                                        let separator = '';
                                        return(
                                            <>
                                            
                                                <div className="separator"></div>
                                                <span className="text-pink d-block mt-1"><Translate s={'lbl_secteurs_recherches'}/></span>
                                                {
                                                    campaign_secteurs_activites.map((ici,kci) => {
                                                        if(kci < campaign_secteurs_activites_cnt) separator = ', ';
                                                        else separator = '';
                                                        return(
                                                            <>
                                                                <Translate s={'marque_secteurs_activites_'+ici}/>{separator}
                                                            </>
                                                        )
                                                    })
                                                }
                                            </>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if(this.state.campaign.campaign_cible_partagee !== null){
                                        const campaign_cible_partagee = JSON.parse(this.state.campaign.campaign_cible_partagee);
                                        const campaign_cible_partagee_cnt = Object.keys(campaign_cible_partagee).length-1;
                                        let separator = '';
                                        return(
                                            <>
                                                <div className="separator"></div>
                                                <span className="text-pink d-block mt-1"><Translate s={'lbl_cible_partagee'}/></span>
                                                {
                                                    campaign_cible_partagee.map((ici,kci) => {
                                                        if(kci < campaign_cible_partagee_cnt) separator = ', ';
                                                        else separator = '';
                                                        return(
                                                            <>
                                                                <Translate s={'marque_cibles_'+ici.toLowerCase()}/>{separator}
                                                            </>
                                                        )
                                                    })
                                                }
                                            </>
                                        )
                                    }
                                })()}

                                
                                {(() => {
                                    if(this.state.campaign.campaign_sexe !== null){
                                        const campaign_sexe = JSON.parse(this.state.campaign.campaign_sexe);
                                        return(
                                            campaign_sexe.map((ics,kcs) => {
                                                return(
                                                    <>
                                                    <span className={"bico ico_sexe_"+ics}></span>
                                                    </>
                                                )
                                            })
                                        )
                                    }
                                })()}
                                {(() => {if(this.state.campaign.campaign_sexe !== null)  return (<br />) })()}
                                {(() => {
                                    if(this.state.campaign.campaign_sexe !== null){
                                        const campaign_sexe = JSON.parse(this.state.campaign.campaign_sexe);
                                        const campaign_sexe_cnt = Object.keys(campaign_sexe).length-1;
                                        let separator = '';
                                        return(
                                            campaign_sexe.map((ics,kcs) => {
                                                if(kcs < campaign_sexe_cnt) separator = ', ';
                                                else separator = '';
                                                return(
                                                    <>
                                                        <Translate s={'campaign_sexe_'+ics}/>{separator}
                                                    </>
                                                )
                                            })
                                        )
                                    }
                                })()}
                                {(() => {if(this.state.campaign.campaign_sexe !== null)  return (<br />) })()}
                                
                                {(() => {
                                    if(this.state.campaign.campaign_age_range !== null){
                                        const campaign_age_range = JSON.parse(this.state.campaign.campaign_age_range);
                                        const campaign_age_range_cnt = Object.keys(campaign_age_range).length-1;
                                        let separator = '';
                                        return(
                                            campaign_age_range.map((iar,kar) => {
                                                if(kar < campaign_age_range_cnt) separator = ', ';
                                                else separator = '';
                                                return(
                                                    <>
                                                        <Translate s={'age_range'+iar}/>{separator}
                                                    </>
                                                )
                                            })
                                        )
                                    }
                                })()}
                                
                                
                                {(() => {
                                    if(this.state.campaign.campaign_enceinte === 1){
                                        return(
                                            <span className="text-pink d-block"><Translate s='attent_enfant' /></span>
                                        )
                                    }
                                })()}
                                <div className="separator"></div>
                                {(() => {
                                    if(this.state.campaign.campaign_pare !== null){
                                        let pare = '';
                                        if(this.state.campaign.campaign_pare === 'multipare') pare = <Translate s='label_multipare' />;
                                        else pare = <Translate s='label_primapare' />;
                                        return(
                                            <>
                                            <span className="d-block">{pare}</span>
                                            </>
                                        )
                                    }
                                })()}
                                
                                
                                {(() => {if(this.state.campaign.campaign_pms_secteur !== null || this.state.campaign.campaign_pms_sections_classes !== null)  return ( <div className="separator"></div>) })()}

                                {(() => {
                                    if(this.state.campaign.campaign_pms_secteur !== null){
                                        let cps = '';
                                        if(this.state.campaign.campaign_pms_secteur === 'public') cps = <Translate s='profil_profil_pro_milieu_scolaire_pms_secteur_public' />;
                                        if(this.state.campaign.campaign_pms_secteur === 'prive') cps = <Translate s='profil_profil_pro_milieu_scolaire_pms_secteur_prive' />;
                                        return(
                                            <>
                                            <span className="text-pink d-block mt-1">{cps}</span>
                                            </>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if(this.state.campaign.campaign_pms_sections_classes !== null){
                                        const campaign_pms_sections_classes = JSON.parse(this.state.campaign.campaign_pms_sections_classes);
                                        const campaign_pms_sections_classes_cnt = Object.keys(campaign_pms_sections_classes).length-1;
                                        let separator = '';
                                        return(
                                            campaign_pms_sections_classes.map((ipsc,kpsc) => {
                                                if(kpsc < campaign_pms_sections_classes_cnt) separator = ', ';
                                                else separator = '';
                                                return(
                                                    <>
                                                        <Translate s={'profil_profil_pro_milieu_scolaire_pms_sections_classes_'+ipsc.toLowerCase()}/>{separator}
                                                    </>
                                                )
                                            })
                                        )
                                    }
                                })()}
                                {(() => {
                                    if(this.state.campaign.campaign_localisation_region !== null || this.state.campaign.campaign_localisation_departement !== null){
                                        return ( 
                                            <>
                                                <div className="separator"></div>
                                                <span className="text-pink d-block"><Translate s="label_localisation" /></span>
                                                <span className="bico ico_pin"></span>
                                            </>
                                        )                            
                                    }
                                })()}
                                {(() => {
                                    if(this.state.campaign.campaign_localisation_region !== null){
                                        const campaign_localisation_region = JSON.parse(this.state.campaign.campaign_localisation_region);
                                        const campaign_localisation_region_cnt = Object.keys(campaign_localisation_region).length-1;
                                        let separator = '';
                                        return(
                                            campaign_localisation_region.map((iclr,kclr) => {
                                                if(kclr < campaign_localisation_region_cnt) separator = ', ';
                                                else separator = '';
                                                return(
                                                    <>{REGIONS[iclr]}{separator}</>
                                                )
                                            })
                                        )
                                    }
                                })()}                                
                                
                                {(() => {if(this.state.campaign.campaign_localisation_departement !== null)  return ( <br />) })()}

                                {(() => {
                                    if(this.state.campaign.campaign_localisation_departement !== null){
                                        const campaign_localisation_departement = JSON.parse(this.state.campaign.campaign_localisation_departement);
                                        const campaign_localisation_departement_cnt = Object.keys(campaign_localisation_departement).length-1;
                                        let separator = '';
                                        return(
                                            campaign_localisation_departement.map((icld,kcld) => {
                                                if(kcld < campaign_localisation_departement_cnt) separator = ', ';
                                                else separator = '';
                                                return(
                                                    <>{DEPARTEMENTS[icld]}{separator}</>
                                                )
                                            })
                                        )
                                    }
                                })()}
                                
                                </div>
                        </div>


                    </div>
                    

                </div>
                
                <CampaignApply readOnly={this.state.readOnly} uid_campaign={this.state.campaign.uid_campaign} campaign_status={this.state.campaign.status} user_campaign={this.state.user_campaign}  questions={this.state.campaign.extra_questions}/>

            </div>
        )
    }
}

export default CampaignBrand;