import React from 'react';
import {Link} from "react-router-dom";

import Translate from '../../Helpers/Translate';
import logo from '../../Assets/img/default_logo.png';

import { showToast,showDomError } from '../../Helpers/Toast';
import { getLocalStorageItem} from '../../Helpers/Storage';
import Loading from '../../Components/Loading'


class Activate extends React.Component {  
    
    constructor(props) {
        super(props);
        
        this.state = {
            toastState: false,
            toastMessage: 'Message',
            app_langage:'',
            user_country_code: 'FR',
            pendingActivation: true,
            success: false,
            error: false,
            error_message:'',

        };   
    }
    componentDidMount(){
        const id = this.props.match.params.id;
        const activation = this.props.match.params.activation;

        this.activate(id,activation);
    } 
    activate = (id,activation) => {
        let url , data;     
        url = process.env.REACT_APP_API_URL + '/auth/activate';
        data = {
            "id": id,
            "activation": activation,
        }
        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            body: JSON.stringify(data)
        })
        .then((res) => {
            if(res.status === 200) return res.json();
            else showToast("Error Logging in");            
        })
        .then(
            (result) => {
                let error_message = '';
                let error = false;
                let success = false;
                if(result.status !== 'success') {
                    error_message = result.message;
                    error = true;
                }
                else success = true;
                this.setState({ 
                    pendingActivation: false,
                    error_message:error_message,
                    success:success,
                    error:error
                });

            },
            (error) => {        
                showToast(error.toString());
            }
        )
    }

    getLocalisationLang = async () => {
        //This fetch local storage lang to set flag as active   
        const localisation_lang = await getLocalStorageItem('localisation_lang');
        let cc = 'FR'; 
        if(localisation_lang == 'french') cc = 'FR';
        if(localisation_lang == 'english') cc =  'EN';
    
        this.setState({
            app_langage:localisation_lang,
            user_country_code : cc
        })
    }
    render() { 
        return this.state.pendingActivation ? <Loading alwaysShow={false} /> : this.renderActivated();      
    } 
    renderActivated() {  
        return (
            
            <div id="bk_login" className="bg_blur h-100 container">
                <main className="form-signin">
                    <img className="mb-4" src={logo} alt="" width="90" />
                    {(() => {
                        if(this.state.success){
                            return(
                                <>
                                    <p className="h4 mb-4 fw-normal"><Translate s='auth_activate_confirmation' /></p>
                                    <Link to={'/login'}><Translate s="auth_login_cta" /></Link>
                                </>
                            )
                        }
                    })()}
                    {(() => {
                        if(this.state.error){
                            return(
                                <>
                                    <div id="error_dom" className="alert alert-danger text-start">{this.state.error_message}</div>
                                    <Link to={'/auth/pwd_forget'}><Translate s="auth_forgotpassword" /></Link>
                                </>
                            )
                        }
                    })()}
                        
                
                
                        
                </main>
              

            </div>
        )
    }
}

export default Activate;