import React from 'react';
import Fetcher from '../../Helpers/Fetcher';
import { initiateFormErrors, validateEntireForm, validateField, validateForm, errorClass } from '../../Helpers/Formvalidation';
import Translate from '../../Helpers/Translate';
import {renderButton,addLoadingButton,removeLoadingButton} from "../../Forms/DealSaveButton";
import { showToast } from '../../Helpers/Toast';
import BackButton from '../../Components/BackButton';

class StepProfil extends React.Component {  
    //https://dribbble.com/shots/8411843-App-multi-step-form-and-progress
    
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            error:false,
            user:{},
            user_meta:{},
            constants:{
                cat_influenceur:{},
                type_influenceur:{}
            },
            formFieldsNames:['cat_influenceur','type_influenceur','description'],
            formFields:{},
            formErrors:{},

            selected_pro:'',
            readOnly:false,
            type_influenceur_required:[],
        }
    }
    componentDidMount(){
        //Fetch data 
        new Fetcher().callApi({
            api_path:'/profil/init',
            api_data:{},
            method:'POST',
        }).then((r) => {
            if(r.status === 'success'){
                this.setState({
                    isLoaded: true,
                    user:r.results.user,
                    user_meta:r.results.user_meta,
                    constants:r.results.constants
                  }, () => {
                        //setup default formFields
                        const that = this;
                        let selected_pro = this.state.selected_pro;
                        let type_influenceur_required = this.state.type_influenceur_required;
                        let ff = this.state.formFields;
                        if(this.state.user_meta.cat_influenceur !== ""){
                            ff['cat_influenceur'] = {};
                            const db_cat_influenceur = ((this.state.user_meta.cat_influenceur !== undefined)?JSON.parse(this.state.user_meta.cat_influenceur):[]);
                            [].forEach.call(db_cat_influenceur, function(c) {
                                ff['cat_influenceur'][c] = true;
                                type_influenceur_required[c] = true;
                                if(that.state.constants['cat_influenceur'][c].group === 2) selected_pro = c;
                                //Disable checkboxes i more than 2 choices
                                if(db_cat_influenceur.length > 1){
                                    const unchecked_group = document.querySelectorAll("[name='cat_influenceur']");                                    
                                    [].forEach.call(unchecked_group, function(cc) {
                                        if(db_cat_influenceur.includes(cc.value)) return;
                                        cc.disabled = true;
                                        cc.parentNode.classList.add('disabled');
                                    });
                                }

                                
                            });
                            
                        }
                        if(this.state.user_meta.type_influenceur!== undefined && this.state.user_meta.type_influenceur !== ""){
                            ff['type_influenceur'] = {};
                            const db_type_influenceur = JSON.parse(this.state.user_meta.type_influenceur);
                            [].forEach.call(db_type_influenceur, function(c) {
                                ff['type_influenceur'][c] = true;
                            });
                        }

                        //description
                        ff['description'] = this.state.user_meta.description;

                        this.setState({
                            formFields:ff,
                            type_influenceur_required:type_influenceur_required,
                            selected_pro:selected_pro
                        }, () => {      
                            //Initate errors
                            const fe = initiateFormErrors(this.state.formFieldsNames);
                            this.setState({
                                formErrors:fe,
                            });                           

                        })   
                  })    
            }
            else {
                this.setState({
                    isLoaded: true,
                    error:true
                });   
            }
        });

    }
    handleChange(evt){
        let e = '';
        if(evt.target !== undefined) e = evt.target;
        else e = evt;
        console.log(e)
        const name = e.name;
        const type = e.type;
        const value = type === "checkbox" ? e.checked : e.value;  
        let selected_pro = this.state.selected_pro;
        let type_influenceur_required = this.state.type_influenceur_required;

        //update form fields
        let ff = {};
        ff = this.state.formFields;
        let fe = {};
        fe = this.state.formErrors;
        const el = document.getElementsByName(name);
        //if(el.length > 2){ //checkbox
        if(type === "checkbox"){
            if(ff[name] !== undefined) ff[name][e.value] = value;
            else { 
                ff[name] = {};
                ff[name][e.value] = value;
            }
        }
        else ff[name] = value;

        
        console.log(name,type,e.value)
        console.log(name,type,value)

        //Custom business rules
        //-- cat_influenceur > only one per group
        if(name === 'cat_influenceur'){
            let group = '';
            group = this.state.constants.cat_influenceur[e.value]['group'];
            if(group === 2) {
                ff['type_influenceur'] = {}; //object
                fe['type_influenceur'] = ''; //string
                selected_pro = e.value; //if pro, set state
            }

            const check_group = document.querySelectorAll("[data-group='"+group+"']");
            const check_group_tot = check_group.length;
            const unchecked_group = document.querySelectorAll("[data-group='"+group+"']:not(:checked)");
            const unchecked_group_tot = unchecked_group.length;
            //Disable other group 2 options
            [].forEach.call(unchecked_group, function(c) {
                c.disabled= value?true:false;
                if(value) c.parentNode.classList.add('disabled');
                else c.parentNode.classList.remove('disabled');
            });
            if(group === 2 && unchecked_group_tot == check_group_tot) {
                selected_pro = '';
                type_influenceur_required[e.value] = false;
            }

            //Type influenceur hide/show
            if(value && group === 2){
                const type_influenceur = document.querySelectorAll("[name='type_influenceur']");
                [].forEach.call(type_influenceur, function(c) {
                     c.checked=false; //flush / uncheck all
                });
                /*const type_influenceur_to_show = document.querySelectorAll("[data-parent='"+e.value+"']");
                [].forEach.call(type_influenceur_to_show, function(c) {
                     c.parentNode.classList.remove('d-none');
                });*/
                type_influenceur_required[e.value] = true;
            }
            //TODO if only one cat_influenceur AND == parents, then fe type_influenceur = success
            const cat_influenceur = document.querySelectorAll("[name='cat_influenceur']:checked");
            if(cat_influenceur.length === 1 && cat_influenceur[0].value === 'influenceur'){
                fe['type_influenceur'] = 'success';
            }

        }
        console.log(ff);
        //Errors
        const err = validateField(e);
        fe[name] = (err.fieldValid?'success':'error');
        //push state
        this.setState({
            formFields:ff,
            formErrors:fe,
            selected_pro:selected_pro,
            type_influenceur_required:type_influenceur_required
        });

    }
    handleSubmit = (e) =>  {
        if(validateForm(this.state.formErrors) === 'success'){
            const saveBtnEls = addLoadingButton(e);
            new Fetcher().callApi({
                api_path:'/profil/save/',
                api_data:{
                    slug: 'profil',
                    formFields: this.state.formFields,
                }, 
                method:'POST',
            }).then((r) => {
                if(r.status === 'success'){
                    showToast('app_well_saved');
                    removeLoadingButton(e,saveBtnEls);
                    //move back to steps
                    this.props.history.goBack();
                    //this.props.history.push('/register/steps');
                }
                else showToast('app_an_error_occured'); 
            });
        }
        else{
            const tmp_err = validateEntireForm(this.state.formFieldsNames,this.state.formErrors);
            this.setState({formErrors : tmp_err});
        }

    }
    render() {   
        const cat_influenceur = this.state.constants.cat_influenceur; 
        const cat_influenceur_key = Object.keys(cat_influenceur);

        const type_influenceur = this.state.constants.type_influenceur; 
        const type_influenceur_key = Object.keys(type_influenceur);

        return (
            <div id="bk_register_steps" className="">
                <div className="container-fluid bg-white">
                    <div className="header row px-3 py-5">
                        <div className="col text-center">
                            <BackButton />
                            <h2 className="header_title"><Translate s="dropdown_monprofil" /></h2>
                        </div>
                    </div>
                </div>
                
                <div className="container pb-9">

                    <div className="row my-4"> 
                        <div className="col">
                            <label class="form-label mb-0"><Translate s="profil_info_youare" /> : </label>
                            <small><Translate s="profil_info_youare_explanation" /></small>
                            <div className="form-selectgroup form-selectgroup-boxes d-flex flex-column mt-2">
                                {
                                    Object.values(cat_influenceur).map((vd,kd) => {
                                        return (
                                            <label key={cat_influenceur_key[kd]} className={`form-selectgroup-item flex-fill ${errorClass(this.state.formErrors.cat_influenceur)}`}>
                                                <input 
                                                    type="checkbox" 
                                                    name={"cat_influenceur"}
                                                    value={cat_influenceur_key[kd]} 
                                                    className={"form-selectgroup-input "+(cat_influenceur.class !== undefined?cat_influenceur.class:'')}
                                                    checked={(this.state.formFields['cat_influenceur'] !== undefined && this.state.formFields['cat_influenceur'][cat_influenceur_key[kd]] !== undefined && this.state.formFields['cat_influenceur'][cat_influenceur_key[kd]])?true:false} 
                                                    onChange={(e) =>this.handleChange(e)} 
                                                    data-is_required={true}
                                                    data-group={vd.group}
                                                    data-validation="checkbox_multiple"
                                                />
                                                <div className="form-selectgroup-label d-flex align-items-center p-3">
                                                    <div className="me-3">
                                                        <span className="form-selectgroup-check"></span>
                                                    </div>
                                                    <div>
                                                        <span className="payment payment-provider-visa payment-xs me-2"></span>
                                                        <Translate s={'profil_info_youare_'+cat_influenceur_key[kd]} />
                                                    </div>
                                                </div>
                                            </label>
                                        )
                                    })
                                }
                                <div className="invalid-feedback"><Translate s="app_err_mandatoryfield" /></div>
                            </div>              
                        </div>
                    </div>
                    

                    <div className={"row my-4 "+(this.state.selected_pro == ''?'d-none':'')}> 
                            <div className="col-12">
                                <label class="form-label mb-0"><Translate s="profil_info_youare" /> <Translate s={"profil_info_as_"+this.state.selected_pro} /> : </label>
                                <div className="form-selectgroup form-selectgroup-boxes d-flex flex-column mt-2">
                                    {
                                        Object.values(type_influenceur).map((vd,kd) => {
                                            return (
                                                <label key={type_influenceur_key[kd]} className={'form-selectgroup-item flex-fill '+' '+((this.state.type_influenceur_required[vd.parent] !== undefined && this.state.type_influenceur_required[vd.parent] === true)?'':'d-none')+' '+errorClass(this.state.formErrors.type_influenceur)}>
                                                    <input 
                                                        type="checkbox" 
                                                        name={"type_influenceur"}
                                                        value={type_influenceur_key[kd]} 
                                                        className={"form-selectgroup-input"}
                                                        checked={(this.state.formFields['type_influenceur'] !== undefined && this.state.formFields['type_influenceur'][type_influenceur_key[kd]] !== undefined && this.state.formFields['type_influenceur'][type_influenceur_key[kd]])?true:false} 
                                                        onChange={(e) =>this.handleChange(e)} 
                                                        data-is_required={(this.state.type_influenceur_required[vd.parent] !== undefined)?this.state.type_influenceur_required[vd.parent]:false}
                                                        disabled={(this.state.type_influenceur_required[vd.parent] === undefined)?true:!this.state.type_influenceur_required[vd.parent]}
                                                        data-parent={vd.parent}
                                                        data-validation="checkbox_multiple"
                                                    />
                                                    <div className="form-selectgroup-label d-flex align-items-center p-3">
                                                        <div className="me-3">
                                                            <span className="form-selectgroup-check"></span>
                                                        </div>
                                                        <div>
                                                            <span className="payment payment-provider-visa payment-xs me-2"></span>
                                                            <Translate s={'profil_info_user_type_'+type_influenceur_key[kd]} />
                                                        </div>
                                                    </div>
                                                </label>
                                            )
                                        })
                                    }
                                    <div className="invalid-feedback"><Translate s="app_err_mandatoryfield" /></div>
                                </div>              
                            </div>
                            <div className="my-3">
                                <label className="form-label"><Translate s="profil_info_define_work" /></label>
                                <input type="text" className={`form-control ${errorClass(this.state.formErrors.type_influenceur_precision)}`} autoComplete="off" data-is_required={false} name="type_influenceur_precision" value={this.state.formFields.type_influenceur_precision} onChange={(e) => this.handleChange(e)} />
                            </div>

                    </div>

                    {(() => {
                            if(this.state.formFields['cat_influenceur'] !== undefined && this.state.formFields['cat_influenceur']['influenceur'] !== undefined && this.state.formFields['cat_influenceur']['influenceur']) {
                                return(
                                    <div className="row mt-3 align-items-center">
                                        <div className="col-2 switch_ctnr">
                                            <label className="switch v-center">
                                                <input type="checkbox" name="arpp" id="arpp" data-is_required={false}  onChange={(e) => this.handleChange(e)} checked={this.state.formFields.arpp || (this.state.formFields.arpp === undefined && this.state.user_meta.arpp === "1")} />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                        <div className="col-10">
                                            <label className="switch_txt form-label mb-0" htmlFor="arpp">Certification ARPP</label>
                                        </div>
                                    </div>

                                )
                            }
                        })()}
                       

                    <div className="row my-4"> 
                            <div className="col-12">
                        
                                <label class="form-label required" for="description"><Translate s="profil_univers_description" /><br /> <small><Translate s="profil_univers_description_sublabel" /></small></label>                                
                                <textarea className={`form-control ${errorClass(this.state.formErrors.description)}`} data-is_required={true} id="description" name="description" rows="4" value={this.state.formFields.description} maxlength="500" minlength="50"  onChange={(e) => this.handleChange(e)}></textarea>
                                <div className="invalid-feedback"><Translate s="app_err_descprofil" /></div>

                        </div>
                    </div>


                    <div className="fixed-bottom bg-white gradient-top">
                        <div className={"btn-bottom-ctnr d-grid p-3 mt-4 "+(this.state.readOnly?'d-none':'')}>
                        {renderButton((e) => this.handleSubmit(e))}
                        </div>
                    </div>

                </div>

            </div>
            
        )
    }  
}

export default StepProfil;