import React from 'react';
import Fetcher from '../../Helpers/Fetcher';
import { initiateFormErrors, validateEntireForm, validateField, validateForm, errorClass } from '../../Helpers/Formvalidation';
import Translate from '../../Helpers/Translate';
import {renderButton,addLoadingButton,removeLoadingButton} from "../../Forms/DealSaveButton";
import { showToast } from '../../Helpers/Toast';
import BackButton from '../../Components/BackButton';
import {genCheckgroupBoxes} from '../../Forms/GenCheckgroupBoxes';

import{check_inf_cat} from "../../Helpers/Global";

class StepUnivers extends React.Component {      
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            error:false,
            user:{},
            user_meta:{},
            themes:{},
            univers_produits:{},
            cat_influenceur:{},
            formFieldsNames:['themes','univers_produits'],
            formFields:{},
            formErrors:{},

            readOnly:false,
        }
    }
    componentDidMount(){
        //Fetch data 
        new Fetcher().callApi({
            api_path:'/profil/init',
            api_data:{},
            method:'POST',
        }).then((r) => {
            if(r.status === 'success'){
                //parse themes and univers_produits
                let ff = r.results.user_meta;
                ff.themes = ((r.results.user_meta.themes !== undefined)?JSON.parse(r.results.user_meta.themes):[]);
                ff.univers_produits = ((r.results.user_meta.univers_produits !== undefined)?JSON.parse(r.results.user_meta.univers_produits):[]);
                
                 //set state
                this.setState({
                    isLoaded: true,
                    user:r.results.user,
                    themes:r.results.constants.themes,
                    univers_produits:r.results.constants.univers_produits,
                    cat_influenceur:JSON.parse(r.results.user_meta.cat_influenceur),
                    formFields:ff,
                  }, () => {
                    //console.log(this.state.formFields);
                  })    
            }
            else {
                this.setState({
                    isLoaded: true,
                    error:true
                });   
            }
        });

    }
    handleChange(evt){
        let e = '';
        if(evt.target !== undefined) e = evt.target;
        else e = evt;

        const name = e.name;
        const type = e.type;
        const value = type === "checkbox" ? e.checked : e.value; 
        //update form fields
        let ff = {};
        ff = this.state.formFields;
        let fe = {};
        fe = this.state.formErrors;
        console.log(name);
        console.log(e.value);
        const el = document.getElementsByName(name);
        if(el.length > 2){ //checkbox or radio
            console.log(ff[name]);
            if(value) ff[name].push(e.value); //add
            else { // remove
                var index = ff[name].indexOf(e.value);
                if (index !== -1) {
                    ff[name].splice(index, 1);
                }
            }
        }
        else ff[name] = value;
        //Errors
        const err = validateField(e);
        console.log(err);
        fe[name] = (err.fieldValid?'success':'error');

        //push state
        this.setState({
            formFields:ff,
            formErrors:fe,
        });

    }
    handleSubmit = (e) =>  {
        if(validateForm(this.state.formErrors) === 'success'){
            const saveBtnEls = addLoadingButton(e);
            new Fetcher().callApi({
                api_path:'/profil/save/',
                api_data:{
                    slug: 'univers',
                    formFields: this.state.formFields,
                }, 
                method:'POST',
            }).then((r) => {
                if(r.status === 'success'){
                    showToast('app_well_saved');
                    removeLoadingButton(e,saveBtnEls);
                    //move back to steps
                    this.props.history.goBack();
                    //this.props.history.push('/register/steps');
                }
                else showToast('app_an_error_occured'); 
            });
        }
        else{
            const tmp_err = validateEntireForm(this.state.formFieldsNames,this.state.formErrors);
            this.setState({formErrors : tmp_err});
        }

    }
    filterIt(arr, searchKey) {
        return arr.filter(function(obj) {
          return Object.keys(obj).some(function(key) {
            return obj[key].includes(searchKey);
          })
        });
    }
    render() { 
        return (
            <div id="bk_register_steps" className="">
                <div className="container-fluid bg-white">
                    <div className="header row px-3 py-5">
                        <div className="col text-center">
                            <BackButton />
                            <h2 className="header_title"><Translate s="dropdown_mescentresinterets" /></h2>
                        </div>
                    </div>
                </div>
                
                <div className="container pb-9">
               
                    {(() => {
                        if(check_inf_cat(this.state.cat_influenceur,'influenceur')) {
                            return (
                                <div className="row  justify-content-center my-4"> 
                                    <div className="col-12 col-md-6 mb-3">
                                        {genCheckgroupBoxes({
                                            label:'profil_univers_themes',
                                            name:'themes',
                                            defaultValue:this.state.formFields.themes,
                                            onChangeAction:(e) => this.handleChange(e),
                                            required:true,
                                            validation:"checkbox_multiple",
                                            class:errorClass(this.state.formErrors.themes),
                                            data: Object.entries(this.state.themes).map(([k, v]) => {
                                                return {
                                                        'key':k,
                                                        'value':<Translate s={"profil_univers_themes_"+k} />
                                                    }
                                                })
                                        })} 
                                    </div>
                                </div>
                            )
                        }
                    })()} 

                    
                    {/* just for pro_petite_enfance ou pro_milieu_scolaire  */}
                    {(() => {
                        if(check_inf_cat(this.state.cat_influenceur,'pro_petite_enfance') || check_inf_cat(this.state.cat_influenceur,'pro_milieu_scolaire')) {
                            return (
                            Object.values(this.state.cat_influenceur).map((vcat,kcat) => {
                                if(this.state.univers_produits[vcat] === undefined) return;
                                return (
                                    <div className="row justify-content-center my-4"> 
                                    <div className="col-12 col-md-6 mb-3">
                                        {genCheckgroupBoxes({
                                            label:'profil_univers_produits',
                                            name:'univers_produits',
                                            defaultValue:this.state.formFields.univers_produits,
                                            onChangeAction:(e) => this.handleChange(e),
                                            required:true,
                                            validation:"checkbox_multiple",
                                            class:errorClass(this.state.formErrors.univers_produits),
                                            data: Object.entries(this.state.univers_produits[vcat]).map(([k, v]) => {
                                                return {
                                                        'key':k,
                                                        'value':<Translate s={"profil_univers_produits_"+vcat+"_"+k} />
                                                    }
                                                })
                                        })} 
                                    </div>
                                </div>
                                )
                            })
                            )
                           
                        }
                    })()}
                
                        
                    <div className="fixed-bottom bg-white gradient-top">
                        <div className={"btn-bottom-ctnr d-grid p-3 mt-4 "+(this.state.readOnly?'d-none':'')}>
                        {renderButton((e) => this.handleSubmit(e))}
                        </div>
                    </div>

                </div>

            </div>
            
        )
    }  
}

export default StepUnivers;