import React from 'react';

import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';

import { setLocalStorageItem,deleteLocalStorageItem } from '../../Helpers/Storage';
import Fetcher from '../../Helpers/Fetcher';

class DesImpersonate extends React.Component {  
    
    constructor(props) {
        super(props);
        this.state = {};   
    }
    componentDidMount(){
        new Fetcher().callApi({
            api_path:'/auth/desimpersonate',
            api_data:{},
            method:'GET',
        }).then((r) => {
            if(r.status === 'success'){
                SecureStoragePlugin.set({key:"bk_tokens_token", value:r.results.token}).then(success => console.log(success));
                SecureStoragePlugin.set({key:"bk_tokens_refresh_token", value:r.results.refresh_token}).then(success => console.log(success));
                deleteLocalStorageItem('impersonating_details');
                setLocalStorageItem("impersonating",false).then(
                    () => {
                        window.location.href = '/';
                    }
                );
            }
        });
    } 
    

    render() {
        return (
            <></>
        )

    }
}

export default DesImpersonate;
