import React from 'react';
import { withRouter } from 'react-router-dom';

import ico_home from '../Assets/img/ico_home.png';
import ico_profil from '../Assets/img/ico_profil.png';
import ico_notifications from '../Assets/img/ico_notifications.png';
import ico_messagerie from '../Assets/img/ico_messagerie.png';
import ico_full_heart from '../Assets/img/ico_full_heart.png';

import Translate from '../Helpers/Translate';

import { Preferences } from '@capacitor/preferences';


let p = '';

class Navigation extends React.Component {  
    
    constructor(props) {
        super(props);
        this.homeBtn = React.createRef();
        
        this.state = {
            active:{
                'campaigns':'button-inactive',
                'deals':'button-inactive',
                'chats':'button-inactive',
                'alerts':'button-inactive',
                'account':'button-inactive',
            },
            groups : [],
            path:this.props.location.pathname.substring(1),
            chat_count:this.props.chat_count,
            notification_count:this.props.notification_count,  
        };
    }
    componentDidMount() {
        if(this.state.path === '') p = 'campagnes';
        else p = this.state.path;
        this.setState({
            active:{
                [p]:'button-active'
            }
        });
        this.setGroup();
    }
    componentDidUpdate(previousProps, previousState){
        if (previousProps.location.pathname !== this.props.location.pathname) {
            
            var tplps = this.props.location.pathname.substring(1);
            if(tplps === '') p = 'campagnes';
            else p = tplps;
            
            this.setState({
                path:tplps,
                active:{
                    [p]:'button-active'
                }
            })
        }
        
    
        if (previousProps.notification_count !== this.props.notification_count || previousProps.chat_count !== this.props.chat_count) {
            this.setState({
                notification_count:this.props.notification_count,
                chat_count:this.props.chat_count
            })
        }

    }
    goTo = (url) => {
        this.props.history.push(url);
        this.setState({
            active:{
                [url]:'button-active'
            }
        });
        
    }
    hideNavigation = (t) => {
        if(t === 'inf' && this.state.path === 'login' || this.state.path.startsWith("auth") || this.state.path.startsWith("register") || this.state.path === 'logout' || this.state.path === 'lock' || this.state.path === 'intro' || this.state.path.startsWith("campaign/") || this.state.path.startsWith("deal/") || this.state.path.startsWith("chat/") || this.state.path.startsWith("alertssettings") || this.state.path.startsWith("help")) return true;
        else return false;
    }
    setGroup = async () => {
        const g = await this.getGroup();
        this.setState({
            groups:g
        })
    }
    getGroup = async () => {
        const { value } = await Preferences.get({ key: 'bk_user_info' });
        const v = JSON.parse(value);
        if(v !== null) { 
            const g = v.groups.map((e) => {
                return parseInt(e.id)
            })
            return g;
        }
        else return [];
    }
    render() {
        //TODO get user group
        //onsole.log(this.state.groups);
        //return (<></>)
        /*if(this.state.groups.includes(3)) return this.renderBrandNav();
        else*/
        return this.renderInfNav();
        
    }
    renderInfNav() { 
        return (
            <>
                {this.hideNavigation('inf') ?'': (
                    <footer className="footer">
                        <div id="buttonGroup" className="btn-group selectors" role="group" aria-label="Basic example">
                            <button id="campaigns" type="button" className={"btn "+this.state.active['campaigns']} ref={this.homeBtn} onClick={() => this.goTo('/campaigns')}>
                                <div className="selector-holder">
                                    <img src={ico_home} alt="campaigns" />
                                    <Translate s="app_nav_home" />
                                </div>
                            </button>
                            <button id="create" type="button" className={"btn "+this.state.active['deals']}  onClick={() => this.goTo('/deals')}>
                                <div className="selector-holder">
                                    <img src={ico_full_heart} alt="deals" /> 
                                    <Translate s="app_nav_deals" />                                    
                                </div>
                            </button>
                            <button id="create" type="button" className={"btn "+this.state.active['alerts']}  onClick={() => this.goTo('/alerts')}>
                                <div className="selector-holder">
                                    {this.state.notification_count > 0 && <span className="pills pink">{this.state.notification_count}</span>}
                                    <img src={ico_notifications} alt="alerts" />
                                    <Translate s="app_nav_alerts" />
                                </div>
                            </button>
                            <button id="create" type="button" className={"btn "+this.state.active['chats']}  onClick={() => this.goTo('/chats')}>
                                <div className="selector-holder">
                                    {this.state.chat_count > 0 && <span className="pills pink">{this.state.chat_count}</span>}
                                    <img src={ico_messagerie} alt="chats" /> 
                                    <Translate s="app_nav_messages" />                                    
                                </div>
                            </button>
                            <button id="create" type="button" className={"btn "+this.state.active['account']}  onClick={() => this.goTo('/account')}>
                                <div className="selector-holder">
                                    <img src={ico_profil} alt="account" /> 
                                    <Translate s="app_nav_account" />
                                    
                                </div>
                            </button>
                        </div>
                    </footer>
                )}
            </>

        )
    }  
    renderBrandNav() { 
        return (
            <>
                {this.hideNavigation('brand') ?'': (
                    <footer className="footer">
                        <div id="buttonGroup" className="btn-group selectors" role="group" aria-label="Basic example">
                            <button id="campaigns" type="button" className={"btn "+this.state.active['campaigns']} ref={this.homeBtn} onClick={() => this.goTo('/brand/campaigns')}>
                                <div className="selector-holder">
                                    <img src={ico_home} alt="campaigns" />
                                    <Translate s="nav_my_campaigns" />
                                </div>
                            </button>
                            <button id="create" type="button" className={"btn "+this.state.active['deals']}  onClick={() => this.goTo('/brand/brands')}>
                                <div className="selector-holder">
                                    <img src={ico_full_heart} alt="deals" /> 
                                    <Translate s="nav_my_brands" />                                    
                                </div>
                            </button>
                            <button id="create" type="button" className={"btn "+this.state.active['alerts']}  onClick={() => this.goTo('/brand/alerts')}>
                                <div className="selector-holder">
                                    {this.state.notification_count > 0 && <span className="pills pink">{this.state.notification_count}</span>}
                                    <img src={ico_notifications} alt="alerts" />
                                    <Translate s="app_nav_alerts" />
                                </div>
                            </button>
                            <button id="create" type="button" className={"btn "+this.state.active['chats']}  onClick={() => this.goTo('/brand/chats')}>
                                <div className="selector-holder">
                                    {this.state.chat_count > 0 && <span className="pills pink">{this.state.chat_count}</span>}
                                    <img src={ico_messagerie} alt="chats" /> 
                                    <Translate s="app_nav_messages" />                                    
                                </div>
                            </button>
                            <button id="create" type="button" className={"btn "+this.state.active['account']}  onClick={() => this.goTo('/brand/account')}>
                                <div className="selector-holder">
                                    <img src={ico_profil} alt="account" /> 
                                    <Translate s="app_nav_account" />
                                    
                                </div>
                            </button>
                        </div>
                    </footer>
                )}
            </>

        )
    }  
}

export default withRouter(Navigation);
