import React, { Component } from 'react';

import {BrowserRouter as Router,Switch,Route} from "react-router-dom";

import './Scss/App.scss';

import Login from './Login';
import Register from './Pages/Auth/Register';
import RegisterBrand from './Pages/Auth/RegisterBrand';

import Activate from './Pages/Auth/Activate';
import PasswordForget from './Pages/Auth/PasswordForget';
import PasswordNew from './Pages/Auth/PasswordNew';
import Split from './Pages/Auth/Split';
import DeleteAccount from './Pages/Auth/DeleteAccount';

import Steps from './Pages/Register/Steps';
import StepProfil from './Pages/Register/StepProfil';
import StepCoordonnees from './Pages/Register/StepCoordonnees';
import StepUnivers from './Pages/Register/StepUnivers';
import StepReseau from './Pages/Register/StepReseau';
import StepTypeInfluenceur from './Pages/Register/StepTypeInfluenceur';
import StepTypePpe from './Pages/Register/StepTypePpe';
import StepTypePms from './Pages/Register/StepTypePms';
import StepTypePs from './Pages/Register/StepTypePs';
import StepMarque from './Pages/Register/StepMarque';

import BrandCampaigns from './Pages/Brand/Campaigns';
import BrandPartnerships from './Pages/Brand/Partnerships';

import Intro from './Intro';
import Ad from './Ad';
import Lock from './Lock';
import Impersonate from './Pages/Auth/Impersonate';
import DesImpersonate from './Pages/Auth/DesImpersonate';

import Help from './Help';
import Contact from './Contact';

import Campaigns from './Campaigns';
import Campaign from './Campaign';

import Account from './Account';
import Alerts from './Alerts';
import SettingsNotifications from './Pages/Settings/Notifications';
import SettingsLanguage from './Pages/Settings/Language';
import SettingsSecurity from './Pages/Settings/Security';

import Deals from './Deals';
import Deal from './Deal';

import Chats from './Chats';
import Chat from './Chat';

import Error404 from './Error404';

import PrivateRoute from './Middleware/PrivateRoute';
import Navigation from './Components/Navigation';

import {getTranslation} from './Helpers/Translation';

import { Device } from '@capacitor/device';
import {PushNotifications } from '@capacitor/push-notifications';
import Fetcher from './Helpers/Fetcher';

import ScrollToTop from './Components/ScrollToTop';
import { Network } from '@capacitor/network';

import AppUrlListener from './Helpers/AppUrlListener';

import { getLocalStorageItem } from './Helpers/Storage';

import { AppUpdate } from '@capawesome/capacitor-app-update';
import { Dialog } from '@capacitor/dialog';
import {_translate} from  './Helpers/Translation';


class App extends Component {
    chatIntervalID;

	constructor(props) {
        super(props);
        this.state = {
            localisationLoaded:false,
            themeCheck:false,
            theme:'theme-light',
            chat_count:0,
            notification_count:0,
            notifications:'',
            fireRedirect: false,
            impersonating_details:'',
        }     
    }

    componentDidMount(){
        //localisation fetch
        //this.loadTranslation(); 
        getTranslation() 
        
        //Get pills for noti and chat
        this.fetchPillsCount();
        
        //Push Notification listeners
        this.logDeviceInfo().then((platform) => { 
            //add class to root
            document.getElementById('root').classList.add('body_'+platform); //_ios'); 
            //skip notification on web
            if(platform !== 'web'){
                //Notification
                 this.registerNotificationListeners();   
                 
                //App update
                this.getCurrentAppVersion();
                
            }
        }) 
        //Network
        Network.addListener('networkStatusChange', status => {
            const erroroffline = document.getElementById('erroroffline');
            if(!status.connected) erroroffline.classList.remove('d-none')
            else erroroffline.classList.add('d-none')
        });     

        
        //Impersonating
        
        //Data
        getLocalStorageItem('impersonating')
        .then((ret) => JSON.parse(ret))
        .then((r) => {
            if(r !== null){
                const impersonating = document.getElementById('impersonating');
                if(r) impersonating.classList.remove('d-none')
                else impersonating.classList.add('d-none')
            }
        });    
        getLocalStorageItem('impersonating_details')
        .then((r) => {
            if(r !== null){
                this.setState({
                    impersonating_details:r
                })
            }
        });   

    }    
    getCurrentAppVersion = async () => {
        const result = await AppUpdate.getAppUpdateInfo();
        if(result.updateAvailability === 2) {
            if(await this.showConfirmAppUpdate()) AppUpdate.openAppStore();
        }
    }
    showConfirmAppUpdate = async () => {
        const { value } = await Dialog.confirm({
          title: await _translate("confirm_new_app_version_title"),
          message: await _translate("confirm_new_app_version"),
          okButtonTitle: await _translate("cta_download"),
          cancelButtonTitle: await _translate("cta_later")
        });
      
        return value;
    }
    logDeviceInfo = async () => {
        const info = await Device.getInfo();
        return info.platform;
    }
    registerNotificationListeners = () => {

        PushNotifications.register();

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
            (notification) => {
                this.openPageviaNotification(notification);
                //this.setState(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
            }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
            (notification) => {
                this.openPageviaNotification(notification.notification);
                //this.setState(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
            }
        );
    }
    openPageviaNotification(notification){
        //mark as read
        if(notification.data.uid_notification !== undefined && window.location.pathname !== '/login'){
            new Fetcher().callApi({
                api_path:'/notifications/notification_mark_read/',
                api_data:{
                    "uid_notification": notification.data.uid_notification
                },
                method:'POST',
            }).then((r) => {
                //Go to the URL
                if(notification.data.url !== undefined){
                    window.location.href = notification.data.url;
                }
            });

        }
        else if(notification.data.url !== undefined){
            window.location.href = notification.data.url;
        }
        else {}
    }    
    componentWillUnmount() {
        clearTimeout(this.chatIntervalID);
    }
    fetchPillsCount = () => {
        if(window.location.pathname !== '/login' && !window.location.pathname.startsWith('/auth')){
            new Fetcher().callApi({
                api_path:'/helpers/hlp_pills_count/',
                api_data:{},
                method:'GET',
            }).then((r) => {
                if(r.status === 'success'){
                    this.setState({
                        chat_count:r.results.chat,
                        notification_count:r.results.notification
                    });
                    clearTimeout(this.chatIntervalID);
                    this.chatIntervalID = setTimeout(this.fetchPillsCount.bind(this), 60*1000); //each 60 seconds
                }
            });
        }
        
    }
    refreshPills = () => {
        this.fetchPillsCount();
    }
    /*render() {  
        return this.state.localisationLoaded ? this.renderApp() : (       
            <Loading alwaysShow={false} />
        );            
    } */
    render() {
      return (
        <div id="app" className={"App h-100 "+this.state.theme}>
            <div id="erroroffline" className="alert alert-danger d-none">Il semblerait que vous soyez hors ligne. Vous devez être connecté(e) pour utiliser notre application.</div>
            <div id="impersonating" className="alert alert-warning d-none">Impersonating {this.state.impersonating_details} <a href={'/auth/desimpersonate'}  className="btn btn-text-primary">Terminer la session</a></div>
          
          <Router>     
              <ScrollToTop />
              <AppUrlListener></AppUrlListener>
              <Switch>
                    <Route exact path="/login" component={Login}  /> 
                    <Route exact path="/auth/split" component={Split}  />                     
                    <Route exact path="/auth/activate/:id/:activation" component={Activate}  /> 
                    <Route exact path="/auth/register" component={Register}  /> 
                    <Route exact path="/auth/register_brand" component={RegisterBrand}  /> 
                    
                    <Route exact path="/auth/pwd_forget" component={PasswordForget}  /> 
                    <Route exact path="/auth/pwd_new/:code" component={PasswordNew}  /> 
                    <PrivateRoute exact path="/auth/impersonate/:uid_impersonated" component={Impersonate} />
                    <PrivateRoute exact path="/auth/desimpersonate/" component={DesImpersonate} />
                    <PrivateRoute exact path="/auth/deleteaccount/" component={DeleteAccount} />
                    
                    <PrivateRoute exact path="/register/steps" component={Steps}  /> 
                    <PrivateRoute exact path="/register/step_profil" component={StepProfil}  /> 
                    <PrivateRoute exact path="/register/step_coordonnees" component={StepCoordonnees}  /> 
                    <PrivateRoute exact path="/register/step_univers" component={StepUnivers}  /> 
                    <PrivateRoute exact path="/register/step_reseau" component={StepReseau}  /> 
                    <PrivateRoute exact path="/register/step_influenceur" component={StepTypeInfluenceur}  /> 
                    <PrivateRoute exact path="/register/step_pro_petite_enfance" component={StepTypePpe}  /> 
                    <PrivateRoute exact path="/register/step_pro_milieu_scolaire" component={StepTypePms}  /> 
                    <PrivateRoute exact path="/register/step_pro_sante" component={StepTypePs}  /> 
                    <PrivateRoute exact path="/register/step_marque" component={StepMarque}  /> 
                    
                    
                    <PrivateRoute exact path="/brand/campaigns" component={BrandCampaigns}  /> 
                    <PrivateRoute exact path="/brand/campaigns/partnerships/:id_campaign" component={BrandPartnerships}  /> 


                    <PrivateRoute exact path="/ad" component={Ad} />
                    <PrivateRoute exact path="/intro" component={Intro} />
                    <PrivateRoute exact path="/lock" component={Lock} />
                    
                    {/*INF*/}
                    <PrivateRoute exact path="/help" component={Help} />
                    <PrivateRoute exact path="/campaigns" component={Campaigns} />
                    <PrivateRoute exact path="/campaign/:uid" component={Campaign} />
                    <PrivateRoute exact path="/campaign/:uid/:option" component={Campaign} />
                    <PrivateRoute exact path="/deals" component={Deals} />
                    <PrivateRoute exact path="/deals/:type" component={Deals} />
                    <PrivateRoute exact path="/deal/:uid" component={Deal} />
                    <PrivateRoute exact path="/deal/:uid/:option" component={Deal} />
                    {/*COMMON*/}
                    <PrivateRoute exact path="/contact" component={Contact} />
                    <PrivateRoute exact path={["/account","/brand/account"]} component={Account} />
                    <PrivateRoute exact path={["/chats","/brand/chats"]} component={Chats} />
                    <PrivateRoute exact path="/chat/:uid/:secret_key" component={Chat}  refreshPills={this.refreshPills} />
                    <PrivateRoute exact path={["/alerts","/brand/alerts"]} component={Alerts} refreshPills={this.refreshPills}  />
                    <PrivateRoute exact path="/settings/notifications" component={SettingsNotifications}  />
                    <PrivateRoute exact path="/settings/language" component={SettingsLanguage}  />
                    <PrivateRoute exact path="/settings/security" component={SettingsSecurity}  />
                    
                  <PrivateRoute exact path="/" component={Campaigns} />
                  <Route path="*"  render={props => <Error404 {...props} err="err"/>} />
              </Switch>
              <Navigation notification_count={this.state.notification_count} chat_count={this.state.chat_count}  />
          </Router>
      </div>
      )
    }

}

export default App;