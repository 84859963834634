import React from 'react';
import {Link} from "react-router-dom";

import Translate from '../../Helpers/Translate';
import logo from '../../Assets/img/default_logo.png';
import orange_arrow from '../../Assets/img/orange_arrow.png';

import { HiLockClosed } from 'react-icons/hi';
import { showToast,showDomError } from '../../Helpers/Toast';


class PasswordNew extends React.Component {  
    
    constructor(props) {
        super(props);
        
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        
        this.state = {
            toastState: false,
            toastMessage: 'Message',
            formFields: { password: '',password_check:'',optin:'',cgu:''},
            formErrors: { password: '',password_check:'',optin:'',cgu:''},
            formErrorsMessages: { password: '',password_check:'',optin:'',cgu:''},
            app_langage:'',
            user_country_code: 'FR',
            invalid_code : false,
            code:this.props.match.params.code,
            csrf:'',
            uid:'',
            success:false,
        };   
    }
    componentDidMount(){
        this.new_pwd();
    }
    handleChange(e) {
        
        const id = e.target.id;
        const value =  e.target.type === 'checkbox' ? e.target.checked : e.target.value;  
        console.log(value);

        this.setState({
            formFields:{
                ...this.state.formFields,
                [id]: value,
            },
            formErrors : {
                ...this.state.formErrors,
                [id]:''
            }
        });
    }
    errorClass(error) {
        return(error.length === 0 ? '' : 'is-invalid');
    }
    handleSubmit() {
        //Chech data
        if(this.validateForm()){
            this.new_pwd();
            //if(this.state.formAction === 'login') this.login();
            //else this.editChild();
        }        

    }
    validateForm() {   
        let fieldValidationErrors = this.state.formErrors;
        let formErrorsMessages = this.state.formErrorsMessages;
        let formIsValid = true;        
        Object.keys(this.state.formFields).forEach(fieldName => {
            var value = this.state.formFields[fieldName];
            if(fieldName === 'password'){
                const pwd_rule = new RegExp('^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$','i');
                //console.log(!pwd_rule.test(value));
                if(!pwd_rule.test(value)) {
                    formIsValid = false;
                    fieldValidationErrors.password = 'error';
                    formErrorsMessages.password = <Translate s="app_err_pwdrule" />
                }
                else {
                    fieldValidationErrors.password = '';
                }
            }
            if(fieldName === 'password_check'){
                const passwordValid = this.state.formFields.password;
                let passwordCheckValid = value; //(!value.trim().length)?false:true;
                //fieldValidationErrors.password_check = passwordCheckValid ? '': 'error';
                console.log(passwordValid);
                console.log(passwordCheckValid);
                if(passwordCheckValid !== passwordValid) {
                    formIsValid = false;
                    fieldValidationErrors.password_check = 'error';
                    formErrorsMessages.password_check = <Translate s="app_err_pwdcheck" />
                }
                else fieldValidationErrors.password_check = ''
            }
          })
          this.setState({
            formErrors: fieldValidationErrors,
            formErrorsMessages:formErrorsMessages,
        });
          return formIsValid;
    }
    new_pwd = () => {
        let url , credentials;     
        url = process.env.REACT_APP_API_URL + '/auth/reset_password';
        credentials = {
            "code": this.state.code,
            "uid": this.state.uid,
            "csrf": this.state.csrf,
            "password": this.state.formFields.password,
            "password_check": this.state.formFields.password_check
        }
        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            body: JSON.stringify(credentials)
        })
        .then((res) => {
            if(res.status === 200) return res.json();
            else showToast("Error Logging in");            
        })
        .then(
            (r) => {
                let invalid_code = this.state.invalid_code;
                let csrf,uid;
                let code = this.state.code;
                let success = this.state.success;
                if(r.status === 'invalid_code') {
                    invalid_code = true;
                }
                if(r.status === 'csrf') {
                    csrf = r.result.csrf
                    uid = r.result.uid
                    code = r.result.code
                }
                if(r.status === 'success') {
                    success = true;
                }
                if(r.status === 'error_dom') {
                    invalid_code = true;
                    showDomError(r.message);
                }
                this.setState({ 
                    invalid_code:invalid_code,
                    csrf:csrf,
                    uid:uid,
                    code:code,
                    success:success,
                });
            },
            (error) => {        
                showToast(error.toString());
            }
        )
    }
    render() {   
        return (
            <div id="bk_login" className="bg_blur h-100 container">
                <main className="form-signin">
                        <img className="mb-4" src={logo} alt="" width="90" />
                        <h1 className="h3 mb-4 fw-normal"><Translate s='auth_forgotpassword_title' /></h1>
                        {(() => {
                            if(this.state.invalid_code){
                                return(
                                    <>
                                        <div id="error_dom" className="alert alert-danger text-start"><Translate s="auth_activate_error" /></div>
                                        
                                        <p className="mt-4 mb-3">
                                            <Link to={'/pwd_forget'} className="cta_orange cta_bordered_orange text-andesmedium" >
                                                <Translate s="cta_retour" /> 
                                            </Link>
                                        </p>
                                    </>
                                )
                            }
                        })()}
                        {(() => {
                            if(this.state.success){
                                return(
                                    <>
                                        <div className="alert alert-success text-start"><Translate s="auth_newpassword_success" /></div>
                                        <Link to={'/login'} className="mt-2 w-100 btn btn-lg btn-primary"><Translate s="cta_retour" /></Link>
                                    </>
                                )
                            }
                        })()}
                        {(() => {
                            if(!this.state.invalid_code && !this.state.success){
                                return(
                                    <>
                                         <div className="form-floating mt-3">
                                            <input type="password" className={"form-control "+this.errorClass(this.state.formErrors.password)} id="password" placeholder="Password" value={this.state.formFields.password}  onChange={this.handleChange} />
                                            <label htmlFor="password"><HiLockClosed /> <Translate s="auth_pwd" /></label>
                                            <div className="invalid-feedback  text-start">{this.state.formErrorsMessages.password}</div>
                                        </div>
                                        <div className="form-floating mt-3">
                                            <input type="password" className={"form-control "+this.errorClass(this.state.formErrors.password_check)} id="password_check" placeholder="Confirm Password" value={this.state.formFields.password_check}  onChange={this.handleChange} />
                                            <label htmlFor="password_check"><HiLockClosed /> <Translate s="auth_pwd_confirm" /></label>
                                            <div className="invalid-feedback text-start">{this.state.formErrorsMessages.password_check}</div>
                                        </div>

                                        <button className="mt-2 w-100 btn btn-lg btn-primary" onClick={() => this.handleSubmit()}><Translate s="auth_newpassword_cta" /></button>
                                        
                                        
                                        <p className="mt-4 mb-3">
                                            <Link to={'/pwd_forget'} className="cta_orange cta_bordered_orange text-andesmedium" >
                                                <Translate s="cta_retour" /> 
                                            </Link>
                                        </p>
                                    </>
                                )
                            }
                        })()}
                        
                       
                </main>
            </div>
        )
    }  
}

export default PasswordNew;