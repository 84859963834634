import React from 'react';
import {Link} from "react-router-dom";
import Fetcher from './Helpers/Fetcher';

import Translate from './Helpers/Translate';
import { initiateFormErrors, validateEntireForm, validateField, validateForm, errorClass } from './Helpers/Formvalidation';
import { showToast } from './Helpers/Toast';
import BackButton from "./Components/BackButton";
import {renderButton,addLoadingButton,removeLoadingButton} from "./Forms/DealSaveButton";

class DealPartnershipInsight extends React.Component {  
    
    constructor(props) {
        super(props);
        this.thisForm = React.createRef();
        this.state = {
            campaign: this.props.campaign_data,
            user_campaign_meta: this.props.user_campaign_meta,
            readOnly:this.props.readOnly,
            formFieldsNames:['questionnaire_marque'],
            //formFields:{},
            formErrors:{
                questionnaire_marque:'',
            }
        };   
    }
    componentDidMount(){
        //Initiate form error
        const tmp_err = initiateFormErrors(this.state.formFieldsNames);
        this.setState({formErrors : tmp_err});
        //end init error
    }
    handleChange(evt){
        const name = evt.target.name;
        const type = evt.target.type;
        const value = type === "checkbox" ? evt.target.checked : evt.target.value;    
        this.setState({
            user_campaign_meta:{
                ...this.state.user_campaign_meta,
                [name]: value,
            }
        }, () => {
            //Errors
            const err = validateField(evt.target);
            this.setState({
                formErrors : {
                    ...this.state.formErrors,
                    [name]:(err.fieldValid?'success':'error')
                }
            });

        });
        
    }
    handleSubmit = (e) =>  {
        if(validateForm(this.state.formErrors) === 'success'){
            const saveBtnEls = addLoadingButton(e);
            
            new Fetcher().callApi({
                api_path:'/campaigns/save_brand_survey/',
                api_data:{
                    "uid_campaign" : this.state.campaign.uid_campaign,
                    "user_campaign_meta": this.state.user_campaign_meta
                },
                method:'POST',
            }).then((r) => {
                if(r.status === 'success'){
                    showToast('app_well_saved');
                    removeLoadingButton(e,saveBtnEls);
                }
                else showToast('app_an_error_occured');
            });
        }
        else{
            const tmp_err = validateEntireForm(this.state.formFieldsNames,this.state.formErrors);
            this.setState({formErrors : tmp_err});

        }
    }
    render() { 
        return (
            <div id="bk_campaign" className="container brand_page fixed_header  bg-white min-vh-100 px-md-0">
                
                <div className="row header">
                    <div className="col px-0">
                        <BackButton />
                        <div className="logo_marque" style={{'backgroundImage':"url("+process.env.REACT_APP_SITE_URL+'/coffrefort/brands/'+this.state.campaign.id_marque+'/'+this.state.campaign.logo_marque+")"}}></div>
                        <Link className="top_right_button ico_help" to="/help"></Link>
                    </div>
                </div>

                <div className="row content mt-5 px-md-5">
                    <div className="col-12 text-center mt-2">
                        <h2 className="text-andeslight">Mon partenariat</h2>
                    </div>
                    <form ref={this.thisForm} className="col-12 mt-2">
                        {(() => {
                            if(this.state.campaign.campaign_type === '13'){
                                return(
                                    <>
                                        <p className="mb-2"> <Translate s="program_fiche_questionnaire_marque" /></p>
                                        <div className="form-selectgroup form-selectgroup-boxes d-flex flex-column">
                                            <label className="form-selectgroup-item flex-fill">
                                                <input type="radio" name="questionnaire_marque" value="1" className="form-selectgroup-input" onChange={(e) => this.handleChange(e)} checked={this.state.user_campaign_meta.questionnaire_marque === "1"} />
                                                <div className="form-selectgroup-label d-flex align-items-center p-3">
                                                    <div className="me-3"><span className="form-selectgroup-check"></span></div>
                                                    <div>Oui</div>
                                                </div>
                                            </label>
                                            <label className="form-selectgroup-item flex-fill">
                                                <input type="radio" name="questionnaire_marque" value="0" className={`form-selectgroup-input ${errorClass(this.state.formErrors.mes_photos)}`} onChange={(e) => this.handleChange(e)} checked={this.state.user_campaign_meta.questionnaire_marque === "0"} />
                                                <div className="form-selectgroup-label d-flex align-items-center p-3">
                                                    <div className="me-3"><span className="form-selectgroup-check"></span></div>
                                                    <div>Non</div>
                                                </div>
                                                <div className="invalid-feedback">Merci de cocher une réponse</div>
                                            </label>
                                        </div>
                                    </>
                                )
        
                            }
                        })()}
                    </form>
                   
                </div>
                
                <div className={"d-grid p-3 mt-4 fixed-bottom bg-white "+(this.state.readOnly?'d-none':'')}>
                   {renderButton((e) => this.handleSubmit(e))}
                </div>
            </div>
        )
    }  
}

export default DealPartnershipInsight;