import React from 'react';
import Fetcher from '../../Helpers/Fetcher';
import { initiateFormErrors, validateEntireForm, validateField, validateForm, errorClass } from '../../Helpers/Formvalidation';
import Translate from '../../Helpers/Translate';
import {renderButton,addLoadingButton,removeLoadingButton} from "../../Forms/DealSaveButton";
import { showToast } from '../../Helpers/Toast';
import BackButton from '../../Components/BackButton';
import {genRadiogroupBoxes} from '../../Forms/GenRadiogroupBoxes';

import{placeToAddress} from "../../Helpers/Google";
import Autocomplete from "react-google-autocomplete";

class StepTypePs extends React.Component {      
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            error:false,
            user:{},
            user_meta:{},

            formFieldsNames:['ps_type_lieu','ps_ville','ps_tps','fg_ps_specialite'],
            formFields:{
                ppe_type_lieu:{},
            },
            formErrors:{},

            readOnly:false,
            showDetailsSpecialite:false,
            ps_autocomplete:false,
            sections_classes:{}
        }
    }
    componentDidMount(){
        //Fetch data 
        new Fetcher().callApi({
            api_path:'/profil/init',
            api_data:{},
            method:'POST',
        }).then((r) => {
            if(r.status === 'success'){

                //parse sections classes
                let ff = r.results.user_meta;
                ff.pms_sections_classes = ((r.results.user_meta.pms_sections_classes !== undefined)?JSON.parse(r.results.user_meta.pms_sections_classes):[]);
                //parse ppe_type_lieu
                r.results.user_meta.ppe_type_lieu = ((r.results.user_meta.ppe_type_lieu !== undefined)?JSON.parse(r.results.user_meta.ppe_type_lieu):[]);
                if(r.results.user_meta.ppe_type_lieu[0] !== undefined && r.results.user_meta.ppe_type_lieu[0] === '') r.results.user_meta.ppe_type_lieu = [];
                
                //Set states
                this.setState({
                    isLoaded: true,
                    user:r.results.user,
                    sections_classes:r.results.constants.sections_classes,
                    formFields:ff,
                  }, () => {
                    
                    this.showDetailsSpecialite();
                    //reinitiate form errors if check or not
                    this.setState({
                        formErrors:initiateFormErrors(this.state.formFieldsNames)
                    });
                    
                  })    
            }
            else {
                this.setState({
                    isLoaded: true,
                    error:true
                });   
            }
        });

    }    
    handleChange(evt){
        let e = '';
        if(evt.target !== undefined) e = evt.target;
        else e = evt;

        const name = e.name;
        const type = e.type;
        const value = type === "checkbox" ? e.checked : e.value; 
        //update form fields
        let ff = {};
        ff = this.state.formFields;
        let fe = {};
        fe = this.state.formErrors;
        console.log(name);
        console.log(e.value);
        const el = document.getElementsByName(name);
    

        //Rules
        const regex = /(.*)\[([0-9]*)\]/g;
        const array_field = regex.exec(name);
        if(array_field !== null){
            ff[array_field[1]][array_field[2]] = value;
        }
        else if(el.length > 2){ //checkbox or radio
            if(value) ff[name].push(e.value); //add
            else { // remove
                var index = ff[name].indexOf(e.value);
                if (index !== -1) {
                    ff[name].splice(index, 1);
                }
            }
        }
        else ff[name] = value;
        
        //Errors
        const err = validateField(e);
        fe[name] = (err.fieldValid?'success':'error');

        console.log(ff);
        //push state
        this.setState({
            formFields:ff,
            formErrors:fe
        }, () => {
            this.showDetailsSpecialite();
        });


    }
    manageAutocomplete(p){
        //console.log(p);
        const e = placeToAddress(p);
        this.setState({
            formFields:{
                ...this.state.formFields,
                ps_ville:(e.city !== undefined?e.city.long_name:''),
                ps_pays:(e.country !== undefined?e.country.long_name:''),
                ps_cc:(e.country !== undefined?e.country.short_name:''),
                ps_lat:p.geometry.location.lat(),
                ps_lng:p.geometry.location.lng(),
                ps_autocomplete:true
            }
        })

    }
    showDetailsSpecialite = () =>{
        let showDetailsSpecialite = false;
        if(this.state.formFields.fg_ps_specialite === "1"){
            showDetailsSpecialite = true;
        } 
        this.setState({
            showDetailsSpecialite:showDetailsSpecialite
        }, ()  => {
            
            //reinitiate form errors if check or not
            this.setState({
                formErrors:initiateFormErrors(this.state.formFieldsNames)
            })

        });
    }
    handleSubmit = (e) =>  {
        if(validateForm(this.state.formErrors) === 'success'){
            const saveBtnEls = addLoadingButton(e);
            new Fetcher().callApi({
                api_path:'/profil/save/',
                api_data:{
                    slug: 'pro_sante',
                    formFields: this.state.formFields,
                }, 
                method:'POST',
            }).then((r) => {
                if(r.status === 'success'){
                    showToast('app_well_saved');
                    removeLoadingButton(e,saveBtnEls);
                    //move back to steps
                    this.props.history.goBack();
                    //this.props.history.push('/register/steps');
                }
                else showToast('app_an_error_occured'); 
            });
        }
        else{
            const tmp_err = validateEntireForm(this.state.formFieldsNames,this.state.formErrors);
            this.setState({
                formErrors : tmp_err
            });
        }

    }
    detectNoAutocomplete = (t) => {
        let ff = this.state.formFields;
        let fe = this.state.formErrors;
        if(t==='change'){
            ff.ps_autocomplete = false;
        }
        if(t==='blur'){
            if(!ff.ps_autocomplete || (document.querySelector('input[name=ps_ville]') !== undefined && document.querySelector('input[name=ps_ville]').value === '')){

                fe.ps_ville = 'error';

                ff.ps_autocomplete = false;
                ff.ps_ville = '';
                ff.ps_pays = '';
                ff.ps_cc = '';
                ff.ps_lat = '';
                ff.ps_lng = '';
            }
            else {
                ff.ps_autocomplete = true;           
                fe.ps_ville = 'sucess';
            }

            this.setState({
                formFields:ff,
                formErrors:fe
            }, () => {
                console.log(this.state.formFields);
                console.log(this.state.formErrors);
            });
        }      
    }
    render() {  
        return (
            <div id="bk_register_steps" className="">
                <div className="container-fluid bg-white">
                    <div className="header row px-3 py-5">
                        <div className="col text-center">
                            <BackButton />
                            <h2 className="header_title"><Translate s="dropdown_monactivite" /></h2>
                        </div>
                    </div>
                </div>
                
                <div className="container pb-9">


                    <div className="row justify-content-center my-3"> 
                        <div className="col-12 col-md-6 mb-3">
                            {genRadiogroupBoxes({
                                label:'profil_profil_pro_sante_ps_type_lieu',
                                name:'ps_type_lieu',
                                defaultValue:this.state.formFields.ps_type_lieu,
                                onChangeAction:(e) => this.handleChange(e),
                                required:true,
                                class:errorClass(this.state.formErrors.ps_type_lieu),
                                data:[{    
                                    key:'milieu_hospitalier',
                                    value:<Translate s='profil_profil_pro_sante_ps_type_lieu_milieu_hospitalier' />
                                },{
                                    key:'cabinet_independant',
                                    value:<Translate s='profil_profil_pro_sante_ps_type_lieu_cabinet_independant' />
                                }]
                            })} 
                        </div>
                    </div>

                        
                    <div className="row justify-content-center my-3"> 
                        <div className="col-12 col-md-6 mb-3">
                            <label className="form-label required"><Translate s="profil_profil_pro_sante_ps_ville" /></label>
                            <small className={'text-danger '+((this.state.formFields.ps_autocomplete === false)?'':'d-none')}><Translate s="app_lbl_use_ac" /></small>
                            <Autocomplete
                                apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                                name="ps_ville"
                                className={"form-control "+errorClass(this.state.formErrors.ps_ville)}
                                defaultValue={this.state.formFields.ps_ville}
                                data-autocompleteValidation={(this.state.formFields.ps_autocomplete === '' || !this.state.formFields.ps_autocomplete)?false:true}
                                data-is_required={true}
                                options = {{
                                    types: ['(cities)']
                                }}
                                onPlaceSelected={(place,e,a) => {
                                    this.handleChange(e);
                                    this.manageAutocomplete(place);
                                }}
                                onChange = {() => this.detectNoAutocomplete('change')}
                                onBlur = {() => this.detectNoAutocomplete('blur')}
                            />
                            <div className="invalid-feedback"><Translate s="app_err_mandatoryfield" /></div>
                        </div>
                    </div>


                    <div className="row justify-content-center my-3"> 
                        <div className="col-12 col-md-6 mb-3">
                            <label className={"form-label required "}><Translate s="profil_profil_pro_sante_ps_tps" /></label>
                            <input type="text" className={`form-control ${errorClass(this.state.formErrors.ps_tps)}`} autoComplete="off" data-is_required={true} name="ps_tps" value={this.state.formFields.ps_tps} onChange={(e) => this.handleChange(e)} />
                            <div className="invalid-feedback"><Translate s="app_err_mandatoryfield" /></div>
                        </div>
                    </div>                    

                        
                    <div className="row justify-content-center mb-3"> 
                        <div className="col-12 col-md-6">
                        {genRadiogroupBoxes({
                            label:'profil_profil_pro_sante_fg_ps_specialite',
                            name:'fg_ps_specialite',
                            defaultValue:this.state.formFields.fg_ps_specialite,
                            onChangeAction:(e) => this.handleChange(e),
                            required:true,
                            class:errorClass(this.state.formErrors.fg_ps_specialite),
                            data:[{    
                                key:'1',
                                value:<Translate s='yes' />
                            },{
                                key:'0',
                                value:<Translate s='no' />
                            }]
                        })} 
                        </div>
                    </div>

                    <div className={'row justify-content-center '+(this.state.showDetailsSpecialite?'':'d-none')}>
                        <div className="col-12 col-md-6 mb-3">
                            <label className={"form-label "+(this.state.showDetailsSpecialite?'required':'')}><Translate s="si_oui_laquelle" /></label>
                            <input type="text" className={`form-control ${errorClass(this.state.formErrors.ps_specialite)}`} autoComplete="off" data-is_required={this.state.showDetailsSpecialite} name="ps_specialite" value={this.state.formFields.ps_specialite} onChange={(e) => this.handleChange(e)} />
                        </div>
                    </div>   


                    <div className="fixed-bottom bg-white gradient-top">
                        <div className={"btn-bottom-ctnr d-grid p-3 mt-4 "+(this.state.readOnly?'d-none':'')}>
                        {renderButton((e) => this.handleSubmit(e))}
                        </div>
                    </div>

                </div>

            </div>
            
        )
    }  
}

export default StepTypePs;