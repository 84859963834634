import React from "react";
import Fetcher from "../../Helpers/Fetcher";
import {
  initiateFormErrors,
  validateEntireForm,
  validateField,
  validateForm,
  errorClass,
} from "../../Helpers/Formvalidation";
import Translate from "../../Helpers/Translate";
import {
  renderButton,
  addLoadingButton,
  removeLoadingButton,
} from "../../Forms/DealSaveButton";
import { showToast } from "../../Helpers/Toast";
import BackButton from "../../Components/BackButton";
import { genCheckgroupBoxes } from "../../Forms/GenCheckgroupBoxes";

class StepMarque extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      error: false,
      user: {},
      user_meta: {},

      formFieldsNames: [
        "marque_nom_marque",
        "marque_date_creation_entreprise",
        "marque_nb_employe",
        "marque_cibles",
        "marque_secteurs_activites",
        "marque_ville_siege",
      ],
      formFields: {
        ppe_type_lieu: {},
      },
      formErrors: {},

      readOnly: false,
      marque_cibles: {},
      secteurs_activites: {},
      triggers: {
        secteurs_activites: false,
      },
    };
  }
  componentDidMount() {
    //Fetch data
    new Fetcher()
      .callApi({
        api_path: "/profil/init",
        api_data: {},
        method: "POST",
      })
      .then((r) => {
        if (r.status === "success") {
          //parse sections classes
          let ff = r.results.user_meta;
          ff.marque_cibles =
            r.results.user_meta.marque_cibles !== undefined
              ? JSON.parse(r.results.user_meta.marque_cibles)
              : [];
          ff.marque_secteurs_activites =
            r.results.user_meta.marque_secteurs_activites !== undefined
              ? JSON.parse(r.results.user_meta.marque_secteurs_activites)
              : [];
          let trigger_autres_secteurs_activites =
            this.state.triggers.secteurs_activites;
          if (
            r.results.user_meta.autres_secteurs_activites !== "" &&
            r.results.user_meta.autres_secteurs_activites !== undefined
          )
            trigger_autres_secteurs_activites = true;

          //parse ppe_type_lieu
          r.results.user_meta.ppe_type_lieu =
            r.results.user_meta.ppe_type_lieu !== undefined
              ? JSON.parse(r.results.user_meta.ppe_type_lieu)
              : [];
          if (
            r.results.user_meta.ppe_type_lieu[0] !== undefined &&
            r.results.user_meta.ppe_type_lieu[0] === ""
          )
            r.results.user_meta.ppe_type_lieu = [];

          //Set states
          this.setState(
            {
              isLoaded: true,
              user: r.results.user,
              marque_cibles: r.results.constants.marque_cibles,
              secteurs_activites: r.results.constants.secteurs_activites,
              formFields: ff,
            },
            () => {
              //reinitiate form errors if check or not
              this.setState({
                formErrors: initiateFormErrors(this.state.formFieldsNames),
              });
            }
          );
        } else {
          this.setState({
            isLoaded: true,
            error: true,
          });
        }
      });
  }
  handleChange(evt) {
    let e = "";
    if (evt.target !== undefined) e = evt.target;
    else e = evt;

    const name = e.name;
    const type = e.type;
    const value = type === "checkbox" ? e.checked : e.value;
    //update form fields
    let ff = {};
    ff = this.state.formFields;
    let fe = {};
    fe = this.state.formErrors;
    const el = document.getElementsByName(name);

    //Rules
    const regex = /(.*)\[([0-9]*)\]/g;
    const array_field = regex.exec(name);
    if (array_field !== null) {
      ff[array_field[1]][array_field[2]] = value;
    } else if (el.length > 2) {
      //checkbox or radio
      if (value) ff[name].push(e.value); //add
      else {
        // remove
        var index = ff[name].indexOf(e.value);
        if (index !== -1) {
          ff[name].splice(index, 1);
        }
      }
    } else ff[name] = value;

    //Errors
    const err = validateField(e);
    fe[name] = err.fieldValid ? "success" : "error";

    console.log(ff);
    //push state
    this.setState({
      formFields: ff,
      formErrors: fe,
    });
  }

  handleSubmit = (e) => {
    if (validateForm(this.state.formErrors) === "success") {
      const saveBtnEls = addLoadingButton(e);
      new Fetcher()
        .callApi({
          api_path: "/profil/save/",
          api_data: {
            slug: "marque",
            formFields: this.state.formFields,
          },
          method: "POST",
        })
        .then((r) => {
          if (r.status === "success") {
            showToast("app_well_saved");
            removeLoadingButton(e, saveBtnEls);
            //move back to steps
            this.props.history.goBack();
            //this.props.history.push('/register/steps');
          } else showToast("app_an_error_occured");
        });
    } else {
      const tmp_err = validateEntireForm(
        this.state.formFieldsNames,
        this.state.formErrors
      );
      this.setState({
        formErrors: tmp_err,
      });
    }
  };
  showOtherField = (e, t) => {
    let ff = this.state.formFields;
    let triggers = this.state.triggers;
    if (!e.checked) ff["autres_" + t] = "";
    triggers[t] = !this.state.triggers[t];
    this.setState({
      triggers: triggers,
      formFields: ff,
    });
  };
  render() {
    return (
      <div id="bk_register_steps" className="">
        <div className="container-fluid bg-white">
          <div className="header row px-3 py-5">
            <div className="col text-center">
              <BackButton />
              <h2 className="header_title">
                <Translate s="dropdown_monactivite" />
              </h2>
            </div>
          </div>
        </div>

        <div className="container pb-9">
          
        <div className="row justify-content-center my-3">
            <div className="col-12 col-md-6 mb-3">
              <label className={"form-label required "}>
                <Translate s="app_lbl_brandname" /> 
              </label>
              <p className="small"><small><i><Translate s="app_lbl_brandname_single" /></i></small></p>
              <input
                type="text"
                className={`form-control ${errorClass(
                  this.state.formErrors.marque_nom_marque
                )}`}
                autoComplete="off"
                data-is_required={true}
                name="marque_nom_marque"
                value={this.state.formFields.marque_nom_marque}
                onChange={(e) => this.handleChange(e)}
              />
            </div>
          </div> 

          <div className="row justify-content-center my-3">
            <div className="col-12 col-md-6 mb-3">
              <label className={"form-label required"}>
                <Translate s="marque_date_creation_entreprise" />
              </label>
              <input
                type="date"
                className={`form-control ${errorClass(
                  this.state.formErrors.marque_date_creation_entreprise
                )}`}
                autoComplete="off"
                data-is_required={true}
                name="marque_date_creation_entreprise"
                value={this.state.formFields.marque_date_creation_entreprise}
                onChange={(e) => this.handleChange(e)}
              />
            </div>
          </div>
          <div className="row justify-content-center my-3">
            <div className="col-12 col-md-6 mb-3">
              <label className={"form-label required"}>
                <Translate s="marque_nb_employe" />
              </label>
              <input
                type="number"
                className={`form-control ${errorClass(
                  this.state.formErrors.marque_nb_employe
                )}`}
                autoComplete="off"
                data-is_required={true}
                name="marque_nb_employe"
                value={this.state.formFields.marque_nb_employe}
                onChange={(e) => this.handleChange(e)}
              />
            </div>
          </div>

          <div className="row justify-content-center my-3">
            <div className="col-12 col-md-6 mb-3">
              <label className={"form-label required "}>
                <Translate s="marque_ville_siege" />
              </label>
              <input
                type="text"
                className={`form-control ${errorClass(
                  this.state.formErrors.marque_ville_siege
                )}`}
                autoComplete="off"
                data-is_required={true}
                name="marque_ville_siege"
                value={this.state.formFields.marque_ville_siege}
                onChange={(e) => this.handleChange(e)}
              />
            </div>
                </div>
          <div className="row justify-content-center my-4">
            <div className="col-12 col-md-6 mb-3">
              {genCheckgroupBoxes({
                label: "marque_cibles",
                name: "marque_cibles",
                defaultValue: this.state.formFields.marque_cibles,
                onChangeAction: (e) => this.handleChange(e),
                required: true,
                class: errorClass(this.state.formErrors.marque_cibles),
                data: Object.entries(this.state.marque_cibles).map(([k, v]) => {
                  return {
                    key: k,
                    value: (
                      <Translate
                        s={
                          "marque_cibles_" +
                          k.toLowerCase()
                        }
                      />
                    ),
                  };
                }),
              })}
            </div>
          </div>

          <div className="row justify-content-center my-4">
            <div className="col-12 col-md-6 mb-3">
              {genCheckgroupBoxes({
                label: "marque_secteurs_activites",
                name: "marque_secteurs_activites",
                defaultValue: this.state.formFields.marque_secteurs_activites,
                onChangeAction: (e) => this.handleChange(e),
                required: true,
                class: errorClass(
                  this.state.formErrors.marque_secteurs_activites
                ),
                data: Object.entries(this.state.secteurs_activites).map(
                  ([k, v]) => {
                    return {
                      key: k,
                      value: (
                        <Translate
                          s={
                            "marque_secteurs_activites_" +
                            k.toLowerCase()
                          }
                        />
                      ),
                    };
                  }
                ),
              })}

              <div className="form-selectgroup form-selectgroup-boxes d-flex flex-column">
                <label
                  key="secteurs_activites_autres"
                  className={"form-selectgroup-item flex-fill "}
                >
                  <input
                    type="checkbox"
                    className={"form-selectgroup-input"}
                    checked={this.state.triggers.secteurs_activites}
                    onChange={(e) =>
                      this.showOtherField(e, "secteurs_activites")
                    }
                    data-is_required={false}
                  />
                  <div className="form-selectgroup-label d-flex align-items-center p-3">
                    <div className="me-3">
                      <span className="form-selectgroup-check"></span>
                    </div>
                    <div>
                      <span className="payment payment-provider-visa payment-xs me-2"></span>
                      <Translate s="autres" />
                    </div>
                  </div>
                </label>
              </div>

              <div
                className={
                  "ps-4 " +
                  (this.state.triggers.secteurs_activites ? "" : "d-none")
                }
              >
                <label>
                  <Translate s="app_lbl_specify" />
                </label>
                <input
                  type="text"
                  className={`form-control ${errorClass(
                    this.state.formErrors.autres_secteurs_activites
                  )}`}
                  autoComplete="off"
                  data-is_required={this.state.triggers.secteurs_activites}
                  name="autres_secteurs_activites"
                  value={this.state.formFields.autres_secteurs_activites}
                  onChange={(e) => this.handleChange(e)}
                />
              </div>
            </div>
          </div>

          <div className="fixed-bottom bg-white gradient-top">
            <div
              className={
                "btn-bottom-ctnr d-grid p-3 mt-4 " +
                (this.state.readOnly ? "d-none" : "")
              }
            >
              {renderButton((e) => this.handleSubmit(e))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StepMarque;
