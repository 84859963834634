import React from 'react';
import { withRouter} from 'react-router-dom';
import Translate from './Helpers/Translate';
import { validateField, validateForm } from './Helpers/Formvalidation';
import { getLocalStorageItem } from './Helpers/Storage';
import Address from './Forms/Address';
import { Dialog } from '@capacitor/dialog';
import { showToast } from './Helpers/Toast';
import Fetcher from './Helpers/Fetcher';
import {addLoadingButton,removeLoadingButton} from "./Forms/DealSaveButton";

class CampaignApply extends React.Component {  
    
    constructor(props) {
        super(props);
        this.refChangeAddress = React.createRef();
        this.state = {
            questions:JSON.parse(this.props.questions),
            uid_campaign : this.props.uid_campaign,
            errorMessageStep:{
                1:false,
                2:false,
                3:false,
            },
            user_meta:{
                prenom:'',
                nom:'',
            },
            readOnly:(this.props.readOnly?this.props.readOnly:false)
        };   
    }
    componentDidMount(){
        //Resize
        window.addEventListener('resize', this.updateDimensions);
        //Data
        getLocalStorageItem('bk_user_info')
        .then((ret) => JSON.parse(ret))
        .then((r) => {
            this.setState({
                token:r.token
            },() =>{this.getUserMeta()});
        });    

    }
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
      document.body.classList.remove('noscroll');
    }
    updateDimensions = () => {
        document.getElementById('bk_apply').getElementsByClassName("modal_body")[0].style.height = window.innerHeight-this.state.acm_title_height-this.state.acm_cta_height+"px";
    };
    getUserMeta(){
        new Fetcher().callApi({
            api_path:'/helpers/get_user_meta/',
            api_data: {
                "metas": ['prenom','nom', 'adresse','code_postal','ville','pays']
            },
            method:'POST',
        }).then((r) => {
            if(r.status === 'success'){
                this.setState({
                    isLoaded: true,
                    user_meta:r.results
                  })    
            }
            else {
                this.setState({
                    isLoaded: true,
                    error:true
                  });      
            }
        });
    }
    showModal = () => {
        document.body.classList.add('noscroll');
        const acm = document.getElementById('bk_apply');
        acm.classList.remove('sliding-bottom-close');
        const acm_title = acm.getElementsByClassName("modal_header")[0];
        const acm_content = acm.getElementsByClassName("modal_body")[0];
        const acm_cta = acm.getElementsByClassName("modal_cta")[0];
        this.setState({
            acm_title_height : acm_title.offsetHeight,
            acm_cta_height : acm_cta.offsetHeight,
        },() => {acm_content.style.height = window.innerHeight-this.state.acm_title_height-this.state.acm_cta_height+"px"})

    }
    closeModal = async () =>{  
        const { value } = await Dialog.confirm({
            title: 'Ma candidature',
            message: 'Êtes-vous sur d\'annuler votre candidature',
          });
          if(value){
            const acm = document.getElementById('bk_apply');
            acm.classList.add('sliding-bottom-close');
            document.body.classList.remove('noscroll');
          }
    }
    nextStep(next_step){
        let i;
        const cur_step = next_step-1;
        const cur_step_ctnr =  document.getElementById('step_'+cur_step);
        const next_step_ctnr =  document.getElementById('step_'+next_step);

        //form validation
        const inputs = cur_step_ctnr.elements;
        let err = [];
        for (i = 0; i < inputs.length; i++) {
            err[i] = (validateField(inputs[i]).fieldValid?'success':'error');
        }
                
        if(validateForm(err) === 'success'){
            this.setState({
                errorMessageStep : {
                    ...this.state.errorMessageStep,
                    [cur_step]:false
                }
            });
            next_step_ctnr.classList.remove('d-none');
            document.getElementById('step_'+cur_step).classList.add('d-none');

            //nav
            let x, steppers;
            x = document.getElementsByClassName("step_content");
            steppers = document.getElementsByClassName("stepper");
            for (i = 0; i < x.length; i++) {
                if(i > cur_step) steppers[i].classList.remove("active");
            }
            document.getElementById('stepper_'+next_step).classList.add('active');

        }
        else{
            this.setState({
                errorMessageStep : {
                    ...this.state.errorMessageStep,
                    [cur_step]:true
                }
            });
        }
  

    }
    prevStep(prev_step){
        const cur_step = prev_step+1;
        document.getElementById('step_'+prev_step).classList.remove('d-none');
        document.getElementById('step_'+cur_step).classList.add('d-none');

        //nav
        let i, x, steppers;
        x = document.getElementsByClassName("step_content");
        steppers = document.getElementsByClassName("stepper");
        for (i = 0; i < x.length; i++) {
            if(i >= prev_step) steppers[i].classList.remove("active");
        }
        document.getElementById('stepper_'+prev_step).classList.add('active');

    }
    handleChange = (evt) => {
        
        const id = evt.target.id;
        const name = evt.target.name;
        const value = evt.target.value;
        this.setState({
            [name] : {
                ...this.state[name],
                [id]: value
            }
        });
    }
    handleSubmit = (e) => {
        const saveBtnEls = addLoadingButton(e);
        new Fetcher().callApi({
            api_path:'/campaigns/apply',
            api_data:{
                "uid_campaign": this.state.uid_campaign,
                "extra_question" : this.state.extra_question,
                'user_campaign_status': this.props.user_campaign.status
            },
            method:'POST',
        }).then((r) => {
            removeLoadingButton(e,saveBtnEls);
            if(r.status === 'success'){
                window.location.href = '/campaign/'+this.state.uid_campaign;
            }
            else { showToast('Erreur'); }
        });
    }
    refusInfluenceur = (e) => {
        new Fetcher().callApi({
            api_path:'/campaigns/refus_influenceur',
            api_data:{
                "uid_campaign": this.state.uid_campaign
            },
            method:'POST',
        }).then((r) => {
            if(r.status === 'success'){
                window.location.href = '/campaign/'+this.state.uid_campaign;
            }
            else { showToast('Erreur'); }
        });
    }
    onClickk = (e) => {
        e.preventDefault();
        this.refChangeAddress.current.showBlockWithData();
    };
    render() { 
        return (            
            <div className="fixed-bottom bg-white gradient-top">
                <div className={"footer-cta d-grid p-3 mt-4 btn-bottom-ctnr "+((this.state.readOnly)?'d-none':'')}>
                    {(() => {
                        if(this.props.campaign_status === 'paused'){
                            return(
                                <div className="alert alert-pending"><Translate s="program_status_paused" /></div>
                            )
                        }
                        else if(this.props.user_campaign.status === 'non_valide'){
                            return(
                                <div className="alert alert-pending"><Translate s="program_status_encours" /></div>
                            )
                        }
                        else if(this.props.user_campaign.status === 'attente'){
                            return(
                                <div className="alert alert-pending"><Translate s="program_status_attente" /></div>
                            )
                        }
                        else if(this.props.user_campaign.status === 'preselection'){
                            return(
                                <div className="alert alert-pending"><Translate s="program_status_encours" /></div>
                            )
                        }
                        else if(this.props.user_campaign.status === 'refus'){
                            return(
                                <div className="alert alert-danger"><Translate s="program_status_refus" /></div>
                            )
                        }
                        else if(this.props.user_campaign.status === 'refus_influenceur'){
                            return(
                                <div className="alert alert-danger"><Translate s="program_status_refus_influenceur" /></div>
                            )
                        }
                        else if(this.props.user_campaign.status === 'invite'){
                            return(
                                <div className="row">
                                    <div className="col-4 d-grid"><button id="show_candidature_condition_ctnr" type="button" className="btn btn-secondary" onClick={() => this.refusInfluenceur()}><Translate s="program_cta_decline" /></button></div>
                                    <div className="col-8 d-grid"><button className="btn btn-primary" type="button" onClick={this.showModal}><Translate s="program_cta_accept" /></button></div>
                                </div>
                            )
                        }
                        else {
                            return(
                                <button className="btn btn-primary" type="button" onClick={this.showModal}><Translate s="program_cta" /></button>
                            )
                        }
                    })()}
                    
                </div>


                <div id="bk_apply" className="sliding-bottom-modal sliding-bottom-close" tabIndex="-1" role="dialog">
                    <div className="container">
                        <div className="modal_header">
                            <h5 className="modal-title"><Translate s="program_fiche_candidature_moreinfo" /></h5>
                            <button type="button" className="close close_btn" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}></button>
                            

                        </div>

                        <div className="modal_body bg-white">
                            

                            <div className="modal_steps row mb-4 py-3">
                                <div className="col-12 d-flex justify-content-center">
                                    <span id="stepper_1" data-step="1" className="stepper badge rounded-pill active">Questions</span>
                                    <span id="stepper_2" data-step="2" className="stepper badge rounded-pill">Conditions</span>
                                    <span id="stepper_3" data-step="2" className="stepper badge rounded-pill">Adresse</span>
                                </div>
                            </div>

                            <form id="step_1" className="step_content">
                            
                                {this.state.errorMessageStep[1] && <div className="alert alert-danger">Merci de répondre aux questions de sélection</div>}
                                
                                {(() => {
                                    const questions = Object.values(this.state.questions).filter(function (el) {
                                        return el != ''; //drop the emptyness , legacy behaviour
                                      });
                                    if(questions.length > 0){
                                        return (
                                            Object.values(questions).map((iv,kv) => {
                                                return (
                                                    <div key={kv} className="form-group mt-2">
                                                        <label htmlFor={"extra_question_"+kv}>{iv}</label>
                                                        {/*<input type="text" className="form-control" id={"extra_question_"+kv} name="extra_question" onChange={this.handleChange} />*/}
                                                        <textarea className="form-control" id={"extra_question_"+kv} name="extra_question" rows="2" onChange={this.handleChange}></textarea>

                                                    </div>
                                            )})
                                        )
                                        
                                    }
                                    else {
                                        return (
                                            <div className="alert alert-primary"><Translate s="app_lbl_noselectionquestions" /></div>
                                        )
                                    }
                                })()}
                                    
                                <div className="modal_cta row py-3 absolute-bottom bg-white gradient-top">
                                    <div className="col-12 d-grid">
                                        <button id="show_candidature_condition_ctnr" type="button" className="btn btn-primary"  onClick={() => this.nextStep(2)}><Translate s="btn_i_continue" /></button>
                                    </div>
                                </div>
                
                                
                            </form>

                            
						    <form id="step_2" className="step_content d-none">
                                {this.state.errorMessageStep[2] && <div className="alert alert-danger">Vous devez accepter toutes les conditions de partenariat pour poursuivre votre candidature</div>}
                            
                                <p className="pink text-center"><Translate s="program_fiche_candidature_mescond" /></p>
                            
                                <div className="row mt-4 align-items-center">
                                    <div className="col-2 switch_ctnr">
                                        <label className="switch v-center">
                                            <input type="checkbox" value="1" name="conditions[]" id="condition_brief" />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                    <div className="col-10">
                                        <label className="switch_txt" htmlFor="condition_brief"><Translate s="txt_respect_brief" /></label>
                                    </div>
                                </div>
                                <div className="row mt-4 align-items-center">
                                    <div className="col-2 switch_ctnr">
                                            <label className="switch v-center">
                                                <input type="checkbox" value="1" name="conditions[]" id="condition_30jour" />
                                                <span className="slider round"></span>
                                            </label>
                                    </div>
                                    <div className="col-10">
                                        <label className="switch_txt" htmlFor="condition_30jour"><Translate s="txt_30_day_delay_small" /></label>
                                    </div>
                                </div>
                                <div className="row mt-4 align-items-center">
                                    <div className="col-2 switch_ctnr">
                                        <label className="switch v-center">
                                            <input type="checkbox" value="1" name="conditions[]" id="condition_ci_cub" />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                    <div className="col-10">
                                        <label className="switch_txt" htmlFor="condition_ci_cub"><Translate s="txt_privacy_tou" rep={["https://bilbokid.com/app/coffrefort/cgu/FR-charte-influenceurs-bilbokid.pdf","https://bilbokid.com/app/coffrefort/cgu/FR-cgu-influenceurs-bilbokid.pdf"]} /></label>
                                    </div>
                                </div>

                                <br />
                                <div className="row error_condition d-none">
                                    <div className="col-12 text-center">
                                        <p className="red"><Translate s="txt_eligible_condition" /></p>
                                    </div>
                                </div>

                                
                                
                                <div className="modal_cta row py-3 absolute-bottom bg-white border-top-grey">
                                    <div className="col-3 d-grid">
                                        <button id="show_candidature_condition_ctnr" type="button" className="btn btn-secondary"   onClick={() => this.prevStep(1)}>Retour</button>
                                    </div>
                                    <div className="col-9 d-grid">
                                        <button id="show_candidature_condition_ctnr" type="button" className="btn btn-primary"  onClick={() => this.nextStep(3)}><Translate s="btn_i_continue" /></button>
                                    </div>
                                </div>


                            </form>

						    <form id="step_3" className="step_content d-none">
                                {this.state.errorMessageStep[3] && <div className="alert alert-danger"><Translate s="form_error_step_order" /></div>}
                                
                                <p className="text-center"><Translate s='program_fiche_candidature_address' /></p>
                                {this.state.user_meta.prenom} {this.state.user_meta.nom} <br/> 
                                {this.state.user_meta.adresse} <br />{this.state.user_meta.code_postal} {this.state.user_meta.ville} ({this.state.user_meta.pays}) 
                                <button className="btn-text" onClick={this.onClickk}>Changer mon adresse</button>
                                <Address  ref={this.refChangeAddress} refrechCallback={() => this.getUserMeta()} data={this.state.user_meta} />
                            
                                <div className="mt-2 alert alert-warning"><Translate s='program_fiche_candidature_address_disclaimer' />. Ce changement sera instantané et mis à jour sur votre profil.</div>
                            

                                    
                                <div className="modal_cta row py-3 absolute-bottom bg-white border-top-grey">
                                    <div className="col-3 d-grid">
                                        <button id="show_candidature_condition_ctnr" type="button" className="btn btn-secondary"   onClick={() => this.prevStep(2)}>Retour</button>
                                    </div>
                                    <div className="col-9 d-grid">
                                        <button id="show_candidature_condition_ctnr" type="button" className="btn btn-primary"  onClick={(e) => this.handleSubmit(e)}>
                                            <span className="spinner-border spinner-border-sm d-none" role="status" aria-hidden="true"></span> 
                                            <span className="text-save"><Translate s="btn_i_confirm" /></span>
                                            <span className="text-loading d-none">Chargement...</span></button>
                                    </div>
                                </div>


                            </form>            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(CampaignApply);