import React from 'react';
import BackButton from "./Components/BackButton";
import Translate from './Helpers/Translate';

class Contact extends React.Component {  
    
    constructor(props) {
        super(props);
        
        this.state = {
        };   
    }
    componentDidMount() {
        var acc = document.getElementsByClassName("btn_accordion");
        var i;

        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function() {
                this.classList.toggle("active");
                var panel = this.nextElementSibling;
                if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
                } else {
                panel.style.maxHeight = panel.scrollHeight + "px";
                } 
            });
        }

    }

    render() {  
        return (
            
            <div id="bk_help" className="container  bg-white min-vh-100">

            
                <div className="row header header_icon">
                    <div className="col px-0">
                        <BackButton />
                        <div className="icon"></div>
                    </div>
                </div>

                    
                <div className="row content mt-5 mb-1">
                    <div className="col">
                        <h1 className="text-center text-andeslight mt-2 py-2"><Translate s="auth_contact_us" /></h1>
                        <div className="content text-center mt-5">
                            <div className="alert alert-warning my-2 mb-4"><Translate s="app_lbl_contact" /></div>
                            <p><a href="mailto:partenariat@bilbokid.com">partenariat@bilbokid.com</a></p>
                       
                        </div>
                    


                    </div>
                </div>
                
            </div>
        )
    }  
}

export default Contact;