import { Preferences } from '@capacitor/preferences';

export const setLocalStorageItem = async (k,v)  => { 
    await Preferences.set({key:k,value:v});
}
export const getLocalStorageItem = async (k)  => { 
    const { value } = await Preferences.get({key:k});
    return value;
}

export const deleteLocalStorageItem = async (k)  => {
    await Preferences.remove({key:k});
}
/*export const getLocalStorageItem = (k) => {
    return localStorage.getItem(k);
}*/
/*export const setLocalStorageItem = (k,i) => {
    return localStorage.setItem(k,i);
}*/