import React from 'react';
import {Link} from "react-router-dom";

import Translate from '../../Helpers/Translate';
import logo from '../../Assets/img/default_logo.png';

import { HiOutlineMail,HiLockClosed } from 'react-icons/hi';
import { showToast,showDomError } from '../../Helpers/Toast';


class PasswordForget extends React.Component {  
    
    constructor(props) {
        super(props);
        
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        
        this.state = {
            toastState: false,
            toastMessage: 'Message',
            formFields: {email: '',},
            formErrors: {email: '',},
            formErrorsMessages: {email: '',},
            pendingCheckEmail: false,

        };   
    }
    componentDidMount(){

    }
    handleChange(e) {
        
        const id = e.target.id;
        const value =  e.target.type === 'checkbox' ? e.target.checked : e.target.value;  
        console.log(value);

        this.setState({
            formFields:{
                ...this.state.formFields,
                [id]: value,
            },
            formErrors : {
                ...this.state.formErrors,
                [id]:''
            }
        });
    }
    errorClass(error) {
        return(error.length === 0 ? '' : 'is-invalid');
    }
    handleSubmit() {
        //Chech data
        if(this.validateForm()){
            this.forgot_password();
            //if(this.state.formAction === 'login') this.login();
            //else this.editChild();
        }        

    }
    validateForm() {   
        let fieldValidationErrors = this.state.formErrors;
        let formErrorsMessages = this.state.formErrorsMessages;
        let formIsValid = true;        
        Object.keys(this.state.formFields).forEach(fieldName => {
            var value = this.state.formFields[fieldName];
            if(fieldName === 'email'){
                let emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                fieldValidationErrors.email = emailValid ? '' : 'error';
                if(!emailValid) {
                    formIsValid = false;
                    formErrorsMessages.email = <Translate s="app_err_email" />;
                }
            }
          })
          this.setState({
            formErrors: fieldValidationErrors,
            formErrorsMessages:formErrorsMessages,
        });
          return formIsValid;
    }
    forgot_password = () => {
        let url , credentials;     
        url = process.env.REACT_APP_API_URL + '/auth/forgot_password';
        credentials = {
            "email": this.state.formFields.email,
        }
        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            body: JSON.stringify(credentials)
        })
        .then((res) => {
            if(res.status === 200) return res.json();
            else showToast("Error Logging in");            
        })
        .then(
            (result) => {
                if(result.status === 'error') showToast(result.message);
                else if(result.status === 'error_dom') showDomError(result.message);
                else this.setState({ pendingCheckEmail: true});
            },
            (error) => {        
                showToast(error.toString());
            }
        )
    }
    render() { 
        return this.state.pendingCheckEmail ? this.renderCheckEmail() : this.renderForm();      
    } 
    renderCheckEmail() {  
        return (
            
            <div id="bk_login" className="bg_blur h-100 container">
                <main className="form-signin">
                        <img className="mb-4" src={logo} alt="" width="90" />
                        <p className="h4 mb-4 fw-normal"><Translate s='auth_forgotpassword_confirmation' /></p>
                        <Link to={'/login'}><Translate s="cta_retour" /></Link>
                </main>
            </div>
        )
    }
    renderForm() {  
        return (
            <div id="bk_login" className="bg_blur h-100 container">
                <main className="form-signin">
                        <img className="mb-4" src={logo} alt="" width="90" />
                        <h1 className="h3 mb-4 fw-normal"><Translate s='auth_forgotpassword' /></h1>
                        <div id="error_dom" className="alert alert-danger text-start d-none"></div>
                        <div className="form-floating">
                            <input type="email" className={"form-control "+this.errorClass(this.state.formErrors.email)} id="email" placeholder="name@example.com" value={this.state.formFields.email}  onChange={this.handleChange} />
                            <label htmlFor="email"><HiOutlineMail /> <Translate s="auth_email" /></label>
                            <div className="invalid-feedback text-start mt-1">{this.state.formErrorsMessages.email}</div>
                        </div>


                        <button className="mt-2 w-100 btn btn-lg btn-primary" onClick={() => this.handleSubmit()}><Translate s="auth_forgotpassword_cta" /></button>

                        <p className="mt-4 mb-3">
                            <Link to={'/login'} className="cta_orange cta_bordered_orange text-andesmedium" >
                                <Translate s="cta_retour" /> 
                            </Link>
                        </p>
                        
                </main>
            </div>
        )
    }  
}

export default PasswordForget;