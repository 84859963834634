import { Preferences } from '@capacitor/preferences';

// LOGIN STATUS
export const isLogin = async () => {
    const { value } = await Preferences.get({ key: 'bk_user_info' });
    const v = JSON.parse(value);
    if (v !== null && v.isLogin) return true;
    else return false;
}
export const isValidated = async () => {
    const { value } = await Preferences.get({ key: 'bk_user_info' });
    const v = JSON.parse(value);
    if (v !== null && v.approved_profil) return true;
    else return false;
}
export const isLoginAndValidated = async () => {
    const { value } = await Preferences.get({ key: 'bk_user_info' });
    const v = JSON.parse(value);
    //all green
    if (v !== null && v.isLogin === true && v.completed_profil === "1" && v.approved_profil === "1") return {isLogin:true,isCompleted:true,isValided:true,groups:v.groups}; 
    //Login but no profil  && v.approved_profil === "0"
    else if (v !== null && v.isLogin === true  && v.completed_profil === "0") return {isLogin:true,isCompleted:false,isValided:false,groups:v.groups};
    //login + profil but not approved = locked 
    else if (v !== null && v.isLogin === true && v.completed_profil === "1"  && v.approved_profil === "0") return {isLogin:true,isCompleted:true,isValided:false,groups:v.groups};
    else return {isLogin:false,isCompleted:false,isValided:false,groups:v.groups};
}