import {getLocalStorageItem,setLocalStorageItem} from './Storage';
import { Preferences } from '@capacitor/preferences';

export const getTranslation = async (lang)  => {
        const localisation_lang = await getLocalStorageItem('localisation_lang');
        if(lang === undefined && localisation_lang !== null) lang = localisation_lang;
        if(lang === undefined && localisation_lang === null) lang = 'french';

        return getLocalStorageItem('localisation_file_version')
        .then((localisation_file_version) => {
            //if(Date.now() - localisation_file_date > 7*24*60*60*1000){
                
            if(localisation_file_version !== process.env.REACT_APP_LANG_VERSION || localisation_lang !== lang){   
                fetch(process.env.REACT_APP_API_URL + '/auth/fetch_languages/'+lang, {
                    method: 'GET',
                    headers: {"Content-Type": "application/json"},
                  })
                  .then((res) => res.json())
                  .then((result) => {
                        //console.log(JSON.stringify(result));
                        setLocalStorageItem('localisation_lang',lang);
                        setLocalStorageItem('localisation_file_version',process.env.REACT_APP_LANG_VERSION);
                        //setLocalStorageItem('localisation_file_date',Date.now());
                        setLocalStorageItem('localisation_file',JSON.stringify(result));
                        /*this.setState({
                            'localisationLoaded':true
                        })*/
                        window.location.reload(false);
                    },
                    (error) => { 
                        //console.log('error');
                    }
                  )
            }
            return true;
            
        })
}

export const _translate = async (k)  => {
   const { value } =  await Preferences.get({key:'localisation_file'})
   const v = JSON.parse(value);
   if(v!== null && v[k] !== undefined) {
       return v[k]
   }
   else return k;  

}

export const getLang = async (ret = 'lang')  => {
    const localisation_lang = await getLocalStorageItem('localisation_lang');
    if(ret === 'cc') {
        if(localisation_lang == 'french') return 'FR';
        else if(localisation_lang == 'english') return 'EN';
        else return 'FR';
    }
    else return localisation_lang;
}

export const renderTranslationButtons = (selectedLang = 'french')  => {
    return (
        <>
            <button className={'btn btn-sm btn-circle btn-flag flag-fr '+((selectedLang == 'french')?'btn-active':'')} onClick={() => getTranslation('french')}></button>
            <button className={'btn btn-sm btn-circle btn-flag flag-en '+((selectedLang == 'english')?'btn-active':'')} onClick={() => getTranslation('english')}></button>
        </>
    )
}
