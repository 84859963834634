import React from 'react';
import {Link} from "react-router-dom";
import Fetcher from './Helpers/Fetcher';

import Loading from './Components/Loading';

import CampaignApply from './CampaignApply';
import Translate from './Helpers/Translate';

class Ad extends React.Component {  
    
    constructor(props) {
        super(props);
        this.state = {
            ad:{},
        };   
    }
    componentDidMount() {
        this.getAd();                 
    }
    getAd(){        
        new Fetcher().callApi({
            api_path:'/helpers/internal_ad',
            api_data:{},
            method:'GET',
        }).then((r) => {
            if(r.status === 'success'){
                this.setState({
                    isLoaded: true,
                    ad: r.results,
                  },() => {
                      ////Set header height 
                      /*const acm_content = document.getElementsByClassName("header")[0];
                      acm_content.style.height = window.innerHeight/2.5+"px";*/
                  })    

            }
            else {
                this.props.history.replace('/error');
            }
        });
    }

    render() {  
        return this.state.isLoaded ? (this.state.hasError ? this.renderError() : this.renderAd()) : (       
            <Loading alwaysShow={false} />
        );            
    } 
    renderError() {  
        return (       
            <div id="wtbid_home" className="container"><div className="row grow w-100"> <span>Oops ! Something went wrong, try to hit the back button or report it to contact@bilbokid.com</span></div></div>
        );            
    } 
    renderAd(){  
        return (   
            <div id="bk_campaign" className="">
            <div className="container bg-white">
                <div className="row header">
                    <div className="col_ad px-0" style={{'backgroundImage':"url("+process.env.REACT_APP_SITE_URL+'/coffrefort/ad/'+this.state.ad.ad_banniere_image+")"}}>
                        <div className="desktop_images text-center"></div>
                    </div>
                </div>
            </div>
            <div className="container bg-white min-vh-100">
                <div className="row content pt-5">

                    <div className="col-12 mt-1 text-center text-andeslight">
                        <h1 className="bk_campaign_title text-pink ">{this.state.ad.ad_title}</h1>
                        <div className="" dangerouslySetInnerHTML={{ __html: this.state.ad.ad_description}} />
                    </div>
                    
                    <div className="text-center p-3 mt-4 btn-bottom-ctnr ">
                        {this.state.ad.ad_cta.startsWith('/') && <Link to={this.state.ad.ad_cta} className="btn btn-primary"><span>{this.state.ad.ad_cta_txt}</span></Link>}
                        {this.state.ad.ad_cta.startsWith('http') && <Link to={{ pathname: this.state.ad.ad_cta}} className="btn btn-primary" target="_blank"><span>{this.state.ad.ad_cta_txt}</span></Link>}
                    </div>

                </div>
            </div>
                


        </div>
        )
    }
}

export default Ad;