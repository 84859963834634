
import React, { Component } from 'react';

import {Redirect,Route} from "react-router-dom";
import { isLoginAndValidated } from './Auth';

import Alerts from '../Alerts';
import Chat from '../Chat';

class PrivateRoute extends Component {
    
	constructor(props) {
        super(props); 
        this.state = {
            authCheck:false,
            authed:false,
            completed:false,
            validated:false,
            groups:null,
        }
        //alert(this.props.location.pathname);
        localStorage.setItem('redir', this.props.location.pathname);
    }
    componentDidMount() {
        //const that = this;
        isLoginAndValidated().then((ret) => this.setState({
            completed:ret.isCompleted,
            completedInf:ret.isCompletedInf,
            validated:ret.isValided,
            authed:ret.isLogin,
            authCheck:true,
            groups:ret.groups
        }, () => this.pathChecker()));
        /*.then((r) => {
            that.pathChecker();
        });*/
    }
    loading(){
        return (
            <p>...</p>
        )
    } 
    componentDidUpdate(previousProps, previousState){;
        if (previousProps.location.pathname !== this.props.location.pathname) {
           this.pathChecker();
        }
    }
    pathChecker = () => {
        //console.log(this.state);
        if(this.state.groups === null || this.state.groups === undefined)  window.location.href = '/login';
        const g = this.state.groups.map((e) => {
            return parseInt(e.id)
        })

        if(
            (
                (Array.isArray(this.props.path) && this.props.path.findIndex((a) => { return a.startsWith("/brand/");}, "/brand/") === 0) 
                || 
                (!Array.isArray(this.props.path) && this.props.path.startsWith("/brand/"))
            ) 
            && g.includes(2)
        ) window.location.href = '/';
        /*if(          
            (
                (Array.isArray(this.props.path) && !this.props.path.findIndex((a) => { return a.startsWith("/brand/");}, "/brand/") === 1) 
                || 
                (!Array.isArray(this.props.path) && !this.props.path.startsWith("/brand/"))
            ) 
            && (g.includes(3) || g.includes(4))
        ) window.location.href = '/brand/campaigns';*/

        //default paths
        //if(!this.props.path.startsWith("brand/") && (g.includes(3) || g.includes(3))) return;
            
    }
    render(){
        return (
            !this.state.authCheck ? this.loading : (
                this.state.authed ? (
                    (this.state.completed && !this.state.validated && this.props.path !== '/lock') ? <Redirect to="/lock" /> : (
                        (!this.state.completed && !this.state.validated && !this.props.path.startsWith("/register"))?<Redirect to="/register/steps" /> :
                        (
                            (this.props.path.includes('/alerts')) ? <Route path={this.props.path} render={innerprops =><Alerts {...innerprops} refreshPills={this.props.refreshPills}  />} /> 
                            : 
                            (this.props.path.includes("/chat/")) ? <Route path={this.props.path} render={innerprops =><Chat {...innerprops} refreshPills={this.props.refreshPills}  />} /> 
                                :
                                <Route {...this.props} render={innerprops =>  <Component {...innerprops}  />} /> 
                        )
                    )
                ) : <Redirect to={"/login#r="+this.props.location.pathname} />
            )
        )
    }
}


export default PrivateRoute;