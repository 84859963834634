import React from 'react';

import BackButton from "./Components/BackButton";
import Slider from "react-slick";

import CampaignApply from './CampaignApply';
import Translate from './Helpers/Translate';

const carrousel_settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [/*
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },*/
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            }
        }
    ]
  };


class CampaignDescription extends React.Component {  
    
    constructor(props) {
        super(props);
        this.state = {
            campaign: this.props.campaign_data, 
            user_campaign: this.props.user_campaign, 
            campaign_images: this.props.campaign_images, 
            readOnly:this.props.readOnly,
        };   
    }
    render() { 
        return (
            <div id="bk_campaign" className="container description_page fixed_header bg-white  px-md-0 min-vh-100">
                
                <div className="row header">
                    <div className="col px-0">
                        
                        <BackButton />
                        <div className="logo_marque" style={{'backgroundImage':"url("+process.env.REACT_APP_SITE_URL+'/coffrefort/brands/'+this.state.campaign.id_marque+'/'+this.state.campaign.logo_marque+")"}}></div>
                    </div>
                </div>

                <div className="row mt-5 pb-7  px-md-5">
                    <div className="col-12 mt-5 px-0">
                        <Slider {...carrousel_settings}>

                            {Object.values(this.state.campaign_images).map((iv,kv) => {
                                return (
                                        <img key={kv} id={kv} src={process.env.REACT_APP_SITE_URL+'/coffrefort/campaigns/'+this.state.campaign.uid_campaign+'/'+iv.image_url} className="bd-placeholder-img" alt="" />
                                    
                                )
                            })}
                    
                        </Slider>
                    </div>
                    
                  
                    
                    <div className="col-12  mt-3 mb-5">
                        <h1 className="text-center text-andeslight font-14"><Translate s="app_campaign_campaign" /></h1>
                        <div className="text text-andeslight text-padding" dangerouslySetInnerHTML={{ __html: this.state.campaign.campaign_description}} />
                    </div>
                </div>
                
                <CampaignApply readOnly={this.state.readOnly} uid_campaign={this.state.campaign.uid_campaign} campaign_status={this.state.campaign.status} user_campaign={this.state.user_campaign}  questions={this.state.campaign.extra_questions}/>

            </div>
        )
    }
}

export default CampaignDescription;