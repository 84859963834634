
import Hammer from 'hammerjs';
delete Hammer.defaults.cssProps.userSelect;

export const openTab = ((evt,tab) => {
    let i, x, tablinks;
    x = document.getElementsByClassName("tabcontent");
    for (i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablink");
    for (i = 0; i < x.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" bk_tabs_active", "");
    }
    document.getElementById(tab).style.display = "flex";
    evt.currentTarget.className += " bk_tabs_active";
})
export const swipeTab = ((isLoaded,ctnrId) => {
    
    if(isLoaded){
        //Swipes
        const square = document.querySelector(ctnrId);
        var hammer = new Hammer(square);
        hammer.on('swipeleft', function(e) {
            const activebtn = document.querySelectorAll(".bk_tabs button.bk_tabs_active")[0];
            if (activebtn.nextSibling === null) return;
            activebtn.nextSibling.click();
        });
        hammer.on('swiperight', function(e) {
            const activebtn = document.querySelectorAll(".bk_tabs button.bk_tabs_active")[0];
            if (activebtn.previousSibling === null) return;
            activebtn.previousSibling.click();
        });
    }
})
export const unmountHammer = ((ctnrId) => {
    //Swipes
    const square = document.querySelector(ctnrId);
    var hammer = new Hammer(square);
    hammer.remove('swipeleft');
    hammer.remove('swiperight');
})
