import React from 'react';
import {Link} from "react-router-dom";

import {IoPowerOutline} from 'react-icons/io5';

import Fetcher from './Helpers/Fetcher';
import Translate from './Helpers/Translate';
import { Browser } from '@capacitor/browser';
import UploadAvatar from './Forms/UploadAvatar';
import Loading from './Components/Loading';

class Account extends React.Component {  
    
    constructor(props) {
        super(props);
        
        this.state = {
            uid: '',
            steps:{},
            user_meta:{},
            isLoaded:false,
        };   
    }
    componentWillMount() {
        this.fetchAccountApi();    
        this.fetchStepProgression();
    }
    fetchAccountApi(tkn){
        new Fetcher().callApi({
            api_path:'/account/get',
            api_data:{},
            method:'POST',
        }).then((r) => {
            if(r.status === 'success'){
                this.setState({
                    isLoaded: true,
                    user:r.results.user,
                    user_meta:r.results.user_meta
                  }/*, () => {
                      this.displayAvatar();
                  }*/)    
            }
            else {
                this.setState({
                    isLoaded: true,
                    error:true
                  });   
            }
        });
    } 
    fetchStepProgression(){
        new Fetcher().callApi({
            api_path:'/profil/step_progression/',
            api_data:{}, 
            method:'POST',
        }).then((r) => {
            if(r.status === 'success'){
                console.log(r.results);
                this.setState({
                    steps:r.results.steps,
                })
            }
        });
    }
    goToPage= (url) => {
        this.props.history.push(url);
    }
    openCapacitorSite = async (url) => {
      await Browser.open({ url: url });
    };
    render() {  
        return this.state.isLoaded ? this.renderAccount() : (       
            <Loading alwaysShow={false} />
        );            
    }
    renderAccount() { 
        return (
            
            <div id="bk_account" className="container bg-white min-vh-100">
                
                <div className="row header">
                    <div className="col px-0">
                        <UploadAvatar user_meta={this.state.user_meta} />
                        <Link className="top_right_button" to="/login"><IoPowerOutline /></Link>
                    </div>
                </div>

                <div className="row content mt-5 pb-7"> 


                    <div className="col-12 campaign_nav mt-5">
                        {
                            Object.values(this.state.steps).map((iv,kv) => {
                                return (
                                    <button key={kv} onClick={() => this.goToPage('/register/step_'+iv.slug)}><Translate s={iv.translation} /></button>
                                )
                            })
                        }
                        <button onClick={() => this.goToPage('/settings/notifications')}><Translate s="app_my_notifications" /></button>
                        <button onClick={() => this.goToPage('/settings/security')}><Translate s="dropdown_securite" /></button>
                        <button onClick={() => this.goToPage('/settings/language')}><Translate s="app_my_languages" /></button>
                        <button onClick={() => this.goToPage('/help')}>FAQ</button>
                        <button onClick={() => this.goToPage('/contact')}><Translate s="auth_contact_us" /></button>
                        
                    </div>
    
                    <p className="mt-3">
                        <button onClick={() => this.goToPage('/auth/deleteaccount')} className="btn-text text-secondary"><Translate s="btn_delete_account" /></button>
                    </p>

                    
                </div>

            </div>
        )
    }
}

export default Account;