import React from 'react';
import Fetcher from '../../Helpers/Fetcher';
import { initiateFormErrors, validateEntireForm, validateField, validateForm, errorClass } from '../../Helpers/Formvalidation';
import Translate from '../../Helpers/Translate';
import {renderButton,addLoadingButton,removeLoadingButton} from "../../Forms/DealSaveButton";
import { showToast } from '../../Helpers/Toast';
import BackButton from '../../Components/BackButton';
import {genCheckgroupBoxes} from '../../Forms/GenCheckgroupBoxes';
import{placeToAddress} from "../../Helpers/Google";
import Autocomplete from "react-google-autocomplete";

class StepTypePpe extends React.Component {      
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            error:false,
            user:{},
            user_meta:{},

            formFieldsNames:['ppe_type_lieu','ppe_ville_type_lieu','ppe_nb_enfant_charge','ppe_anciennete_petit_enfance'],
            formFields:{
                ppe_type_lieu:{},
            },
            formErrors:{},

            readOnly:false,
            showDetailsEnceinte:false,
            showDetailsEnfants:false,
            ppe_autocomplete:false,
            ppe_type_lieu:['maison_assistance_maternelle','votre_domicile','domicile_parent','creche_garderie','accueil_specialisee'],
        }
    }
    componentDidMount(){
        //Fetch data 
        new Fetcher().callApi({
            api_path:'/profil/init',
            api_data:{},
            method:'POST',
        }).then((r) => {
            if(r.status === 'success'){

                
                //parse ppe_type_lieu
                r.results.user_meta.ppe_type_lieu = ((r.results.user_meta.ppe_type_lieu !== undefined)?JSON.parse(r.results.user_meta.ppe_type_lieu):[]);
                if(r.results.user_meta.ppe_type_lieu[0] !== undefined && r.results.user_meta.ppe_type_lieu[0] === '') r.results.user_meta.ppe_type_lieu = [];
                
                //Set states
                this.setState({
                    isLoaded: true,
                    user:r.results.user,
                    formFields:r.results.user_meta,
                  }, () => {
                    //reinitiate form errors if check or not
                    this.setState({
                        formErrors:initiateFormErrors(this.state.formFieldsNames)
                    })
                    
                  })    
            }
            else {
                this.setState({
                    isLoaded: true,
                    error:true
                });   
            }
        });

    }    
    handleChange(evt){
        let e = '';
        if(evt.target !== undefined) e = evt.target;
        else e = evt;

        const name = e.name;
        const type = e.type;
        const value = type === "checkbox" ? e.checked : e.value; 
        //update form fields
        let ff = {};
        ff = this.state.formFields;
        let fe = {};
        fe = this.state.formErrors;
        console.log(name);
        console.log(e.value);
        const el = document.getElementsByName(name);
    

        //Rules
        const regex = /(.*)\[([0-9]*)\]/g;
        const array_field = regex.exec(name);
        if(array_field !== null){
            ff[array_field[1]][array_field[2]] = value;
        }
        else if(el.length > 2){ //checkbox or radio
            if(value) ff[name].push(e.value); //add
            else { // remove
                var index = ff[name].indexOf(e.value);
                if (index !== -1) {
                    ff[name].splice(index, 1);
                }
            }
        }
        else ff[name] = value;
        
        //Errors
        const err = validateField(e);
        fe[name] = (err.fieldValid?'success':'error');

        console.log(ff);
        //push state
        this.setState({
            formFields:ff,
            formErrors:fe
        });


    }
    manageAutocomplete(p){
        //console.log(p);
        const e = placeToAddress(p);
        this.setState({
            formFields:{
                ...this.state.formFields,
                //adresse:(e.streetNumber !== undefined?e.streetNumber.long_name+' ':'')+(e.streetName !== undefined?e.streetName.long_name:''),
                ppe_cp_type_lieu:(e.zip !== undefined?e.zip.long_name:''),
                ppe_ville_type_lieu:(e.city !== undefined?e.city.long_name:''),
                ppe_pays_type_lieu:(e.country !== undefined?e.country.long_name:''),
                ppe_cc_type_lieu:(e.country !== undefined?e.country.short_name:''),
                ppe_lat_type_lieu:p.geometry.location.lat(),
                ppe_lng_type_lieu:p.geometry.location.lng(),
                ppe_autocomplete:true
            }
        })

    }
    handleSubmit = (e) =>  {
        if(validateForm(this.state.formErrors) === 'success'){
            const saveBtnEls = addLoadingButton(e);
            new Fetcher().callApi({
                api_path:'/profil/save/',
                api_data:{
                    slug: 'pro_petite_enfance',
                    formFields: this.state.formFields,
                }, 
                method:'POST',
            }).then((r) => {
                if(r.status === 'success'){
                    showToast('app_well_saved');
                    removeLoadingButton(e,saveBtnEls);
                    //move back to steps
                    this.props.history.goBack();
                    //this.props.history.push('/register/steps');
                }
                else showToast('app_an_error_occured'); 
            });
        }
        else{
            const tmp_err = validateEntireForm(this.state.formFieldsNames,this.state.formErrors);
            console.log(tmp_err);
            this.setState({
                formErrors : tmp_err
            });
        }

    }
    detectNoAutocomplete = (t) => {
        let ff = this.state.formFields;
        let fe = this.state.formErrors;
        if(t==='change'){
            ff.ppe_autocomplete = false;
        }
        if(t==='blur'){
            if(!ff.ppe_autocomplete || (document.querySelector('input[name=ppe_ville_type_lieu]') !== undefined && document.querySelector('input[name=ppe_ville_type_lieu]').value === '')){

                fe.ppe_ville_type_lieu = 'error';

                ff.ppe_autocomplete = false;
                ff.ppe_cp_type_lieu = '';
                ff.ppe_ville_type_lieu = '';
                ff.ppe_pays_type_lieu = '';
                ff.ppe_cc_type_lieu = '';
                ff.ppe_lat_type_lieu = '';
                ff.ppe_lng_type_lieu = '';
            }
            else {
                ff.ppe_autocomplete = true;           
                fe.ppe_ville_type_lieu = 'sucess';
            }

            this.setState({
                formFields:ff,
                formErrors:fe
            }, () => {
                console.log(this.state.formFields);
                console.log(this.state.formErrors);
            });
        }      
    }
    render() {  
        return (
            <div id="bk_register_steps" className="">
                <div className="container-fluid bg-white">
                    <div className="header row px-3 py-5">
                        <div className="col text-center">
                            <BackButton />
                            <h2 className="header_title"><Translate s="dropdown_monactivite" /></h2>
                        </div>
                    </div>
                </div>
                
                <div className="container pb-9">


                    <div className="row justify-content-center my-4"> 
                        <div className="col-12 col-md-6">
                            
                        {genCheckgroupBoxes({
                            label:'profil_profil_pro_petite_enfance_ppe_type_lieu',
                            name:'ppe_type_lieu',
                            defaultValue:this.state.formFields.ppe_type_lieu,
                            onChangeAction:(e) => this.handleChange(e),
                            required:true,
                            class:errorClass(this.state.formErrors.ppe_type_lieu),
                            data: this.state.ppe_type_lieu.map(v => {
                                return {
                                        'key':v,
                                        'value':<Translate s={"profil_profil_pro_petite_enfance_ppe_type_lieu_"+v} />
                                    }
                                })
                            })} 
                        </div>
                    </div>
		        
                    <div className="row justify-content-center my-4"> 
                        <div className="col-12 col-md-6 mb-3">
                            <label className="form-label required"><Translate s="profil_profil_pro_petite_enfance_ppe_ville_type_lieu" /></label>
                            <small className={'text-danger '+((this.state.formFields.ppe_autocomplete === false)?'':'d-none')}><Translate s="app_lbl_use_ac" /></small>
                            <Autocomplete
                                apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                                name="ppe_ville_type_lieu"
                                className={"form-control "+errorClass(this.state.formErrors.ppe_ville_type_lieu)}
                                defaultValue={this.state.formFields.ppe_ville_type_lieu}
                                data-autocompleteValidation={(this.state.formFields.ppe_autocomplete == '' || !this.state.formFields.ppe_autocomplete)?false:true}
                                data-is_required={true}
                                options = {{
                                    types: ['(cities)']
                                }}
                                onPlaceSelected={(place,e,a) => {
                                    this.handleChange(e);
                                    this.manageAutocomplete(place);
                                }}
                                onChange = {() => this.detectNoAutocomplete('change')}
                                onBlur = {() => this.detectNoAutocomplete('blur')}
                            />
                        </div>
                    </div>
                        
                    <div className="row justify-content-center my-4"> 
                        <div className="col-12 col-md-6 mb-3">
                            <label className={"form-label required "}><Translate s="profil_profil_pro_petite_enfance_ppe_nb_enfant_charge" /></label>
                            <input type="number" className={`form-control ${errorClass(this.state.formErrors.ppe_nb_enfant_charge)}`} autoComplete="off" data-is_required={true} name="ppe_nb_enfant_charge" value={this.state.formFields.ppe_nb_enfant_charge} onChange={(e) => this.handleChange(e)} />
                        </div>
                    </div>
                        
                    <div className="row justify-content-center my-4"> 
                        <div className="col-12 col-md-6 mb-3">
                            <label className={"form-label required "}><Translate s="profil_profil_pro_petite_enfance_ppe_anciennete_petit_enfance" /></label>
                            <input type="text" className={`form-control ${errorClass(this.state.formErrors.ppe_anciennete_petit_enfance)}`} autoComplete="off" data-is_required={true} name="ppe_anciennete_petit_enfance" value={this.state.formFields.ppe_anciennete_petit_enfance} onChange={(e) => this.handleChange(e)} />
                        </div>
                    </div>
                        
                    <div className="row justify-content-center my-4"> 
                        <div className="fixed-bottom bg-white gradient-top">
                            <div className={"btn-bottom-ctnr d-grid p-3 mt-4 "+(this.state.readOnly?'d-none':'')}>
                            {renderButton((e) => this.handleSubmit(e))}
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            
        )
    }  
}

export default StepTypePpe;