import React from 'react';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import {getLocalStorageItem} from './Storage';


class Fetcher extends React.Component {
    async getTokens(key) {
        return await SecureStoragePlugin.get({ key });
    }
    getLocalisationLang = async () => {
        const localisation_lang = await getLocalStorageItem('localisation_lang');
        return localisation_lang
    }
    callApi = async (d, new_token = '') => {
        //get tkn
        let tkn; 
        if(new_token === '') tkn  = await this.getTokens('bk_tokens_token').then((r) => { return r.value}).catch(error => {window.location.href = '/login';});
        else tkn = new_token;
        //Get user select language 
        d.api_data.user_display_lang = await this.getLocalisationLang();
        //call api
        const url =  process.env.REACT_APP_API_URL + d.api_path;
        const res = await fetch(url, {
                method: 'POST',
                headers: {
                    "Authorization": tkn,
                    "Content-Type": "application/json", 
                },
                body: JSON.stringify(d.api_data)
            })
            .then(r => {
                return r.text()
            })
            .then((data) => {
                return ((data.substring(0, 1) === '<'  || data === "")? {status:'error',message:'server_error'} : JSON.parse(data))
            })
            .then((body) => {
                if(body.status === 'token_error') window.location.href = '/login';
                else if(body.status === 'token_expired') return 'get_new_token';
                else return body
            })
            .catch((error) => {
                console.log(error);
                //reject(error)
            });

            /*.then((r) => r.json())
            .then((body) => {
                if(body.status === 'token_error') window.location.href = '/login';
                else if(body.status === 'token_expired') return 'get_new_token';
                else return body
            });*/
        //if new tkn requested then call same func again
        if(res === 'get_new_token'){
            const new_tkn = await this.refreshToken(d).then((rr) => { return rr}); 
            if(new_tkn === 'pending_new_token') {
                await this.sleep(1000);
                return await this.callApi(d)
            }
            else return await this.callApi(d,new_tkn)
        }
        else return res;
    }
    xhrFileUpload = async (d, new_token = '') => {
        //get tkn
        let tkn; 
        if(new_token === '') tkn  = await this.getTokens('bk_tokens_token').then((r) => { return r.value}).catch(error => {window.location.href = '/login';});
        else tkn = new_token;
        //call api
        const url =  process.env.REACT_APP_API_URL + d.api_path;

        let res = await  new Promise(resolve => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url);
            xhr.setRequestHeader("Authorization", tkn);
            //xhr.setRequestHeader("Content-Type",  "multipart/form-data");
            //xhr.setRequestHeader("Cache-Control", "no-cache");
            xhr.onload = function() {
                resolve(JSON.parse(xhr.response));
            };
            xhr.onprogress = function(event) {
                if (event.lengthComputable) {
                    console.log(`Received ${event.loaded} of ${event.total} bytes`);
                } else {
                    console.log(`Received ${event.loaded} bytes`); // no Content-Length
                }
            };
            xhr.onerror = function() {
                alert("Request failed");
            }
            xhr.send(JSON.stringify(d.api_data));
        });
        //if new tkn requested then call same func again
        if(res === 'get_new_token'){
            const new_tkn = await this.refreshToken(d).then((rr) => { return rr}); 
            if(new_tkn === 'pending_new_token') {
                await this.sleep(1000);
                return await this.xhr(d)
            }
            else return await this.xhr(d,new_tkn)
        }
        else return res;
    }
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    refreshToken = async (d) => {
        const bku  =  await this.getTokens('bk_tokens_refresh_token').then((r) => { return r.value}).catch(error => {window.location.href = '/login';});
        const nt = await fetch(process.env.REACT_APP_API_URL + '/auth/refresh_token', {
                method: 'POST',
                headers: {"Content-Type": "application/json", },
                body: JSON.stringify({'refresh_token':bku})
                })
                .then((res) => res.json())
                .then((result) => {
                    if(result.status === 'pending_new_token') return 'pending_new_token';
                    else if(result.status === 'error') window.location.href = '/login';
                    else if(result.status === 'token_error') window.location.href = '/login';
                    else if(result.status === 'success'){
                        SecureStoragePlugin.set({key:"bk_tokens_token", value:result.results.token});
                        SecureStoragePlugin.set({key:"bk_tokens_refresh_token", value:result.results.refresh_token});
                        return result.results.token;
                    }
                    else return false;
                },
                (error) => {
                    return false;
                }
        );
        return nt;
    }

}
export default Fetcher;