
import React from 'react';
import Translate from '../../Helpers/Translate';
import {Link} from "react-router-dom";
import logo from '../../Assets/img/default_logo.png';
import BackButton from '../../Components/BackButton';

class Split extends React.Component {  
    
    render() {  
        return (
            <div id="bk_splash" className="h-100 container-fluid px-0">
                
                <div className="header row pt-4">
                    <div className="col text-center">
                        <BackButton />
                        <img className="mb-4" src={logo} alt="" width="90" />
                    </div>
                </div>

                <section className="divided_block">
                    <div className="splash_col left d-flex justify-content-center">
                        <Link to="/auth/register">
                            <div>
                                <p className="subtitle text-andesmedium"><Translate s="profil_info_youare" /></p>
                                <p className="highlight"><Translate s="splash_person" /></p>
                                <p className="content text-andesmedium"><Translate s="splash_person_text" /></p>
                            </div>
                        </Link>
                    </div>
                    <div className="splash_col right d-flex justify-content-center">
                        <Link to="/auth/register_brand">
                            <div>
                                <p className="subtitle text-andesmedium"><Translate s="profil_info_youare" /></p>
                                <p className="highlight"><Translate s="splash_brand" /></p>
                                <p className="content text-andesmedium"><Translate s="splash_brand_text" /></p>
                            </div>
                        </Link>
                    </div>
                </section>
            </div>
        )
    }  
}

export default Split;

