import React from 'react';

import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';

import { getLocalStorageItem, setLocalStorageItem,deleteLocalStorageItem } from '../../Helpers/Storage';
import Fetcher from '../../Helpers/Fetcher';

class Impersonate extends React.Component {  
    
    constructor(props) {
        super(props);
        
        this.state = {};   
    }
    componentDidMount(){
        const uid_impersonated = this.props.match.params.uid_impersonated;

        new Fetcher().callApi({
            api_path:'/auth/impersonate',
            api_data:{
                uid_impersonated:uid_impersonated
            },
            method:'GET',
        }).then((r) => {
            if(r.status === 'success'){
                SecureStoragePlugin.set({key:"bk_tokens_token", value:r.results.token}).then(success => console.log(success));
                SecureStoragePlugin.set({key:"bk_tokens_refresh_token", value:r.results.refresh_token}).then(success => console.log(success));
                setLocalStorageItem("impersonating",true);
                setLocalStorageItem("impersonating_details",r.results.firstname+' '+r.results.lastname).then(
                    () => {
                        window.location.href = '/';
                    }
                );

                /*setLocalStorageItem("bk_user_info",JSON.stringify(r.results)).then(
                    () => {
                        //window.location.href = '/';
                    }
                );*/
            }
        });
    } 


    render() {
        return (
            <></>
        )

    }
}

export default Impersonate;