import React from 'react';
import {Link} from "react-router-dom";
import Fetcher from './Helpers/Fetcher';
import Translate from './Helpers/Translate';
import { showToast } from './Helpers/Toast';
import BackButton from "./Components/BackButton";
import {renderButton,addLoadingButton,removeLoadingButton} from "./Forms/DealSaveButton";
import {VscFilePdf,VscCloudUpload} from 'react-icons/vsc';
import { initiateFormErrors, validateEntireForm, validateField, validateForm, errorClass } from './Helpers/Formvalidation';
import {genRadiogroupBoxes} from './Forms/GenRadiogroupBoxes';
import Autocomplete from "react-google-autocomplete";
import{placeToAddress} from "./Helpers/Google";
import { showVatField } from './Helpers/Global';

class DealInvoice extends React.Component {  
    
    constructor(props) {
        super(props);
        this.submitBtn = React.createRef();
        this.state = {
            user_data: this.props.user_data,
            user_meta: this.props.user_meta,
            user_posts: this.props.user_posts,
            user_campaign: this.props.user_campaign,
            brand_meta: this.props.brand_meta,
            campaign: this.props.campaign_data,
            campaign_client_meta: this.props.campaign_client_meta,
            user_campaign_meta: this.props.user_campaign_meta,
            facture: this.props.user_campaign_meta.facture,
            greenLight:false,

            formFieldsNames:[
                'campaign_invoice_vat_type',
                'campaign_invoice_number','campaign_invoice_company','campaign_invoice_lastname','campaign_invoice_firstname','campaign_invoice_telephone',
                'campaign_invoice_siret','campaign_invoice_iban','campaign_invoice_bic', 'campaign_invoice_numtva',
                //Address
                'campaign_invoice_address_full','campaign_invoice_address', 'campaign_invoice_code_postal','campaign_invoice_ville','campaign_invoice_pays', 'campaign_invoice_country_code',
                //Annonceur
                'campaign_invoice_brand_company','campaign_invoice_brand_siret','campaign_invoice_brand_name',
                //Partenariat
                'campaign_invoice_campaign_type','campaign_invoice_eta_date',
                'campaign_invoice_data_confirmation',
                'facture',
                'campaign_invoice_autocomplete',
            ],
            formFieldsDefinitions:{
                'campaign_invoice_vat_type' : {
                    dv : {db : 'user_meta',col: 'vat_type'},
                    iv : {db : 'user_campaign_meta',col: 'campaign_invoice_vat_type'},
                },

                //block 1
                'campaign_invoice_number' : {
                    dv : null,
                    iv : {db : 'user_campaign_meta',col: 'campaign_invoice_number'},
                },
                'campaign_invoice_company' : {
                    dv : {db : 'user_data',col: 'company'},
                    iv : {db : 'user_campaign_meta',col: 'campaign_invoice_company'},
                },
                'campaign_invoice_lastname' : {
                    dv : {db : 'user_data',col: 'last_name'},
                    iv : {db : 'user_campaign_meta',col: 'campaign_invoice_lastname'},
                },
                'campaign_invoice_firstname' : {
                    dv : {db : 'user_data',col: 'first_name'},
                    iv : {db : 'user_campaign_meta',col: 'campaign_invoice_firstname'},
                },

                'campaign_invoice_address_full' : {
                    dv : {db : 'user_meta',col: 'adresse'},
                    iv : {db : 'user_campaign_meta',col: 'campaign_invoice_address_full'},
                },

                'campaign_invoice_address' : {
                    dv : {db : 'user_meta',col: 'adresse'},
                    iv : {db : 'user_campaign_meta',col: 'campaign_invoice_address'},
                },
                'campaign_invoice_code_postal' : {
                    dv : {db : 'user_meta',col: 'code_postal'},
                    iv : {db : 'user_campaign_meta',col: 'campaign_invoice_code_postal'},
                },
                'campaign_invoice_ville' : {
                    dv : {db : 'user_meta',col: 'ville'},
                    iv : {db : 'user_campaign_meta',col: 'campaign_invoice_ville'},
                },
                'campaign_invoice_pays' : {
                    dv : {db : 'user_meta',col: 'pays'},
                    iv : {db : 'user_campaign_meta',col: 'campaign_invoice_pays'},
                },
                'campaign_invoice_country_code' : {
                    dv : {db : 'user_meta',col: 'country_code'},
                    iv : {db : 'user_campaign_meta',col: 'campaign_invoice_country_code'},
                },

                'campaign_invoice_telephone' : {
                    dv : {db : 'user_meta',col: 'telephone'},
                    iv : {db : 'user_campaign_meta',col: 'campaign_invoice_telephone'},
                },
                'campaign_invoice_iban' : {
                    dv : {db : 'user_meta',col: 'iban'},
                    iv : {db : 'user_campaign_meta',col: 'campaign_invoice_iban'},
                },
                'campaign_invoice_bic' : {
                    dv : {db : 'user_meta',col: 'bic'},
                    iv : {db : 'user_campaign_meta',col: 'campaign_invoice_bic'},
                },
                'campaign_invoice_siret' : {
                    dv : {db : 'user_meta',col: 'siret'},
                    iv : {db : 'user_campaign_meta',col: 'campaign_invoice_siret'},
                },
                'campaign_invoice_numtva' : {
                    dv : {db : 'user_meta',col: 'numtva'},
                    iv : {db : 'user_campaign_meta',col: 'campaign_invoice_numtva'},
                },
                
                //block 2
                'campaign_invoice_brand_company' : {
                    dv : {db : 'campaign',col: 'company'},
                    iv : null,
                },
                'campaign_invoice_brand_siret' : {
                    dv : {db : 'brand_meta',col: 'stripe_siret'},
                    iv : null,
                },
                'campaign_invoice_brand_name' : {
                    dv : {db : 'campaign',col: 'nom_marque'},
                    iv : null,
                },
                'campaign_invoice_campaign_type' : {
                    dv : {db : 'campaign',col: 'campaign_type'},
                    iv : null,
                },
                'campaign_invoice_eta_date' : {
                    dv : {db : 'campaign',col: 'eta_date'},
                    iv : null,
                },    
                'facture' : {
                    dv : {db : 'user_campaign_meta',col: 'facture'},
                    iv : null,
                },        
                'campaign_invoice_data_confirmation' : {
                    dv : null,
                    iv : {db : 'user_campaign_meta',col: 'campaign_invoice_data_confirmation'},
                },   
                'campaign_invoice_autocomplete' : {
                    dv : null,
                    iv : {db : 'user_campaign_meta',col: 'campaign_invoice_autocomplete'},
                },
                
            },
            formFields:{},
            formErrors:{},
            saveTimer:null,
            savingStatus:'',
            savingStatusText:'',
            hide_siret:false,
            hide_numtva:false,
        };   
        
        
        //Set default data
        const kkeys = Object.keys(this.state.formFieldsDefinitions);
        let ff = {};
        Object.values(this.state.formFieldsDefinitions).map((v,k) => {
            //console.log(this.getFieldValue(v.dv,v.iv));
            ff[kkeys[k]] = this.getFieldValue(v.dv,v.iv)
        });
        console.log(ff);
        this.state.formFields = ff;

    }
    componentDidMount(){
        //Custom business rules 
        //--SIRET
        if(this.state.formFields.campaign_invoice_country_code.toUpperCase() != 'FR') this.setState({hide_siret:true,})
        //--VAT
        if(showVatField(this.state.formFields.campaign_invoice_country_code,this.state.formFields.campaign_invoice_vat_type)) this.setState({hide_numtva:true,})


        //Set default error data > onMount to refresh the data when back
        this.setState({
            formErrors:initiateFormErrors(this.state.formFieldsNames),
        });


    }
    getFieldValue = (dv, iv) => { //default value, invoice value
        if(iv !== null && this.state[iv.db][iv.col] !== undefined && this.state[iv.db][iv.col] !== '') return this.state[iv.db][iv.col];
        else if(dv !== null && this.state[dv.db][dv.col] !== undefined && this.state[dv.db][dv.col] !== '') return this.state[dv.db][dv.col];
        else return '';
    }
    handleChange(evt){
        let e = '';
        if(evt.target !== undefined) e = evt.target;
        else e = evt;

        const name = e.name;
        const type = e.type;
        const value = type === "checkbox" ? e.checked : e.value;
        

        //update form fields
        let ff = {};
        ff = this.state.formFields;
        ff[name] =  value;
        
        //Errors
        const err = validateField(e);
        let fe = {};
        fe = this.state.formErrors;
        fe[name] = (err.fieldValid?'success':'error');

        //Auto invoice num gen logic
        //if(name ==='campaign_invoice_number_auto' && value) ff['campaign_invoice_number'] = '';

        //TVA logic 
        let hn = false;
        if(showVatField(this.state.formFields.campaign_invoice_country_code,this.state.formFields.campaign_invoice_vat_type))  hn = true;
     

        //push state
        this.setState({
            formFields:ff,
            formErrors:fe,
            hide_numtva:hn
        }, () => {
            //Auto save
            this.autoSave();
        });

    }
    
    componentWillUnmount(){
        this.setState({
            saveTimer:null
        })
    }
    autoSave(){
        if(this.state.saveTimer) clearTimeout(this.state.saveTimer);

        this.state.saveTimer = setTimeout(() => {
            this.setState({
                saveTimer:null,
                savingStatusText:<Translate s="app_lbl_saving" />,
            },() => {
                this.handleSave()
            });
        }, 1000);
    }
    handleSave = () => {
        new Fetcher().callApi({
            api_path:'/campaigns/save_invoice/',
            api_data:{
                uid_campaign : this.state.campaign.uid_campaign,
                formFields: this.state.formFields,
                pdf: false,
            },
            method:'POST',
        }).then((r) => {
            if(r.status === 'success'){
                this.setState({
                    savingStatus:'active',
                    savingStatusText:<Translate s="app_lbl_saved" />,
                    formFields:r.results.formFields,
                });
            }
            else{
                this.setState({
                    savingStatus:'active',
                    savingStatusText:'Error...',
                });
            }
        });
    }
    handleSubmit = (e) =>  {
        console.log(this.state.formErrors)
        if(validateForm(this.state.formErrors) === 'success'){
            const saveBtnEls = addLoadingButton(e);
            
            new Fetcher().callApi({
                api_path:'/campaigns/save_invoice/',
                api_data:{
                    uid_campaign : this.state.campaign.uid_campaign,
                    formFields: this.state.formFields,
                    pdf: true,
                }, 
                method:'POST',
            }).then((r) => {
                if(r.status === 'success'){
                    showToast('app_well_saved');
                    removeLoadingButton(e,saveBtnEls);
                    this.setState({
                        savingStatus:'', //flush saved status
                        formFields:{
                            ...this.state.formFields,
                            facture:r.results.formFields.facture
                        }
                    });
                    window.scrollTo(0, 0)
                }
                else showToast('app_an_error_occured'); 
            });
        }
        else{
            const tmp_err = validateEntireForm(this.state.formFieldsNames,this.state.formErrors);
            console.log(tmp_err);
            this.setState({formErrors : tmp_err});
        }

    }
    dateFormat(d){
        if(d !== null){
            const [year, month, day] = [...d.split("-")];
            const monthIndex = month - 1; // remember that Date's constructor 2nd param is monthIndex (0-11) not month number (1-12)!
            const dd = new Date(year, monthIndex, day);
            return dd.toLocaleDateString('fr-FR', {month: 'long', year: 'numeric'});
        }
        else return '';
    }
    manageAutocomplete(p){
        const e = placeToAddress(p);
        //Siret
        let hs = this.state.hide_siret;
        let htva = this.state.hide_numtva;
        if(e.country.short_name !== 'FR') hs = true;
        else hs = false;
        //TVA 
        if(this.state.formFields.campaign_invoice_vat_type === 'no_vat') htva = true;
        else htva = false;
        //hide Siret
        this.setState({
            hide_siret:hs,
            hide_numtva:htva,
            formFields:{
                ...this.state.formFields,
                campaign_invoice_address:(e.streetNumber !== undefined?e.streetNumber.long_name+' ':'')+(e.streetName !== undefined?e.streetName.long_name:''),
                campaign_invoice_code_postal:(e.zip !== undefined?e.zip.long_name:''),
                campaign_invoice_ville:(e.city !== undefined?e.city.long_name:''),
                campaign_invoice_pays:(e.country !== undefined?e.country.long_name:''),
                campaign_invoice_country_code:(e.country !== undefined?e.country.short_name:''),
                campaign_invoice_autocomplete:true
            },
            formErrors:{
                ...this.state.formErrors,
                campaign_invoice_address_full : 'success',
                campaign_invoice_address : 'success',
            }
        }, () => {
            this.autoSave();
        })
    }
    detectNoAutocomplete = (t) => {
        let ff = this.state.formFields;
        let fe = this.state.formErrors;
        if(t==='change'){
            ff.campaign_invoice_autocomplete = false;
        }
        if(t==='blur'){
            if(!ff.campaign_invoice_autocomplete){

                fe.campaign_invoice_address_full = 'error';
                fe.campaign_invoice_address = 'error';
               
                ff.campaign_invoice_autocomplete = false;
                ff.campaign_invoice_address_full = '';
                ff.campaign_invoice_address = '';
                ff.campaign_invoice_code_postal = '';
                ff.campaign_invoice_ville = '';
                ff.campaign_invoice_pays = '';
                ff.campaign_invoice_country_code = '';
            }
            else {
                ff.campaign_invoice_autocomplete = true;           
                fe.campaign_invoice_address_full = 'success';
                fe.campaign_invoice_address = 'success';
            }

            this.setState({
                formFields:ff,
                formErrors:fe
            }, () => {
                console.log(this.state.formFields);
                console.log(this.state.formErrors);
            });




        }      
    }
    render(){
        return this.state.user_posts === false ? this.renderPendingMessage() : (this.state.formFields.facture == '' ? this.renderForm() : (       
            this.renderDownloadLink())
        );  
    }
    renderPendingMessage(){
        return (
            <div id="bk_campaign" className="container brand_page fixed_header bg-white min-vh-100 px-md-0">
                
                <div className="row header">
                    <div className="col px-0">
                        <BackButton />
                        <div className="logo_marque" style={{'backgroundImage':"url("+process.env.REACT_APP_SITE_URL+'/coffrefort/brands/'+this.state.campaign.id_marque+'/'+this.state.campaign.logo_marque+")"}}>
                            
                            <p className={"savingStatus "+this.state.savingStatus}>{this.state.savingStatusText}</p>

                        </div>
                        <Link className="top_right_button ico_help" to="/help"></Link>
                    </div>
                </div>
                <div className="row content mt-5">
                    <div className="col-12 text-center mt-2">
                        <h2 className="text-andeslight"><Translate s="program_fiche_invoice" /></h2>
                    </div>
                    <p className='my-4'><Translate s="lbl_post_before_invoice" /></p>
                </div>
        </div>
        )
    }
    renderDownloadLink(){
        return (
            <div id="bk_campaign" className="container brand_page fixed_header bg-white min-vh-100 px-md-0">
                
                <div className="row header">
                    <div className="col px-0">
                        <BackButton />
                        <div className="logo_marque" style={{'backgroundImage':"url("+process.env.REACT_APP_SITE_URL+'/coffrefort/brands/'+this.state.campaign.id_marque+'/'+this.state.campaign.logo_marque+")"}}>
                            
                            <p className={"savingStatus "+this.state.savingStatus}>{this.state.savingStatusText}</p>

                        </div>
                        <Link className="top_right_button ico_help" to="/help"></Link>
                    </div>
                </div>
                <div className="row content mt-5">
                    <div className="col-12 text-center mt-2">
                        <h2 className="text-andeslight"><Translate s="program_fiche_invoice" /></h2>
                    </div>
                    <div className="col-12 text-center mt-5">
                        <p className="text-center"><Translate s="app_lbl_invoice_generated" /></p>
                        <br />
                        <a href={process.env.REACT_APP_SITE_URL+'/coffrefort/invoices_influencers/'+this.state.campaign.id_campaign+'/'+this.state.formFields.facture} target="_blank" className="btn btn-primary"><Translate s='lbl_dl_invoice' /></a>
                    </div>
                </div>
        </div>
        )
    }
    renderForm() { 
        const cc = JSON.parse(this.state.campaign.campaign_contrepartie);
        let rem = cc.rem.value; 
        if(this.state.user_campaign.rem_custom !== '0') rem = this.state.user_campaign.rem_custom;
        return (
            <div id="bk_campaign" className="container brand_page fixed_header bg-white min-vh-100 px-md-0">
                
                <div className="row header">
                    <div className="col px-0">
                        <BackButton />
                        <div className="logo_marque" style={{'backgroundImage':"url("+process.env.REACT_APP_SITE_URL+'/coffrefort/brands/'+this.state.campaign.id_marque+'/'+this.state.campaign.logo_marque+")"}}>
                            
                            <p className={"savingStatus "+this.state.savingStatus}>{this.state.savingStatusText}</p>

                        </div>
                        <Link className="top_right_button ico_help" to="/help"></Link>
                    </div>
                </div>

                <div className="row content mt-5 mt-md-0 px-md-4">
                    <div className="col-12 text-center mt-2">
                        <h2 className="text-andeslight"><Translate s="program_fiche_invoice" /></h2>
                    </div>
                    <form className="col-12 mt-2">


                        <div className="row mb-1"> 
                            <div className="col">
                                {genRadiogroupBoxes({
                                    label:'app_lbl_vatstatus',
                                    name:'campaign_invoice_vat_type',
                                    defaultValue:this.state.formFields.campaign_invoice_vat_type,
                                    onChangeAction:(e) => this.handleChange(e),
                                    required:true,
                                    class:errorClass(this.state.formErrors.campaign_invoice_vat_type),
                                    data:[{    
                                        key:'vat',
                                        value:<Translate s='app_lbl_vat_applicable' />
                                    },{
                                        key:'no_vat',
                                        value:<Translate s='app_lbl_vat_not_applicable' />
                                    }]
                                })}                        
                            </div>
                        </div>
                        

                        <div className="row mb-4"> 
                            <div className="col-12 mt-sm-4 col-lg-6 mt-lg-0 col-xxl-4 mt-lg-0">
                                <label className="form-label"><Translate s="app_lbl_yourinfo" /></label>
                                <fieldset className="form-fieldset">
                                    <div className="mb-3">
                                        <label className="form-label required"><Translate s="app_lbl_invoicenum" /></label>
                                        <input type="text" className={`form-control ${errorClass(this.state.formErrors.campaign_invoice_number)}`} autoComplete="off" data-is_required={true} name="campaign_invoice_number" value={this.state.formFields.campaign_invoice_number} onChange={(e) => this.handleChange(e)} />
                                    
                                        {/*<label className="form-check">
                                            <input type="checkbox" className="form-check-input" name="campaign_invoice_number_auto" value="1" onChange={(e) => this.handleChange(e)} defaultChecked={(this.state.formFields.campaign_invoice_number_auto === "1" || this.state.formFields.campaign_invoice_number_auto?true:false)} />
                                            <span className="form-check-label required">Générer automatiquement un numéro de facture.</span>
                                        </label>*/ }    
                                    </div>
                                    <div className="mb-3">
                                    <label className="form-label"><Translate s="app_lbl_company_trade" /></label>
                                    <input type="text" className={`form-control ${errorClass(this.state.formErrors.campaign_invoice_company)}`} autoComplete="off" data-is_required={false} name="campaign_invoice_company" value={this.state.formFields.campaign_invoice_company} onChange={(e) => this.handleChange(e)} />
                                    </div>
                                    <div className="mb-3">
                                    <label className="form-label required"><Translate s="profil_info_lastname" /></label>
                                    <input type="text" className={`form-control ${errorClass(this.state.formErrors.campaign_invoice_lastname)}`} autoComplete="off" data-is_required="true" name="campaign_invoice_lastname" value={this.state.formFields.campaign_invoice_lastname} onChange={(e) => this.handleChange(e)} />
                                    </div>
                                    <div className="mb-3">
                                    <label className="form-label required"><Translate s="profil_info_firstname" /></label>
                                    <input type="text" className={`form-control ${errorClass(this.state.formErrors.campaign_invoice_firstname)}`} autoComplete="off" data-is_required="true" name="campaign_invoice_firstname" value={this.state.formFields.campaign_invoice_firstname} onChange={(e) => this.handleChange(e)} />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label required"><Translate s="profil_info_address" /></label>
                                        <small className={'text-danger '+((this.state.formFields.campaign_invoice_autocomplete === false)?'':'d-none')}><Translate s="app_lbl_use_ac" /></small>
                                        <Autocomplete
                                            apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                                            name="campaign_invoice_address_full"
                                            className={`form-control ${errorClass(this.state.formErrors.campaign_invoice_address_full)}`}
                                            defaultValue={this.state.formFields.campaign_invoice_address_full}
                                            data-is_required={true}
                                            data-validation='autocomplete'
                                            data-autocompleteValidation={(this.state.formFields.campaign_invoice_autocomplete == '' || !this.state.formFields.campaign_invoice_autocomplete)?false:true}
                                            options = {{
                                                types: ['address']
                                            }}
                                            onPlaceSelected={(place,e,a) => {
                                                this.handleChange(e);
                                                this.manageAutocomplete(place);
                                            }}
                                            onChange = {() => this.detectNoAutocomplete('change')}
                                            onBlur = {() => this.detectNoAutocomplete('blur')}
                                        />

                                        {/*<input type="text" className={`form-control ${errorClass(this.state.formErrors.campaign_invoice_address)}`} autoComplete="off" name="campaign_invoice_address" value={this.state.formFields.campaign_invoice_address} />*/}
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label required"><Translate s="profil_info_phone" /></label>
                                        <input type="text" className={`form-control ${errorClass(this.state.formErrors.campaign_invoice_telephone)}`} autoComplete="off" data-is_required="true" name="campaign_invoice_telephone" value={this.state.formFields.campaign_invoice_telephone} onChange={(e) => this.handleChange(e)} />
                                    </div>

                                    <div className={"mb-3 "+(this.state.hide_siret?'d-none':'')}>
                                        <label className={"form-label "+(!this.state.hide_siret?'required':'')}><Translate s="stripe_siret" /></label>
                                        <input type="number" className={`form-control ${errorClass(this.state.formErrors.campaign_invoice_siret)}`} autoComplete="off" data-is_required={!this.state.hide_siret} minLength="14" maxLength="14" name="campaign_invoice_siret" value={this.state.formFields.campaign_invoice_siret} onChange={(e) => this.handleChange(e)} />
                                        <div className="invalid-feedback"><Translate s="app_err_siret" /></div>
                                    </div>

                                    <div className={"mb-3 "+(this.state.hide_numtva?'d-none':'')}>
                                        <label className={"form-label "+(!this.state.hide_numtva?'required':'')}><Translate s="stripe_tva" /></label>
                                        <input type="text" className={`form-control ${errorClass(this.state.formErrors.campaign_invoice_numtva)}`} autoComplete="off" data-is_required={!this.state.hide_numtva} name="campaign_invoice_numtva" value={this.state.formFields.campaign_invoice_numtva} onChange={(e) => this.handleChange(e)} />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label required">IBAN</label>
                                        <input type="text" className={`form-control ${errorClass(this.state.formErrors.campaign_invoice_iban)}`} autoComplete="off" data-is_required="true" name="campaign_invoice_iban" value={this.state.formFields.campaign_invoice_iban} onChange={(e) => this.handleChange(e)} />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label required">BIC</label>
                                        <input type="text" className={`form-control ${errorClass(this.state.formErrors.campaign_invoice_bic)}`} autoComplete="off" data-is_required="true" name="campaign_invoice_bic" value={this.state.formFields.campaign_invoice_bic} onChange={(e) => this.handleChange(e)} />
                                    </div>

                                    
                                </fieldset>
                            </div>
                            <div className="col-12 mt-sm-4 col-lg-6 mt-lg-0 col-xxl-4 mt-lg-0">
                                <label className="form-label"><Translate s="app_lbl_brandinfo" /></label>
                                <fieldset className="form-fieldset">
                                    <div className="mb-3">
                                        <label className="form-label"><Translate s="app_lbl_companyname" /></label>
                                        <input type="text" className={`form-control ${errorClass(this.state.formErrors.campaign_invoice_brand_company)}`} autoComplete="off" data-is_required={false}name="campaign_invoice_brand_company" value={this.state.formFields.campaign_invoice_brand_company} readOnly={true} />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label"><Translate s="stripe_siret" /></label>
                                        <p className="form-control" readOnly={true}>{this.state.formFields.campaign_invoice_brand_siret}</p>
                                    </div>
                                </fieldset>
                                
                                <label className="form-label"><Translate s="app_lbl_partenariat" /></label>
                                <fieldset className="form-fieldset">
                                    <div className="mb-3">
                                        <label className="form-label"><Translate s="app_lbl_nature" /></label>
                                        <p className="form-control" readOnly={true}><Translate s={"campaign_type_"+this.state.formFields.campaign_invoice_campaign_type} /></p>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label"><Translate s="invoice_promo_brand" /></label>
                                        <p className="form-control" readOnly={true}>{this.state.formFields.campaign_invoice_brand_name}</p>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label"><Translate s="invoice_completion_date" /></label>
                                        <input type="text" className={`form-control ${errorClass(this.state.formErrors.campaign_invoice_eta_date)}`} autoComplete="off" data-is_required={false} name="campaign_invoice_eta_date" value={this.dateFormat(this.state.formFields.campaign_invoice_eta_date)} readOnly={true} />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label"><Translate s="app_lbl_price" /></label>
                                        <p className="form-control" readOnly={true}>{rem}</p>
                                    </div>
                                </fieldset>
                            </div>

                        </div>
                        
                        <fieldset className="form-fieldset">
                            <label className="form-check">
                              <input type="checkbox" name="campaign_invoice_data_confirmation" className={`form-check-input ${errorClass(this.state.formErrors.campaign_invoice_data_confirmation)}`} data-is_required={true} defaultChecked={(this.state.formFields.campaign_invoice_data_confirmation === "1"?true:false)} onChange={(e) => this.handleChange(e)} />
                              <span className="form-check-label required"><Translate s="app_lbl_confirmdata" /></span>
                            </label>
                          </fieldset>

                    </form>
                   
                </div>
                
                <div className="fixed-bottom bg-white gradient-top">
                    <div className="d-grid p-3 mt-4 btn-bottom-ctnr">
                    {renderButton((e) => this.handleSubmit(e),'btn_generate')}
                    </div>
                </div>
            </div>
        )
    }  
}

export default DealInvoice;