import React from 'react';
import {BsCheckLg} from 'react-icons/bs';
import {FaLock} from 'react-icons/fa';
import logo from '../../Assets/img/default_logo.png';
import Fetcher from '../../Helpers/Fetcher';
import {renderButton} from "../../Forms/DealSaveButton";
import {getLocalStorageItem,setLocalStorageItem} from '../../Helpers/Storage'

import Translate from '../../Helpers/Translate';
import UploadAvatar from '../../Forms/UploadAvatar';
import Loading from '../../Components/Loading';

class Steps extends React.Component {    
    constructor(props) {
        super(props);
        this.state = {
            steps:{},
            steps_cnt:0,
            readOnly:true,
            isLoaded:false,
        }
    }
    componentDidMount(){
        this.fetchStepProgression();
    }
    fetchStepProgression(){
        
        new Fetcher().callApi({
            api_path:'/profil/step_progression/',
            api_data:{}, 
            method:'POST',
        }).then((r) => {
            if(r.status === 'success'){
                console.log(r.results);
                this.setState({
                    isLoaded:true,
                    steps:r.results.steps,
                    steps_cnt:Object.values(r.results.steps).length,
                    readOnly:!r.results.completed,
                    user_meta:r.results.user_meta,

                })
            }
        });
    }
    handleSubmit = async () => {
        const bk_user_info = await getLocalStorageItem('bk_user_info');
        const ret = JSON.parse(bk_user_info);
        if(!this.state.readOnly){
            new Fetcher().callApi({
                api_path:'/profil/set_completed/',
                api_data:{}, 
                method:'POST',
            }).then((r) => {
                if(r.status === 'success'){
                    //Set completed_profil to 1 and redir to /lock
                    ret.completed_profil = "1";
                    setLocalStorageItem("bk_user_info",JSON.stringify(ret)).then(() => {window.location.href = '/lock'});
                }
            });
        }
    }
    goToStep = (i) => {
        if(i.completion !== '') this.props.history.push('/register/step_'+i.slug);
    } 
    getCompletionClass = (c) => {
        if(c === 'completed') return 'step_done';
        else if(c === 'next') return 'step_next';
        else return '';
    }
    getCompletionIco = (c) => {
        if(c === 'completed') return <div className="ico align-middle"></div>;
        else if(c === 'next') return <div className="ico align-middle"></div>;
        else return <div className="align-middle icon"><FaLock /></div>;
    }
    render() {  
        return this.state.isLoaded ? this.renderSteps() : (       
            <Loading alwaysShow={false} />
        );            
    }
    renderSteps() {  
        //{this.state.steps_cnt}
        return (
            <div id="bk_register_steps">
                <div className="container-fluid bg-white">
                    <div className="row header px-3 pt-5">
                        <div className="col text-center upload_avatar_ctnr">
                            <img className="mb-4" src={logo} alt="" width="90" />
                            <h2 className="header_title  pb-6"><Translate s="lbl_steps_heading" /></h2>
                            <UploadAvatar user_meta={this.state.user_meta} />
                        </div>
                    </div>
                </div>
                
                <div className="steps_ctnr container-fluid pb-9 pt-3">
                    

                    <div className="row steps gy-3 gx-4 mt-5"> 
                    {
                    Object.values(this.state.steps).map((iv,kv) => {
                            return (
                                <div key={"s"+kv} className='col-12 col-md-6 col-lg-4'>
                                <div className={"step_item p-3 pointer "+this.getCompletionClass(iv.completion)} onClick={() => this.goToStep(iv)}>
                                   <div className='step_digit'>{kv+1}</div>
                                   <div className='step_checkmark'><BsCheckLg /></div>
                                   
                                   <div className="d-flex align-items-center ps-3">
                                           <div  className="flex-grow-1">
                                               <h3><Translate s={iv.translation} /></h3>
                                           </div>
                                           {this.getCompletionIco(iv.completion)}
                                   </div>
                               </div>
                               
                           </div>
                        )
                    } )}
                
                        
                    </div>
                </div>

                <div className="fixed-bottom bg-white gradient-top">
                    <div className={"btn-bottom-ctnr d-grid p-3 mt-4 "+(this.state.readOnly?'d-none':'')}>
                    {renderButton((e) => this.handleSubmit(e))}
                    </div>
                </div>

            </div>
            
        )
    }  
}

export default Steps;