//$url+'/'+$trig+'/'+$id_user+'/'+$id_campaign+'/'+$security_key
import React from 'react';
import { getLocalStorageItem } from './Helpers/Storage';
import Translate from './Helpers/Translate';
import {AiOutlineSend} from 'react-icons/ai';
import {MdAttachFile} from 'react-icons/md';

import Loading from './Components/Loading';
import BackButton from './Components/BackButton';
import { showToast } from './Helpers/Toast';
import Fetcher from './Helpers/Fetcher';

import UploadImage from './Forms/UploadImage';
import DisplayGridImages from './Forms/DisplayGridImages';

class Chat extends React.Component {  
    
    constructor(props) {
        super(props);
        
        this.state = {
            isLoaded:false,
            hasError:false,
            campaign:'',
            message:'',
            images:{},
            totChatImages:1,
            messages:[],
            uid:this.props.match.params.uid,
            secret_key:this.props.match.params.secret_key,
        };   
    }

    componentDidMount(){        
        getLocalStorageItem('bk_user_info')
        .then((ret) => JSON.parse(ret))
        .then((r) => {
            this.setState({
                firstname:r.firstname,
                token:r.token
            },()=>{
                this.fetchChatApi();
            });

        });
        
    }
    componentDidUpdate(prevProps, prevState) {
        
        if(this.state.isLoaded){
            //scrolling viewport calc
            const acm = document.getElementById('bk_chat');
            const acm_title = acm.getElementsByClassName("header")[0];
            const acm_content = acm.getElementsByClassName("body")[0];
            const acm_cta = acm.getElementsByClassName("cta")[0];
            acm_content.style.height = window.innerHeight-acm_title.offsetHeight-acm_cta.offsetHeight+"px";

            //Scroll to bottom
            acm_content.scrollTop = acm_content.scrollHeight - acm_content.clientHeight;
        }
    }
    fetchChatApi(){
        new Fetcher().callApi({
            api_path:'/chats/get/',
            api_data:{
                "uid": this.props.match.params.uid,
                "secret_key": this.props.match.params.secret_key,
            },
            method:'POST',
        }).then((r) => {
            if(r.status === 'success'){
                this.setState({
                    isLoaded: true,
                    messages:r.results.messages,
                    campaign:r.results.campaign,
                    brand_info:r.results.brand_info
                })    
                //update pills
                this.props.refreshPills();

            }
            else {
                this.setState({
                    isLoaded: true,
                    error:true
                  });    
            }
        });
    }        
    handleChange = (evt) => {
        const value = evt.target.value;
        this.setState({
            message:value
        });
    }
    sendMessage = () => {
        if(this.state.message.replace(/\s+/g, '') !== '' || Object.keys(this.state.images).length > 0){            
            new Fetcher().callApi({
                api_path:'/chats/send',
                api_data:{
                    "uid": this.state.uid,
                    "message" : this.state.message,
                    "images" : this.state.images
                },
                method:'POST',
            }).then((r) => {
                if(r.status === 'success'){
                    //refresh data set
                    this.fetchChatApi();
                    //clear images 
                    const s = document.getElementById('slideup_pic_chat');
                    s.classList.remove('active');
                    this.setState({
                       images:{},
                       totChatImages:1,
                    })
                    //clear textarea
                    document.getElementById('chat_message').value = '';
                }
                else showToast('Erreur');
            });
        }
    }
    showAttachement = () => {
        const s = document.getElementById('slideup_pic_chat');
        s.classList.toggle('active');
    }
    setChatImages = (t,ffk,ffv) => {
        let im = this.state.images;
        if(t === 'add') im[ffk] = ffv;
        if(t === 'delete') delete im[ffk];        
        this.setState({
            images:im
        })
    }
    setTotChatImages = (t) => { 
        let tot = this.state.totChatImages;
        if(t==='inc') tot =  tot+1
        if(t==='dec') tot =  tot-1
        this.setState({totChatImages:tot},() => {
            //this.setState({formErrors:initiateFormErrors(this.state.formFieldsNames)});
            //this.setChatImages('add','images', this.state.images);

            //Update in DB in case they do not save the form but jsut use the go back
            //new Fetcher().callApi({api_path:'/helpers/update_user_meta/',api_data:{"metas": {'topPicUgc':tot}},method:'POST'}); 
        })
    } 
    nl2br (str, is_xhtml) {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }
    displayChatImages = (iv) => {
        
     
            return (
                Object.values(iv.chat_images).map((iv_im,kv_im) => {
                    return (
                        <>{iv_im}</>
                    )
                })
            )

    }
    render() {  
        return this.state.isLoaded ? (this.state.hasError ? this.renderError() : this.renderChat()) : (       
            <Loading alwaysShow={false} />
        );            
    } 
    renderError() {  
        return (       
            <div id="wtbid_home" className="container"><div className="row grow w-100"> <span>Oops ! Something went wrong, try to hit the back button or report it to contact@bilbokid.com</span></div></div>
        );            
    } 
    renderChat() {
        const message_dates = Object.keys(this.state.messages);
        let $class = '';

        return (
            <div id="bk_chat" className="h-100">
                <div className="container-fluid">
                    <div className="header row py-3">
                        <div className="col">
                            <BackButton />
                            <h2 className="header_title text-andeslight text-uppercase"><Translate s="lbl_chat_youand" /> et {this.state.campaign.nom_marque}</h2>
                        </div>
                    </div>
                </div>
                <div className="container bg-white">
                    <div className="alert alert-info">
                            
                        {(() => {
                            if(this.state.campaign.owner_fg === "1" || this.state.campaign.owner_fg === "0"  || this.state.campaign.force_bilbokid === "1"){
                                return (
                                    <Translate s="txt_chat_bilbokid" rep={[this.state.campaign.nom_marque]}  />
                                )
                            }
                            else if(this.state.campaign.owner_fg !== "0" && this.state.campaign.group_id === "4") {
                                return (
                                    <Translate s="txt_chat_agence" rep={[this.state.brand_info.company,this.state.campaign.nom_marque]}  />
                                )
                            }
                            else { 
                                return (
                                    <Translate s="txt_chat_marque" rep={[this.state.campaign.nom_marque]}  />
                                )
                            }
                        })()}
                        
                    </div>
                    <div className="body row pt-3 px-0"> 
                        <div className="brand_chat_view col">
                        {(() => {
                            if(Object.keys(this.state.messages).length){
                                return(
                                    Object.values(this.state.messages).map((v_date,k_date) => {
                                        const formated_date= new Date(message_dates[k_date].replace(/-/g, "/"));
                                        return (
                                            <div key={k_date} id={k_date}>
                                            <p className="chat_date">{formated_date.toLocaleDateString('fr-FR',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</p>
                                                {
                                                    
                                                    Object.values(v_date).map((iv,kv) => {
                                                        
                                                        if(iv.id_user === iv.id_initiator) $class="right_mess";
                                                        else $class="left_mess";
                                                        
                                                        const formated_hour= new Date(iv.chat_message_sent.replace(/-/g, "/"));

                                                        return (
                                                            <>
                                                                <div className={"chat_mess text-andeslight "+$class}>
                                                                    <p className="chat_hour">{formated_hour.toLocaleTimeString('fr-FR',{ hour: 'numeric', minute: 'numeric'})}</p>

                                                                    
                                                                    {iv.chat_is_deleted === 'Y' && <p className="text-muted small "><Translate s="lbl_text_removed" /></p>}
                                                                    {iv.chat_is_deleted === 'N' && <div className="mess" dangerouslySetInnerHTML={{ __html: this.nl2br(iv.chat_message,true)}} />}

                                                                    {(iv.chat_images !== null && Object.keys(iv.chat_images).length > 0) && <div className='row justify-content-start flex-row'> <DisplayGridImages images={iv.chat_images} />
                                                                    <p className="text-muted small "><Translate s="lbl_delete_after_7days" /></p></div>}

                                                                    {iv.chat_had_image === 'Y' && <p className="text-muted small "><Translate s="lbl_had_images" /></p>}

                                                                </div>
                                                                <div className="clearfix"></div>
                                                            </>

                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                ) 
                            }
                            else {
                                return (
                                    <p><Translate s="lbl_no_message" /></p>
                                )
                            }
                        })()}
                            </div>

                           
                    </div>
                </div>

                
                <div id="slideup_pic_chat" className="row justify-content-start flex-row">
                    {[...Array(this.state.totChatImages)].map((x, i) => { 
                        let pic_url = '';
                        if(this.state.images['pic_chat_'+i] !== undefined) pic_url = this.state.images['pic_chat_'+i];
                        return (
                            <UploadImage 
                                setFormFields={this.setChatImages}
                                setTotImages={this.setTotChatImages}
                                db_field={"pic_chat_"+i}
                                name="pic_chat"
                                api_endpoint="/chats/upload_chat_images"
                                api_delete_endpoint="/chats/delete_chat_image"
                                image_folder='medias_chat'
                                pic_url={pic_url}
                                is_required={false}
                                tot_images={this.state.totChatImages}
                                min_images={1}
                            />


                        )
                    })}
                </div>

                <div className="cta row px-2 py-3 fixed-bottom bg-white border-top-grey">
                
                    <div className="col-10 d-grid pe-0 position-relative">
                        <button className="show_attachment" onClick={()=>this.showAttachement()}><MdAttachFile /></button>
                        <textarea name="chat_message" className="form-control white_input" id="chat_message" placeholder="Message" onChange={this.handleChange}></textarea>

                    </div>
                    <div className="col-2 d-grid">
                        <button id="show_candidature_condition_ctnr" type="button" className="btn btn-primary" onClick={this.sendMessage}><AiOutlineSend /></button>
                    </div>
                </div>

            </div>
        )
    }  
}

export default Chat;