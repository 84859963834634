import React from 'react';
import Translate from './Helpers/Translate';

import BackButton from "./Components/BackButton";

class DealEvaludation extends React.Component {  
    
    constructor(props) {
        super(props);
        this.state = {
            campaign: this.props.campaign_data, 
            user_campaign: this.props.user_campaign
        };   
    }
    
    render() { 
        return (
            <div id="bk_campaign" className="container brand_page fixed_header bg-white min-vh-100 px-md-0">
                
                <div className="row header">
                    <div className="col px-0">
                        <BackButton />
                        <div className="logo_marque" style={{'backgroundImage':"url("+process.env.REACT_APP_SITE_URL+'/coffrefort/brands/'+this.state.campaign.id_marque+'/'+this.state.campaign.logo_marque+")"}}></div>
                    </div>
                </div>

                <div className="row content mt-5  px-md-5">
                    <div className="col-12 text-center mt-2">
                        <h2 className="text-andeslight">Mon évaluation</h2>
                    </div>
                    <div className="col-12 mt-2">
                        <p><Translate s='title_ma_note' /> : {((this.state.user_campaign.note_serieux && this.state.user_campaign.note_serieux !== "0")?this.state.user_campaign.note_serieux:'-')}</p>
				        <p><Translate s='lbl_comment' /> : {((this.state.user_campaign.note_serieux_commentaire)?this.state.user_campaign.note_serieux_commentaire:<Translate s='label_non_renseigne' />)}</p>
                    </div>        
                </div>
            </div>
        )
    }  
}

export default DealEvaludation;