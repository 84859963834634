import React from 'react';
import {Link} from "react-router-dom";
import Fetcher from './Helpers/Fetcher';

import Loading from './Components/Loading';
import { getLocalStorageItem } from './Helpers/Storage';
import { gen_security_key } from './Helpers/Security';
import {truncateText} from './Helpers/Global';
import Translate from './Helpers/Translate';

class Chats extends React.Component {  
    
    constructor(props) {
        super(props);
        
        this.state = {
            isLoaded:false,
            chats:[],
        };   
    }

    componentDidMount(){        
        getLocalStorageItem('bk_user_info')
        .then((ret) => JSON.parse(ret))
        .then((r) => {
            this.setState({
                firstname:r.firstname,
                avatar:r.avatar,
                token:r.token
            });
            this.fetchChatApi(r.token);    
        });
    }
    fetchChatApi(filter = ''){
        new Fetcher().callApi({
            api_path:'/chats/list/',
            api_data:{
                "filter": filter
            },
            method:'POST',
        }).then((r) => {
            if(r.status === 'success'){
                this.setState({
                    isLoaded: true,
                    chats:r.results
                  }) 
            }
            else { 
                this.setState({
                isLoaded: true,
                error:true
              });    
            }
        });
    } 
    goToChat(){
        
    }
    render() {  
        return (
            <div id="bk_chats" className="bg_blur h-100 container bg-white">
                
                <div className="row header header_icon">
                    <div className="col px-0">
                        <div className="icon"></div>
                        <Link className="top_right_button ico_help" to="/help"></Link>
                    </div>
                </div>

                {/*<div className="row my-5">
                    <div className="col">
                        <h2 className="header_title text-center text-pink text-andesbold">Messagerie</h2>
                    </div>
                </div>*/}
                    
                    <div className="row mt-5 pt-4 px-0 pb-7"> 
                        <div className="col chat_nav">
                        {(() => {
                            if(!this.state.isLoaded){
                                return (
                                    <Loading />
                                )
                            }
                            else {
                                if(Object.keys(this.state.chats).length > 0){
                                    return(
                                    Object.values(this.state.chats).map((iv,kv) => {
                                    return (
                                        <Link 
                                            to={{ pathname : '/chat/'+iv.info.uid_campaign+'/'+gen_security_key([iv.info.id_user,iv.info.id_marque,iv.info.uid_campaign])}} 
                                            className="chat_item row d-flex flex-wrap align-items-center" key={kv} id={kv}>
                                                <div className="col-2 col-md-1 col-lg-1 px-0 text-center">
                                                    <img src={process.env.REACT_APP_SITE_URL+'/coffrefort/brands/'+iv.info.id_marque+'/'+iv.info.logo_marque} className="img-fluid" alt="" />
                                                </div>
                                                <div className="col-9 col-md-11 col-lg-11 text-start">{truncateText(iv.info.chat_message, 50)}</div>
                                            </Link>
                                    )
                                })
                                    ) 
                                }
                                else {
                                    return (
                                        <p><Translate s="lbl_no_message" /></p>
                                    )
                                }
                            }
                        })()}
                            </div>
                    </div>
            </div>
        )
    }  
}

export default Chats;