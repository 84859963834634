import React from 'react';
import Translate from './Helpers/Translate';

import BackButton from "./Components/BackButton";

class DealPackage extends React.Component {  
    
    constructor(props) {
        super(props);
        this.state = {
            campaign: this.props.campaign_data, 
            user_campaign: this.props.user_campaign
        };   
    }
    
    render() { 
        return (
            <div id="bk_campaign" className="container brand_page fixed_header  bg-white min-vh-100 px-md-0">
                
                <div className="row header">
                    <div className="col px-0">
                        <BackButton />
                        <div className="logo_marque" style={{'backgroundImage':"url("+process.env.REACT_APP_SITE_URL+'/coffrefort/brands/'+this.state.campaign.id_marque+'/'+this.state.campaign.logo_marque+")"}}></div>
                    </div>
                </div>

                <div className="row content mt-5 px-md-5">
                    <div className="col-12 text-center mt-2">
                        <h2 className="text-andeslight">Mon colis</h2>
                    </div>
                    <div className="col-12 mt-2">
                        
                        {(() => {
                            if(this.state.user_campaign.status === 'valide') {
                                return (
                                    <div className="alert alert-warning my-2">Votre colis n'a pas encore été expédié. Vous en serez notifié(e) dès que cela sera le cas. Vous retrouverez ici les informations de tracking.</div>
                                )
                            }
                        })()}

                        <p><Translate s='label_num_colis' /> : {((this.state.user_campaign.colis_num_suivi)?this.state.user_campaign.colis_num_suivi:<Translate s='label_non_renseigne' />)}</p>
				        <p><Translate s='label_transporteur' /> : {((this.state.user_campaign.colis_transporteur)?this.state.user_campaign.colis_transporteur:<Translate s='label_non_renseigne' />)}</p>
                        
				        <p><Translate s='label_date_envoi' /> : {((this.state.user_campaign.colis_date_envoie)?this.state.user_campaign.colis_date_envoie:<Translate s='label_non_renseigne' />)}</p>                        
                    </div>        
                </div>
            </div>
        )
    }  
}

export default DealPackage;