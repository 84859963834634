import React from 'react';
import Fetcher from './Helpers/Fetcher';

import logo from './Assets/img/logo_thin.png';
import {openTab,swipeTab,unmountHammer} from './Components/Tabs';

import Loading from './Components/Loading';
import Error from './Components/Error';
import Translate from './Helpers/Translate';

class Deals extends React.Component {  
    
    constructor(props) {
        super(props);
        
        this.state = {
            isLoaded: false,
            error:false,
            deals_open:{},
            deals_closed:{},
            bk_tabs_active:{
                open:'bk_tabs_active',
                closed:''
            }
        };   
    }
    componentDidMount(){
        //Init call
        this.fetchCampaignApi();
        //handle openned tab
        if(this.props.match.params.type === 'closed'){
            this.setState({  
                bk_tabs_active:{
                    open:'',
                    closed:'bk_tabs_active'
                }
            })
        }
    }
    componentDidUpdate(){
        swipeTab(this.state.isLoaded,'#bk_deals');
    }
    componentWillUnmount(){
        unmountHammer('#bk_deals');
    }
    fetchCampaignApi = () =>{
        
        new Fetcher().callApi({
            api_path:'/campaigns/list/deals',
            api_data:{
                "filter":''
            },
            method:'POST',
        }).then((r) => {
            if(r.status === 'success'){
                this.setState({
                    isLoaded: true,
                    deals_open: r.results.open,
                    deals_closed: r.results.closed,
                    
                })    
            }
            else{
                this.setState({
                    isLoaded: true,
                    error:true
                });  
            }
        });
    }  
    goToDeal(uid){
        this.props.history.push('/deal/'+uid);
    }
    render() {  
        return this.state.isLoaded ? (
            this.state.hasError ? <Error /> : this.renderPage()) : (       
                <Loading alwaysShow={false} />
        )
    }
    renderPage(){
        return (
            <div id="bk_deals" className="bg_blur h-100">
                <div className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 header text-center pt-5 pb-1">
                                <img className="mb-4" src={logo} alt="" width="150" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grey_dsktp_bg h-100">
                    <div className="container bg-white pt-md-2">
                        <div className="deals row"> 
                            <div className="col-12">
                                <div className="bk_tabs w3-black row">
                                    <button className={"col bk_tabs_item bk_tabs_button tablink "+this.state.bk_tabs_active.open} onClick={(e) => openTab(e,'open')}><Translate s="menu_fo_ongoing" /></button>
                                    <button className={"col bk_tabs_item bk_tabs_button tablink "+this.state.bk_tabs_active.closed} onClick={(e) => openTab(e,'closed')}><Translate s="menu_fo_done" /></button>
                                </div>

                                <div id="open" className="tabcontent min-vh-100 campaigns row gx-3 px-1" style={{display:this.state.bk_tabs_active.open === 'bk_tabs_active'?'':'none'}}>
                                
                                        {
                                        (this.state.deals_open)?
                                            Object.values(this.state.deals_open).map((iv,kv) => {
                                                    return (
                                                        
                                                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 mb-3 pointer"  key={kv} id={iv.id_campaign} onClick={() => this.goToDeal(iv.uid_campaign)}>
                                                            <img src={process.env.REACT_APP_SITE_URL+'/coffrefort/campaigns/'+iv.uid_campaign+'/thumbs/'+iv.image_url} className="bd-placeholder-img" alt={iv.campaign_name} />                                                
                                                    </div>
                                                    
                                                )
                                            })
                                        : <p><Translate s="lbl_no_deals" /></p>
                                        }
                                </div>

                                <div id="closed" className="tabcontent campaigns row gx-3" style={{display:this.state.bk_tabs_active.closed === 'bk_tabs_active'?'':'none'}}>
                                {
                                        (this.state.deals_closed)?
                                            Object.values(this.state.deals_closed).map((iv,kv) => {
                                                    return (
                                                        
                                                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 mb-2"  key={kv} id={iv.id_campaign} onClick={() => this.goToDeal(iv.uid_campaign)}>
                                                            <img src={process.env.REACT_APP_SITE_URL+'/coffrefort/campaigns/'+iv.uid_campaign+'/thumbs/'+iv.image_url} className="bd-placeholder-img"  alt="" />
                                                            <div className="stats">
                                                                <div className="data">
                                                                {(() => {
                                                                    if(iv.stats_reachs !== '0' && iv.stats_reachs !== null){
                                                                        return (
                                                                            <>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="12" cy="12" r="2"></circle><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path></svg>  Reach : {iv.stats_reachs}
                                                                            <br />
                                                                            </>
                                                                        )
                                                                    }
                                                                })()}
                                                                
                                                                {(() => {
                                                                    if(iv.likes !== '0' && iv.likes !== null){
                                                                        return (
                                                                            <>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572"></path></svg> Likes : {iv.likes}
                                                                            <br />
                                                                            </>
                                                                        )
                                                                    }
                                                                })()}
                                                            
                                                                
                                                                {(() => {
                                                                        if(iv.comments !== '0' && iv.comments !== null){
                                                                            return (
                                                                                <>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1" /><line x1="12" y1="12" x2="12" y2="12.01" /><line x1="8" y1="12" x2="8" y2="12.01" /><line x1="16" y1="12" x2="16" y2="12.01" /></svg> Comm. : {iv.comments}
                                                                                <br />
                                                                                </>
                                                                            )
                                                                        }
                                                                })()}
                                                                
                                                                
                                                                {(() => {
                                                                    if(iv.stats_engagements !== '0' && iv.stats_engagements !== null){
                                                                        return (
                                                                            <>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                            <path d="M5.931 6.936l1.275 4.249m5.607 5.609l4.251 1.275" />
                                                                            <path d="M11.683 12.317l5.759 -5.759" />
                                                                            <circle cx="5.5" cy="5.5" r="1.5" />
                                                                            <circle cx="18.5" cy="5.5" r="1.5" />
                                                                            <circle cx="18.5" cy="18.5" r="1.5" />
                                                                            <circle cx="8.5" cy="15.5" r="4.5" />
                                                                            </svg> Engag. : {iv.stats_engagements}
                                                                            <br />            
                                                                            </>
                                                                        )
                                                                    }
                                                                })()}

                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                    <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
                                                                </svg> Note : {iv.note_serieux === '0'?'-':iv.note_serieux}

                                                                </div>
                                                            </div>
                                                    </div>
                                                    
                                                )
                                            })
                                        : <p><Translate s="lbl_no_finished_deals" /></p>
                                        }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }  
}

export default Deals;