import React from 'react';
import { Preferences } from '@capacitor/preferences';

class Translate extends React.Component {  
    
    constructor(props) {
        super(props);
        this.state = {
            translation:''
        };   
    }
    componentDidMount(){
        this.getTranslation(this.props.s)
        .then((s) => {
                this.setState({
                    translation:s
                })
        })
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.s !== this.props.s) {           
            this.getTranslation(this.props.s)
            .then((s) => {
                    this.setState({
                        translation:s
                    })
            })
        }
    }
    async getTranslation(k){
        if(k === undefined) return '';
        const { value } =  await Preferences.get({key:'localisation_file'})
        const v = JSON.parse(value);
        if(v!== null && v[k] !== undefined) {
            if(this.props !== undefined && this.props.rep){
                let n=-1;
                const sprintf = v[k].replace(/%s/g, function() { //v[k].replace(/\%s/g, function() {
                    n=n+1;
                    return '{'+n+'}';
                });
                return <span dangerouslySetInnerHTML={{ __html: this.stringFormat(sprintf,this.props.rep)}} />
            }
            else return <span dangerouslySetInnerHTML={{ __html: v[k]}} />
        }
        else return k;  
    }
    stringFormat(s,args) {
        for (var i = 0; i < args.length; i++) {       
          var reg = new RegExp("\\{" + i + "\\}", "gm");             
          s = s.replace(reg, args[i]);
        }
      
        return s;
    }
    fetchTrans = async (s) =>{
        const r =  await this.getTranslation(s).then((s) => {return s});
        console.log(r);
        return r;
    }
    render() {  
        return (
            this.state.translation
        )
    }  
}

export default Translate;
