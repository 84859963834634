import React from 'react';
import {Link} from "react-router-dom";

import BackButton from "./Components/BackButton";

import CampaignApply from './CampaignApply';
import {AiOutlineLink} from 'react-icons/ai';
import Translate from './Helpers/Translate';

class CampaignBrand extends React.Component {  
    
    constructor(props) {
        super(props);
        this.state = {
            campaign: this.props.campaign_data, 
            user_campaign: this.props.user_campaign, 
            campaign_inspiration: ((this.props.campaign_data.campaign_inspiration !== null)?JSON.parse(this.props.campaign_data.campaign_inspiration):{}),
            readOnly:this.props.readOnly,
        };   
    }
    render() { 
        return (
            <div id="bk_campaign" className="container brand_page fixed_header  bg-white  px-md-0 min-vh-100">
                
                <div className="row header">
                    <div className="col px-0">
                        
                        <BackButton />
                        <div className="logo_marque" style={{'backgroundImage':"url("+process.env.REACT_APP_SITE_URL+'/coffrefort/brands/'+this.state.campaign.id_marque+'/'+this.state.campaign.logo_marque+")"}}></div>
                    </div>
                </div>

                <div className="row content mt-5 pb-7 px-md-5">
                    <div className="col-12 mt-2">
                        <h1 className="text-center text-andeslight font-14"><Translate s="app_campaign_brand" /></h1>
                        <h2 className="text-left text-andesmedium"><Translate s="app_lbl_brandpresentation" /></h2>
                        <div className="text-andeslight" dangerouslySetInnerHTML={{ __html: this.state.campaign.presentation_marque}} />
                        
                        <h2 className="text-left text-andesmedium mt-3"><Translate s="app_lbl_moreinspiration" /></h2>
                        <div className="col-12 mt-3 text-center text-pink text-andeslight">
                            {(this.state.campaign_inspiration.site_web)?<Link to={{ pathname: this.state.campaign_inspiration.site_web }} className="ico ico_site_web ico_big" target="_blank"></Link>:''}
                            {(this.state.campaign_inspiration.fb)?<Link to={{ pathname: this.state.campaign_inspiration.fb }} className="ico ico_fb ico_big" target="_blank"></Link>:''}
                            {(this.state.campaign_inspiration.yt)?<Link to={{ pathname: this.state.campaign_inspiration.yt }} className="ico ico_yt ico_big" target="_blank"></Link>:''}
                            {(this.state.campaign_inspiration.ig)?<Link to={{ pathname: this.state.campaign_inspiration.ig }} className="ico ico_ig ico_big" target="_blank"></Link>:''}
                            {(this.state.campaign_inspiration.lien)?<Link to={{ pathname: this.state.campaign_inspiration.lien }} className="ico ico_big ico_link" target="_blank"><AiOutlineLink /></Link>:''}


                        </div>

                    </div>
                </div>
                
            <CampaignApply readOnly={this.state.readOnly} uid_campaign={this.state.campaign.uid_campaign} campaign_status={this.state.campaign.status} user_campaign={this.state.user_campaign}  questions={this.state.campaign.extra_questions}/>

            </div>
        )
    }
}

export default CampaignBrand;