import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Dialog } from '@capacitor/dialog';

class BackButton extends Component {
    constructor(props) {
      super(props);
      this.state = {
        goBack:true,
        classes:((this.props.classes !== undefined)?this.props.classes:''),
      }
    }
    componentDidUpdate(previousProps, previousState){
      if (previousProps.unSavedData !== this.props.unSavedData) {
        this.setState({
          goBack:!this.props.unSavedData
        })
      }
    }
    checkFirst = async () => {
      if(!this.state.goBack) {
        const { value } = await Dialog.confirm({
            title: 'Données non enregistrées',
            message: `Êtes-vous sûr ? Vous perdrez toutes les données non enregistrées`,
        });
        if(value){    
          this.props.history.goBack();
        }
      }
      else this.props.history.goBack();
    }
    render() {
      return (
        <button className={"go_back_btn "+this.state.classes} onClick={() => this.checkFirst()}></button>
      )
    }
  }
export default withRouter(BackButton);