import React from 'react';

import logo from './Assets/img/logo_thin.png';
import Loading from './Components/Loading';
import {Link} from "react-router-dom";
import { getLocalStorageItem } from './Helpers/Storage';
import Fetcher from './Helpers/Fetcher';
import { Browser } from '@capacitor/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleDown } from '@fortawesome/free-regular-svg-icons'
import Translate from './Helpers/Translate';

class Campaigns extends React.Component {  
    
    constructor(props) {
        super(props);
        
        this.state = {
            isLoaded:false,
            showEndList:false,
            firstname: '',
            avatar:'dummy',
            internal_ad:false,
            campaigns:[],
            campaigns_tags:[],
            campaigns_tags_keys:[],
            page:0,
            prevY: 0,
            groups : [],
            uid_user:'',
            cat_influenceur_brand:false
        };   
    }

    componentDidMount(){
       getLocalStorageItem('bk_user_info')
        .then((ret) => JSON.parse(ret))
        .then((r) => {       
            const g = r.groups.map((e) => {
                 return parseInt(e.id)
            })
            this.setState({uid_user:r.uid_user})
            this.setState({groups:g});
            if(r.cat_influenceur !== false){
                const ci = JSON.parse(r.cat_influenceur.cat_influenceur) 
                this.setState({cat_influenceur_brand:ci.includes('marque')});
            }
        });
        this.fetchCampaignApi();
        var options = {
            root: null,
            rootMargin: "25%",
            threshold: 1.0
          };   
        this.observer = new IntersectionObserver(
            this.handleObserver.bind(this),
            options
        );
        this.observer.observe(this.loadingRef);
    }
    handleObserver(entities, observer) {
        const y = entities[0].boundingClientRect.y;
        if (this.state.prevY > y && this.state.isLoaded) {
          //const lastCampaign = this.state.campaigns[this.state.campaigns.length - 1];
          const curPage = this.state.page+1;
          this.setState(
              {page: curPage,isLoaded: false }, 
              () => this.fetchCampaignApi()
            );
        }
        this.setState({ prevY: y });
      }
    fetchCampaignApi = async () => {
        const r = await new Fetcher().callApi({
            api_path:'/campaigns/list',
            api_data:{
                "page": this.state.page
            },
            method:'POST',
        })
        if(r.status === 'success'){
            if(r.results === false){
                this.setState({
                    isLoaded: true,
                    showEndList:true
                }); 
            }
            else {
                this.setState({
                    isLoaded: true,
                    campaigns: [...this.state.campaigns, ...r.results],
                    internal_ad:r.internal_ad       
                }, () => {
                    //manage height viewport to trigger insertersection observerv
                    if(document.body.scrollHeight <= window.innerHeight) {
                        this.setState({page:1},() => this.fetchCampaignApi())
                    }
                }); 
            }
        }
        else{
            this.setState({
                isLoaded: true,
                error:true
            });  
        }
        
    }  
    goToCampagne(uid, status){
        if(status === 'live') this.props.history.push('/campaign/'+uid);
    }
    /*applyfilter(e,filter){
        //drop old filters
        const filters = document.getElementsByClassName('filter');
        [].forEach.call(filters, function(el) {
            el.classList.remove("f_active");
        });

        e.currentTarget.classList.add('f_active');
        this.fetchCampaignApi(this.state.token,filter);
    }*/
    openCapacitorSite = async (url) => {
        //console.log(url);
        await Browser.open({ url: url });
      };
    render() { 
        let $active = '';
        return (
            <div id="bk_campaigns" className="bg_blur h-100 pb-5">
                <div className="bg-white">
                    <div className="container">
                        { /*<div className="row px-3 pt-5">
                            <div className="col-8">
                                <h2 className="header_title text-andesmedium mb-0">Hello {this.state.firstname}</h2>
                                <p className="text-andeslight">Bilbokid</p>
                            </div>
                            <Link to={{pathname : "/account"}} className="col-4 text-end">{(this.state.avatar == 'dummy')?<svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" width="40" height="40"><mask id="mask__beam" maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36"><rect width="36" height="36" fill="white" rx="72"></rect></mask><g mask="url(#mask__beam)"><rect width="36" height="36" fill="#4691c5"></rect><rect x="0" y="0" width="36" height="36" transform="translate(-2 6) rotate(206 18 18) scale(1.2)" fill="#6fc5c4" rx="36"></rect><g transform="translate(-6 2) rotate(-6 18 18)"><path d="M13,21 a1,0.75 0 0,0 10,0" fill="black"></path><rect x="13" y="14" width="1.5" height="2" rx="1" stroke="none" fill="black"></rect><rect x="21" y="14" width="1.5" height="2" rx="1" stroke="none" fill="black"></rect></g></g></svg>:<img src={this.state.avatar} />}</Link>
                            
                            <div className="row px-1 pt-4"> 
                                <div className="col-12"></div>
                            </div>
                        </div>
                        
                        <div className="row px-3">
                            <div className="col-12">
                                <h2 className="header_title text-andesbold mb-3">Campagnes</h2>
                            </div>
                        </div>
                        
                        <div className="ps-3">
                            <div className="mt-1 mb-4 filters">
                                <span className="filter f_active" onClick={(e) => this.applyfilter(e,'')}>Tous</span>    
                                {
                                this.state.campaigns_tags.map((iv,kv) => {
                                    return (
                                            
                                        <span key={"filter_"+this.state.campaigns_tags_keys[kv]} className="filter" onClick={(e) => this.applyfilter(e,this.state.campaigns_tags_keys[kv])}>{iv}</span>
                                    )
                                })}

                            </div>
                            </div> */}
                             {(() => {
                                    if(this.state.groups.includes(3) || (this.state.groups.includes(2) && this.state.cat_influenceur_brand)) { 
                                        return ( 
                                            <div class="d-flex justify-content-between">
                                                <div className="col d-none d-md-block"></div>
                
                                                    <div className="col header text-center pt-5 pb-1">
                                                        <img className="mb-4" src={logo} alt="" width="150" />
                                                    </div>
                                                    <div className="col pt-5 pb-1 text-end d-none d-md-block">
                                                        <div class="dropdown">
                                                            <button class="btn btn-outline-orange px-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <Translate s="cta_espace_publisher" />
                                                                <FontAwesomeIcon icon={faArrowAltCircleDown} className="circle" />
                                                            </button>
                                                            <ul class="dropdown-menu simple-drop">
                                                            {this.state.groups.includes(3) && <li><button className="btn-text btn-link text-no-underline" onClick={() => this.openCapacitorSite(process.env.REACT_APP_SITE_URL+'/brand/campaigns/')}><Translate s="cta_espace_annonceur" /></button></li>}
                                                            {!this.state.groups.includes(3) && <li><button className="btn-text btn-link text-no-underline" onClick={() => this.openCapacitorSite(process.env.REACT_APP_SITE_URL+'/fr/auth/login?onboarding=brand&uid_user='+this.state.uid_user)}><Translate s="cta_espace_annonceur" /></button></li>}
                                                            </ul> 
                                                        </div>
                                                    </div>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div class="d-flex justify-content-between">
                                                <div className="col"></div>
                                                    <div className="col header text-center pt-5 pb-1">
                                                        <img className="mb-4" src={logo} alt="" width="150" />
                                                    </div>
                                                    <div className="col">
                                                    </div>
                                            </div>
                                        )
                                    }
                                })()}


                    </div>
                </div>
                
                <div className="grey_dsktp_bg">
                    <div className="container">
                                     

                        <div className="campaigns row gx-3 px-1">
                        {(() => {
                            if(this.state.internal_ad){
                                return(
                                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 mb-3">
                                        <Link to={"/ad"}>
                                            <img src={process.env.REACT_APP_SITE_URL+'/coffrefort/ad/'+this.state.internal_ad.ad_image} alt="ad" className="bd-placeholder-img" />
                                        </Link>
                                    </div>
                                )
                            }
                        })()}             

                            {Object.values(this.state.campaigns).map((iv,kv) => {
                                    if(iv.status === 'live') $active = 'pointer';
                                    else if(iv.status_campaign_user === 'non_valide') $active = 'pointer';
                                    else $active = 'grayscale';
                                    return (
                                        
                                    <div className={"col-6 col-md-4 col-lg-4 col-xl-3 mb-3 "+$active}  key={kv} id={iv.id_campaign} onClick={() => this.goToCampagne(iv.uid_campaign,iv.status)}>
                                            <img src={process.env.REACT_APP_SITE_URL+'/coffrefort/campaigns/'+iv.uid_campaign+'/thumbs/'+iv.image_url} alt={iv.campaign_name} className="bd-placeholder-img" />                                
                                    </div>
                                    
                                )
                            })}
                            {
                            (this.state.showEndList &&
                            <div className="showEndList alert alert-danger mt-3">
                                Fin des campagnes
                            </div>
                            )}
                            <div 
                                ref={loadingRef => (this.loadingRef = loadingRef)}
                                className="mb-5"
                            >{
                                (!this.state.isLoaded &&
                                <div className={"page_"+this.state.page}>
                                    <Loading alwaysShow={true} />
                                </div>
                                )}
                            </div>
                            
                        </div>
                    </div>
                </div>
                        
            </div>
        )
    }  
}

export default Campaigns;