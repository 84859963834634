import React from 'react';
import {Link} from "react-router-dom";

import Translate from '../../Helpers/Translate';
import logo from '../../Assets/img/default_logo.png';
import orange_arrow from '../../Assets/img/orange_arrow.png';

import { HiOutlineMail,HiLockClosed, HiOutlinePhone } from 'react-icons/hi';
//import {isValidDate} from './Helpers/Formvalidation';
import { showToast,showDomError } from '../../Helpers/Toast';
import { getLocalStorageItem} from '../../Helpers/Storage';

import { Device } from '@capacitor/device';
import { Browser } from '@capacitor/browser';

import {renderTranslationButtons} from '../../Helpers/Translation';
import {genRadioInline} from '../../Forms/GenRadiogroupBoxes';
import BackButton from '../../Components/BackButton';

import Select from 'react-select'


class RegisterBrand extends React.Component {  
    
    constructor(props) {
        super(props);
        
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        
        this.state = {
            toastState: false,
            toastMessage: 'Message',
            formFields: {first_name:'',last_name:'',company:'',main_sector:'',groups:'',email: '', phone:'', password: '',password_check:'',cgu:''},
            formErrors: {first_name:'',last_name:'',company:'',main_sector:'',groups:'',email: '', phone:'',password: '',password_check:'',cgu:''},
            formErrorsMessages: {first_name:'',last_name:'',main_sector:'',groups:'',company:'',email: '', phone:'',password: '',password_check:'',cgu:''},
            app_langage:'',
            user_country_code: 'FR',
            pendingCheckEmail: false,
            sectors: ['edition_presse','materiel_educatif','loisirs_creatifs','eveil_jeux','hygiene_soins','loisirs_sorties','equipement','hightech','textile','alimentation','sante','bijoux','film_animation','decoration','sport', 'services', 'autres']
        };   
    }
    componentDidMount(){
        this.getLocalisationLang();
    }
    handleChange(e,n = '') {
        let name,value;
        if(e.target === undefined) {
            name = n;
            value = e.value;
        }
        else { 
            name = e.target.name;
            value =  e.target.type === 'checkbox' ? e.target.checked : e.target.value;  
        }

        this.setState({
            formFields:{
                ...this.state.formFields,
                [name]: value,
            },
            formErrors : {
                ...this.state.formErrors,
                [name]:''
            }
        });
    }
    errorClass(error) {
        return(error.length === 0 ? '' : 'is-invalid');
    }
    handleSubmit() {
        //Chech data
        if(this.validateForm()){
            this.register();
        }        

    }
    validateForm() {   
        let fieldValidationErrors = this.state.formErrors;
        let formErrorsMessages = this.state.formErrorsMessages;
        let formIsValid = true;        
        Object.keys(this.state.formFields).forEach(fieldName => {
            var value = this.state.formFields[fieldName];
            if(fieldName === 'first_name' || fieldName === 'last_name' || fieldName === 'company' ||  fieldName === 'phone' || fieldName === 'main_sector'){
                console.log(fieldName,value);
                let textValid = value.length >= 1;
                fieldValidationErrors[fieldName] = textValid ? '' : 'error';
                if(!textValid) {
                    formIsValid = false;
                    formErrorsMessages[fieldName] = <Translate s="app_err_mandatoryfield" />;
                }
            }
            if(fieldName === 'email'){
                let emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                fieldValidationErrors.email = emailValid ? '' : 'error';
                if(!emailValid) {
                    formIsValid = false;
                    formErrorsMessages.email = <Translate s="app_err_email" />;
                }
            }
            if(fieldName === 'password'){
                const pwd_rule = new RegExp('^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$','i');
                //console.log(!pwd_rule.test(value));
                if(!pwd_rule.test(value)) {
                    formIsValid = false;
                    fieldValidationErrors.password = 'error';
                    formErrorsMessages.password = <Translate s="app_err_pwdrule" />
                }
                else {
                    fieldValidationErrors.password = '';
                }
            }
            if(fieldName === 'password_check'){
                const passwordValid = this.state.formFields.password;;
                let passwordCheckValid = value; //(!value.trim().length)?false:true;
                //fieldValidationErrors.password_check = passwordCheckValid ? '': 'error';
                if(passwordCheckValid !== passwordValid) {
                    formIsValid = false;
                    fieldValidationErrors.password_check = 'error';
                    formErrorsMessages.password_check = <Translate s="app_err_pwdcheck" />
                }
                else fieldValidationErrors.password_check = ''
            }
            if(fieldName === 'cgu'|| fieldName === 'groups'){
                console.log(value);
                let cguValid = (!value)?false:true;
                fieldValidationErrors[fieldName] = cguValid ? '' : 'error';
                if(!cguValid) {
                    formIsValid = false;
                    formErrorsMessages[fieldName] = <Translate s="app_err_mandatoryfield" />;
                }
            }
          })
          this.setState({
            formErrors: fieldValidationErrors,
            formErrorsMessages:formErrorsMessages,
        });
          return formIsValid;
    }
    logDeviceInfo = async () => {
        const info = await Device.getInfo();
        return info.platform;
    }
    register = () => {
        let url , credentials;     
        url = process.env.REACT_APP_API_URL + '/auth/register/brand';
        credentials = {
            "first_name": this.state.formFields.first_name,
            "last_name": this.state.formFields.last_name,
            "company": this.state.formFields.company,
            "email": this.state.formFields.email,
            "password": this.state.formFields.password,
            "password_check": this.state.formFields.password_check,
            "cgu": this.state.formFields.cgu,
            "phone": this.state.formFields.phone,
            "main_sector": this.state.formFields.main_sector,
            "groups": this.state.formFields.groups,
            "user_display_lang": this.state.app_langage
        }
        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            body: JSON.stringify(credentials)
        })
        .then((res) => {
            if(res.status === 200) return res.json();
            else showToast("Error Logging in");            
        })
        .then(
            (result) => {
                if(result.status === 'error') showToast(result.message);
                else if(result.status === 'error_dom') {
                    showDomError(result.message);
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;

                }
                else this.setState({ pendingCheckEmail: true});
            },
            (error) => {        
                showToast(error.toString());
            }
        )
    }
    openCapacitorSite = async (url) => {
      await Browser.open({ url: url });
    }
    getLocalisationLang = async () => {
        //This fetch local storage lang to set flag as active   
        const localisation_lang = await getLocalStorageItem('localisation_lang');
        let cc = 'FR'; 
        if(localisation_lang == 'french') cc = 'FR';
        if(localisation_lang == 'english') cc =  'EN';
    
        this.setState({
            app_langage:localisation_lang,
            user_country_code : cc
        })
    }
    render() { 
        return this.state.pendingCheckEmail ? this.renderCheckEmail() : this.renderForm();      
    } 
    renderCheckEmail() {  
        return (
            
            <div id="bk_register" className="bg_blur h-100 container v-center">
                <main className="form-signin">
                        <img className="mb-4" src={logo} alt="" width="90" />
                        <p className="h6 mb-4 fw-normal"><Translate s='auth_register_confirmation' /></p>
                        <Link to={'/login'}><Translate s="auth_login_cta" /></Link>
                </main>
            </div>
        )
    }
    renderForm() {  
        return (
            <div id="bk_register" className="bg_blur bg-primary container">
                <div className="row">
                    <div className="col-12 col-sm-6 text-white my-5 px-4 order-2 order-sm-1 text-start"> 
                        <p className="text-andesbold text-uppercase font-weight-bold py-1"><Translate s="auth_brand_register_title" /></p>
                        <p className="font-weight-bold"><Translate s='auth_brand_register_desc' /></p>
                        <ul className="signin_icos">
                            <li className="ico_round_notoriete"><Translate s="auth_brand_register_1" /></li>
                            <li className="ico_round_stars"><Translate s="auth_brand_register_2" /></li>
                            <li className="ico_round_camera_pink"><Translate s="auth_brand_register_3" /></li>
                        </ul>
                        <p><Translate s="auth_brand_register_4" /></p>
                        <br />
                        <div className="text-center">
                            <p className="text-white font-weight-bold"><Translate s="auth_brand_discuss" /></p>
                            <a href="https://www.bilbokid.com/contact" className="btn btn-white text-uppercase" target="_blank"><Translate s="auth_brand_askdemo" /></a>
                        </div>
                </div>

                <div className="col-12 col-sm-6 bg-white pt-5 px-3  order-1 order-sm-2">
                    <main className="form-signin">
                            <div className="header row pt-4">
                                <div className="col text-center">
                                    <BackButton classes="on_white" />
                                    <img className="my-4" src={logo} alt="" width="90" />
                                </div>
                            </div>

                            <h1 className="h3 mb-4 fw-normal"><Translate s='auth_register_brand_title' /></h1>
                            <div id="error_dom" className="alert alert-danger text-start d-none"></div>
                            <div className="form-floating mt-3">
                                <input type="text" placeholder="first_name" className={"form-control "+this.errorClass(this.state.formErrors.first_name)} id="first_name" name="first_name" value={this.state.formFields.first_name}  onChange={this.handleChange} />
                                <label htmlFor="first_name"><Translate s="profil_info_firstname" /></label>
                                <div className="invalid-feedback text-start mt-1">{this.state.formErrorsMessages.first_name}</div>
                            </div>
                            <div className="form-floating mt-3">
                                <input type="text" placeholder="last_name" className={"form-control "+this.errorClass(this.state.formErrors.last_name)} id="last_name" name="last_name" value={this.state.formFields.last_name}  onChange={this.handleChange} />
                                <label htmlFor="last_name"><Translate s="profil_info_lastname" /></label>
                                <div className="invalid-feedback text-start mt-1">{this.state.formErrorsMessages.last_name}</div>
                            </div>
                            <div className="form-floating mt-3">
                                <input type="text" placeholder="company" className={"form-control "+this.errorClass(this.state.formErrors.company)} id="company" name="company" value={this.state.formFields.company}  onChange={this.handleChange} />
                                <label htmlFor="company"><Translate s="auth_company" /></label>
                                <div className="invalid-feedback text-start mt-1">{this.state.formErrorsMessages.company}</div>
                            </div>

                            <div className="row justify-content-center my-3"> 
                                <Select 
                                    name="main_sector"
                                    placeholder={<Translate s={'auth_main_sector'} />}
                                    className={"col-12 "+this.errorClass(this.state.formErrors.main_sector)}
                                    classNamePrefix="react-select"
                                    onChange={(e) => this.handleChange(e,'main_sector')}
                                    options={
                                        this.state.sectors.map(v => { 
                                        return {
                                            'label':<Translate s={'campaign_tag_'+v} />,
                                            'value':  v
                                        }})  
                                    } />
                                    <div className="invalid-feedback text-start">{this.state.formErrorsMessages.main_sector}</div>
                            </div>
                            <div className="row justify-content-center my-3"> 
                                <div className="col-12 my-3">
                                    {genRadioInline({
                                        label:'',
                                        name:'groups',
                                        defaultValue:this.state.formFields.groups,
                                        onChangeAction:(e) => this.handleChange(e),
                                        required:true,
                                        class:this.errorClass(this.state.formErrors.groups),
                                        data:[{    
                                            key:'3',
                                            value:<Translate s='auth_role_advertiser' />
                                        },{
                                            key:'4',
                                            value:<Translate s='auth_role_agency' />
                                        }]
                                    })}
                                </div>
                            </div>
                            
                            <div className="form-floating mt-3">
                                <input type="text" placeholder="phone" className={"form-control "+this.errorClass(this.state.formErrors.phone)} id="phone" name="phone" value={this.state.formFields.phone}  onChange={this.handleChange} />
                                <label htmlFor="phone"><HiOutlinePhone /> <Translate s="profil_info_phone" /></label>
                                <div className="invalid-feedback text-start mt-1">{this.state.formErrorsMessages.phone}</div>
                            </div>
                            <div className="form-floating mt-3">
                                <input type="email" className={"form-control "+this.errorClass(this.state.formErrors.email)} id="email" name="email" placeholder="name@example.com" value={this.state.formFields.email}  onChange={this.handleChange} />
                                <label htmlFor="email"><HiOutlineMail /> <Translate s="auth_email" /></label>
                                <div className="invalid-feedback text-start mt-1">{this.state.formErrorsMessages.email}</div>
                            </div>
                            <div className="form-floating mt-3">
                                <input type="password" className={"form-control "+this.errorClass(this.state.formErrors.password)} id="password" name="password" placeholder="Password" value={this.state.formFields.password}  onChange={this.handleChange} />
                                <label htmlFor="password"><HiLockClosed /> <Translate s="auth_pwd" /></label>
                                <div className="invalid-feedback  text-start">{this.state.formErrorsMessages.password}</div>
                            </div>
                            <div className="form-floating mt-3">
                                <input type="password" className={"form-control "+this.errorClass(this.state.formErrors.password_check)} id="password_check" name="password_check" placeholder="Confirm Password" value={this.state.formFields.password_check}  onChange={this.handleChange} />
                                <label htmlFor="password_check"><HiLockClosed /> <Translate s="auth_pwd_confirm" /></label>
                                <div className="invalid-feedback text-start">{this.state.formErrorsMessages.password_check}</div>
                            </div>

                            <div className="checkbox my-3 text-start">
                                <label>
                                    <input type="checkbox" name="cgu" id="cgu" value="1" className={"form-check-input "+this.errorClass(this.state.formErrors.cgu)} onChange={this.handleChange} /> <Translate s='auth_register_cgu'   />  <a href={process.env.REACT_APP_SITE_URL+'/coffrefort/cgu/'+this.state.user_country_code+'-cgv-cgu-annonceurs-bilbokid.pdf'} target="_blank" class="cta_orange"><Translate s='auth_cgu_annonceur' /></a>
                                    <div className="invalid-feedback text-start pt-3">{this.state.formErrorsMessages.cgu}</div>
                                </label>
                            </div>

                            <button className="mt-2 w-100 btn btn-lg btn-primary" onClick={() => this.handleSubmit()}><Translate s="auth_register_account_cta" /></button>

                            <p className="mt-4 mb-3">
                                <Link to={'/login'} className="cta_orange cta_bordered_orange text-andesmedium" >
                                    <Translate s="auth_cta_alreadyaccount_1" /> 
                                    <img src={orange_arrow} alt="" width="25" className="d-inline-block align-top mx-2" />
                                    <Translate s="auth_cta_alreadyaccount_2" />
                                </Link>
                            </p>
                            <p className='mt-4 mb-3'>
                            {renderTranslationButtons(this.state.app_langage)}
                            </p>

                            <p className="text-center footer_links"><a href="mailto:contact@bilbokid.com"><Translate s='auth_contact_us' /></a> - <a href={process.env.REACT_APP_SITE_URL+'/coffrefort/cgu/'+this.state.user_country_code+'-'+'politique-donnees-personnelles-bilbokid-2020.pdf'} target="_blank"><Translate s='auth_data_policy' /></a></p>
                    </main>
                </div>
            </div>
        </div>
        )
    }  
}

export default RegisterBrand;