import Translate from '../Helpers/Translate';
export const genCheckgroupBoxes = (d) => {
    return (
        <>
            <label class="form-label"><Translate s={d.label} /></label>
            <div className="form-selectgroup form-selectgroup-boxes d-flex flex-column">
                {
                    Object.values(d.data).map((vd,kd) => {
                        let checked = false;
                        //console.log(vd.key); 
                        if(Object.keys(d.defaultValue).length > 0 && d.defaultValue.includes(vd.key) === true) checked = true;
                        return (
                            <label key={d.name+vd.key} className={"form-selectgroup-item flex-fill "+(d.class !== undefined?d.class:'')}>
                                <input 
                                    type="checkbox"
                                    name={d.name} 
                                    value={vd.key} 
                                    className={"form-selectgroup-input "+(d.class !== undefined?d.class:'')}
                                    checked={checked} 
                                    onChange={(e) => d.onChangeAction(e)} 
                                    data-is_required={(d.required !== undefined?d.required:false)} 
                                    data-validation={(d.validation !== undefined)?d.validation:null}
                                />
                                <div className="form-selectgroup-label d-flex align-items-center p-3">
                                    <div className="me-3">
                                        <span className="form-selectgroup-check"></span>
                                    </div>
                                    <div>
                                        <span className="payment payment-provider-visa payment-xs me-2"></span>
                                        {vd.value}
                                    </div>
                                </div>
                            </label>
                        )
                    })
                }
                <div className="invalid-feedback"><Translate s="app_err_mandatoryfield" /></div>
            </div>
        </>
    )
}