export const check_inf_cat = (haystack, needle) => {
  let c = false;
  for (const [k, v] of Object.entries(haystack)) {
    if(v == needle) c = true;
  }
  return c;

}


export const CampaignStatus = (status) => {
  let $color = '';
  switch (status) {
    case 'draft':
      $color = 'red'
      break;
    case 'approval_demand':
      $color = 'red'
      break;
    case 'approved':
      $color = 'pink'
      break;
    case 'live':
      $color = 'light_green'
      break;
    case 'paused':
      $color = 'light_grey'
      break;
    case 'archived':
      $color = 'light_grey'
      break;
    case 'done':
      $color = 'light_grey'
      break;     
    default:
      $color = '';
      break;
  }
  return $color;
}

export const CampaignUserStatus = (status) => {
    let $txt = '';
    switch (status) {
      case 'candidature_en_cours':
        $txt = 'candidature_en_cours'
        break;
      case 'refus':
        $txt = 'refus'
        break;
      case 'non_valide':
        $txt = 'program_status_encours'
        break;
      case 'attente':
        $txt = 'program_status_encours'
        break;
      case 'valide':
        $txt = 'program_status_valide'
        break;
      case 'valide_colis_a_envoyer':
        $txt = 'program_status_colis_envoye'
        break;
      case 'colis_envoye':
        $txt = 'program_status_colis_envoye_ter'
        break;
      case 'colis_non_recu':
        $txt = 'program_status_colis_non_recu_notification'
        break;
      case 'deal_en_cours':
        $txt = 'program_status_deal_en_cours'
        break;
      case 'cloturer':
        $txt = 'closed'
        break;
      case 'programme_annule':
        $txt = 'programme_annule'
        break;
      default:
        $txt = '';
        break;
    }
    return $txt;
  }

 export const truncateText = (text, length) => {
    if (text.length <= length) {
      return text;
    }
  
    return text.substr(0, length) + '\u2026'
  }

  export const showVatField = (cc = 'FR', vat = 'vat') => {
    //TVA not mandatory for FR but is for all EU countries
    //
    const region_code_eur = ["AT","BE","HR","BG","CY","CZ","DK","EE","FI","FR","DE","GR","HU","IE","IT","LV","LT","LU","MT","NL","PL","PT","RO","SK","SI","ES","SE","GB"]
    if(
      (cc !== 'FR' && region_code_eur.includes(cc)) 
      || 
      (cc === 'FR' && vat === 'no_vat')
    ) return true;
    else return false; 
  }

  export const dateTimeToFrDate = (s) =>{
    var d = new Date(s); 
    return d.toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric'}) 
  }

  export const infDisplayNickname = (um,u,s = 25) => {
    if(um.ig_username !== undefined && um.ig_username.trim() != '') return truncateText(um.ig_username,s);
    else if(um.username_facebook !== undefined && um.username_facebook.trim() != '') return truncateText(um.username_facebook,s);
    else if(um.nom_blog  !== undefined && um.nom_blog.trim() != '') return truncateText(um.nom_blog,s);
    else return truncateText(u.first_name,s);
  }