import React from 'react';

import BackButton from "./Components/BackButton";

import CampaignApply from './CampaignApply';

//import {t} from './Helpers/Localisation';
import Translate from './Helpers/Translate';

class CampaignBrief extends React.Component {  
    
    constructor(props) {
        super(props);
        this.state = {
            campaign: this.props.campaign_data, 
            conditions: ((this.props.campaign_data.conditions !== null)?JSON.parse(this.props.campaign_data.conditions):{}),
            user_campaign: this.props.user_campaign, 
            contrepartie: JSON.parse(this.props.campaign_data.campaign_contrepartie), 
            readOnly:this.props.readOnly,
        };  
    }
    componentDidMount(){
        /*t('txt_30_day_delay').then((s) => {
            this.setState({
                'txt_30_day_delay': s
            })
        })*/
    }
    remGrille = (des) => {
        return des.split('\n').map((item, key) => {
            if(item !== '') return <li key={key}>{item}</li>
        })        
    }
    render() { 
        const date_but =new Date(this.state.campaign.eta_date).toLocaleDateString();
        return (
            <div id="bk_campaign" className="container brand_page fixed_header bg-white  px-md-0 min-vh-100">
                
                <div className="row header">
                    <div className="col px-0">
                        
                        <BackButton />
                        <div className="logo_marque" style={{'backgroundImage':"url("+process.env.REACT_APP_SITE_URL+'/coffrefort/brands/'+this.state.campaign.id_marque+'/'+this.state.campaign.logo_marque+")"}}></div>
                    </div>
                </div>

                <div className="row content mt-5 pb-7  px-md-5">
                    <div className="col-12 mt-2">

                        <h1 className="text-center text-andeslight font-14"><Translate s="app_campaign_brief" /></h1>
                        {(() => {
                            if(this.state.campaign.eta_date !== null) {
                                return (
                                    <>
                                        <div className="text-center my-4 py-3 border-top-bottom">
                                            <Translate s="lbl_eta_date" /><br />
                                            {date_but}
                                        </div>
                                    </>
                                )
                            }
                            else {
                                if(this.state.campaign.campaign_type !== 7) {
                                    return (
                                    <div className="alert alert-warning"><Translate s="txt_30_day_delay_butoir" /></div>)
                                }   else {
                                  return (
                                    <div className="alert alert-warning"><Translate s="txt_15_day_delay_butoir" /></div>
                                  )
                                }
                            }
                        })()}
                        


                        <h2 className="text-left text-andesmedium"><Translate s="app_lbl_conditions" /></h2>
                        <ul className="text text-andeslight">
                        {Object.values(this.state.conditions).map((iv,kv) => {
                            return (       
                              <li  key={kv} dangerouslySetInnerHTML={{ __html: iv}} />
                            )
                        })}
             
                        {(() => {
                        if(this.state.campaign.campaign_hashtag !== null && this.state.campaign.campaign_hashtag !== '') {
                            return (
                                <li><Translate s='txt_campaign_hashtag_desc' rep={[this.state.campaign.campaign_hashtag]} /></li>
                                )
                        } 
                        })()}
             
                        {(() => {
                        if(this.state.user_campaign.status === "deal_en_cours") {
                            return (
                                <li><Translate s='txt_campaign_insertionstats_desc' /></li>
                                )
                        } 
                        })()}

                        </ul>
                        {(() => {
                            if(this.state.contrepartie){
                                const contrepartie_keys = Object.keys(this.state.contrepartie);
                                return(
                                    <>
                                    <h2 className="text-left text-andesmedium"><Translate s="app_lbl_conterpart" /></h2>
                                    <ul className="text text-andeslight">
                                    {Object.values(this.state.contrepartie).map((iv,kv) => {
                                            if(contrepartie_keys[kv] === 'product_donation' || contrepartie_keys[kv] === 'product_sample' || contrepartie_keys[kv] === 'mutual_exchange'){
                                                return  <li key={kv}><Translate s={"label_campaign_contrepartie_"+contrepartie_keys[kv]} /> : {iv.designation} {iv.value !== '' && iv.value !== undefined?'(valeur de '+iv.value+' € TTC)':''}</li>
                                            }
                                            if(contrepartie_keys[kv] === 'rem'){
                                                return  <li key={kv}><Translate s={"label_campaign_contrepartie_"+contrepartie_keys[kv]} /> : {iv.value !==''?iv.value+' € HT':''}</li>
                                            }
                                            if(contrepartie_keys[kv] === 'rem_grille'){
                                                 return this.remGrille(iv.designation);
                                                  
                                            }
                                            if(contrepartie_keys[kv] === 'rem_coupon' || contrepartie_keys[kv] === 'rem_gift' || contrepartie_keys[kv] === 'expenses'){
                                                return  <li key={kv}><Translate s={"label_campaign_contrepartie_"+contrepartie_keys[kv]} /> : {iv.value !==''?iv.value+' € TTC':''}</li>
                                            }
                                    })}
                                    </ul>
                                    </>
                            ) 
                            }
                        })()}
                        <div className="row mt-4 mb-3 mx-1">
		                    <div className="col-12 text">
                                <span className="bico ico_exclamation s15 dvalign"></span> &nbsp;&nbsp; <u className="dvalign"><Translate s="lbl_important" /></u><br />
                                <p className="text-justify"><Translate s="txt_reseau_mention" /></p>
                                <p className="text-justify text-italic"><Translate s="txt_collaboration_annonceur" /></p>
                            </div>
                        </div>
                        
                        
                    </div>
                </div>

                
                <CampaignApply readOnly={this.state.readOnly} uid_campaign={this.state.campaign.uid_campaign} campaign_status={this.state.campaign.status} user_campaign={this.state.user_campaign}  questions={this.state.campaign.extra_questions}/>

            </div>
        )
    }
}

export default CampaignBrief;