import React from 'react';
import loading_gif from '../Assets/img/loading.gif';

class Loading extends React.Component {  
    constructor(props) {
      super(props);
      this.state = {
        okdisplay:(!this.props.alwaysShow)?false:true,
        heading:(this.props.heading === undefined)?false:this.props.heading
      }
    }
    componentDidMount(){
        if(!this.props.alwaysShow){
            this.timerHandle = setTimeout(() => {
                this.setState({
                    okdisplay:true,
                    heading:this.state.heading
                });  
                this.timerHandle = 0;   
            }, 750);
        }
    } 
    componentWillUnmount = () => {            
        if (this.timerHandle) {               
            clearTimeout(this.timerHandle);      
            this.timerHandle = 0;                
        }                                        
    }   
    render() {  
        if(this.state.okdisplay){
            return (
                <div className="container h-100">
                    <div className="d-flex h-100 justify-content-center align-items-center">
                        <div className="text-center">
                            <img src={loading_gif} alt="loading..." />
                            {this.state.heading && <p className="fs-7">{this.state.heading}</p>}
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <></>
            )
        }
    }  
}

export default Loading;