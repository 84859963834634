import React from 'react';
import {Link} from "react-router-dom";
import Fetcher from './Helpers/Fetcher';

import { gen_security_key } from './Helpers/Security';

import Translate from './Helpers/Translate';
import {CampaignUserStatus} from './Helpers/Global';
import {AiOutlineLink} from 'react-icons/ai';


import Loading from './Components/Loading';
import DealPartnership from './DealPartnership';
import DealPartnershipInsight from './DealPartnershipInsight';
import DealPackage from './DealPackage';
import DealOpinion from './DealOpinion';
import DealInvoice from './DealInvoice';
import DealInvoiceHorsFrance from './DealInvoiceHorsFrance';
import DealEvaluation from './DealEvaluation';

import { showToast } from './Helpers/Toast';

class Deal extends React.Component {  
    
    constructor(props) {
        super(props);
        this.btnOpinion = React.createRef();
        this.btnInvoice = React.createRef();
        this.btnPartnership = React.createRef();
        this.btnPartnershipInsight = React.createRef();
        
        this.state = {
            uid: '',
            isLoaded: false,
            readOnly:false,
            errorDisplay:'d-none', 
            campaign:{},
            user_posts:{},
            user_data:{},
            user_meta:{},
            user_campaign:{},
            user_campaign_meta:{},
            campaign_images:{},
            campaign_inspiration:{}
        };   
    }
    componentDidMount() {
        const uid = this.props.match.params.uid;

        this.setState({
            uid:uid
        }, () => this.getCampagne());                  
      
    }   
    componentDidUpdate(previousProps, previousState){;
        //reload the page data to get fresh data from server
        if (previousProps.location.pathname !== this.props.location.pathname && this.props.history.action === 'POP') {
            const uid = this.props.match.params.uid;
            this.getCampagne(this.state.token,uid);
        }
    }
    getCampagne(){ 
        new Fetcher().callApi({
            api_path:'/campaigns/get/'+this.state.uid,
            api_data:{},
            method:'GET',
        }).then((r) => {
            if(r.status === 'success'){
                if(!r.results.user_campaign) {
                    //redirect to /campaign/uid
                    this.props.history.replace('/campaign/'+this.state.uid);
                }
                this.setState({
                    isLoaded: true,
                    user_campaign: r.results.user_campaign,
                    user_meta: r.results.user_meta,     
                    user_data: r.results.user_data,     
                    user_posts:r.results.user_posts,      
                    brand_meta:r.results.brand_meta,   
                    user_campaign_meta:r.results.user_campaign_meta,      
                    campaign: r.results.campaign,
                    campaign_images: r.results.images,
                    campaign_inspiration: ((r.results.campaign.campaign_inspiration !== null)?JSON.parse(r.results.campaign.campaign_inspiration):''),
                    campaign_client_meta: r.results.campaign_client_meta,
                    readOnly:(r.results.user_campaign.status === 'cloturer' || r.results.user_campaign.status === 'programme_annule')?true:false
                })   
            }
            else {
                this.props.history.replace('/error');
            }
        });

    }
    packageStatus = (package_status) => {
        new Fetcher().callApi({
            api_path:'/campaigns/package/',
            api_data:{
                "uid_campaign" : this.state.campaign.uid_campaign,
                "package_status": package_status
            },
            method:'POST',
        }).then((r) => {
            if(r.status === 'success'){
                this.getCampagne();
                if(package_status === 'received') showToast('Merci pour votre confirmation ! À vous de jouer !');
            }
            else {}
        });
    }
    validateDeal = () => {
        new Fetcher().callApi({
            api_path:'/campaigns/validate_deal/',
            api_data:{
                "uid_campaign" : this.state.campaign.uid_campaign
            },
            method:'POST',
        }).then((r) => {
            if(r.status === 'success'){
                if(this.btnPartnership.current !== null){ //if btn exist due to campaign type
                    if(r.results.err_partnership) {
                        this.btnPartnership.current.classList.add('error');
                        this.setState({errorDisplay:''});
                    }
                    else this.btnPartnership.current.classList.add('success');
                }
                
                if(this.btnPartnershipInsight.current !== null){ //if btn exist due to campaign type
                    if(r.results.err_partnership_insight) {
                        this.btnPartnershipInsight.current.classList.add('error');
                        this.setState({errorDisplay:''});
                    }
                    else this.btnPartnershipInsight.current.classList.add('success');
                }
                

                if(this.btnOpinion.current !== null){ //if btn exist due to campaign type
                    if(r.results.err_opinion) {
                        this.btnOpinion.current.classList.add('error');
                        this.setState({errorDisplay:''});
                    }
                    else this.btnOpinion.current.classList.add('success');
                }
                
                if(this.btnInvoice.current !== null){
                    if(r.results.err_invoice) {
                        this.btnInvoice.current.classList.add('error');
                        this.setState({errorDisplay:''});
                    }
                    else this.btnInvoice.current.classList.add('success');
                    //Always ok
                    //this.btnInvoice.current.classList.add('success');
                }

                //Go close
                if(!r.results.err_partnership && !r.results.err_opinion && !r.results.err_partnership_insight){ //&& !r.results.err_invoice 
                    this.props.history.push('/deals/closed');
                }
            }
            else {
                this.setState({
                    isLoaded: true,
                    error:true
                });    
            }

        });        
    }
    backToDeals(){
        this.props.history.push('/deals/');
    }
    goToDealPage(page){
        this.props.history.push('/deal/'+this.state.uid+'/'+page);
    }
    goToCampaignPage(page, readOnly = true){
        this.props.history.push({
            pathname : '/campaign/'+this.state.uid+'/'+page,
            state:{readOnly:readOnly}
        });
    }
    
    render() {  
        return this.state.isLoaded ? (this.state.hasError ? this.renderError() : this.renderPage(this.props.match.params.option)) : (       
            <Loading alwaysShow={true} />
        );            
    } 
    renderPage(param){
        switch(param) {
            case 'partnership':
                return  <DealPartnership campaign_data={this.state.campaign} user_meta={this.state.user_meta} user_posts={this.state.user_posts}  readOnly={this.state.readOnly}   />
            case 'partnership_insight':
                return  <DealPartnershipInsight campaign_data={this.state.campaign} user_campaign_meta={this.state.user_campaign_meta} readOnly={this.state.readOnly} />                
            case 'package':
                return  <DealPackage campaign_data={this.state.campaign} user_campaign={this.state.user_campaign} />
            case 'opinion':
                return <DealOpinion campaign_data={this.state.campaign} user_campaign_meta={this.state.user_campaign_meta} readOnly={this.state.readOnly} />
            case 'invoice':
                return <DealInvoice campaign_data={this.state.campaign} campaign_client_meta={this.state.campaign_client_meta} user_campaign_meta={this.state.user_campaign_meta} user_data={this.state.user_data} user_meta={this.state.user_meta} brand_meta={this.state.brand_meta} user_campaign={this.state.user_campaign} user_posts={this.state.user_posts}  />
            case 'invoice_not_fr':
                return <DealInvoiceHorsFrance campaign_data={this.state.campaign} campaign_client_meta={this.state.campaign_client_meta} user_campaign_meta={this.state.user_campaign_meta} user_data={this.state.user_data} user_meta={this.state.user_meta} brand_meta={this.state.brand_meta} user_campaign={this.state.user_campaign}  />
            case 'evaluation':
                return <DealEvaluation campaign_data={this.state.campaign} user_campaign={this.state.user_campaign} />
            default:
                return this.renderCampaignHome();
          }
    }
    renderError() {  
        return (       
            <div id="wtbid_home" className="container"><div className="row grow w-100"> <span>Oops ! Something went wrong, try to hit the back button or report it to contact@bilbokid.com</span></div></div>
        );            
    } 
    renderCampaignHome(){  
        return (   
            <div id="bk_campaign" className="container bg-white min-vh-100">
            <div className="row header">
                <div className="col px-0" style={{'backgroundImage':"url("+process.env.REACT_APP_SITE_URL+'/coffrefort/campaigns/'+this.state.campaign.uid_campaign+'/thumbs/'+this.state.campaign.image_url+")"}}>
                    
                    <div className="desktop_images">
                        {Object.values(this.state.campaign_images).map((iv,kv) => {
                            if(kv >= 3) return;
                            return (
                                    <img key={kv} id={kv} src={process.env.REACT_APP_SITE_URL+'/coffrefort/campaigns/'+this.state.campaign.uid_campaign+'/thumbs/'+iv.image_url} className="bd-placeholder-img" alt="" />
                            )
                        })}
                    </div>

                    <button onClick={() => this.backToDeals()} className="close"></button>
                    <div className="logo_marque" style={{'backgroundImage':"url("+process.env.REACT_APP_SITE_URL+'/coffrefort/brands/'+this.state.campaign.id_marque+'/'+this.state.campaign.logo_marque+")"}}></div>
                </div>
            </div>
            <div className="row content mt-5">
                <div className="col-12 mt-2 text-center text-pink text-andeslight">
                {(this.state.campaign_inspiration.site_web)?<Link to={{ pathname: this.state.campaign_inspiration.site_web }} className="ico ico_site_web" target="_blank"></Link>:''}
                {(this.state.campaign_inspiration.fb)?<Link to={{ pathname: this.state.campaign_inspiration.fb }} className="ico ico_fb" target="_blank"></Link>:''}
                {(this.state.campaign_inspiration.yt)?<Link to={{ pathname: this.state.campaign_inspiration.yt }} className="ico ico_yt" target="_blank"></Link>:''}
                {(this.state.campaign_inspiration.ig)?<Link to={{ pathname: this.state.campaign_inspiration.ig }} className="ico ico_ig" target="_blank"></Link>:''}
                {(this.state.campaign_inspiration.lien)?<Link to={{ pathname: this.state.campaign_inspiration.lien }} className="ico ico_link" target="_blank"><AiOutlineLink /></Link>:''}

                </div>
                <div className="col-12 mt-1 text-center text-pink text-andeslight">
                    <h1>{this.state.campaign.campaign_name}</h1>
                </div>
                
                <div className="col">
                    <Link 
                        to={{
                            pathname:'/campaign/'+this.state.uid,
                            state:{readOnly:true, backToLink:'/deal/'+this.state.uid}

                        }}
                        className="d-block border-primary text-decoration-none"
    
                    > 
                        <Translate s={CampaignUserStatus(this.state.user_campaign.status)} />
                        <span className="d-block  fs-7 text-decoration-underline">Voir ma campagne</span>
                    </Link>
                </div> 
                {(() => {
                    if(this.state.user_campaign.status === 'colis_envoye'){
                        return (
                            <div>
                                <p className="my-0"><Translate s='label_num_colis' /> : {((this.state.user_campaign.colis_num_suivi)?this.state.user_campaign.colis_num_suivi:<Translate s='label_non_renseigne' />)}</p>
                                <p className="my-0"><Translate s='label_transporteur' /> : {((this.state.user_campaign.colis_transporteur)?this.state.user_campaign.colis_transporteur:<Translate s='label_non_renseigne' />)}</p>
                                <p className="my-0"><Translate s='label_date_envoi' /> : {((this.state.user_campaign.colis_date_envoie)?this.state.user_campaign.colis_date_envoie:<Translate s='label_non_renseigne' />)}</p>  

                                <div className="mt-4 text-center">
                                    <button id="show_candidature_condition_ctnr" type="button" className="btn btn-primary" onClick={() => this.packageStatus('received')}><Translate s="program_status_colis_received" /></button>
                                    <button id="show_candidature_condition_ctnr" type="button" className="btn btn-link mt-2" onClick={() => this.packageStatus('not_received')}><Translate s="program_status_colis_not_received" /></button>
                                </div>
                            </div>
                        )
                    }
                    else if(this.state.user_campaign.status === 'colis_non_recu'){
                        return (
                            <div>
                                <p className="my-0"><Translate s='label_num_colis' /> : {((this.state.user_campaign.colis_num_suivi)?this.state.user_campaign.colis_num_suivi:<Translate s='label_non_renseigne' />)}</p>
                                <p className="my-0"><Translate s='label_transporteur' /> : {((this.state.user_campaign.colis_transporteur)?this.state.user_campaign.colis_transporteur:<Translate s='label_non_renseigne' />)}</p>
                                <p className="my-0"><Translate s='label_date_envoi' /> : {((this.state.user_campaign.colis_date_envoie)?this.state.user_campaign.colis_date_envoie:<Translate s='label_non_renseigne' />)}</p>  

                                <div className="mt-4 text-center">
                                    <button id="show_candidature_condition_ctnr" type="button" className="btn btn-primary" onClick={() => this.packageStatus('received')}><Translate s="program_status_colis_received" /></button>
                                </div>
                            </div>
                        )
                    }
                    else {
                            return (
    
                                <div className="col-12 campaign_nav mt-1">              
                                    {(() => {
                                        if(this.state.readOnly) {
                                            return (
                                                <button onClick={() => this.goToDealPage('evaluation')}><Translate s="app_nav_deal_evaluation" /></button>
                                            )
                                        }
                                    })()}
                                    {(() => {
                                        if(this.state.campaign.campaign_type !== '7' && this.state.campaign.campaign_type !== '13') {
                                            return (
                                                <button  ref={this.btnPartnership} onClick={() => this.goToDealPage('partnership')}><Translate s="app_nav_deal_partnership" /></button>
                                            )
                                        }  
                                    })()}
                                    {(() => {
                                        if(this.state.campaign.campaign_type === '13') {
                                            return (
                                                <button  ref={this.btnPartnershipInsight} onClick={() => this.goToDealPage('partnership_insight')}><Translate s="app_nav_deal_partnership" /></button>
                                            )
                                        }  
                                    })()} 
                                    {(() => {
                                        if(this.state.user_campaign.status !== 'deal_en_cours' && 
                                        this.state.campaign.campaign_contrepartie && 
                                        (this.state.campaign.campaign_contrepartie.indexOf('product_donation') !== -1 || this.state.campaign.campaign_contrepartie.indexOf('product_sample') !== -1)
                                        && !this.state.readOnly
                                        ) {
                                            return (
                                                <button onClick={() => this.goToDealPage('package')}><Translate s="app_nav_deal_package" /></button>
                                            )
                                        }
                                    })()}   
                                    <button ref={this.btnOpinion} onClick={() => this.goToDealPage('opinion')}><Translate s="app_nav_deal_experience" /></button>
                                                
                                    {(() => {
                                        if(this.state.campaign.rem === '1') {
                                            return (
                                                <button  ref={this.btnInvoice} onClick={() => this.goToDealPage(this.state.user_meta.country_code.toUpperCase() === 'FR'?'invoice':'invoice_not_fr')}><Translate s="app_nav_deal_invoice" /></button>
                                            )
                                        }
                                    })()}
                                    <button onClick={() => this.goToCampaignPage('brief',true)}><Translate s="app_nav_deal_brief" /></button>
                                </div>
                            )
                        
                    }
                })()}



                <div className={"alert alert-danger alert-without-icon mt-2 "+this.state.errorDisplay}>
                    <Translate s="app_deal_check_error" />
                </div>
            </div>   

            
            <div className={"modal_cta  p-3 mt-4 fixed-bottom border-top-grey "+(this.state.readOnly?'d-none':'')}>
                <div className="row btn-bottom-ctnr">
                    <div className="col-3 d-grid ">
                        <Link to={{ pathname : '/chat/'+this.state.campaign.uid_campaign+'/'+gen_security_key([this.state.id_user,this.state.campaign.id_marque,this.state.campaign.uid_campaign])}} className="btn btn-pink btn-chat p-0"><span className="bico ico_messagerie_w s20"></span></Link>
                    </div>
                    <div className="col-9 d-grid">
                        <button id="show_candidature_condition_ctnr" type="button" className="btn btn-primary" onClick={() => this.validateDeal()}><Translate s="btn_i_close" /></button>
                    </div>
                </div>
            </div> 


        </div>
        )
    }
}

export default Deal;