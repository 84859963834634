import React from 'react';
import Loading from '../../Components/Loading';

import {Link} from "react-router-dom";
import Translate from '../../Helpers/Translate';
import {_translate} from  '../../Helpers/Translation';
import Fetcher from '../../Helpers/Fetcher';
import { ActionSheet, ActionSheetButtonStyle } from '@capacitor/action-sheet';
import {dateTimeToFrDate,CampaignStatus} from '../../Helpers/Global';

class BrandCampaigns extends React.Component {  
    constructor(props) {
        super(props);
        
        this.state = {
            isLoaded:false,
            showEndList:false,
            hasCampaigns:false,
            firstname: '',
            avatar:'dummy',
            campaigns:[],
            campaigns_tags:[],
            campaigns_tags_keys:[],
            page:0,
            prevY: 0
        };   
    }

    componentDidMount(){
        this.fetchCampaignApi();
    }
    fetchCampaignApi = async () => {
        const r = await new Fetcher().callApi({
            api_path:'/brand/campaigns/list',
            api_data:{
                "page": this.state.page
            },
            method:'POST',
        })
        if(r.status === 'success'){
            if(r.total === 0) {
                this.setState({
                    hasCampaigns: false,
                    isLoaded: true,
                }); 
            }
            else if(r.results === false){
                this.setState({
                    hasCampaigns:true,
                    isLoaded: true,
                    showEndList:true
                }); 
            }
            else {
                this.setState({
                    hasCampaigns:true,
                    isLoaded: true,
                    campaigns: [...this.state.campaigns, ...r.results]              
                }, () => {
                    //manage height viewport to trigger insertersection observerv
                    /*if(document.body.scrollHeight <= window.innerHeight) {
                        this.setState({page:1},() => this.fetchCampaignApi())
                    }*/
                }); 
            }
        }
        else{
            this.setState({
                isLoaded: true,
                error:true
            });  
        }
        
    }  
    showActions = async (e,iv) => {
        console.log(e.target);
        console.log(e.currentTarget);
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        const is_action_btn = e.target.classList.contains('bico');
        console.log(vw, is_action_btn);
        if(!is_action_btn && vw >= 768){
            //TODO detect e current target
            this.props.history.push("/brand/campaigns/partnerships/"+iv.id_campaign);
        }
        else if(vw < 768) {
            let options = [];
            let i  = 0;

            if(iv.status !== 'draft' && iv.status !== 'approval_demand'  && iv.status !== 'approved'){
                options[i] = {};
                options[i].title =  await _translate("label_candidature_campaign");
                options[i].style =  ActionSheetButtonStyle.Destructive;
                i++;
            }
            
            options[i] = {};
            options[i].title =  await _translate("label_preview_campaign");
            i++;

            options[i] = {};
            options[i].title =  await _translate("label_dup_campaign");
            i++;

            if(iv.status === 'draft' || iv.status === 'approved'){
                options[i] = {};
                options[i].title =  await _translate("label_del_campaign");
                i++;
            }
            if(iv.campaign_type != 13 && (iv.status === 'done' || iv.status === 'live' || iv.status === 'paused')){
                options[i] = {};
                options[i].title =  await _translate("txt_campaign_reporting");
                i++;
            }
            
            options[i] = {};
            options[i].title =  await _translate("cta_cancel");
            options[i].style =  ActionSheetButtonStyle.Cancel;
            i++;

            const result = await ActionSheet.showActions({
                title: 'Actions',
                message: 'Select an option to perform',
                options: options,
            });
        
            console.log('Action Sheet result:', result.index);
            if(iv.status !== 'draft' && iv.status !== 'approval_demand'  && iv.status !== 'approved'){
                if(result.index === 0) this.props.history.push("/brand/campaigns/partnerships/"+iv.id_campaign);
                if(result.index === 1) this.props.history.push("/brand/campaigns/preview/"+iv.id_campaign);
                if(result.index === 2) this.props.history.push("/brand/campaigns/duplicate/"+iv.id_campaign);
                if(result.index === 3) this.props.history.push("/brand/campaigns/reporting/"+iv.id_campaign);
            }
            else if(iv.status === 'draft' || iv.status === 'approved'){
                if(result.index === 0) this.props.history.push("/brand/campaigns/preview/"+iv.id_campaign);
                if(result.index === 1) this.props.history.push("/brand/campaigns/duplicate/"+iv.id_campaign);
                if(result.index === 2) this.props.history.push("/brand/campaigns/delete/"+iv.id_campaign);
            }
            else if(iv.campaign_type != 13 && (iv.status === 'done' || iv.status === 'live' || iv.status === 'paused')){
                if(result.index === 0) this.props.history.push("/brand/campaigns/preview/"+iv.id_campaign);
                if(result.index === 1) this.props.history.push("/brand/campaigns/duplicate/"+iv.id_campaign);
                if(result.index === 2) this.props.history.push("/brand/campaigns/reporting/"+iv.id_campaign);

            }
            else {}

        }
        else {

        }
    }
    render () {
        //return (!this.state.hasCampaigns)?this.renderNoCampaign():this.renderCampaigns();
        return this.state.isLoaded ? (this.state.hasCampaigns ? this.renderCampaigns() : this.renderNoCampaign()) : (       
            <Loading alwaysShow={true} />
        );   

    }
    renderNoCampaign(){
        return (            
            <div id="bk_brandcampaigns" className="bg_blur bg-primary h-100 container pb-5">
                <div className="no_campaign">
                    <p className="banner"><Translate s="text_no_campaign" /></p>
                    <Link to={"/brand/credits"} className="offer_cta">Je souscris à une offre</Link>
                    <Link to={"/brand/campaigns/add"} className="cta">
                        <span className="bico ico_heart"></span> <br />
                        <p className="text-kidsscript" style={{fontSize:'40px'}}><Translate s="text_create_campaign" /></p>
                        <span className="bico ico_red_plus"></span>
                    </Link>
                </div>
            </div>
        )
    }
    renderCampaigns() {  
        return (
            <div id="bk_brandcampaigns" className="bg_blur bg-primary container pb-7">
                <div className="d-flex mb-4 px-2 pt-5">
                    <div className="align-self-center">
                        <h2 className="header_title text-white text-andeslight text-uppercase mb-0"><Translate s="cta_my_campaigns" /></h2>
                    </div>
                    <div className="align-self-center ms-auto">
                       <button className="btn btn-secondary"><Translate s="cta_create_campaign" /></button>
                    </div>
                </div>
                
                <div className="campaigns_ctnr">
                        <div className="row campaign_thead align-items-center bg-white my-1 py-2 px-1">
                            <div className="col-12 col-md-5"><Translate s="lbl_name" /></div>
                            <div className="col d-none d-md-block">Date</div>
                            <div className="col d-none d-md-block">Type</div>
                            <div className="col d-none d-md-block text-center"><Translate s="lbl_approved" /></div>
                            <div className="col d-none d-md-block text-center"><Translate s="lbl_tomoderate" /></div>
                            <div className="col d-none d-md-block">Action</div>
                        </div>

                {Object.values(this.state.campaigns).map((iv,kv) => {
                    const candidature = ((parseInt(iv.nb_valide)+parseInt(iv.nb_reponse_back)+parseInt(iv.nb_encours))+"/"+iv.objectif);
                    console.log(iv.status);
                    return (
                                
                        <div key={kv} id={iv.id_campaign} className="row campaign_ctnr align-items-center bg-white my-1 py-2 px-1 pointer" onClick={(e) => this.showActions(e,iv)}>
                            <div className="col-12 col-md-5 1st_col">
                                <div className="pb-2">
                                    <span className="text-grey">{iv.nom_marque}</span>
                                    <span className={"ms-2 badge badge-"+CampaignStatus(iv.status)}><Translate s={"lbl_campaign_status_"+iv.status} /></span>
                                </div>
                                {iv.campaign_name}
                                <span className="pt-2 text-grey d-block d-md-none reduce_info">Date : {dateTimeToFrDate(iv.start_date)}</span>
                                <span className="text-grey d-block d-md-none reduce_info"><Translate s="lbl_approved" /> : {candidature}</span>
                                <span className="text-grey d-block d-md-none reduce_info"><Translate s="lbl_tomoderate" /> : {iv.nb_a_moderer}</span>
                            </div>
                            <div className="col d-none d-md-block ">{dateTimeToFrDate(iv.start_date)}</div>
                            <div className="col d-none d-md-block"><Translate s={"campaign_type_"+iv.campaign_type} /></div>
                            <div className="col d-none d-md-block text-center">{candidature}</div>
                            <div className="col d-none d-md-block text-center">{iv.nb_a_moderer}</div>
                            <div className="col d-none d-md-block">
                                {(() => {
                                    if(iv.status === 'draft'){
                                        return (
                                            <Link to={"/brand/campaigns/campaign/"+iv.id_campaign+"/edit/fiche/"+iv.campaign_main_language} className="dvalign ps-2"><span className="bico ico_editer"></span></Link>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if(iv.status === 'approved'){
                                        return (
                                            <Link to={"/brand/campaigns/campaign/"+iv.id_campaign+"/edit/fiche/"+iv.campaign_main_language} className="dvalign ps-2"><span className="bico ico_valide"></span></Link>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if(iv.status !== 'draft' && iv.status !== 'approval_demand'  && iv.status !== 'approved'){
                                        return (
                                            <Link to={"/brand/campaigns/partnerships/"+iv.id_campaign} className="dvalign ps-2"><span className="bico ico_voir"></span></Link>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if(iv.campaign_type != 13 && (iv.status === 'done' || iv.status === 'live' || iv.status === 'paused')){
                                        return (
                                            <Link to={"/brand/campaigns/reporting/"+iv.id_campaign+(iv.campaign_type == 9?'/ig_stories':'')} className="dvalign ps-2"><span className="bico ico_graph"></span></Link>
                                        )
                                    }
                                })()}
                            </div>
                        </div>
                            
                    )
                })}
                </div>
            </div>
        )
    }  
}

export default BrandCampaigns;