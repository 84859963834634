import React from 'react';
import {Link} from "react-router-dom";
import BackButton from "../../Components/BackButton";
import Translate from '../../Helpers/Translate';
import Fetcher from '../../Helpers/Fetcher';
import { getLocalStorageItem, setLocalStorageItem,deleteLocalStorageItem } from '../../Helpers/Storage';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { initiateFormErrors, validateEntireForm, validateField, validateForm, errorClass } from '../../Helpers/Formvalidation';
import Loading from '../../Components/Loading';
import { showToast } from '../../Helpers/Toast';

class DeleteAccount extends React.Component {  
    constructor(props) {
        super(props);
        
        this.state = {
            isLoaded:false,
            error:false,
            deals_open:false,
            isDeleted:false,
            formFieldsNames: ['password_current'],
            formFields:{
                password_current:'',
            },
            formErrors:{
                password_current:'',
            },
        };   
    }
    componentDidMount(){  
        new Fetcher().callApi({
            api_path:'/campaigns/list/deals',
            api_data:{
                "filter":''
            },
            method:'POST',
        }).then((r) => {
            let deals_open = this.state.deals_open;
            let error = this.state.error;
            
            if(r.status === 'success'){
                if(r.results.open !== false){ //open campaigns  
                    deals_open = true;
                    error = true;
                }                
            }
            else error = true; 

            this.setState({
                isLoaded: true,
                error:error,
                deals_open:deals_open,
            }, () => {
                this.setState({
                    formErrors:initiateFormErrors(this.state.formFieldsNames)
                });
            })
        });
        
    }
    
    handleChange = (evt) => {     
        const name = evt.target.name;
        const type = evt.target.type;
        const value = type === "checkbox" ? evt.target.checked : evt.target.value;
        this.setState({
            formFields:{
                ...this.state.formFields,
                [name]: value,
            }
        }, () => {
            //Errors
            const err = validateField(evt.target);
            this.setState({
                formErrors : {
                    ...this.state.formErrors,
                    [name]:(err.fieldValid?'success':'error')
                }
            });
            

        });

    }
    handleSubmit = () => {
        if(validateForm(this.state.formErrors) === 'success'){
            new Fetcher().callApi({
                api_path:'/profil/delete_account',
                api_data:{
                    formFields:this.state.formFields
                },
                method:'GET',
            }).then((r) => {
                if(r.status === 'success'){
                    deleteLocalStorageItem("bk_user_info"); //force flush
                    SecureStoragePlugin.clear(); //force flush
                    //redir to login
                    this.setState({
                        isDeleted:true,
                    }, () => {
                        setTimeout(function(){
                            window.location.href = '/';
                         }, 5000);
                    })
                }
                if(r.status === 'error'){
                    showToast(r.message);
                }
            });
        }
        else{
            const tmp_err = validateEntireForm(this.state.formFieldsNames,this.state.formErrors);
            this.setState({formErrors : tmp_err});
        }

    }
    render() {  
        return this.state.isLoaded ? this.renderForm() : (       
            <Loading alwaysShow={false} />
        );            
    }
    renderForm() {  
        return (
            <div id="bk_alertssettings" className="container  bg-white min-vh-100">

                <div className="row header header_icon">
                    <div className="col px-0">
                        <BackButton />
                        <div className="icon"></div>
                    </div>
                </div>

                <div className="row mt-5 mb-1">
                    <div className="col">
                        <h1 className="text-center text-andeslight mt-2 py-2 font-14"><Translate s="dropdown_moncompte" /></h1>
                    </div>
                </div>

                {(() => {
                     if(this.state.isDeleted){
                        return (
                            <>
                                <p className="text-center"><Translate s="app_desc_suppression_confirmation" /></p>
                            </>
                        )
                    }
                    else {
                        if(!this.state.deals_open){
                            return(
                                <>
                                    <div className="row justify-content-center my-3"> 
                                    <div className="col-12 col-md-6">
                                        <p className="fw-bold"><Translate s="btn_delete_account" /></p>
                                        <p className=""><Translate s="app_desc_account_suppression" /></p>
                                        <input type="password" name="password_current" className={"form-control "+errorClass(this.state.formErrors.password_current)} id="password_current" value={this.state.formFields.password_current} data-is_required={true}  onChange={this.handleChange} />
                                        <div className="invalid-feedback  text-start"><Translate s="form_error_required" /></div>
                                    </div>
                                </div>

                                <div className="row justify-content-center"> 
                                    <div className="col-12 col-md-6 mb-3">
                                        <button className="mt-2 w-100 btn btn-lg btn-primary" onClick={() => this.handleSubmit()}><Translate s="cta_close_account" /></button>
                                    </div>
                                    <p className="text-center mt-3"><a href="mailto:contact@bilbokid.com" className="btn-text text-secondary"><Translate s="auth_contact_us" /></a></p>
                                </div>
                                </>
                            )
                        }
                    else {
                            return (
                                <>
                                    <p className="text-center"><Translate s="app_desc_account_suppression_warning" /></p>
                                </>
                            )
                        }
                    }
                   
                })()} 

                    
                


            </div>
                    
        )
    }  
}

export default DeleteAccount;