import React from 'react';
import {Link} from "react-router-dom";
import Fetcher from './Helpers/Fetcher';
import Translate from './Helpers/Translate';
import { showToast } from './Helpers/Toast';
import BackButton from "./Components/BackButton";
import {renderButton,addLoadingButton,removeLoadingButton} from "./Forms/DealSaveButton";
import {VscFilePdf,VscCloudUpload} from 'react-icons/vsc';

class DealInvoiceHorsFrance extends React.Component {  
    
    constructor(props) {
        super(props);
        this.submitBtn = React.createRef();
        this.state = {
            campaign: this.props.campaign_data,
            campaign_client_meta: this.props.campaign_client_meta,
            user_campaign_meta: this.props.user_campaign_meta,
            facture: this.props.user_campaign_meta.facture,
            greenLight:false,
            file_selected:false,
        };   
    }
    componentDidMount(){
    }
    handleChange(evt){
        let value =  evt.target.value;          

        const files = evt.target.files[0];
        
        if ( files.type === 'application/pdf' ) {
            const that = this;
            const fr=new FileReader();
            
            fr.readAsDataURL(files);
            fr.addEventListener("load", function () {
                value = this.result;
                that.setState({
                    facture:value,
                    greenLight:true,
                    file_selected:true
                });
                //this.submitBtn.click();
            });
        }
        else showToast('Uniquement des PDF');
    }
    handleSubmit = (e) =>  {
        if(this.state.greenLight){

            const saveBtnEls = addLoadingButton(e);

            new Fetcher().callApi({
                api_path:'/campaigns/save_invoice_hors_france/',
                api_data:{
                    "uid_campaign" : this.state.campaign.uid_campaign,
                    "facture": this.state.facture
                },
                method:'POST',
            }).then((r) => {
                if(r.status === 'success'){
                    if(r.status === 'success') {
                        showToast('app_well_saved');
                        this.setState({
                            facture:r.results,
                            file_selected:false
                        });
                    }
                    else showToast('app_an_error_occured');
                    removeLoadingButton(e,saveBtnEls);
                }
                else {
                    showToast(r.message)
                    removeLoadingButton(e,saveBtnEls);
                }
            });
        }
        else{
            showToast('Aucun fichier');
        }
    }
    render() { 
        return (
            <div id="bk_campaign" className="container brand_page fixed_header">
                
                <div className="row header">
                    <div className="col px-0">
                        <BackButton />
                        <div className="logo_marque" style={{'backgroundImage':"url("+process.env.REACT_APP_SITE_URL+'/coffrefort/brands/'+this.state.campaign.id_marque+'/'+this.state.campaign.logo_marque+")"}}></div>
                        <Link className="top_right_button ico_help" to="/help"></Link>
                    </div>
                </div>

                <div className="row content mt-5">
                    <div className="col-12 text-center mt-2">
                        <h2 className="text-andeslight"><Translate s="program_fiche_invoice" /></h2>
                    </div>
                    <form className="col-12 mt-2">
                        
                        <div className="add_file mt-2">
                            <label htmlFor="invoice">Ajouter un fichier (PDF uniquement)</label>
                            <input 
                                type="file"  
                                accept="application/pdf"
                                className="file_input_id" 
                                id="invoice"
                                name="invoice" 
                                onChange={(e) => this.handleChange(e)} />
                        </div>   

                        <div className={"view_files mt-2 "+(!this.state.file_selected?'d-none':'')}>
                            <p className="title text-andesmedium m-0 mt-3 mb-1">Mes fichiers</p>
                            <div className="view_file p-2">
                                <VscCloudUpload /> Cliquez sur "J'ENREGISTRE"
                            </div>
                        </div>

                        <div className={"view_files mt-2 "+(this.state.facture === undefined || this.state.file_selected?'d-none':'')}>
                            <p className="title text-andesmedium m-0 mt-3 mb-1">Mes fichiers</p>
                            <Link to={{pathname : process.env.REACT_APP_SITE_URL+"/coffrefort/invoices/"+this.state.campaign.id_campaign+"/"+this.state.facture}}target="_blank" className="view_file p-2">
                                <VscFilePdf /> Voir ma facture
                            </Link>
                        </div>

                        <div className="mt-4">
                            <Translate s='fiche_facturation_1' />
                            <ul>
                                <li> <Translate s='fiche_facturation_2' /><br />
                                {(() => {
                                    if(this.state.campaign.id_user_brand !== 1 && this.state.campaign.force_invoice_bilbokid === 0){
                                        return (
                                            <>
                                            {((this.state.campaign_client_meta.adresse)?this.state.campaign_client_meta.adresse:this.state.campaign_client_meta.stripe_address_line1)}
                                            <br /> 
                                            
                                            {(this.state.campaign_client_meta.code_postal)?this.state.campaign_client_meta.code_postal:this.state.campaign_client_meta.stripe_postal_code}
                                             - 
                                             {(this.state.campaign_client_meta.ville)?this.state.campaign_client_meta.ville:this.state.campaign_client_meta.stripe_address_city}, 
                                             {(this.state.campaign_client_meta.pays)?this.state.campaign_client_meta.pays:this.state.campaign_client_meta.stripe_address_country}
                                            </>
                                        )  
                                    } else{
                                        return (
                                            <>
                                            Bilbokid - Onelead <br />5 rue Baudin, <br />34000 Montpellier, France
                                            </>
                                        )
                                    }
                                })()}
                                    <br />
                                    <b><Translate s='fiche_facturation_3' /> : </b>  <Translate s={'campaign_type_'+this.state.campaign.campaign_type} /><br />
                                    <b><Translate s='fiche_facturation_4' /> : </b>  {this.state.campaign.nom_marque}<br />
                                    <Translate s='fiche_facturation_5' /> <br />
                                </li>
                                <li><Translate s='fiche_facturation_6' /></li>
                                <li><Translate s='fiche_facturation_7' /></li>
                            </ul>
                            <p><i><Translate s='txt_banking_account_change' /> <a href="mailto:partenariat@bilbokid.com">partenariat@bilbokid.com</a></i></p>
                        </div>

                    </form>
                   
                </div>
                
                <div className="d-grid p-3 mt-4 fixed-bottom bg-white ">
                   {renderButton((e) => this.handleSubmit(e))}
                </div>
            </div>
        )
    }  
}

export default DealInvoiceHorsFrance;