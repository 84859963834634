import React from 'react';
import {Link} from "react-router-dom";
import {AiOutlineLink} from 'react-icons/ai';
import Fetcher from './Helpers/Fetcher';

import Loading from './Components/Loading';
import CampaignBrand from './CampaignBrand';
import CampaignDescription from './CampaignDescription';
import CampaignProfils from './CampaignProfils';
import CampaignBrief from './CampaignBrief';

import CampaignApply from './CampaignApply';
import Translate from './Helpers/Translate';

class Campaign extends React.Component {  
    
    constructor(props) {
        super(props);
        
        this.state = {
            uid: '',
            campaign:{},
            campaign_inspiration:{},
            readOnly:(this.props.location.state !== undefined && this.props.location.state.readOnly?this.props.location.state.readOnly:false),
            backToLink:(this.props.location.state !== undefined && this.props.location.state.backToLink?this.props.location.state.backToLink:false),
        };   
    }
    componentDidMount() {
        const uid = this.props.match.params.uid;
        this.setState({
            uid:uid
        });
        this.getCampagne(uid);
                 
                  
        
        //this.resizeHeader()
    }
    getCampagne(uid){        
        new Fetcher().callApi({
            api_path:'/campaigns/get/'+uid,
            api_data:{},
            method:'GET',
        }).then((r) => {
            if(r.status === 'success'){
                this.setState({
                    isLoaded: true,
                    campaign: r.results.campaign,
                    user_campaign: r.results.user_campaign,
                    campaign_images: r.results.images,
                    campaign_inspiration: ((r.results.campaign.campaign_inspiration !== null)?JSON.parse(r.results.campaign.campaign_inspiration):{})
                  },() => {
                      ////Set header height 
                      /*const acm_content = document.getElementsByClassName("header")[0];
                      acm_content.style.height = window.innerHeight/2.5+"px";*/
                  })    

            }
            else {
                this.props.history.replace('/error');
            }
        });
    }
    backToCampaigns(){
        if(this.state.backToLink) this.props.history.push(this.state.backToLink);
        else this.props.history.push('/campaigns/');
    }
    goToCampaignPage(page){
        this.props.history.push('/campaign/'+this.state.uid+'/'+page);
    }
    
    render() {  
        return this.state.isLoaded ? (this.state.hasError ? this.renderError() : this.renderPage(this.props.match.params.option)) : (       
            <Loading alwaysShow={false} />
        );            
    } 
    renderPage(param){
        switch(param) {
            case 'brand':
                return  <CampaignBrand campaign_data={this.state.campaign} user_campaign={this.state.user_campaign} readOnly={this.state.readOnly} />
            case 'campaign':
                return  <CampaignDescription campaign_data={this.state.campaign} campaign_images={this.state.campaign_images} user_campaign={this.state.user_campaign} readOnly={this.state.readOnly} />
            case 'profils':
                return <CampaignProfils campaign_data={this.state.campaign} user_campaign={this.state.user_campaign} readOnly={this.state.readOnly} />
            case 'brief':
                return <CampaignBrief campaign_data={this.state.campaign} user_campaign={this.state.user_campaign} readOnly={this.state.readOnly} />
            default:
                return this.renderCampaignHome();
          }
    }
    renderError() {  
        return (       
            <div id="wtbid_home" className="container"><div className="row grow w-100"> <span>Oops ! Something went wrong, try to hit the back button or report it to contact@bilbokid.com</span></div></div>
        );            
    } 
    
    renderCampaignHome(){  
        return (   
            <div id="bk_campaign" className="">
            <div className="container bg-white">
                <div className="row header">
                    <div className="col px-0" style={{'backgroundImage':"url("+process.env.REACT_APP_SITE_URL+'/coffrefort/campaigns/'+this.state.campaign.uid_campaign+'/thumbs/'+this.state.campaign.image_url+")"}}>
                        <div className="desktop_images">
                            {Object.values(this.state.campaign_images).map((iv,kv) => {
                                if(kv >= 3) return;
                                return (
                                        <img key={kv} id={kv} src={process.env.REACT_APP_SITE_URL+'/coffrefort/campaigns/'+this.state.campaign.uid_campaign+'/thumbs/'+iv.image_url} className="bd-placeholder-img" alt="" />
                                    
                                )
                            })}
                        </div>
                        <button onClick={() => this.backToCampaigns()} className="close"></button>
                        <div className="logo_marque" style={{'backgroundImage':"url("+process.env.REACT_APP_SITE_URL+'/coffrefort/brands/'+this.state.campaign.id_marque+'/'+this.state.campaign.logo_marque+")"}}></div>
                    </div>
                </div>
            </div>
            <div className="container bg-white min-vh-100">
                <div className="row content pt-5">
                    <div className="col-12 mt-2 text-center text-pink text-andeslight">
                    {(this.state.campaign_inspiration.site_web)?<Link to={{ pathname: this.state.campaign_inspiration.site_web }} className="ico ico_site_web" target="_blank"></Link>:''}
                    {(this.state.campaign_inspiration.fb)?<Link to={{ pathname: this.state.campaign_inspiration.fb }} className="ico ico_fb" target="_blank"></Link>:''}
                    {(this.state.campaign_inspiration.yt)?<Link to={{ pathname: this.state.campaign_inspiration.yt }} className="ico ico_yt" target="_blank"></Link>:''}
                    {(this.state.campaign_inspiration.ig)?<Link to={{ pathname: this.state.campaign_inspiration.ig }} className="ico ico_ig" target="_blank"></Link>:''}
                    {(this.state.campaign_inspiration.lien)?<Link to={{ pathname: this.state.campaign_inspiration.lien }} className="ico ico_link" target="_blank"><AiOutlineLink /></Link>:''}
                    
                    </div>
                    <div className="col-12 mt-1 text-center text-pink text-andeslight">
                        <h1 className="bk_campaign_title">{this.state.campaign.campaign_name}</h1>
                    </div>
                    
                    <div className="d-flex justify-content-center my-2">
                        {(() => {
                            if(this.state.campaign.owner_fg === "1" || this.state.campaign.owner_fg === "0" || this.state.campaign.force_bilbokid === "1") {
                                return(
                                    <div className="ribbon"><Translate s='campaign_handled_by' /> Bilbokid</div>
                                )
                            }
                            else if(this.state.campaign.owner_fg !== "0" && this.state.campaign.group_id === "4") {
                                return (
                                    <div className="ribbon"><Translate s='campaign_handled_by_agency' /> {this.state.campaign.nom_marque}</div>
                                )
                            } 
                            else {
                                return (
                                    <div className="ribbon"><Translate s='campaign_handled_by' /> {this.state.campaign.nom_marque}</div>
                                )
                            }
                        })()}
                    </div>
                    

                    <div className="col-12 campaign_nav mt-2">
                        <button onClick={() => this.goToCampaignPage('brand')}><Translate s="app_campaign_brand" /></button>
                        <button onClick={() => this.goToCampaignPage('campaign')}><Translate s="app_campaign_campaign" /></button>
                        <button onClick={() => this.goToCampaignPage('profils')}><Translate s="app_campaign_profiles" /></button>
                        <button onClick={() => this.goToCampaignPage('brief')}><Translate s="app_campaign_brief" /></button>
                    </div>
                </div>
            </div>
            
            <CampaignApply  readOnly={this.state.readOnly} uid_campaign={this.state.campaign.uid_campaign} campaign_status={this.state.campaign.status} user_campaign={this.state.user_campaign}  questions={this.state.campaign.extra_questions}/>        


        </div>
        )
    }
}

export default Campaign;