import React from 'react';
import { showToast } from '../../Helpers/Toast';
import BackButton from "../../Components/BackButton";
import Fetcher from '../../Helpers/Fetcher';
import Loading from '../../Components/Loading';
import { initiateFormErrors, validateEntireForm, validateField, validateForm, errorClass } from '../../Helpers/Formvalidation';
import Translate from '../../Helpers/Translate';

class Security extends React.Component {  
    
    constructor(props) {
        super(props);
        
        this.state = {
            isLoaded:true,
            error:false,
            formFields:{
                password_current:'',
                password: '',
                password_check:''
            },
            formErrors:{
                password_current:'',
                password: '',
                password_check:''
            },
            formErrorsMessages: {
                password_current:'',
                password: '',
                password_check:''
            },
            app_langage:'',
        };   
    }
    componentDidMount(){       
    }
    handleChange = (evt) => {     
        const name = evt.target.name;
        const type = evt.target.type;
        const value = type === "checkbox" ? evt.target.checked : evt.target.value;
        this.setState({
            formFields:{
                ...this.state.formFields,
                [name]: value,
            }
        }, () => {
            //Errors
            const err = validateField(evt.target);
            this.setState({
                formErrors : {
                    ...this.state.formErrors,
                    [name]:(err.fieldValid?'success':'error')
                }
            });
            

        });

    }

    handleSubmit() {
        //Chech data
        if(this.validateForm()){
            this.saveChange();
            //if(this.state.formAction === 'login') this.login();
            //else this.editChild();
        }        

    }
    saveChange = () => {
        const that = this;
        new Fetcher().callApi({
            api_path:'/profil/change_pwd',
            api_data:{
                formFields: this.state.formFields
            },
            method:'POST',
        }).then((r) => {
            if(r.status === 'error'){
                showToast(r.message);
            }
            else{
                showToast('app_well_saved');
                //Flush fields
                that.setState({
                    formFields:{}
                });
            }
        });       
                
        
    }
    validateForm() {   
        let fieldValidationErrors = this.state.formErrors;
        let formErrorsMessages = this.state.formErrorsMessages;
        let formIsValid = true;        
        Object.keys(this.state.formFields).forEach(fieldName => {
            var value = this.state.formFields[fieldName];
            if(fieldName === 'password'){
                const pwd_rule = new RegExp('^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$','i');
                //console.log(!pwd_rule.test(value));
                if(!pwd_rule.test(value)) {
                    formIsValid = false;
                    fieldValidationErrors.password = 'error';
                    formErrorsMessages.password = <Translate s="app_err_pwdrule" />
                }
                else {
                    fieldValidationErrors.password = '';
                }
            }
            if(fieldName === 'password_check'){
                const passwordValid = this.state.formFields.password;;
                let passwordCheckValid = value; //(!value.trim().length)?false:true;
                //fieldValidationErrors.password_check = passwordCheckValid ? '': 'error';
                if(passwordCheckValid !== passwordValid) {
                    formIsValid = false;
                    fieldValidationErrors.password_check = 'error';
                    formErrorsMessages.password_check = <Translate s="app_err_pwdcheck" />
                }
                else fieldValidationErrors.password_check = ''
            }
          })
          this.setState({
            formErrors: fieldValidationErrors,
            formErrorsMessages:formErrorsMessages,
        });
          return formIsValid;
    }

        
    render() {  
        return this.state.isLoaded ? this.renderForm() : (   
            <div id="bk_alertssettings" className="container">
                
                <div className="row header header_icon">
                    <div className="col px-0">
                        <BackButton />
                        <div className="icon"></div>
                    </div>
                </div>
    
                <Loading alwaysShow={false} />
            </div>
        );            
    } 
    
    renderForm() {  
        return (
            <div id="bk_alertssettings" className="container  bg-white min-vh-100">
                

                <div className="row header header_icon">
                    <div className="col px-0">
                        <BackButton />
                        <div className="icon"></div>
                    </div>
                </div>

                <div className="row mt-5 mb-1">
                    <div className="col">
                        <h1 className="text-center text-andeslight mt-2 py-2 font-14"><Translate s="dropdown_securite" /></h1>
                    </div>
                </div>


                 
                        
                        <div className="row justify-content-center my-3"> 
                            <div className="col-12 col-md-6 mb-3">
                                <p className="fw-bold"><Translate s="app_lbl_change_pwd" /></p>
                                <label className={"required "} htmlFor="password_current"><Translate s="current_pwd" /></label>
                                <input type="password" name="password_current" className={"form-control "+errorClass(this.state.formErrors.password_current)} id="password_current" value={this.state.formFields.password_current}  onChange={this.handleChange} />
                                <div className="invalid-feedback  text-start">{this.state.formErrorsMessages.password_current}</div>
                            </div>
                        </div>
                            
                        <div className="row justify-content-center my-3"> 
                            <div className="col-12 col-md-6 mb-3">
                                <label className={"required "} htmlFor="password"><Translate s="auth_pwd" /></label>
                                <input type="password" name="password" className={"form-control "+errorClass(this.state.formErrors.password)} id="password" value={this.state.formFields.password}  onChange={this.handleChange} />
                                <div className="invalid-feedback  text-start">{this.state.formErrorsMessages.password}</div>
                            </div>
                        </div>
                            
                        <div className="row justify-content-center my-3"> 
                            <div className="col-12 col-md-6 mb-3">
                                <label className={"required "} htmlFor="password_check"><Translate s="auth_pwd_confirm" /></label>
                                <input type="password" name="password_check" className={"form-control "+errorClass(this.state.formErrors.password_check)} id="password_check" value={this.state.formFields.password_check}  onChange={this.handleChange} />
                                <div className="invalid-feedback text-start">{this.state.formErrorsMessages.password_check}</div>
                            </div>
                        </div>
                            
                        <div className="row justify-content-center my-3"> 
                            <div className="col-12 col-md-6 mb-3">
                                <button className="mt-2 w-100 btn btn-lg btn-primary" onClick={() => this.handleSubmit()}><Translate s="cta_validate" /></button>
                            </div>
                        </div>

                
            </div>
        )
    }  
}

export default Security;