import React from 'react';
import Fetcher from '../../Helpers/Fetcher';
import { initiateFormErrors, validateEntireForm, validateField, validateForm, errorClass } from '../../Helpers/Formvalidation';
import Translate from '../../Helpers/Translate';
import {renderButton,addLoadingButton,removeLoadingButton} from "../../Forms/DealSaveButton";
import { showToast } from '../../Helpers/Toast';
import BackButton from '../../Components/BackButton';
import {genRadiogroupBoxes} from '../../Forms/GenRadiogroupBoxes';
import{placeToAddress} from "../../Helpers/Google";
import Autocomplete from "react-google-autocomplete";
import { Dialog } from '@capacitor/dialog';


class Step2 extends React.Component {      
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            error:false,
            user:{},
            user_meta:{},

            formFieldsNames:['pseudo', 'nom','prenom','adresse','date_naissance','telephone','sexe','facturation','siret','numtva','iban','bic','vat_type','adresse_autocomplete'],
            formFields:{},
            formErrors:{},

            readOnly:false,
            showDetailsFacturation:false,
            numtvarequired:false,
        }
    }
    componentDidMount(){

        //Fetch data 
        new Fetcher().callApi({
            api_path:'/profil/init',
            api_data:{},
            method:'POST',
        }).then((r) => {
            if(r.status === 'success'){
                this.setState({
                    isLoaded: true,
                    user:r.results.user,
                    formFields:r.results.user_meta,
                  }, () => {
                    //Show SIRET, TVA etc
                    this.showDetailsFacturation();
                    
                    //reinitiate form errors if check or not
                    this.setState({
                        formErrors:initiateFormErrors(this.state.formFieldsNames)
                    })
                  })    
            }
            else {
                this.setState({
                    isLoaded: true,
                    error:true
                });   
            }
        });

    }
    handleChange(evt){
        let e = '';
        if(evt.target !== undefined) e = evt.target;
        else e = evt;

        const name = e.name;
        const type = e.type;
        const value = type === "checkbox" ? e.checked : e.value; 
        //update form fields
        let ff = {};
        ff = this.state.formFields;
        let fe = {};
        fe = this.state.formErrors;
         
        const el = document.getElementsByName(name);
        if(el.length > 2){ //checkbox or radio
            if(ff[name] !== undefined) ff[name][e.value] = value;
            else { 
                ff[name] = {};
                ff[name][e.value] = value;
            }
        }
        else ff[name] = value;

        //Vat
        let numtvarequired = this.state.numtvarequired;
        if(name === 'vat_type' && value == 'vat'){
            numtvarequired = true;
        }
        if(name === 'vat_type' && value == 'no_vat'){
            numtvarequired = false;
        }
        
        //Errors
        const err = validateField(e);
        fe[name] = (err.fieldValid?'success':'error');

        //push state
        this.setState({
            formFields:ff,
            formErrors:fe,
            numtvarequired:numtvarequired,
        }, () => {
            this.showDetailsFacturation();
        });

    }
    handleSubmit = (e) =>  {
        console.log(this.state.formErrors);
        if(validateForm(this.state.formErrors) === 'success'){
            const saveBtnEls = addLoadingButton(e);
            new Fetcher().callApi({
                api_path:'/profil/save/',
                api_data:{
                    slug: 'coordonnees',
                    formFields: this.state.formFields,
                }, 
                method:'POST',
            }).then((r) => {
                if(r.status === 'success'){
                    showToast('app_well_saved');
                    removeLoadingButton(e,saveBtnEls);
                    //move back to steps
                    this.props.history.goBack();
                    //this.props.history.push('/register/steps');
                }
                else showToast('app_an_error_occured'); 
            });
        }
        else{
            const tmp_err = validateEntireForm(this.state.formFieldsNames,this.state.formErrors);
            this.setState({formErrors : tmp_err});
        }

    }
    manageAutocomplete(p){
        console.log(p);
        const e = placeToAddress(p);
        this.setState({
            formFields:{
                ...this.state.formFields,
                formatted_address : p.formatted_address,
                adresse:(e.streetNumber !== undefined?e.streetNumber.long_name+' ':'')+(e.streetName !== undefined?e.streetName.long_name:''),
                code_postal:(e.zip !== undefined?e.zip.long_name:''),
                ville:(e.city !== undefined?e.city.long_name:''),
                pays:(e.country !== undefined?e.country.long_name:''),
                country_code:(e.country !== undefined?e.country.short_name:''),
                latitude:p.geometry.location.lat(),
                longitude:p.geometry.location.lng()
            },
            formErrors:{
                ...this.state.formErrors,
                adresse : 'success',
                //campaign_invoice_address : 'success',
            }
        }, () => {
            console.log(this.state.formErrors);
        })

    }
    detectNoAutocomplete = (t) => {
        let ff = this.state.formFields;
        let fe = this.state.formErrors;
        console.log(t);
        if(t==='change'){
            ff.adresse_autocomplete = true;
        }
        if(t==='blur'){
            console.log(ff.adresse_autocomplete);
            if(!ff.adresse_autocomplete){

                fe.adresse = 'error';
                //fe.campaign_invoice_address = 'error';
               
                ff.adresse_autocomplete = false;
                ff.adresse = '';
                ff.code_postal = '';
                ff.ville = '';
                ff.pays = '';
                ff.country_code = '';
            }
            else {
                ff.adresse_autocomplete = true;           
                fe.adresse = 'success';
                //fe.campaign_invoice_address = 'success';
            }




        }     
        
        console.log(fe);

        this.setState({
            formFields:ff,
            formErrors:fe
        }, () => {
            console.log(this.state.formFields);
            console.log(this.state.formErrors);
        }); 
    }
    showDetailsFacturation = () =>{
        if(this.state.formFields.facturation === "1" && this.state.formFields.country_code === undefined) {
            //alert
            this.showCcFirstAlert();
            return;
        }
        let showDetailsFacturation = false;
        if(this.state.formFields.facturation === "1" && (this.state.formFields.country_code !== undefined && this.state.formFields.country_code === 'FR')){
            showDetailsFacturation = true;
        }
        this.setState({
            showDetailsFacturation:showDetailsFacturation
        }, () => 
            //reinitiate form errors if check or not
            this.setState({
                formErrors:initiateFormErrors(this.state.formFieldsNames)
            })
        );
    }
    showCcFirstAlert = async () => {
        await Dialog.alert({
          title: 'Erreur',
          message: 'Merci de d\'abord renseigner votre adresse',
        });
        document.querySelector('input[name="facturation"]').checked=false;

    };
    render() {   
        return (
            <div id="bk_register_steps" className="">
                <div className="container-fluid bg-white">
                    <div className="header row px-3 py-5">
                        <div className="col text-center">
                            <BackButton />
                            <h2 className="header_title"><Translate s="dropdown_mescoodonnees" /></h2>
                        </div>
                    </div>
                </div>
                
                <div className="container pb-9">

                        <div className="row justify-content-center my-3"> 
                            <div className="col-12 col-md-6 mb-3">
                                <label className={"form-label required "}>
                                    {this.state.formFields.lock_profil !== undefined?<Translate s="app_lbl_companyname" />:<Translate s="profil_info_pseudo" />}
                                </label>
                                <input type="text" className={`form-control ${errorClass(this.state.formErrors.pseudo)}`} autoComplete="off" data-is_required={true} name="pseudo" value={this.state.formFields.pseudo} onChange={(e) => this.handleChange(e)} />
                            </div>
                        </div>
                        <div className="row justify-content-center my-3"> 
                            <div className="col-12 col-md-6 mb-3">
                                <label className={"form-label required "}><Translate s="profil_info_lastname" /></label>
                                <input type="text" className={`form-control ${errorClass(this.state.formErrors.nom)}`} autoComplete="off" data-is_required={true} name="nom" value={this.state.formFields.nom} onChange={(e) => this.handleChange(e)} />
                            </div>
                        </div>
                        <div className="row justify-content-center my-3"> 
                            <div className="col-12 col-md-6 mb-3">
                                <label className="form-label required"><Translate s="profil_info_firstname" /></label>
                                <input type="text" className={`form-control ${errorClass(this.state.formErrors.prenom)}`} autoComplete="off" data-is_required={true} name="prenom" value={this.state.formFields.prenom} onChange={(e) => this.handleChange(e)} />
                                </div>
                        </div>
                        
                        
                        {(() => {
                            if(this.state.gmapapikey !== "") {
                                return (

                                    <div className="row justify-content-center my-3"> 
                                    <div className="col-12 col-md-6 mb-3">
                                        <label className="form-label required"><Translate s="profil_info_address" /></label>
                                        <Autocomplete
                                            apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                                            name="adresse"
                                            className={"form-control "+errorClass(this.state.formErrors.adresse)}
                                            defaultValue={(this.state.formFields.formatted_address !== undefined? this.state.formFields.formatted_address:this.state.formFields.adresse)}
                                            //onChange={(e) => }
                                            data-is_required={true}
                                            data-validation='autocomplete'
                                            data-autocompleteValidation={(this.state.formFields.adresse_autocomplete == '' || !this.state.formFields.adresse_autocomplete)?false:true}
                                            options = {{
                                                types: ['address']
                                            }}
                                            onPlaceSelected={(place,e,a) => {
                                                this.handleChange(e);
                                                this.manageAutocomplete(place);
                                            }}
                                            onChange = {() => this.detectNoAutocomplete('change')}
                                            onBlur = {() => this.detectNoAutocomplete('blur')}
                                        />
                                    </div>
                                </div>
                                )
                            }
                            
                        })()}
                        <div className="row justify-content-center my-3"> 
                            <div className="col-12 col-md-6 mb-3">
                                <label className="form-label required mb-0"><Translate s="profil_info_birthday" /></label>
                                <small><Translate s="profil_info_birthday_format" /></small>
                                <input type="text" className={`form-control ${errorClass(this.state.formErrors.date_naissance)}`} autoComplete="off" data-is_required={true} name="date_naissance" value={this.state.formFields.date_naissance} onChange={(e) => this.handleChange(e)} />
                            </div>
                        </div>
                        
                        <div className="row justify-content-center my-3"> 
                            <div className="col-12 col-md-6 mb-3">
                                <label className="form-label required mb-0 mb-md-4">Email</label>
                                <input type="text" disabled={true} className="form-control" autoComplete="off" data-is_required={false} name="email" value={this.state.user.email} onChange={(e) => this.handleChange(e)} />
                            </div>
                        </div>
                        <div className="row justify-content-center my-3"> 
                            <div className="col-12 col-md-6 mb-3">
                                <label className="form-label required mb-0 mb-md-4"><Translate s="profil_info_phone" /></label>
                                <input type="text" className={`form-control ${errorClass(this.state.formErrors.telephone)}`} autoComplete="off" data-is_required={true} name="telephone" value={this.state.formFields.telephone} onChange={(e) => this.handleChange(e)} />
                            </div>
                        </div>

                        <div className="row justify-content-center my-3"> 
                            <div className="col-12 col-md-6 mb-3">
                                {genRadiogroupBoxes({
                                    label:'profil_info_youare',
                                    name:'sexe',
                                    defaultValue:this.state.formFields.sexe,
                                    onChangeAction:(e) => this.handleChange(e),
                                    required:true,
                                    class:errorClass(this.state.formErrors.sexe),
                                    data:[{    
                                        key:'F',
                                        value:<Translate s='profil_info_youare_woman' />
                                    },{
                                        key:'H',
                                        value:<Translate s='profil_info_youare_man' />
                                    }]
                                })}
                            </div>
                        </div>


                    { /* FACTURATION */ }
                    
                    <div className="row justify-content-center my-3"> 
                        <div className="col-12 col-md-6 mb-1">
                        {genRadiogroupBoxes({
                            label:'profil_info_invoicing',
                            name:'facturation',
                            defaultValue:this.state.formFields.facturation,
                            onChangeAction:(e) => this.handleChange(e),
                            required:true,
                            class:errorClass(this.state.formErrors.facturation),
                            data:[{    
                                key:'1',
                                value:<Translate s='yes' />
                            },{
                                key:'0',
                                value:<Translate s='no' />
                            }]
                        })} 
                        </div>
                    </div>

                    <div className={'row justify-content-center '+(this.state.showDetailsFacturation?'':'d-none')}>


                    <div class="col-12 col-md-6">
                        <div class="flex-column">
                        
                            <div className="col-12 mb-3"> 
                                {genRadiogroupBoxes({
                                    label:'app_lbl_vatstatus',
                                    name:'vat_type',
                                    defaultValue:this.state.formFields.vat_type,
                                    onChangeAction:(e) => this.handleChange(e),
                                    required:this.state.showDetailsFacturation,
                                    class:errorClass(this.state.formErrors.vat_type),
                                    data:[{    
                                        key:'vat',
                                        value:<Translate s='app_lbl_vat_applicable' />
                                    },{
                                        key:'no_vat',
                                        value:<Translate s='app_lbl_vat_not_applicable' />
                                    }]
                                })}   
                            </div>
                            <div className="col-12 mb-3">
                                <label className={"form-label "}><Translate s="stripe_siret" /></label>
                                <input type="text" className={`form-control ${errorClass(this.state.formErrors.siret)}`} autoComplete="off" data-is_required={this.state.showDetailsFacturation}  minLength="14" maxLength="14" name="siret" value={this.state.formFields.siret} onChange={(e) => this.handleChange(e)} />
                                <div className="invalid-feedback"><Translate s="app_err_siret" /></div>
                            </div>
                            <div className="col-12 mb-3">
                                <label className={"form-label "}>IBAN</label>
                                <input type="text" className={`form-control ${errorClass(this.state.formErrors.iban)}`} autoComplete="off" data-is_required={this.state.showDetailsFacturation} name="iban" value={this.state.formFields.iban} onChange={(e) => this.handleChange(e)} />
                                <div className="invalid-feedback"><Translate s="form_error_required" /></div>
                            </div>
                            <div className="col-12 mb-3">
                                <label className={"form-label "}>BIC</label>
                                <input type="text" className={`form-control ${errorClass(this.state.formErrors.bic)}`} autoComplete="off" data-is_required={this.state.showDetailsFacturation} name="bic" value={this.state.formFields.bic} onChange={(e) => this.handleChange(e)} />
                                <div className="invalid-feedback"><Translate s="form_error_required" /></div>
                            </div>
                            <div className="col-12 mb-3">
                                <label className="form-label"><Translate s="stripe_tva" /></label>
                                <input type="text" className={`form-control ${errorClass(this.state.formErrors.numtva)}`} autoComplete="off" data-is_required={this.state.numtvarequired} name="numtva" value={this.state.formFields.numtva} onChange={(e) => this.handleChange(e)} />
                                <div className="invalid-feedback"><Translate s="form_error_required" /></div>
                            </div>

                            </div>
                        </div>
                    </div>

                
                        
                    <div className="fixed-bottom bg-white gradient-top">
                        <div className={"btn-bottom-ctnr d-grid p-3 mt-4 "+(this.state.readOnly?'d-none':'')}>
                        {renderButton((e) => this.handleSubmit(e))}
                        </div>
                    </div>

                </div>

            </div>
            
        )
    }  
}

export default Step2;