import React from 'react';
import Fetcher from '../../Helpers/Fetcher';
import { initiateFormErrors, validateEntireForm, validateField, validateForm, errorClass } from '../../Helpers/Formvalidation';
import Translate from '../../Helpers/Translate';
import {renderButton,addLoadingButton,removeLoadingButton} from "../../Forms/DealSaveButton";
import { showToast } from '../../Helpers/Toast';
import BackButton from '../../Components/BackButton';
import Loading from '../../Components/Loading';
//import FacebookLogin from '@greatsumini/react-facebook-login';
import {FacebookLogin} from '@capacitor-community/facebook-login';
import UploadImage from '../../Forms/UploadImage';
import{check_inf_cat} from "../../Helpers/Global";

class StepReseau extends React.Component {      
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            error:false,
            user:{},
            user_meta:{},

            formFieldsNames:[
                'blog','nom_blog','url_blog','nb_visiteur_blog','nb_page_vue_blog',
                'facebook', 'url_facebook', 'nb_fan_facebook',  
                'instagram', 'compte_instagram', 'nb_abonnes_instagram',  
                'youtube', 'chaine_youtube', 'nb_abonnes_youtube',
                'twitter', 'page_twitter', 'nb_followers_twitter',
                'snapchat', 'utilisateur_snapchat', 'nb_abonnes_snapchat',
                'tiktok', 'utilisateur_tiktok','url_tiktok', 'nb_followers_tiktok',
                'podcast', 'url_podcast', 'name_podcast','nb_auditeur_podcast',
                'ugc', 'url_ugc', 'brand_ugc','pic_ugc','strength_ugc',
            ],
            formFields:{},
            formErrors:{},

            readOnly:false,
            fb_pages:{},
            fb_disable_manual:false,
            pendingServer:false,

            topPicUgc:1,
            minPicUgc:6,
            hide_network:''
        }
    }

    setFormFields = (t,ffk,ffv) => {
        let ff = this.state.formFields;
        if(t === 'add') ff[ffk] = ffv;
        if(t === 'delete') delete ff[ffk];        
        this.setState({formFields:ff},() => {
            this.setState({formErrors:initiateFormErrors(this.state.formFieldsNames)});
        })
    }
    setTotImages = (t) => { 
        let tot = this.state.topPicUgc;
        if(t==='inc') tot =  tot+1
        if(t==='dec') tot =  tot-1
        this.setState({topPicUgc:tot},() => {
            //this.setState({formErrors:initiateFormErrors(this.state.formFieldsNames)});
            this.setFormFields('add','topPicUgc', this.state.topPicUgc);

            //Update in DB in case they do not save the form but jsut use the go back
            new Fetcher().callApi({api_path:'/helpers/update_user_meta/',api_data:{"metas": {'topPicUgc':tot}},method:'POST'}); 
        })
    }   

    componentDidMount(){
        this.initForm();
        
    }
    initForm = () => {
        
        //Fetch data 
        new Fetcher().callApi({
            api_path:'/profil/init',
            api_data:{},
            method:'POST',
        }).then((r) => {
            if(r.status === 'success'){
                //FB pages states
                let fb_pages = {};
                let fb_disable_manual = false;
                if(r.results.user_meta.fb_api !== undefined && r.results.user_meta.fb_api === "1" && r.results.user_meta.fb_page_id !== undefined){
                    fb_pages = [{
                        id:r.results.user_meta.fb_page_id,
                        name:r.results.user_meta.fb_page_name,
                        checked:true,
                    }];
                    
                    fb_disable_manual = true;
                }
                
                //topPicUgc
                let topPicUgc = this.state.topPicUgc;
                if(r.results.user_meta.topPicUgc !== undefined) topPicUgc = parseInt(r.results.user_meta.topPicUgc);
                
                //if cat inf is only marque hide some networks
                const cat_influenceur = JSON.parse(r.results.user_meta.cat_influenceur);
                let tmp_hide_network = '';
                if(cat_influenceur.length == 1 && check_inf_cat(cat_influenceur,'marque')) {
                    tmp_hide_network='d-none';
                }

                //Set all states
                this.setState({
                    isLoaded: true,
                    user:r.results.user,
                    formFields:r.results.user_meta,
                    fb_pages:fb_pages,
                    fb_disable_manual:fb_disable_manual,
                    topPicUgc:topPicUgc,
                    hide_network:tmp_hide_network
                  }, () => {                  
                    //Errors
                    this.setState({
                        formErrors:initiateFormErrors(this.state.formFieldsNames)
                    },() => {
                       //console.log(this.state.formErrors);

                  })
                })    
            }
            else {
                this.setState({
                    isLoaded: true,
                    error:true
                });   
            }
        });
    }
    handleChange(evt){
        let e = '';
        if(evt.target !== undefined) e = evt.target;
        else e = evt;

        const name = e.name;
        const type = e.type;
        const value = type === "checkbox" ? e.checked : e.value; 
        //update form fields
        let ff = {};
        ff = this.state.formFields;
        let fe = {};
        fe = this.state.formErrors;
        const el = document.getElementsByName(name);
        if(el.length > 2){ //checkbox or radio
            if(ff[name] !== undefined) ff[name][e.value] = value;
            else { 
                ff[name] = {};
                ff[name][e.value] = value;
            }
        }
        else ff[name] = value;
        
        //Errors
        const err = validateField(e);
        fe[name] = (err.fieldValid?'success':'error');

        //push state
        this.setState({
            formFields:ff,
            formErrors:fe,
        },() => {
            //reinitiate form errors if network check or not
            this.setState({
                formErrors:initiateFormErrors(this.state.formFieldsNames)
            })
        });

    }
    handleSubmit = (e) =>  {
        if(validateForm(this.state.formErrors) === 'success'){
            const saveBtnEls = addLoadingButton(e);
            new Fetcher().callApi({
                api_path:'/profil/save/',
                api_data:{
                    slug: 'reseau',
                    formFields: this.state.formFields,
                }, 
                method:'POST',
            }).then((r) => {
                if(r.status === 'success'){
                    showToast('app_well_saved');
                    removeLoadingButton(e,saveBtnEls);
                    //move back to steps
                    this.props.history.goBack();
                    //this.props.history.push('/register/steps');
                }
                else showToast('app_an_error_occured'); 
            });
        }
        else{
            const tmp_err = validateEntireForm(this.state.formFieldsNames,this.state.formErrors);
            this.setState({formErrors : tmp_err});
        }

    }
    handleFbChange = (e, k, fb_data) => {
        let ff = this.state.formFields;
        let fbp = this.state.fb_pages;
        let fb_disable_manual = false;

        const pages_cnt = document.querySelectorAll("[name='fb_page_id']:checked").length;
        if(pages_cnt > 0){
            //fb_api set to 1
            ff['fb_api'] = 1;
            //save id to fb_page_id and name to fb_page_name
            ff['fb_page_id'] = fb_data.id;
            ff['fb_page_name'] = fb_data.name;

            //check it 
            fbp[k]['checked'] = true;

            //disable manual field mandatory
            fb_disable_manual = true;
        }
        else fbp[k]['checked'] = false;

        this.setState({
            formFields:ff,
            fb_disable_manual:fb_disable_manual,
            fb_pages:fbp
        },() => {
            //Call full auth details
            this.captureFullAuthDetails();
        });

    }
    hasIt($i){
        let has_it = false;
        if(this.state.formFields[$i] === "1" || this.state.formFields[$i] === true) has_it = true;
        return has_it;
    }
    triggerFacebookLogin = async () => {
        const FACEBOOK_PERMISSIONS = ['pages_read_engagement','instagram_basic','instagram_manage_insights','read_insights'];        
        await FacebookLogin.initialize({ appId: '357043501455493' });
        const result = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });
        if(result.accessToken.token !== null){
            const r = await FacebookLogin.getCurrentAccessToken();
            this.captureAuth(r);
        }

    }
    captureAuth = (response) => {

        new Fetcher().callApi({
            api_path:'/profil/capture_auth/fb',
            api_data:{
                auth_response: response,
            }, 
            method:'POST',
        }).then((r) => {
            console.log(r)
            if(r.status === 'success'){
                console.log(r);
                this.setState({
                    fb_pages: r.results.data
                }, () => {  
                    //trigger first page click by default
                    const first_id = r.results.data[0].id;
                    document.getElementById(first_id).click();
                })
                
                
            }
            else {
                console.error(r.results);
                showToast('app_an_error_occured'); 
            }
        });
    }
    captureFullAuthDetails = () => {
        new Fetcher().callApi({
            api_path:'/profil/capture_fb_ig_account',
            api_data:{
                fb_page_id: this.state.formFields.fb_page_id,
                fb_page_name: this.state.formFields.fb_page_name,
            },
            method:'POST',
        }).then((r) => {
            let ff = this.state.formFields;
            if(r.status === 'success'){
                ff.ig_user_id = r.ig_user_id;
                ff.ig_username = r.results.username;
                ff.nb_abonnes_instagram = r.results.followers_count;
                ff.ig_followers_count = r.results.followers_count;
                ff.ig_follows_count = r.results.follows_count;
                ff.ig_profile_picture_url = r.results.profile_picture_url;
                ff.ig_media_count = r.results.media_count;

                this.setState({
                    formFields : ff,
                })
            }
            else showToast('app_an_error_occured'); 
        });
    }
    deleteFbPermission = () => {
        this.setState({
            pendingServer:true
        })
        new Fetcher().callApi({
            api_path:'/profil/delete_fb_permission',
            api_data:{},
            method:'POST',
        }).then((r) => {
            
            this.setState({
                pendingServer:false
            })
            if(r.status === 'success'){                
                this.initForm();
            }
            else showToast(r.message); 
        });
    }
    render() {  
        return !this.state.pendingServer ? this.renderForm() : (       
            <Loading alwaysShow={false} />
        );            
    } 
    renderForm() {
        return (
            <div id="bk_register_steps" className="pb-5">
                <div className="container-fluid bg-white">
                    <div className="header row px-3 py-5">
                        <div className="col text-center">
                            <BackButton />
                            <h2 className="header_title"><Translate s="dropdown_mesreseaux" /></h2>
                        </div>
                    </div>
                </div>
                
                <div className="container pb-9">

                    { /* BLOG */ }
                    <div className="row mt-4 justify-content-center"> 
                        <div className="col-12 col-md-6 ">
                                <fieldset className="form-fieldset">
                                    <label className="form-check form-switch">
                                        <input 
                                            className="form-check-input" 
                                            type="checkbox" 
                                            name="blog"
                                            data-is_required={false}
                                            checked={this.hasIt('blog')}
                                            onChange={(e) => this.handleChange(e)}/>
                                        <span className="form-check-label"><Translate s="lbl_bg" /></span>
                                    </label>

                                    { /*Custom blog fields */ }
                                    <div className={"row mt-4 "+(this.hasIt('blog')?'':'d-none')}> 
                                        <div className="col-12 mb-3">
                                            <label className={"form-label required "}><Translate s="profil_univers_nom_blog" /></label>
                                            <input type="text" className={`form-control ${errorClass(this.state.formErrors.nom_blog)}`} autoComplete="off" data-is_required={this.hasIt('blog')} name="nom_blog" value={this.state.formFields.nom_blog} onChange={(e) => this.handleChange(e)} />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <label className="form-label required"><Translate s="profil_univers_url_blog" /></label>
                                            <input type="text" data-validation="url" className={`form-control ${errorClass(this.state.formErrors.url_blog)}`} autoComplete="off" data-is_required={this.hasIt('blog')} name="url_blog" value={this.state.formFields.url_blog} onChange={(e) => this.handleChange(e)} />
                                            <div className="invalid-feedback"><Translate s="app_err_url" /></div>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <label className="form-label"><Translate s="profil_univers_nb_visiteur_blog" /></label>
                                            <input type="number" className={`form-control ${errorClass(this.state.formErrors.nb_visiteur_blog)}`} autoComplete="off" data-is_required={false} name="nb_visiteur_blog" value={this.state.formFields.nb_visiteur_blog} onChange={(e) => this.handleChange(e)} />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <label className="form-label"><Translate s="profil_univers_nb_page_vue_blog" /></label>
                                            <input type="number" className={`form-control ${errorClass(this.state.formErrors.nb_page_vue_blog)}`} autoComplete="off" data-is_required={false} name="nb_page_vue_blog" value={this.state.formFields.nb_page_vue_blog} onChange={(e) => this.handleChange(e)} />
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>


                        { /* Facebook */ }
                        <div className="row mt-2 justify-content-center"> 
                            <div className="col-12 col-md-6">
                                <fieldset className="form-fieldset">
                                    <label className="form-check form-switch">
                                        <input 
                                            className="form-check-input" 
                                            type="checkbox" 
                                            name="facebook"
                                            data-is_required={false}
                                            checked={this.hasIt('facebook')}
                                            onChange={(e) => this.handleChange(e)}/>
                                        <span className="form-check-label">Facebook</span>
                                    </label>


                                    { /*Custom facebook fields */ }
                                    <div className={"row mt-4 "+(this.hasIt('facebook')?'':'d-none')}> 
                                    
 
                                        <div className="col-12 mb-3">
                                            <p><b><Translate s='label_recommended' /> - <Translate s='label_fb_click' /></b></p>
                                            <button className="fb_connect" onClick={() => this.triggerFacebookLogin()}>Connect to Facebook</button>
                                            {/*<FacebookLogin
                                                appId="357043501455493"
                                                scope="pages_read_engagement,instagram_basic,instagram_manage_insights,read_insights"
                                                style={{
                                                    display:'block',
                                                    backgroundColor: '#4267b2',
                                                    color: '#fff',
                                                    margin:'auto',
                                                    fontSize: '14px',
                                                    padding: '10px 20px',
                                                    border: 'none',
                                                    borderRadius: '4px',
                                                }}
                                                onSuccess={(response) => {
                                                    console.log('Login Success!', response);
                                                    //fb_api
                                                    this.captureAuth(response);
                                                }}
                                                onFail={(error) => {
                                                    console.log('Login Failed!', error);
                                                }}
                                                onProfileSuccess={(response) => {
                                                    console.log('Get Profile Success!', response);
                                                }}
                                            />*/}
                                            <p className="font-italic small  pt-2"><Translate s="txt_private_life" /> <span className="cta-link" onClick={() => this.deleteFbPermission()}><Translate s="cta_click_here" /></span></p>
                                            <div className="ps-2 mt-3 form-selectgroup form-selectgroup-boxes d-flex flex-column">
                                            {(() => {
                                                if(this.state.fb_pages.length > 0){
                                                    return (
                                                    Object.values(this.state.fb_pages).map((ifp,kfp) => {
                                                        return(
                                                            
                                                                <label key={"kfp_"+kfp} className="form-selectgroup-item flex-fill">
                                                                    <input
                                                                        type="radio"
                                                                        name="fb_page_id" 
                                                                        id={ifp.id} 
                                                                        defaultChecked={(ifp.checked !== undefined && ifp.checked)?true:false}
                                                                        value={ifp.id} 
                                                                        data-is_required={true}
                                                                        className="form-selectgroup-input" 
                                                                        onChange={(e) => this.handleFbChange(e,kfp,ifp)}
                                                                    />
                                                                    <div className="form-selectgroup-label d-flex align-items-center p-3">
                                                                        <div className="me-3"><span className="form-selectgroup-check"></span></div>
                                                                        <div>{ifp.name}</div>
                                                                    </div>
                                                                </label>
                                                        )
                                                    })
                                                    )
                                                }                                          
                                            
                                            })()}
                                        </div>


                                        </div>
                                        <p><b><Translate s="label_or_fill_in" /> : </b></p>
                                        <div className="col-12 mb-3">
                                            <label className={"form-label "+((!this.state.fb_disable_manual)?'required':'')}><Translate s="profil_univers_social_url_facebook" /></label>
                                            <input type="text" data-validation="url" className={`form-control ${errorClass(this.state.formErrors.url_facebook)}`} autoComplete="off" data-is_required={((this.hasIt('facebook') && !this.state.fb_disable_manual)?true:false)}  name="url_facebook" value={this.state.formFields.url_facebook} onChange={(e) => this.handleChange(e)} /><div className="invalid-feedback"><Translate s="app_err_url" /></div>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <label className={"form-label "+((!this.state.fb_disable_manual)?'required':'')}><Translate s="profil_univers_social_nb_fan_facebook" /></label>
                                            <input type="number" className={`form-control ${errorClass(this.state.formErrors.nb_fan_facebook)}`} autoComplete="off" data-is_required={((this.hasIt('facebook') && !this.state.fb_disable_manual)?true:false)} name="nb_fan_facebook" value={this.state.formFields.nb_fan_facebook} onChange={(e) => this.handleChange(e)} />
                                        </div>

                                       
                                    </div>
                                </fieldset>
                            </div>
                        </div>

                        { /* INSTAGRAM */ }
                        <div className="row mt-2 justify-content-center"> 
                            <div className="col-12 col-md-6">
                                <fieldset className="form-fieldset">
                                    <label className="form-check form-switch">
                                        <input 
                                            className="form-check-input" 
                                            type="checkbox" 
                                            name="instagram"
                                            data-is_required={false}
                                            checked={this.hasIt('instagram')}
                                            onChange={(e) => this.handleChange(e)}/>
                                        <span className="form-check-label">Instagram</span>
                                    </label>

                                    { /*FB API connected > display ig_user_id, ig_profile_picture_url, ig_username, ig_followers_count   */ }
                                    


                                    <div className={"mt-3 form-selectgroup form-selectgroup-boxes d-flex flex-column "+((this.state.formFields.ig_user_id !== undefined && this.state.formFields.ig_user_id !== '')?'':'d-none')}>
                                        <label className="form-selectgroup-item flex-fill">
                                            <input
                                                type="radio"
                                                className="form-selectgroup-input" 
                                                checked={true}
                                            />
                                            <div className="form-selectgroup-label d-flex align-items-center p-3">
                                                <div className="form-selectgroup-label-content d-flex align-items-center">
                                                    <span className="avatar me-3" style={{backgroundImage:"url("+this.state.formFields.ig_profile_picture_url+")"}}></span>
                                                    <div>
                                                        <div className="font-weight-medium">{this.state.formFields.ig_username}</div>
                                                        <div className="text-muted">{this.state.formFields.ig_followers_count} followers</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>


                                    { /*Custom instagram fields */ }
                                    <div className={"row mt-4 "+(this.hasIt('instagram') && (this.state.formFields.ig_user_id === undefined || (this.state.formFields.ig_user_id 
                                    !== undefined && this.state.formFields.ig_user_id === '')) ?'':'d-none')}> 
                                        <div className="col-12 mb-3">
                                            <label className={"form-label "+((!this.state.fb_disable_manual)?'required':'')}><Translate s="profil_univers_social_compte_instagram" /></label>
                                            <input type="text" data-validation="url" className={`form-control ${errorClass(this.state.formErrors.compte_instagram)}`} autoComplete="off" data-is_required={this.hasIt('instagram')} name="compte_instagram" value={this.state.formFields.compte_instagram} onChange={(e) => this.handleChange(e)} />
                                            <div className="invalid-feedback"><Translate s="app_err_url" /></div>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <label className={"form-label "+((!this.state.fb_disable_manual)?'required':'')}><Translate s="profil_univers_social_nb_abonnes_instagram" /></label>
                                            <input type="number" className={`form-control ${errorClass(this.state.formErrors.nb_abonnes_instagram)}`} autoComplete="off" data-is_required={this.hasIt('instagram')} name="nb_abonnes_instagram" value={this.state.formFields.nb_abonnes_instagram} onChange={(e) => this.handleChange(e)} />
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>

                        { /* YOUTUBE */ }
                        <div className="row mt-2 justify-content-center"> 
                            <div className="col-12 col-md-6">
                                <fieldset className="form-fieldset">
                                    <label className="form-check form-switch">
                                        <input 
                                            className="form-check-input" 
                                            type="checkbox" 
                                            name="youtube"
                                            data-is_required={false}
                                            checked={this.hasIt('youtube')}
                                            onChange={(e) => this.handleChange(e)}/>
                                        <span className="form-check-label">Youtube</span>
                                    </label>

                                    { /*Custom youtube fields */ }
                                    <div className={"row mt-4 "+(this.hasIt('youtube')?'':'d-none')}> 
                                        <div className="col-12 mb-3">
                                            <label className={"form-label required "}><Translate s="profil_univers_social_chaine_youtube" /></label>
                                            <input type="text" data-validation="url" className={`form-control ${errorClass(this.state.formErrors.chaine_youtube)}`} autoComplete="off" data-is_required={this.hasIt('youtube')} name="chaine_youtube" value={this.state.formFields.chaine_youtube} onChange={(e) => this.handleChange(e)} />
                                            <div className="invalid-feedback"><Translate s="app_err_url" /></div>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <label className="form-label required"><Translate s="profil_univers_social_nb_abonnes_youtube" /></label>
                                            <input type="number" className={`form-control ${errorClass(this.state.formErrors.nb_abonnes_youtube)}`} autoComplete="off" data-is_required={this.hasIt('youtube')} name="nb_abonnes_youtube" value={this.state.formFields.nb_abonnes_youtube} onChange={(e) => this.handleChange(e)} />
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>

                        { /* TWITTER */ }
                        <div className={"row mt-2 justify-content-center "+this.state.hide_network}> 
                            <div className="col-12 col-md-6">
                                    <fieldset className="form-fieldset">
                                        <label className="form-check form-switch">
                                            <input 
                                                className="form-check-input" 
                                                type="checkbox" 
                                                name="twitter"
                                                data-is_required={false}
                                                checked={this.hasIt('twitter')}
                                                onChange={(e) => this.handleChange(e)}/>
                                            <span className="form-check-label">Twitter</span>
                                        </label>

                                        { /*Custom twitter fields */ }
                                        <div className={"row mt-4 "+(this.hasIt('twitter')?'':'d-none')}> 
                                            <div className="col-12 mb-3">
                                                <label className={"form-label required "}><Translate s="profil_univers_social_page_twitter" /></label>
                                                <input type="text" data-validation="url" className={`form-control ${errorClass(this.state.formErrors.page_twitter)}`} autoComplete="off" data-is_required={this.hasIt('twitter')} name="page_twitter" value={this.state.formFields.page_twitter} onChange={(e) => this.handleChange(e)} />
                                                <div className="invalid-feedback"><Translate s="app_err_url" /></div>
                                            </div>
                                            <div className="col-12 mb-3">
                                                <label className="form-label required"><Translate s="profil_univers_social_nb_followers_twitter" /></label>
                                                <input type="number" className={`form-control ${errorClass(this.state.formErrors.nb_followers_twitter)}`} autoComplete="off" data-is_required={this.hasIt('twitter')} name="nb_followers_twitter" value={this.state.formFields.nb_followers_twitter} onChange={(e) => this.handleChange(e)} />
                                            </div>
                                        </div>
                                    </fieldset>
                            </div>

                        </div>

                        { /* SNAPCHAT */ }
                        <div className={"row mt-2 justify-content-center "+this.state.hide_network}> 
                            <div className="col-12 col-md-6">
                                    <fieldset className="form-fieldset">
                                        <label className="form-check form-switch">
                                            <input 
                                                className="form-check-input" 
                                                type="checkbox" 
                                                name="snapchat"
                                                data-is_required={false}
                                                checked={this.hasIt('snapchat')}
                                                onChange={(e) => this.handleChange(e)}/>
                                            <span className="form-check-label">Snapchat</span>
                                        </label>

                                        { /*Custom snapchat fields */ }
                                        <div className={"row mt-4 "+(this.hasIt('snapchat')?'':'d-none')}> 
                                            <div className="col-12 mb-3">
                                                <label className={"form-label required "}><Translate s="profil_univers_social_utilisateur_snapchat" /></label>
                                                <input type="text" className={`form-control ${errorClass(this.state.formErrors.utilisateur_snapchat)}`} autoComplete="off" data-is_required={this.hasIt('snapchat')} name="utilisateur_snapchat" value={this.state.formFields.utilisateur_snapchat} onChange={(e) => this.handleChange(e)} />
                                            </div>
                                            <div className="col-12 mb-3">
                                                <label className="form-label required"><Translate s="profil_univers_social_nb_abonnes_snapchat" /></label>
                                                <input type="number" className={`form-control ${errorClass(this.state.formErrors.nb_abonnes_snapchat)}`} autoComplete="off" data-is_required={this.hasIt('snapchat')} name="nb_abonnes_snapchat" value={this.state.formFields.nb_abonnes_snapchat} onChange={(e) => this.handleChange(e)} />
                                            </div>
                                        </div>
                                    </fieldset>
                            </div>
                        </div>

                        { /* TIKTOK  */ }
                        <div className="row mt-2 justify-content-center"> 
                            <div className="col-12 col-md-6">
                                    <fieldset className="form-fieldset">
                                        <label className="form-check form-switch">
                                            <input 
                                                className="form-check-input" 
                                                type="checkbox" 
                                                name="tiktok"
                                                data-is_required={false}
                                                checked={this.hasIt('tiktok')}
                                                onChange={(e) => this.handleChange(e)}/>
                                            <span className="form-check-label">Tiktok</span>
                                        </label>

                                        { /*Custom tiktok fields */ }
                                        <div className={"row mt-4 "+(this.hasIt('tiktok')?'':'d-none')}> 
                                            <div className="col-12 mb-3">
                                                <label className={"form-label required "}><Translate s="profil_univers_social_utilisateur_tiktok" /></label>
                                                <input type="text" className={`form-control ${errorClass(this.state.formErrors.utilisateur_tiktok)}`} autoComplete="off" data-is_required={this.hasIt('tiktok')} name="utilisateur_tiktok" value={this.state.formFields.utilisateur_tiktok} onChange={(e) => this.handleChange(e)} />
                                            </div>
                                            <div className="col-12 mb-3">
                                                <label className={"form-label required "}><Translate s="profil_univers_social_url_tiktok" /></label>
                                                <input type="text" data-validation="url" className={`form-control ${errorClass(this.state.formErrors.url_tiktok)}`} autoComplete="off" data-is_required={this.hasIt('tiktok')} name="url_tiktok" value={this.state.formFields.url_tiktok} onChange={(e) => this.handleChange(e)} />
                                                <div className="invalid-feedback"><Translate s="app_err_url" /></div>
                                            </div>
                                            
                                            <div className="col-12 mb-3">
                                                <label className="form-label required"><Translate s="profil_univers_social_nb_followers_tiktok" /></label>
                                                <input type="number" className={`form-control ${errorClass(this.state.formErrors.nb_followers_tiktok)}`} autoComplete="off" data-is_required={this.hasIt('tiktok')} name="nb_followers_tiktok" value={this.state.formFields.nb_followers_tiktok} onChange={(e) => this.handleChange(e)} />
                                            </div>
                                        </div>
                                    </fieldset>
                            </div>
                        </div>

                        { /* PODCAST  */ }
                        <div className="row mt-2 justify-content-center"> 
                            <div className="col-12 col-md-6">
                                    <fieldset className="form-fieldset">
                                        <label className="form-check form-switch">
                                            <input 
                                                className="form-check-input" 
                                                type="checkbox" 
                                                name="podcast"
                                                data-is_required={false}
                                                checked={this.hasIt('podcast')}
                                                onChange={(e) => this.handleChange(e)}/>
                                            <span className="form-check-label">Podcast</span>
                                        </label>

                                        { /*Custom podcast fields */ }
                                        <div className={"row mt-4 "+(this.hasIt('podcast')?'':'d-none')}> 
                                            <div className="col-12 mb-3">
                                                <label className={"form-label required "}><Translate s="profil_univers_social_url_podcast" /></label>
                                                <input type="text" data-validation="url" className={`form-control ${errorClass(this.state.formErrors.url_podcast)}`} autoComplete="off" data-is_required={this.hasIt('podcast')} name="url_podcast" value={this.state.formFields.url_podcast} onChange={(e) => this.handleChange(e)} />
                                                <div className="invalid-feedback"><Translate s="app_err_url" /></div>
                                            </div>
                                            <div className="col-12 mb-3">
                                                <label className={"form-label required "}><Translate s="profil_univers_social_nom_podcast" /></label>
                                                <input type="text" className={`form-control ${errorClass(this.state.formErrors.name_podcast)}`} autoComplete="off" data-is_required={this.hasIt('podcast')} name="name_podcast" value={this.state.formFields.name_podcast} onChange={(e) => this.handleChange(e)} />
                                            </div>                                            
                                            <div className="col-12 mb-3">
                                                <label className="form-label required"><Translate s="profil_univers_social_nb_auditeur_podcast" /></label>
                                                <input type="number" className={`form-control ${errorClass(this.state.formErrors.nb_auditeur_podcast)}`} autoComplete="off" data-is_required={this.hasIt('podcast')} name="nb_auditeur_podcast" value={this.state.formFields.nb_auditeur_podcast} onChange={(e) => this.handleChange(e)} />
                                            </div>
                                        </div>
                                    </fieldset>
                            </div>
                        </div>
                        
                        { /* UGC  */ }
                        <div className={"row mt-2 justify-content-center "+this.state.hide_network}> 
                            <div className="col-12 col-md-6">
                                    <fieldset className="form-fieldset">
                                        <label className="form-check form-switch">
                                            <input 
                                                className="form-check-input" 
                                                type="checkbox" 
                                                name="ugc"
                                                data-is_required={false}
                                                checked={this.hasIt('ugc')}
                                                onChange={(e) => this.handleChange(e)}/>
                                            <span className="form-check-label">UGC (Création de contenu)</span>
                                        </label>

                                        { /*Custom UGC fields */ }
                                        <div className={"row mt-4 "+(this.hasIt('ugc')?'':'d-none')}> 
                                            <div className="col-12 mb-3">
                                                <label className={"form-label "}><Translate s="profil_univers_social_url_ugc" /></label>
                                                <input type="text" data-validation="url" className={`form-control ${errorClass(this.state.formErrors.url_ugc)}`} autoComplete="off" data-is_required={false} name="url_ugc" value={this.state.formFields.url_ugc} onChange={(e) => this.handleChange(e)} />
                                                <div className="invalid-feedback"><Translate s="app_err_url" /></div>
                                            </div>
                                            <div className="col-12 mb-3">
                                                <label className={"form-label"}><Translate s="profil_univers_social_brand_ugc" /></label>
                                                <input type="text" className={`form-control ${errorClass(this.state.formErrors.brand_ugc)}`} autoComplete="off" data-is_required={false} name="brand_ugc" value={this.state.formFields.brand_ugc} onChange={(e) => this.handleChange(e)} />
                                            </div>    

                                            <div className="col-12 mb-3">
                                                <label className={"form-label required "}><Translate s="profil_univers_social_pic_ugc" /></label>

                                                <div className={"row justify-content-center align-items-end "+errorClass(this.state.formErrors.pic_ugc)}>
                                                {[...Array(this.state.topPicUgc)].map((x, i) => { 
                                                    let pic_url = '';
                                                    if(this.state.formFields['pic_ugc_'+i] !== undefined) pic_url = this.state.formFields['pic_ugc_'+i];
                                                    return (
                                                        <UploadImage 
                                                            setFormFields={this.setFormFields}
                                                            db_field={"pic_ugc_"+i}
                                                            name="pic_ugc"
                                                            api_endpoint="/helpers/upload_ugc_images"
                                                            api_delete_endpoint="/helpers/delete_user_meta"
                                                            image_folder='medias_ugc'
                                                            pic_url={pic_url}
                                                            is_required={this.hasIt('ugc')}
                                                            tot_images={this.state.topPicUgc}
                                                            setTotImages={this.setTotImages}
                                                            min_images={this.state.minPicUgc}
                                                        />
                                                    )
                                                }
                                                )}                                                
                                                </div><div class="invalid-feedback"><Translate s="error_img_cnt_min" rep={[this.state.minPicUgc]} /></div>
                                                
                                            </div>                                            
                                            <div className="col-12 mb-3">
                                                <label className="form-label required"><Translate s="profil_univers_social_strength_ugc" /></label>
                                                <input type="text" className={`form-control ${errorClass(this.state.formErrors.strength_ugc)}`} autoComplete="off" data-is_required={this.hasIt('ugc')} name="strength_ugc" value={this.state.formFields.strength_ugc} onChange={(e) => this.handleChange(e)} />
                                            </div>
                                        </div>
                                    </fieldset>
                            </div>
                        </div>



                       
                    <div className="fixed-bottom bg-white gradient-top">
                        <div className={"btn-bottom-ctnr d-grid p-3 mt-4 "+(this.state.readOnly?'d-none':'')}>
                        {renderButton((e) => this.handleSubmit(e))}
                        </div>
                    </div>

                </div>

            </div>
            
        )
    }  
}

export default StepReseau;