import React from 'react';
import {Link} from "react-router-dom";

import Translate from './Helpers/Translate';
import logo from './Assets/img/default_logo.png';
import orange_arrow from './Assets/img/orange_arrow.png';

import { HiOutlineMail,HiLockClosed } from 'react-icons/hi';
//import {isValidDate} from './Helpers/Formvalidation';
import { showToast,showDomError } from './Helpers/Toast';
import { getLocalStorageItem, setLocalStorageItem,deleteLocalStorageItem } from './Helpers/Storage';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';


import { Device } from '@capacitor/device';
import { FCM } from "@capacitor-community/fcm";
import {PushNotifications } from '@capacitor/push-notifications';
import Fetcher from './Helpers/Fetcher';
import { Browser } from '@capacitor/browser';

import {renderTranslationButtons} from './Helpers/Translation';


class Login extends React.Component {  
    
    constructor(props) {
        super(props);
        
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        
        this.state = {
            email: '',
            password: '',
            toastState: false,
            toastMessage: 'Message',
            action: "Login",
            actionText: "Se connecter",
            //new
            formFields: {email: '', password: ''},
            formErrors: {email: '', password: ''},
            app_langage:'',
            platform:'mobile',

        };   
    }
    componentDidMount(){
        deleteLocalStorageItem("bk_user_info"); //force flush
        SecureStoragePlugin.clear(); //force flush
        deleteLocalStorageItem('impersonating');
        deleteLocalStorageItem('impersonating_details');
        
        //Get URL params for legacy website redirection
        /*console.log(this.props.location);
        console.log(new URLSearchParams(this.props.location.search).get("s"));*/

        //Set language selector
        this.getLocalisationLang();

        //TEMP show dekstop welcome message
        this.logDeviceInfo().then((platform) => { 
            this.setState({platform:platform});
        });
    }
    handleChange(e) {
        
        const id = e.target.id;
        const value = e.target.value;
        this.setState({
            [id]: value,
            formErrors : {
                ...this.state.formErrors,
                [id]:''
            }
        });
    }
    errorClass(error) {
        return(error.length === 0 ? '' : 'is-invalid');
    }
    handleSubmit() {
        //Chech data
        if(this.validateForm()){
            this.login();
            //if(this.state.formAction === 'login') this.login();
            //else this.editChild();
        }        

    }
    validateForm() {   
        let fieldValidationErrors = this.state.formErrors;
        let formIsValid = true;        
        Object.keys(this.state.formFields).forEach(fieldName => {
            var value = this.state[fieldName];
            if(fieldName === 'email'){
                let emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                fieldValidationErrors.email = emailValid ? '' : 'error';
                if(!emailValid) formIsValid = false;
            }
            if(fieldName === 'password'){
                let passwordValid = (!value.trim().length)?false:true;
                fieldValidationErrors.password = passwordValid ? '': 'error';
                if(!passwordValid) formIsValid = false;
            }
          })
          this.setState({formErrors: fieldValidationErrors});
          return formIsValid;
    }
    logDeviceInfo = async () => {
        const info = await Device.getInfo();
      
        return info.platform;
    }
    login= () => {
        let url , credentials;     
        //if(this.state.action  === 'Login'){
            url = process.env.REACT_APP_API_URL + '/auth/login';
            credentials = {
                "email": this.state.email,
                "password": this.state.password
            }
        //} 
        /*else {
            url = process.env.REACT_APP_API_URL + '/auth/register';
            credentials = {
                "email": this.state.email,
                "password": this.state.password,
            }
        }*/
        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            body: JSON.stringify(credentials)
        })
        .then((res) => {
            if(res.status === 200) return res.json();
            else showToast("Error Logging in");            
        })
        .then(
            (result) => {
                if(result.status === 'error') showToast(result.message);
                else if(result.status === 'error_dom') showDomError(result.message);
                else{
                    const login_time = new Date().getTime();
                    result.results.isLogin = true;
                    result.results.login_time = login_time;

                    SecureStoragePlugin.set({key:"bk_tokens_token", value:result.results.token}).then(success => console.log(success));
                    SecureStoragePlugin.set({key:"bk_tokens_refresh_token", value:result.results.refresh_token}).then(success => console.log(success));
                    delete result.results.token;
                    delete result.results.refresh_token;
                    setLocalStorageItem("bk_user_info",JSON.stringify(result.results))
                        .then(
                            () => {
                                //register push noti tkn                                                        
                                //Init push noti
                                this.logDeviceInfo().then((platform) => { 
                                    if(platform !== 'web'){
                                        //Notification check
                                        PushNotifications.checkPermissions().then((res) => {
                                            if (res.receive !== 'granted') {
                                                PushNotifications.requestPermissions().then((res) => {
                                                    if (res.receive === 'denied') {
                                                        showToast('Push Notification permission denied');
                                                    }
                                                    else {
                                                        showToast('Push Notification permission granted');
                                                        this.registerNotification();
                                                    }
                                                });
                                            }
                                            else {
                                                this.registerNotification();
                                            }
                                        });  
                                    }
                                });
                                //if local storage redir
                                if(localStorage.getItem('redir') !== null && localStorage.getItem('redir') !== '/lock') {
                                    const redir = localStorage.getItem('redir');
                                    localStorage.setItem('redir',null);
                                    this.props.history.push(redir);
                                }
                                else this.props.history.push('/'); //else go to campaigns page
                               

                        });                    
                }
            },
            (error) => {
                console.error(error);                
                showToast(error.toString());

            }
        )
    }
    registerNotification = () => {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
        
        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
            (push_token) => {
                //save token
                new Fetcher().callApi({
                    api_path:'/helpers/update_user_meta',
                    api_data:{
                        "metas":{
                            "push_token": push_token.value
                        }
                    },
                    method:'POST',
                }).then((r) => {
                     // now you can subscribe to a specific topic
                    //TODO BACKLOG add logic if app is open to brands as well
	                if(r.status === 'success'){
                        FCM.subscribeTo({ topic: "all_influencers" })
                        .then((rtkn) => console.log(rtkn))
                        .catch((err) => console.log(err));
                        
                        //showToast('Push registration success:' + push_token.value)
                    }
	                //else {showToast('Push registration DB error')}
                });
                
            }
        );

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
            (error) => {
                //alert('Error on registration: ' + JSON.stringify(error));
            }
        );

    }
    openCapacitorSite = async (url) => {
      await Browser.open({ url: url });
    }
    getLocalisationLang = async () => {
        //This fetch local storage lang to set flag as active   
        const localisation_lang = await getLocalStorageItem('localisation_lang');
        this.setState({
            app_langage:localisation_lang,
        })
    }
    render() {  
        return (
            <div id="bk_login" className="bg_blur h-100 container">
                <main className="form-signin">
                        <img className="mb-4" src={logo} alt="" width="90" />
                        <h1 className="h3 mb-4 fw-normal"><Translate s='app_welcome' /></h1>
                        <p className="fst-italic fs-7"><Translate s="app_sub_header" /></p>
                        {/*(() => {
                            if(this.state.platform === 'web') {
                                return (
                                    <div className="alert alert-primary"><Translate s="app_welcome_new" /></div>
                                )
                            }
                        })()*/}
                        <div id="error_dom" className="alert alert-danger text-start d-none"></div>
                        <div className="form-floating">
                            <input type="email" className={"form-control "+this.errorClass(this.state.formErrors.email)} id="email" placeholder="name@example.com" value={this.state.email}  onChange={this.handleChange}  tabIndex={1} />
                            <label htmlFor="email"><HiOutlineMail /> <Translate s="auth_email" /></label>
                        </div>
                        <br />
                        <div className="form-floating">
                            <input type="password" className={"form-control "+this.errorClass(this.state.formErrors.password)} id="password" placeholder="Password" value={this.state.password}  onChange={this.handleChange}  tabIndex={2} />
                            <label htmlFor="password"><HiLockClosed /> <Translate s="auth_pwd" /></label>
                        </div>

                        <Link to={'/auth/pwd_forget'} className="btn-text btn-text-primary float-end"><Translate s='auth_forgotpassword' /></Link>

                        { /* <div className="checkbox mb-3 text-start">
                            <label>
                                <input type="checkbox" value="remember-me" /> Remember me for 14 days
                            </label>
        </div> */ }
                        <button className="mt-4 w-100 btn btn-lg btn-primary"  tabIndex={3} onClick={() => this.handleSubmit()}><Translate s="auth_login_cta" /></button>
                        
                        { 
                        //TODO change to /auth/split
                        <p className="mt-5 mb-3">
                            <Link to={'/auth/register'} className="cta_orange cta_bordered_orange text-andesmedium" >
                                <Translate s="auth_cta_existingaccount_1" /> 
                                <img src={orange_arrow} alt="" width="25" className="d-inline-block align-top mx-2" />
                                <Translate s="auth_cta_existingaccount_2" />
                            </Link>
    </p> }
                        <p className='mt-4 mb-3'>
                        {renderTranslationButtons(this.state.app_langage)}
                        </p>
                </main>
            </div>
        )
    }  
}

export default Login;