import React from 'react';
import { showToast } from '../../Helpers/Toast';
import BackButton from "../../Components/BackButton";
import Fetcher from '../../Helpers/Fetcher';
import Loading from '../../Components/Loading';
import {renderTranslationButtons} from '../../Helpers/Translation';
import Translate from '../../Helpers/Translate';
import {getLocalStorageItem} from '../../Helpers/Storage';

import {validateField } from '../../Helpers/Formvalidation';
import {genRadiogroupBoxes} from '../../Forms/GenRadiogroupBoxes';

class AlertsLanguage extends React.Component {  
    
    constructor(props) {
        super(props);
        
        this.state = {
            isLoaded:false,
            error:false,
            formfields:{
                prefered_lang:'',
            },
            formErrors:{
                prefered_lang:'',
            },
            app_langage:'',
        };   
    }
    componentDidMount(){       
        this.fetchConfig();
        this.getLocalisationLang();

    }
    getLocalisationLang = async () => {
        //This fetch local storage lang to set flag as active   
        const localisation_lang = await getLocalStorageItem('localisation_lang');
        this.setState({
            app_langage:localisation_lang,
        })
    }
    fetchConfig(){
        new Fetcher().callApi({
            api_path:'/helpers/get_user_data',
            api_data:{},
            method:'GET',
        }).then((r) => {
            if(r.status === 'success'){
                console.log(r.results);
                this.setState({
                    isLoaded:true,
                    error:false,
                    formfields:{
                        prefered_lang:r.results.prefered_lang
                    }
                });

            }
            else {
                this.setState({
                    isLoaded: true,
                    error:true
                });  
            }
        });
    } 
    handleChange = (evt) => {   
        console.log(evt);       
        const name = evt.target.name;
        const type = evt.target.type;
        const value = type === "checkbox" ? evt.target.checked : evt.target.value;   
        console.log(name);
        console.log(value);
        this.setState({
            formfields:{
                ...this.state.formfields,
                [name]: value,
            }
        }, () => {
            //Errors
            const err = validateField(evt.target);
            console.log(err);
            if(err.fieldValid){
                this.saveChange();
            }
            else {
                this.setState({
                    formErrors : {
                        ...this.state.formErrors,
                        [name]:(err.fieldValid?'success':'error')
                    }
                });
            }

        });

    }
    saveChange = () => {
        new Fetcher().callApi({
            api_path:'/helpers/update_language',
            api_data:{"formfields": this.state.formfields},
            method:'POST',
        }).then((r) => {
            if(r.status === 'error'){
                //showToast('Vous avez des campagnes actives');
            }
            else{
                showToast('app_well_saved');
            }
        });       
                
        
    }
        
    render() {  
        return this.state.isLoaded ? this.renderForm() : (   
            <div id="bk_alertssettings" className="container">
                
                <div className="row header header_icon">
                    <div className="col px-0">
                        <BackButton />
                        <div className="icon"></div>
                    </div>
                </div>
    
                <Loading alwaysShow={false} />
            </div>
        );            
    } 
    
    renderForm() {  
        return (
            <div id="bk_alertssettings" className="container  bg-white min-vh-100">
                

                <div className="row header header_icon">
                    <div className="col px-0">
                        <BackButton />
                        <div className="icon"></div>
                    </div>
                </div>


                <div className="row mt-5 mb-1">
                    <div className="col">
                        <h1 className="text-center text-andeslight mt-2 py-2 font-14"><Translate s="app_my_languages" /></h1>
                    </div>
                </div>
                <div className="row mt-3 pb-3"> 
                    <div className="col px-4">   
                        <label class="form-label"><Translate s={'app_language'} /> :</label> 
                        <p className="text-center">{renderTranslationButtons(this.state.app_langage)}</p>
                    </div>
                </div>

                <div className="row pb-7"> 
                    <div className="col px-4">
                        {genRadiogroupBoxes({
                            label:'profil_compte_languages',
                            name:'prefered_lang',
                            defaultValue:this.state.formfields.prefered_lang,
                            onChangeAction:(e) => this.handleChange(e),
                            data:[{
                                key:'EN',
                                value:'English'
                            },{
                                key:'FR',
                                value:'French'
                            }]
                        })}                        
                    </div>
                </div>
                
            </div>
        )
    }  
}

export default AlertsLanguage;