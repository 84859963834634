import React from 'react';

class Intro extends React.Component {  
    
    render() {  
        return (
            <div id="cv_information" className="bg_blur h-100 container">
                <div className="row px-3 pt-5">
                    <div className="col-8">
                        <h2 className="header_title">Intro</h2>
                    </div>
                    
                    <div className="row px-1 pt-4"> 
                        <div className="col-12">
                           


                            </div>
                    </div>
                    </div>
            </div>
        )
    }  
}

export default Intro;